 /* eslint-disable jsx-a11y/anchor-is-valid */
 import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { toAbsoluteUrl } from '../../../_monic/helpers';
import { Settings } from '../../utils/config';
import { getDownloadOnboardingDoc } from '../../modules/auth/redux/AuthCRUD';

  
 const LoadPdf: React.FC<{}> = () => {
  let {id}: any = useParams();  
   const [error, setError] = useState<string | null>(null);
 
   useEffect(() => {
    getDownloadOnboardingDoc(id).then(({data}) => window.location.replace(URL.createObjectURL(new Blob([data], {type: "application/pdf"})))).catch(e => setError('Cannot load the PDF'));
   }, []);

   return (
    <div
      className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
    >
      {/* begin::Content */}
      <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
        {/* begin::Wrapper */}
        <div className='w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>
              <div role='form' aria-label='submit' className='text-center'>
              {error ? (
                <span style={{display: 'block'}}>
                  {error}
                </span>
              ) : (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
          </div>
        </div>
        {/* end::Wrapper */}
      </div>
      {/* end::Content */}
    </div>
   )
 }
 
 export {LoadPdf}
 