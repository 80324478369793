import { AxiosResponse } from 'axios'
import React, { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {Settings} from '../../../utils/config';
import {
  StatisticsWidget1, StatisticsWidget5
} from '../../../../_monic/partials/widgets'
import { HistoricalIssueCounts } from '../../../../_monic/partials/widgets/mixed/HistoricalIssueCounts'
import { NCStatsWidget } from '../../../../_monic/partials/widgets/tables/NCStatsWidget'
import { OrganizationModel } from '../../auth/models/OrganizationModel'
import { WebsiteDashboard } from '../../auth/models/TestRunModel'
import { viewOrganization } from '../../auth/redux/OrganizationCRUD'
import { getWebsiteDashboard } from '../../auth/redux/TestRunCRUD'
import { Autocomplete, TextField } from '@mui/material';

type Props = {
  testScheduleId?: number
  url?: string
  testMode?: string
  wcagVersion?: string
  wcagLevel?: string
}

const Fetcher: FC<{testMode: string, wcagVersion: string, wcagLevel: string, organization?: string, status?: string, filterButtonText: string, filterButtonOnClick: any}> 
  = ({testMode, wcagVersion, wcagLevel, organization, status, filterButtonText, filterButtonOnClick}) => {
  const [testModeFilter, setTestModeFilter] = useState<string>(testMode);
  const [wcagVersionFilter, setWcagVersionFilter] = useState<string>(wcagVersion);
  const [wcagLevelFilter, setWcagLevelFilter] = useState<string>(wcagLevel);
  const [organizationFilter, setOrganizationFilter] = useState<string | undefined>(organization);
  const [statusFilter, setStatusFilter] = useState<string | undefined>(status);
  const [orgData, setOrgData] = useState<Array<OrganizationModel>>();
  const [dataError, setDataError] = useState<{error: string}>();

  useEffect(() => {
    if (organization != null) {
      viewOrganization().then((result) => setOrgData(result.data)).catch(e => setDataError({error: "Server Error! Cannot fetch org data!"}))
    }
  }, []);
  
  return (
  <div className='card card-body'>
    <div className='card-header border-0 pt-5' style={{
      backgroundColor: 'var(--color-gray-200)',
      padding: 20,
    }}>
      <div className='d-flex flex-column align-items-start me-4'>
          <label htmlFor='s_mode'>
            <b>Test Mode</b>
          </label>
          <select
            id='s_mode'
            name='mode'
            data-hide-search='true'
            className='form-select form-select-sm w-200px'
            defaultValue={testModeFilter}
            onChange={e => setTestModeFilter(e.target.value)}
          >
            <option value='-1'>All</option>
            <option value='single_page'>Single Page</option>
            <option value='full_site'>Full Site</option>
          </select>
      </div>
      <div className='d-flex flex-column align-items-start me-4'>
      <label htmlFor='s_wcagVersion'>
        <b>WCAG Version</b>
      </label>
      <select
        id='s_wcagVersion'
        data-hide-search='true'
        className='form-select form-select-sm w-200px'
        name='wcagVersion'
        defaultValue={wcagVersionFilter}
        onChange={e => setWcagVersionFilter(e.target.value)}
      >
        <option value='-1'>All</option>
        <option value='WCAG 2.0 without ARIA'>WCAG 2.0 without ARIA</option>
        <option value='WCAG 2.1 without ARIA'>WCAG 2.1 without ARIA</option>
        <option value='WCAG 2.0'>WCAG 2.0</option>
        <option value='WCAG 2.1'>WCAG 2.1</option>
      </select>
      </div>
      <div className='d-flex flex-column align-items-start me-4'>
      <label htmlFor='s_wcagLevel'>
      <b>WCAG Level</b>
      </label>
      <select
        id='s_wcagLevel'
        data-hide-search='true'
        className='form-select form-select-sm w-200px'
        name='wcagLevel'
        defaultValue={wcagLevelFilter}
        onChange={e => setWcagLevelFilter(e.target.value)}
      >
        <option value='-1'>All</option>
        <option value='Level A'>Level A</option>
        <option value='Level AA'>Level AA</option>
        <option value='Level AAA'>Level AAA</option>
      </select>
      </div>
      {status == null ? null : 
      <div className='d-flex flex-column align-items-start me-4'>
      <label htmlFor='s_status'>
      <b>Status</b>
      </label>
      <select
        id='s_status'
        data-hide-search='true'
        className='form-select form-select-sm w-200px'
        name='status'
        defaultValue={statusFilter}
        onChange={e => setStatusFilter(e.target.value)}
      >
        <option value='-1'>All</option>
        <option value='PENDING'>PENDING</option>
        <option value='RUNNING'>RUNNING</option>
        <option value='PARTIALLY COMPLETE'>PARTIALLY COMPLETE</option>
        <option value='COMPLETE'>COMPLETE</option>
        <option value='FAILURE'>FAILURE</option>
      </select>
      </div>
      }
      {organization == null ? null : 
      <div className='d-flex flex-column align-items-start me-4'>
        <label htmlFor='s_org'>
          <b>Organization</b>
        </label>
        <Autocomplete
          className=' w-200px'
          disablePortal
          id="combo-box-demo"
          options={orgData ? orgData.map(org => ({label: org.name, id: org.id})) : []}
          sx={{ width: 300 }}
          onChange={(event: any, newValue: any) => {
            setOrganizationFilter(newValue ? newValue.id : -1)
          }}
          renderInput={(params) => <TextField {...params} variant="standard" />}
        />
      </div>
      }
      <a href='javascript:void()' className='btn btn-lg btn-primary my-3 ' onClick={() => {
        const testMode = testModeFilter == '-1' ? undefined : testModeFilter;
        const orgId = organizationFilter == '-1' ? undefined : organizationFilter;
        const status = statusFilter == '-1'? undefined : statusFilter;
        const wcagLevel = wcagLevelFilter == "-1" ? undefined : wcagLevelFilter;
        const wcagVersion = wcagVersionFilter == "-1" ? undefined : wcagVersionFilter;
        filterButtonOnClick(testMode, wcagVersion, wcagLevel, orgId, status);
      }}>
        {filterButtonText}
      </a>
    </div>
    </div>
  )
};

const fetchData = (baseUrl: string, testMode: string, wcagVersion: string, wcagLevel: string,
  setData: React.Dispatch<React.SetStateAction<WebsiteDashboard | undefined>>, setDataError: React.Dispatch<React.SetStateAction<DataError | undefined>>) => 
  getWebsiteDashboard({
    baseUrl, 
    testMode, 
    wcagVersion, 
    wcagLevel,
  }).then((result: AxiosResponse) => setData(result.data)).catch(e => setDataError({error: "Server Error! Cannot fetch data!"}));

    //filter by page, datetime range, test options

    // Issues trend, Categories trend (dropdown), Latest Outstanding NCs and resolution status with notes with age, Whole list of Resolved NCs and resolution status with notes with time to resolve
    //stats.cntTotalIssues
    //stats.score
    //stats.issueCntByCat
    //stats.cntTotalFailedTests
    //noncompliance by latest test run id by status not complete
    //noncompliance resolved for the test runs

    // list of unique non compliances with first seen test instance id, status and last seen test instance id, status

const OverviewTab: FC<Props> = ({testScheduleId, url: _url, testMode = 'single_page', wcagVersion = 'WCAG 2.1', wcagLevel = 'Level A'}) => {
  const {url: encodedUrl}: any = useParams();
  const [data, setData] = useState<WebsiteDashboard>();
  const [dataError, setDataError] = useState<DataError>();
  const url = _url ? _url : decodeURIComponent(encodedUrl);
  const fetchReport = (testModeFilter: string, wcagVersionFilter: string, wcagLevelFilter: string) => {
    if (testScheduleId) {
      getWebsiteDashboard({
        testScheduleId,
      }).then((result: AxiosResponse) => setData(result.data)).catch(e => setDataError({error: "Server Error! Cannot fetch data!"}));
    } else {
      fetchData(url, testModeFilter, wcagVersionFilter, wcagLevelFilter, setData, setDataError);
    }
  };
  useEffect(() => fetchReport(testMode, wcagVersion, wcagLevel), []);

  

  if (data == null || data.trends.length == 0) {
    return (
      <div className='card mb-5 mb-xl-10'>
        <div className='card row'>
        {testScheduleId != null ? null : 
        <Fetcher wcagLevel={wcagLevel} wcagVersion={wcagVersion} testMode={testMode} filterButtonText='Filter' filterButtonOnClick={fetchReport} />}
      <div className='card-body  my-2'>
        No data to display
      </div>  
      </div>
      </div>
      )
  }
  const failedTestTrends = data.trends.map(trend => {
    if (trend == null) {
      return 0;
    }
    const failedTest = new Set();
    for (const nc of trend.nc) {
      failedTest.add(nc.auditId);
    }
    return failedTest.size;
  });
  
  const xAxis = [];
  const yAxis = [];
  for (const trend of data.trends) {
    xAxis.push(new Date(trend.created).toLocaleDateString());
    yAxis.push(trend.cntTotalIssues);
  }
  const issueTrendData = {
    xAxis,
    ySeries: [
      {
        name: 'Issues count',
        data: yAxis,
      }
    ],
    max: data.trends[0].cntTotalIssues,
  };

  const failedTestTrendData = {
    xAxis,
    ySeries: [
      {
        name: 'Test Failure count',
        data: failedTestTrends,
      }
    ],
    max: failedTestTrends[failedTestTrends.length - 1],
  };

  const maxIssueCat = data.trends[0].issueCntByCat == null ? null : data.trends[0].issueCntByCat[0];
  const issueCntByCat: Record<string, Array<number>> = {};

  for (const c of data.categoriesCumulative) {
    issueCntByCat[c] = new Array(data.trends.length).fill(0);
  }

  for (let i = 0; i < data.trends.length; i++) {
    const t = data.trends[i];
    if (t.issueCntByCat == null) {
      continue;
    }
    for (const c of t.issueCntByCat) {
      issueCntByCat[c.category][i] = c.count;
    }
  }

  const cYSeries = data.categoriesCumulative.map(cat => {
    return {
      name: cat,
      data: issueCntByCat[cat]
    }
  });

  const categoryTrendData = {
    xAxis,
    ySeries: cYSeries,
    max: maxIssueCat ? maxIssueCat.category : '',
    yAxisMax: maxIssueCat ? maxIssueCat.count * 2 : 0,
  };

  return (
    <div className='mb-15'>
      <div className='card row mb-5 mb-xl-10'>
        {testScheduleId != null ? null : 
        <Fetcher wcagLevel={wcagLevel} wcagVersion={wcagVersion} testMode={testMode} filterButtonText='Filter' filterButtonOnClick={fetchReport} />}
      </div>
      {/* begin::Row */}
      <div className='row g-5 g-xl-8'>
        <div className='col-xl-4' style={{paddingLeft: 0}}>
          <HistoricalIssueCounts
            className='mb-5 mb-xl-8'
            chartColor='primary'
            chartHeight='150px'
            chartData={{
              xAxis: data.trends.map(trend => new Date(trend.created).toLocaleDateString()),
              //yAxis: data.trends.map(trend => trend.score),
              max: data.trends[data.trends.length - 1].score,
              ySeries: [
                {
                  name: 'Score',
                  data: data.trends.map(trend => trend.score),
                }]
            }}
            title='Score Trends'
            subTitle='Score trend over the period of time'
            maxText='Latest Score: '
          />
        </div>

        <div className='col-xl-4'>
          <HistoricalIssueCounts
            className='mb-5 mb-xl-8'
            chartColor='primary'
            chartHeight='150px'
            chartData={issueTrendData}
            title='Issue Trends'
            subTitle='Issue trend over the period of time'
            maxText='Latest Issue #: '
          />
        </div>

        <div className='col-xl-4' style={{paddingRight: 0}}>
          <HistoricalIssueCounts
            className='mb-5 mb-xl-8'
            chartColor='primary'
            chartHeight='150px'
            chartData={failedTestTrendData}
            title='Test Failure Trends'
            subTitle='Test Failures trend over the period of time'
            maxText='Latest Run: '
          />
        </div>
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      <div className='bg-white row g-5 g-xl-8 my-5'>
        <div className='col-xl-4'>
          <StatisticsWidget5
            className='card-xl-stretch mb-xl-8'
            svgIcon='/media/icons/duotune/general/gen032.svg'
            color='danger'
            iconColor='white'
            title={data.trends[0].cntTotalIssues + ""}
            description='Total Issues'
          />
        </div>

        <div className='col-xl-4'>
          <StatisticsWidget5
            className='card-xl-stretch mb-xl-8'
            svgIcon='/media/icons/duotune/ecommerce/ecm008.svg'
            color='info'
            iconColor='white'
            title={data.trends.length + ""}
            description=' Number of Runs'
          />
        </div>

        <div className='col-xl-4'>
          <StatisticsWidget5
            className='card-xl-stretch mb-xl-8'
            svgIcon='/media/icons/duotune/finance/fin006.svg'
            color='primary'
            iconColor='white'
            title={data.trends[0].cntTests + ''}
            description='Total Tests In Each Run'
          />
        </div>
      </div>
      {/* end::Row */}

      <div className='row g-5 g-xl-8'>
        <NCStatsWidget className='card-xxl-stretch mb-5 mb-xl-8' 
        title='Non Compliance Issues'
        subTitle='Top outstanding issues'
        ncList={data.ncList} latestTestRunId={data.trends[data.trends.length - 1].runId} />
      </div>

      <div className='row g-5 g-xl-8'>
      <HistoricalIssueCounts
            className='mb-5 mb-xl-8'
            chartColor='primary'
            chartHeight='150px'
            chartData={categoryTrendData}
            title='Category Trends'
            subTitle='Category trends over the period of time'
            maxText='Category with Max Issues: '
          />
      </div>

      {/* begin::Row */}
      {/* <div className='row g-5 g-xl-8'>
        {[].slice(0, 3).map((x: any, i: any) => (
          <div className='col-xl-4'>
            <StatisticsWidget1
            className='card-xl-stretch mb-xl-8'
            image='abstract-2.svg'
            title={`#${i + 1} most frequently seen issue`}
            time={`${x.count} issues`}
            description={x.title}
          />
          </div>
        ))}
      </div> */}
      {/* end::Row */}

     
      {/* begin::Row */}
      <div className='row g-5 g-xl-8'>
        {[].slice(0, 3).map((x: any, i: any) => (
          <div className='col-xl-4'>
            <StatisticsWidget1
              className='card-xl-stretch mb-xl-8'
              image='abstract-1.svg'
              title={`#${i + 1} Category with highest issue count`}
              time={`${x.count} issues`}
              description={x.category}
            />
          </div>
        ))}
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      <div className='row g-5 g-xl-8'>
        {[].slice(0, 3).map((x: any, i: any) => (
          <div className='col-xl-4'>
            <StatisticsWidget1
              className='card-xl-stretch mb-xl-8'
              image='abstract-4.svg'
              title={`#${i + 1} Page with highest issue count`}
              time={`${x.count} issues`}
              description={`${x.title}`}
              timeColor='black'
            />
          </div>
        ))}
      </div>
      {/* end::Row */}
    </div>
  )
}

export { Fetcher, OverviewTab }

