export default function setupAxios(axios: any, store: any) {
  axios.interceptors.request.use(
    (config: any) => {
      const {auth} = store.getState()
      const user = auth.user;
      if (user) {
        config.headers.pUserName = user.userName;
        config.headers.pSessionId = user.sessionId;
        config.headers.pBrowserId = user.browserId;
        config.headers.pLocalTokenId = user.localTokenId;
      }

      return config
    },
    (err: any) => Promise.reject(err)
  )
}
