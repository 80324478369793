import { FC} from "react";
import { Link } from "react-router-dom";
import { Settings } from "../app/utils/config";
import { MobileLayout } from "./MobileLayout";

export const MobileAbout: FC = () => {
    return (
        <MobileLayout>

        <div className="card card-style">
            <div className="content">
                <div className="border-green-dark ps-3 border-start border-top-0 border-bottom-0 border-end-0">
                    <p>
                    The power of the Web is in its universality. Access by everyone regardless of disability is an essential aspect. 
                    As the web reshapes our world, we have a responsibility to make sure it is recognized as a human right and built for the public good.
                    </p>
                    <strong>Tim Berners-Lee, W3C Director</strong>
                </div>        
            </div>
        </div>

        <div className="card card-style">
            <div className="content">
                <h6 className="color-highlight text-uppercase font-700 mb-1">GENESIS OF</h6>
                <h1 className="text-uppercase font-900 mt-n2">SUGAMYAWEB</h1>
                <p>To enhance the accessibility of the Indian Govt. webspace, the <b>Ministry of Electronics &amp; Information Technology (MeitY)</b> organized an <b>Innovation Challenge</b> in 2021-22. Many Indian
                                    startups participated in multiple stages of the challenge – Ideation, Building a Minimum
                                    Viable Product (MVP) and Functional Product. {Settings.GamyataLabel} emerged as the <b>winner</b> of
                                    the Innovation Challenge in 2022.</p>
                                <p>{Settings.GamyataLabel} is an automated tool for improving the accessibility of web pages. It leverages
                                    automation and system
                                    intelligence to drive testing and decision making ensuring accuracy. It generates audit
                                    reports with guidance and
                                    recommendations, root cause analysis and advanced visualization.</p>
                                <p><strong><em>To use {Settings.GamyataLabel}, Indian Government entities must nominate an Accessibility
                                            Compliance Nodal Officer, by
                                            sharing details at the email address support-sugamya[at]nic[dot]in. 
                                            </em> </strong></p>

            </div>
        </div>

        <div className="card card-style">
                    <div className="content mb-0">
                        <h1 className="text-center mb-0">Features</h1>
                    </div>
                    <div className="row me-2 ms-2 mb-5">
                        <div className="col-6 text-center">
                            <i className="fa fa-boxes color-yellow-dark fa-3x"></i>
                            <h2 className="mt-3 mb-1">End to End Testing</h2>
                        </div>
                        <div className="col-6 text-center">
                            <i className="fa fa-chart-area color-highlight fa-3x"></i>
                            <h2 className="mt-3 mb-1">Smart Reporting</h2>
                        </div>
                        </div>
                    <div className="row me-2 ms-2 mb-5">
                        <div className="col-6 text-center">
                            <i className="fa fa-check color-green-dark fa-3x"></i>
                            <h2 className="mt-3 mb-1">Easy to Use</h2>
                        </div>
                        <div className="col-6 text-center">
                            <i className="fa fa-brain color-blue-dark fa-3x"></i>
                            <h2 className="mt-3 mb-1">Automation & Intelligence</h2>
                        </div>
                    </div>
                </div>

        <div className="card card-style">
            <div className="content">
                <h6 className="color-highlight text-uppercase font-700 mb-1">HOW DO WE MAKE</h6>
                <h1 className="text-uppercase font-900 mt-n2">WEBSITES ACCESSIBLE</h1>
                <p>
                Sites are tested for compliance with <b>GIGW (Guidelines for Indian Government Websites)</b> which include guidelines, a subset of which are specific to accessibility. These guidelines are in conformance with <b>Web Content Accessibility Guidelines (WCAG)</b> which is a set of web standards that aim to make the Internet a more inclusive and accessible space for all.

                At a high level, WCAG sets out 4 key principles - <b>Perceivable, Operable, Robust, Understandable</b>. They provide a solid foundation to create web content that is accessible and usable for all.
                </p>

            </div>
        </div>

        
      </MobileLayout>
    );
}
