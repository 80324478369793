import { FC, useEffect, useState } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { RootState } from "../../../setup";
import { checkIsActive, KTSVG } from "../../../_monic/helpers";
import { LayoutSetup, useLayout } from "../../../_monic/layout/core";
import { Settings } from "../../utils/config";
import { UserModel } from "../../modules/auth/models/UserModel";

export const LandingHeader: FC<{aside?: boolean}> = ({aside}) => {
    useEffect(() => {
        LayoutSetup.initJs();
    }, []);

    const history = useHistory();

    useEffect(() => {
        return history.listen((location) => {
            LayoutSetup.initJs();
        })
    }, [history]);

    const {pathname} = useLocation();
    const {config, classes, attributes} = useLayout();
    //const {aside} = config;
    const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
    const isLoggedIn = user != null;
    return (
        <header className="header-section">
            {Settings.isGamyata ? null : 
            <div className="header-top">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12 clearfix" style={{paddingTop: 3}}>
                            <a href="https://www.xn--i1bj3fqcyde.xn--11b7cb3a6a.xn--h2brj9c/" className="india-logo" target="_blank" 
                            onClick={(e) => !window.confirm('You are being redirected to an external website. Please note that SugamyaWeb cannot be held responsible for external websites content & privacy policies.') && e.preventDefault()}
                            style={{padding: '12px 0'}}>
                                <span>भारत | </span>
                            </a>
                            <a href="https://india.gov.in" className="india-logo" target="_blank" 
                            onClick={(e) => !window.confirm('You are being redirected to an external website. Please note that SugamyaWeb cannot be held responsible for external websites content & privacy policies.') && e.preventDefault()}
                            style={{padding: '12px 0'}}>
                                <span>&nbsp;India</span>
                            </a>
                            <div className="col-xs-12" style={{width: 'auto'}}>
                            {aside && (
                                        <div className='d-flex align-items-center d-lg-none ms-n3 me-1' title='Show aside menu'>
                                            <div
                                            className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
                                            id='kt_aside_mobile_toggle'
                                            >
                                            <KTSVG path='/media/icons/duotune/abstract/abs015.svg' className='svg-icon-2x mt-1' />
                                            </div>
                                        </div>
                                        )} 
                            </div>
                            <div className="accessibility-menu" style={{width: 'auto', float: 'right'}}>
                                <ul>
                                    <li className="skip-content">
                                        <a href="#main-content" aria-label="Skip to main content"
                                            title="Skip to main content">
                                            <span className="mob-hide">Skip to main content</span>
                                            <span className="mob-show"><span style={{transform: "rotate(90deg)"}}
                                                    className="glyphicon glyphicon-share-alt"
                                                    aria-hidden="true"></span></span></a>
                                    </li>
                                    <li>
                                        <Link to="/sitemap" title="Sitemap" className="sitemap">
                                            <span className="fa fa-sitemap"></span></Link>
                                    </li>
                                    <li className="site-contrast">
                                        <div className="contrast-btn-grp">
                                            <a href="#" className="dark-contrast" aria-label="High Contrast"
                                                title="High Contrast">A</a>
                                            <a href="#" className="normal-contrast" aria-label="Normal Contrast"
                                                title="Normal Contrast">A</a>
                                        </div>
                                    </li>
                                    {/* <li>
                                        <a href="#" aria-label="हिन्दी" title="हिन्दी">हिन्दी</a>
                                    </li> */}
                                </ul>
                            </div>
                            {/* <!--accessibility-menu--> */}
                        </div>
                        {/* <!-- clearfix--> */}
                    </div>
                    {/* <!-- row--> */}
                </div>
                {/* <!-- container--> */}
            </div>}
            {/* <!-- header-top--!> */}
            <div className="header-sticky">
                <div className="header-middle">
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12">
                                {Settings.isGamyata ? null : <img src="/assets/images/emblem.png" alt="Indian Emblem" />}
                                <Link to="/" aria-label="SugamyaWeb Logo" title="Go to home" rel="home">    
                                    <img src="/assets/images/logo1.png" alt="SugmayaWeb Logo" />
                                </Link>
                                    <div className="logo">
                                    <div className="logo-text">
                                        <h1 className="h1-text">{Settings.GamyataLabel}</h1>
                                        <strong>Enabling seamless digital experience</strong>
                                    </div>
                                    </div>
                                
                                <div className="header-right">
                                    <a className="mob-show menuToggle" aria-label="menu toggle" href="#"> <span id='fabars' className="fa fa-bars"
                                            aria-hidden="true"></span></a>
                                    <nav className="menu">
                                        <ul>
                                            
                                            <li className={checkIsActive(pathname, '/') ? 'active' : ''}><Link to="/">Home</Link> </li>
                                            <li className={checkIsActive(pathname, '/background') ? 'active' : ''}><Link to='/background'>Background</Link></li>
                                            <li className={checkIsActive(pathname, '/using-sugamyaweb') ? 'active' : ''}><Link to="/using-sugamyaweb">Using {Settings.GamyataLabel}</Link></li>
                                            {isLoggedIn ? (
                                            <li className="gamyata-login">
                                            <a title={user.gamyata ? "Dashboard" : "View Onboarding Request"} href={user.gamyata ? "/v2/dashboard" : "/v2/onboarding"}>
                                                {user.gamyata ? "Dashboard" : "View Onboarding Request"}
                                            </a>
                                            </li>
                                            ) : null}
                                            <li className="gamyata-login">
                                            <Link to={isLoggedIn ? "/logout" : "/login"}>
                                                <span className="fa fa-lock" role="img" aria-label="lock icon"></span>
                                                {isLoggedIn ? "Logout" : "Login"}
                                            </Link>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- header sticky--> */}
        </header>
    );
}

export const BreadcrumbTitle: FC<{children: any}> = ({children}) => {
    return (
        <section className="breadcrumb-section" aria-label="breadcrumb background">
            <div className="container">
                <div className="row">
                    <div className="col-xs-12">
                        <h1>{children}</h1>
                    </div>
                </div>
            </div>
        </section>
    );
};
