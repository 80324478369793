import React, {FC} from 'react'
import { useSelector, shallowEqual } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { RootState } from '../../../../setup';
import {
  StatisticsWidget1,
  StatisticsWidget2,
  StatisticsWidget3,
  StatisticsWidget4,
  StatisticsWidget5,
  StatisticsWidget6,
} from '../../../../_monic/partials/widgets'
import { decodeURISafe, getUrlPrefix } from '../../utils';

type Props = {
  stats: any,
  status: string,
}

const OverviewTab: FC<Props> = ({stats, status}) => {
  const {id}: any = useParams();
  const isMobile = useSelector<RootState>(({auth}) => auth.isMobile, shallowEqual)
  const urlPrefix = getUrlPrefix(isMobile);
  
  if (status != 'COMPLETE' && status != "PARTIALLY COMPLETE") {
    return (
      <div>
        {status == 'FAILURE' ? <span>The test was interrupted due to an error. Please retry or contact admin </span> : 
        <div> <span>The test is in progress... Please wait </span> <span className="spinner-border spinner-border-sm align-middle ms-2"></span></div>}
      </div>
    );
  }
  if (stats == null) {
    return null;
  }
  const {cntPages, cntTests, cntTotalIssues, cntTotalWarns, issueCntByPages, issueCntByTest, issueCntByCat} = stats;
  if (issueCntByPages == null || issueCntByTest == null || issueCntByCat == null) {
    return null;
  }
  /*if (issueCntByPages.length == 0 || issueCntByTest.length == 0 || issueCntByCat.length == 0) {
    return null;
  }*/
  return (
    <div className='card'>
    <div className='card-body bg-white' style={{
      marginLeft: -13,
      marginRight: -13,
    }}>
      {/* begin::Row */}
      <div className='row g-5 g-xl-8'>
        <div className='col-xl-4'>
          <Link to={urlPrefix + `/activities/testmgmt/testresult/${id}/testresults`} title='Test Report'>
            <StatisticsWidget5
              className='card-xl-stretch mb-xl-8'
              svgIcon='/media/icons/duotune/general/gen032.svg'
              color='danger'
              iconColor='white'
              title={cntTotalIssues}
              description='Total Issues'
            />
          </Link>
        </div>

        <div className='col-xl-4'>
          <Link to={urlPrefix + `/activities/testmgmt/testresult/${id}/testresults`} title='Test Report'>
            <StatisticsWidget5
              className='card-xl-stretch mb-xl-8'
              svgIcon='/media/icons/duotune/ecommerce/ecm008.svg'
              color='info'
              iconColor='white'
              title={cntPages}
              description='Total Pages Tested'
            />
          </Link>
        </div>

        <div className='col-xl-4'>
          <Link to={urlPrefix + `/activities/testmgmt/testresult/${id}/testresults`} title='Test Report'>
            <StatisticsWidget5
              className='card-xl-stretch mb-xl-8'
              svgIcon='/media/icons/duotune/finance/fin006.svg'
              color='primary'
              iconColor='white'
              title={cntTests}
              description='Total Tests'
            />
            </Link>
        </div>

        {/* <div className='col-xl-3'>
          <StatisticsWidget5
            className='card-xl-stretch mb-5 mb-xl-8'
            svgIcon='/media/icons/duotune/graphs/gra007.svg'
            color='info'
            iconColor='white'
            title='$50,000'
            description='Milestone Reached'
          />
        </div> */}
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      <div className='row g-5 g-xl-8'>
          <div className='col-xl-4'>
           {cntTotalIssues > 0 && issueCntByPages.length > 0 ? (
            <StatisticsWidget1
              className='card-xl-stretch mb-xl-8'
              image='abstract-4.svg'
              title={`#1 Page with highest issue count`}
              time={`${issueCntByPages[0].count} issues`}
              description={`${decodeURISafe(issueCntByPages[0].title)}`}
              timeColor='black'
            />
            ) : null}
         </div>
         <div className='col-xl-4'>
         {cntTotalIssues > 0 && issueCntByCat.length > 0 ? (
          <StatisticsWidget1
            className='card-xl-stretch mb-xl-8'
            image='abstract-1.svg'
            title={`#1 Category with highest issue count`}
            time={`${issueCntByCat[0].count} occurrences`}
            description={issueCntByCat[0].category}
          />
          ) : null}
        </div>
         
        <div className='col-xl-4'>
          {cntTotalIssues > 0 && issueCntByTest.length > 0 ? (
          <StatisticsWidget1
            className='card-xl-stretch mb-xl-8'
            image='abstract-2.svg'
            title={`#1 most frequently seen issue`}
            time={`${issueCntByTest[0].count} occurrences`}
            description={issueCntByTest[0].title}
          />
          ) : null}
        </div>

      </div>
      {/* end::Row */}

      {/* begin::Row */}
      <div className='row g-5 g-xl-8'>
       <div className='col-xl-4'>
          {cntTotalIssues > 0 && issueCntByPages.length > 1 ? (
            <StatisticsWidget1
              className='card-xl-stretch mb-xl-8'
              image='abstract-4.svg'
              title={`#2 Page with highest issue count`}
              time={`${issueCntByPages[1].count} issues`}
              description={`<a href="${issueCntByPages[1].title}" target="_blank">${decodeURISafe(issueCntByPages[1].title)}</a>`}
              timeColor='black'
            />
            ) : null}
          </div>
          <div className='col-xl-4'>
         {cntTotalIssues > 0 && issueCntByCat.length > 1 ? (
          <StatisticsWidget1
            className='card-xl-stretch mb-xl-8'
            image='abstract-1.svg'
            title={`#2 Category with highest issue count`}
            time={`${issueCntByCat[1].count} occurrences`}
            description={issueCntByCat[1].category}
          />
          ) : null}
        </div>

        <div className='col-xl-4'>
        {cntTotalIssues > 0 && issueCntByTest.length > 1 ? (
          <StatisticsWidget1
            className='card-xl-stretch mb-xl-8'
            image='abstract-2.svg'
            title={`#2 most frequently seen issue`}
            time={`${issueCntByTest[1].count} occurrences`}
            description={issueCntByTest[1].title}
          />
        ) : null}
        </div>

      </div>
      {/* end::Row */}


      {/* begin::Row */}
      <div className='row g-5 g-xl-8'>
        <div className='col-xl-4'>
          {cntTotalIssues > 0 && issueCntByPages.length > 2 ? (
            <StatisticsWidget1
              className='card-xl-stretch mb-xl-8'
              image='abstract-4.svg'
              title={`#3 Page with highest issue count`}
              time={`${issueCntByPages[2].count} issues`}
              description={`${decodeURISafe(issueCntByPages[2].title)}`}
              timeColor='black'
            />
            ) : null}
        </div>
        <div className='col-xl-4'>
         {cntTotalIssues > 0 && issueCntByCat.length > 2 ? (
          <StatisticsWidget1
            className='card-xl-stretch mb-xl-8'
            image='abstract-1.svg'
            title={`#3 Category with highest issue count`}
            time={`${issueCntByCat[2].count} occurrences`}
            description={issueCntByCat[2].category}
          />
          ) : null}
        </div>

        <div className='col-xl-4'>
        {cntTotalIssues > 0 && issueCntByTest.length > 2 ? (
          <StatisticsWidget1
            className='card-xl-stretch mb-xl-8'
            image='abstract-2.svg'
            title={`#3 most frequently seen issue`}
            time={`${issueCntByTest[2].count} occurrences`}
            description={issueCntByTest[2].title}
          />
        ) : null}
        </div>
      </div>
      {/* end::Row */}
    </div>
    </div>
  )
}

export {OverviewTab}
