/* eslint-disable jsx-a11y/anchor-is-valid */
import { useFormik } from 'formik';
import _ from 'lodash';
import React, {FC, useEffect, useState} from 'react'
import ReactMarkdown from 'react-markdown'
import * as Yup from 'yup'
import {DOM} from '../../../utils/renderer/dom';
import {ElementScreenshotRenderer} from '../../../utils/renderer/element-screenshot-renderer';
import { NonComplianceModel, WebsiteDashboardNc } from '../../auth/models/TestRunModel';
import { patchNc, patchNcBulk } from '../../auth/redux/TestRunCRUD';
import { useQuery } from '../../utils';
import { criteriaNumbersByLevels } from '../../../modulesv2/utils';

type Props = {
  audits: Array<AuditDetails>,
  screenshotDetails: any,
  andiScreenshotDetails: any,
  tags: Array<string>,
  categories: Array<string>,
  testReportingDetails: TestReportingDetails,
  ncList: NonComplianceModel[],
  url?: string,
  selectedTest?: string
}

const arrayStrStartsWith = (arr: Array<string>, str: string) => {
  if (!str) {
    return false;
  }
  for (const x of arr) {
    if (x.startsWith(str)) {
      return true;
    }
  }
  return false;
}

const rgbToHex = (rgb: Array<number>): string => {
  return "#" + ((1 << 24) + (rgb[0] << 16) + (rgb[1] << 8) + rgb[2]).toString(16).slice(1);
}

function htmlToElement(html: string): any {
  var template = document.createElement('template');
  html = html.trim(); // Never return a text node of whitespace as the result
  template.innerHTML = html;
  return template.content.firstChild;
}

function installScreenshot(containerId: string, screenshotDetails: any, tab: number) {
  if (screenshotDetails == null) {
    return;
  }
  const newElementId = "screenshotDefinition";
  const exist = document.getElementById(newElementId);
  if (exist != null) {
    exist.parentElement?.appendChild(exist.children[0]);
    exist.parentNode?.removeChild(exist);
  }

  const element = document.getElementById(containerId);
  const root: any = document.createElement('div');
  root.setAttribute('id', newElementId)
    if (root != null) {
      const dom = new DOM(root.ownerDocument);
      const stopPropogationEvent = (e: any) => e.stopImmediatePropagation();
      ElementScreenshotRenderer.installFullPageScreenshot(root, screenshotDetails.screenshot);
      if (tab == 0) {
      ElementScreenshotRenderer.installOverlayFeature({
          dom: dom,
          reportEl: root,
          overlayContainerEl: root,
          fullPageScreenshot: screenshotDetails,
      });
      root.removeEventListener('click', stopPropogationEvent);
      } else {
      root.addEventListener('click', stopPropogationEvent, true);
      root.classList.remove('lh-screenshot-overlay--enabled');
      } 
    }
    // set the wrapper as child (instead of the element)
    element?.parentElement?.replaceChild(root, element);
    // set element as child of wrapper
    root.appendChild(element);
}

const Rectification : FC<{nonCompliance: NonComplianceModel, nonComplianceIds?: number[], auditId?: string, showCommentsLabel: boolean, onChangeNcComment?: any}> = ({
  nonCompliance, nonComplianceIds, auditId, showCommentsLabel, onChangeNcComment,
}) => {
  const [showForm, setShowForm] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [comments, setComments] = useState<string | undefined>(nonCompliance?.comments);
  const initialValues = {comments: comments || ''};
  const formik = useFormik<any>({
    initialValues,
    validationSchema: Yup.object().shape({
      comments: Yup.string().required('Note is required'),
    }),
    onSubmit: (values) => {
      setLoading(true);
      nonCompliance.comments = values.comments;
      let promise = null;
      if (nonComplianceIds && auditId && nonComplianceIds.length > 0) {
        promise = patchNcBulk({comments: values.comments}, nonCompliance.websiteId, auditId, nonComplianceIds);
      } else {
        promise = patchNc({id: nonCompliance.id, comments: values.comments});
      }
      promise.then(() => {
        setLoading(false);
        setShowForm(false);
        setComments(values.comments);
        if (onChangeNcComment) {
          if (nonComplianceIds) {
            onChangeNcComment(nonComplianceIds, values.comments);
          } else {
            onChangeNcComment(nonCompliance);
          }
        }
      }).catch(() => {
        setLoading(false);
      });
    },
  })

  if (nonCompliance == null) {
    return null;
  }

  return (
    <div key={comments}>
      {showCommentsLabel && comments != null ? (
        <div className='d-flex w-100 mt-auto mb-2'>
          <span className='fw-bold fs-6 '>Notes: &nbsp;</span>
          <span className='fs-6 '>{comments}</span>
        </div>
        ) : null }
      {showForm ? (
                            <form onSubmit={formik.handleSubmit} className='form' style={{width: '100%'}}>
                            <div className='fv-row'>
                              <label className='col-form-label required fw-bold fs-6'>Set Notes</label>
                              <input
                                type='text'
                                className='form-control form-control-lg form-control-solid mb-3 ms-2 mb-lg-0'
                                placeholder='Comments'
                                style={{display: 'inline', width: 'auto'}}
                                {...formik.getFieldProps('comments')}
                              />
                              {formik.touched.comments && formik.errors.comments && (
                                <div className='fv-plugins-message-container'>
                                  <div className='fv-help-block'>{formik.errors.comments}</div>
                                </div>
                              )}
                              <button type='submit' className='btn btn-primary ms-2' disabled={loading}>
                              {
                                !loading && 'Save Changes'
                              }
                              {loading && (
                                <span className='indicator-progress' style={{display: 'block'}}>
                                  Please wait...{' '}
                                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                              )}
                            </button>
                            <button onClick={() => setShowForm(false)} className='btn btn-primary ms-2' disabled={loading}>
                              Cancel
                            </button>
                            </div>
                          </form>
                          ) : (
                          <button
                            onClick={() => {
                              setShowForm(true);
                            }}
                            type='button'
                            className='btn btn-sm btn-primary me-3'
                            data-kt-stepper-action='previous'
                          >
                            Set Notes
                          </button>  
                          )}
    </div>
  );
}

const ChangeStatus : FC<{nonCompliance: NonComplianceModel, nonComplianceIds?: number[], auditId?: string, showStatusLabel: boolean, onChangeNcStatus?: any}> = ({
  nonCompliance, nonComplianceIds, auditId, showStatusLabel, onChangeNcStatus,
}) => {
  const [showForm, setShowForm] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [status, setStatus] = useState<string>(nonCompliance?.status);
  const initialValues = {status};
  const formik = useFormik<any>({
    initialValues,
    validationSchema: Yup.object().shape({
      status: Yup.string().required('Status is required'),
    }),
    onSubmit: (values) => {
      setLoading(true);
      if (nonCompliance.comments == null) {
        delete nonCompliance.comments;
      }
      nonCompliance.status = values.status
      let promise = null;
      if (nonComplianceIds && auditId && nonComplianceIds.length > 0) {
        promise = patchNcBulk({status: values.status}, nonCompliance.websiteId, auditId, nonComplianceIds);
      } else {
        promise = patchNc({id: nonCompliance.id, status: values.status});
      }
      promise.then(() => {
        setLoading(false);
        setShowForm(false);
        setStatus(values.status);
        if (onChangeNcStatus) {
          if (nonComplianceIds) {
            onChangeNcStatus(nonComplianceIds, values.status);
          } else {
            onChangeNcStatus(nonCompliance);
          }
        }
      }).catch(() => {
        setLoading(false);
      });
    },
  })
  if (nonCompliance == null) {
    return null;
  }
  let badgeColor = 'badge-danger';
  if (status == 'UNRESOLVED') {
    badgeColor = 'badge-danger';
  } else if (status == 'RESOLVED') {
    badgeColor = 'badge-success';
  } else if (status == 'FIX IN PROGRESS') {
    badgeColor = 'badge-warning';
  } 
  const age = new Date().getTime() - new Date(nonCompliance.created).getTime();
  const stale = age > 86400000; // older than a day
  return (
    <div>
      <div className='d-flex w-100 mt-auto mb-2'>
        {showStatusLabel ? 
        <>
          <span className='fw-bolder fs-6 '>Rectification Status: </span> 
          <span className={`mx-2 badge ${badgeColor}`}>{status}</span>
        </>: null}
      </div>
      {/* {showStatusLabel ? <>
        <b className='text-danger'>{stale ? '!!! ALERT: NC is untriaged for longer than a day !!!' : null} </b><br />
        </> : null} */}
      {showForm ? (
                            <form onSubmit={formik.handleSubmit} className='form' style={{width: '100%'}}>
                            <div className='fv-row'>
                              <label className='col-form-label required fw-bold fs-6'>Change Status</label>
                              <select 
                                className='form-select form-select-solid form-select-lg'
                                {...formik.getFieldProps('status')}
                                style={{display: 'inline', width: 'auto'}}
                              >
                                  <option value='UNRESOLVED'>UNRESOLVED</option>
                                  <option value='FIX IN PROGRESS'>FIX IN PROGRESS</option>
                                  <option value='RESOLVED'>RESOLVED</option>
                              </select>
                              {formik.touched.status && formik.errors.status && (
                                <div className='fv-plugins-message-container'>
                                  <div className='fv-help-block'>{formik.errors.status}</div>
                                </div>
                              )}
                              <button type='submit' className='btn btn-primary ms-2' disabled={loading}>
                              {
                                !loading && 'Set Status'
                              }
                              {loading && (
                                <span className='indicator-progress' style={{display: 'block'}}>
                                  Please wait...{' '}
                                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                              )}
                            </button>
                            <button onClick={() => setShowForm(false)} className='btn btn-primary ms-2' disabled={loading}>
                              Cancel
                            </button>
                            </div>
                          </form>
                          ) : (
                          <button
                            onClick={() => {
                              setShowForm(true);
                            }}
                            type='button'
                            className='btn btn-sm btn-primary me-3'
                            data-kt-stepper-action='previous'
                          >
                            Change Status
                          </button>  
                          )}
    </div>
  );
}

const ChangePriority : FC<{nonCompliance: NonComplianceModel, showPriorityLabel: boolean, onChangeNcPriority?: any}> = ({
  nonCompliance, showPriorityLabel, onChangeNcPriority,
}) => {
  const [showForm, setShowForm] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [priority, setPriority] = useState<string>(nonCompliance?.priority);
  const initialValues = {priority};
  const formik = useFormik<any>({
    initialValues,
    validationSchema: Yup.object().shape({
      priority: Yup.string().required('Priority is required'),
    }),
    onSubmit: (values) => {
      setLoading(true);
      if (nonCompliance.comments == null) {
        delete nonCompliance.comments;
      }
      nonCompliance.priority = values.priority
      patchNc({id: nonCompliance.id, priority: values.priority}).then(() => {
        setLoading(false);
        setShowForm(false);
        setPriority(values.priority);
        onChangeNcPriority && onChangeNcPriority(nonCompliance);
      }).catch(() => {
        setLoading(false);
      });
    },
  })
  if (nonCompliance == null) {
    return null;
  }
  let badgeColor = 'badge-primary';
  if (priority == 'UNTRIAGED') {
    badgeColor = 'badge-primary';
  } else if (priority == 'LOW') {
    badgeColor = 'badge-success';
  } else if (priority == 'MEDIUM') {
    badgeColor = 'badge-warning';
  } else if (priority == 'HIGH') {
    badgeColor = 'badge-danger';
  } 
  const age = new Date().getTime() - new Date(nonCompliance.created).getTime();
  const stale = age > 86400000; // older than a day
  return (
    <div>
      <div className='d-flex w-100 mt-auto mb-2'>
        {showPriorityLabel ? <span className='fw-bolder fs-6 '>Priority: </span> : null}
        <span className={`mx-2 badge ${badgeColor}`}>{priority}</span>
      </div>
      {showForm ? (
                            <form onSubmit={formik.handleSubmit} className='form' style={{width: '100%'}}>
                            <div className='fv-row'>
                              <label className='col-form-label required fw-bold fs-6'>Change Priority</label>
                              <select 
                                className='form-select form-select-solid form-select-lg'
                                {...formik.getFieldProps('priority')}
                                style={{display: 'inline', width: 'auto'}}
                              >
                                  <option value='UNTRIAGED'>UNTRIAGED</option>
                                  <option value='LOW'>LOW</option>
                                  <option value='MEDIUM'>MEDIUM</option>
                                  <option value='HIGH'>HIGH</option>
                              </select>
                              {formik.touched.priority && formik.errors.priority && (
                                <div className='fv-plugins-message-container'>
                                  <div className='fv-help-block'>{formik.errors.priority}</div>
                                </div>
                              )}
                              <button type='submit' className='btn btn-primary ms-2' disabled={loading}>
                              {
                                !loading && 'Set priority'
                              }
                              {loading && (
                                <span className='indicator-progress' style={{display: 'block'}}>
                                  Please wait...{' '}
                                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                              )}
                            </button>
                            <button onClick={() => setShowForm(false)} className='btn btn-primary ms-2' disabled={loading}>
                              Cancel
                            </button>
                            </div>
                          </form>
                          ) : (
                          <button
                            onClick={() => {
                              setShowForm(true);
                            }}
                            type='button'
                            className='btn btn-sm btn-primary me-3'
                            data-kt-stepper-action='previous'
                          >
                            Change Priority
                          </button>  
                          )}
    </div>
  );
}

function getImpactColor(impact: string) {
  let impactColor = 'primary';
    if (impact == 'critical') {
      impactColor = 'danger';
    } else if (impact == 'serious') {
      impactColor = 'warning';
    } else if (impact == 'moderate') {
      impactColor = 'dark';
    } else if (impact == 'minor') {
      impactColor = 'primary';
    }
    return impactColor;
}

function getStatusColor(status: string) {
  let impactColor = 'primary';
    if (status == 'Fail') {
      impactColor = 'danger';
    } else if (status == 'Warn') {
      impactColor = 'warning';
    } else if (status == 'Pass') {
      impactColor = 'success';
    }
    return impactColor;
}

function getFilteredAudits(testReportingDetails: TestReportingDetails, audits: Array<AuditDetails>, filter: string, category: string, wcagLevels: string[], wcagCriterion: string, selectedTest?: string) {
  return Object.values(audits).filter((audit: AuditDetails) => {
    const x = testReportingDetails[audit.id];
    if (!x) {
      // details not yet loaded
      return true;
    }
    if (selectedTest != null) {
      return x.title == selectedTest;
    }
    let f = filter == "All"  || filter == audit.status;
    if (!f) {
      return f;
    } 

    f = category == "All"  || x.category == category;
    if (!f) {
      return f;
    } 

    if (!x.tags) {
      return true;
    }
    
    if (wcagLevels.length > 0 && !wcagLevels.some(wcagLevel => x.tags.includes(wcagLevel))) {
      return false;
    }
  

    f = wcagCriterion == "All" || x.tags.includes(wcagCriterion);
    if (!f) {
      return f;
    }
    return true;
    
    //return false;
    /*if (filterTag.length == 0) {
      return true;
    }
    let isTagPresent = false;
    for (const t of filterTag) {
      if (x.tags.includes(t)) {
        isTagPresent = true;
        break;
      }
    }
    return isTagPresent;*/
  }).sort((x, y) => (y.failingElements?.length - x.failingElements?.length))
}

const Sidebar : FC<{testReportingDetails: TestReportingDetails, auditsFiltered: Array<AuditDetails>, selectedAudit: null | AuditDetails, setSelectedAudit: any}> = ({
  testReportingDetails, auditsFiltered, selectedAudit, setSelectedAudit,
}) => {
  const elements = auditsFiltered == null ? null : auditsFiltered.map((audit: AuditDetails, index) => {
    const details = testReportingDetails[audit.id];
    const badgeColor = getStatusColor(audit.status);
    return (
      // <div ref={(element) => {
      //     if (selectedAudit == audit) {
      //       element && element.scrollIntoView();
      //     }
      //   }}>
      <div>
      <div className='d-flex flex-stack py-4' style={{backgroundColor: selectedAudit == audit ? 'rgba(0, 0, 0, 0.1)' : undefined}}>
        <div className='d-flex align-items-center'>
          <div className='ms-5'>
            <a href='#' className='fs-5 fw-bolder text-primary-900 text-hover-info mb-2' onClick={() => setSelectedAudit(audit)}>
              {details.title}
            </a>
          </div>
        </div>

        <div className='d-flex flex-column align-items-end ms-2'>
        <div className='d-flex flex-column py-4'>
          {audit.failingElements.length > 0 ? (<span className={`badge badge-sm badge-circle badge-light-${badgeColor}`}>{audit.failingElements.length}</span>): null}
          <span className={`badge badge-light-${badgeColor} fw-bolder me-auto px-2 py-2`}>{audit.status}</span>
          </div>
        </div>
      </div>
      <div className='d-flex flex-stack'>
      <div className='align-items-center ms-5'>
      <span className={`badge badge-light-${getImpactColor(details.impact)} fw-bolder me-auto px-2 py-2`}>Impact: {_.startCase(details.impact)}</span>
      </div></div>
      <div className='d-flex flex-stack py-4'>
      <div className='align-items-center ms-5'>
        <span key={Math.random()} className='badge badge-sm badge-light-primary me-3'>
          {details.category}  
        </span>
      {details.tags?.map(tag => (
        <span key={Math.random()} className='badge badge-sm badge-light-primary me-3'>
          {tag}  
        </span>
      ))}
        </div>
      </div>
      <div className='separator separator-dashed'></div>
      </div>
    );
  });
  return <div>{elements}</div>;
};

const AuditSidebarDetail: FC<Props> = ({
  url, testReportingDetails, audits, screenshotDetails, andiScreenshotDetails, tags, categories, ncList, selectedTest,
}) => {
  const [selectedAudit, setSelectedAudit] = useState<AuditDetails | null>(null);
  const [filter, setFilter] = useState<string>("Fail");
  const [category, setCategory] = useState<string>("All");
  const [wcagLevels, setWcagLevels] = useState<string[]>([]);
  const [wcagCriterion, setWcagCriterion] = useState<string>("All");
  const [filteredNcList, setFilteredNcList] = useState<NonComplianceModel[]>([]);
  const queryParams: URLSearchParams = useQuery();
  const auditId = decodeURIComponent(queryParams.get('auditId') || '');
  const auditsFiltered = getFilteredAudits(testReportingDetails, audits, filter, category, wcagLevels, wcagCriterion, selectedTest);
  const selectedDetail = selectedAudit ? testReportingDetails[selectedAudit.id] : null;
  const ncListByFeHash = ncList.reduce((map: any, obj) => (map[obj.feHash] = obj, map), {});

  useEffect(() => {
    if (selectedTest != null && selectedAudit != auditsFiltered[0]) {
      setSelectedAudit(auditsFiltered[0]);
    }
    if (auditId && auditId.length > 0) {
      const sa = audits.filter(a => {
        return a.id == auditId
      });
      if (sa.length > 0) {
        setSelectedAudit(sa[0]);
      }
    }
  }, [auditId, selectedTest]);

  useEffect(() => {
    const newFilteredNcList = selectedAudit ? selectedAudit.failingElements.map(failingElement => {
      if (!ncListByFeHash[failingElement.feHash]) {
        return null;
      }
      return {
        ...ncListByFeHash[failingElement.feHash],
        failingElement,
      }
    }).filter(x => x != null) : [];
    setFilteredNcList(newFilteredNcList);
  }, [selectedAudit]);

  const maxThumbnailSize = {width: 147, height: 100};
  const root: any = document.getElementById('failing-elements');
  const dom = new DOM(root.ownerDocument);
  if (selectedAudit?.src == "lh") {
    installScreenshot('failing-elements', screenshotDetails, 0);
  } else if (selectedAudit?.src == "andi") {
    installScreenshot('failing-elements', andiScreenshotDetails, 0);
  }

  return (
    <div className='d-flex flex-column flex-lg-row'>
      {selectedTest != null ? null : (
      <div className='flex-column flex-lg-row-auto w-100 w-lg-300px w-xl-400px mb-10 mb-lg-0'>
        <div className='card card-flush'>
          
          <div className='card-header pt-7' id='kt_chat_contacts_header'>
            <h4 className='mb-5'>
            Filter Issues By
            </h4>
            <table className='w-100' style={{
              borderCollapse: 'separate',
              borderSpacing: '0 1em',
            }}>
              <tr>
                <td align="right">
                <label htmlFor='s_status'>
                  Status
                </label>
                </td>
                <td>
                <select
              id='s_status'
              name='status'
              data-hide-search='true'
              className='form-select form-select-sm w-200px mx-2'
              defaultValue={filter}
              onChange={e => setFilter(e.target.value)}
            >
              <option value='All'>All</option>
              <option value='Pass'>Pass</option>
              <option value='Fail'>Fail</option>
              <option value='Warn'>Warn</option>
            </select>
                </td>
              </tr>
              <tr>
                <td align="right">
                <label htmlFor='s_category'>
                  Category
                </label>
                </td>
                <td>
                <select
                  id='s_category'
                  name='category'
                  data-hide-search='true'
                  className='form-select form-select-sm w-200px mx-2'
                  defaultValue={category}
                  onChange={e => setCategory(e.target.value)}
                >
                  <option value='All'>All</option>
                  {Array.from(categories).sort().map(cat => <option key={cat} value={cat}>{_.startCase(cat)}</option>)}
                  <option value='experimental'>Experimental</option>
                </select>
                </td>
              </tr>
              <tr>
                <td align="right">
                <label htmlFor='s_wcaglevels'>
                  WCAG Levels
                </label>
                </td>
                <td>
                <select
                  id='s_wcaglevels'
                  name='wcaglevels'
                  data-hide-search='true'
                  className='form-select form-select-sm w-200px mx-2'
                  defaultValue={filter}
                  onChange={e => {
                    setWcagLevels(Array.from(e.target.options).filter(o => o.selected).map(o => o.value));
                  }}
                  multiple={true}
                >
                  {Array.from(tags).sort().map(tag => (
                    tag.startsWith("wcag 2.0 level") || tag.startsWith("wcag 2.1 level") ?
                    <option key={tag} value={tag}>{tag}</option>
                    : ""
                  ))}
                </select> 
                </td>
              </tr>
              <tr> 
                <td align="right">
                <label htmlFor='s_wcagcriterion'>
                  WCAG Criterion
                </label>
                </td>
                <td>
                <select
                  id='s_wcagcriterion'
                  name='wcaglevels'
                  data-hide-search='true'
                  className='form-select form-select-sm w-200px mx-2'
                  defaultValue={filter}
                  onChange={e => setWcagCriterion(e.target.value)}
                >
                  <option value='All'>All</option>
                  {Array.from(tags).filter(tag => {
                    if (!tag.startsWith('wcag criterion ')) {
                      return false;
                    }
                    if (wcagLevels.length > 0) {
                      return wcagLevels.some(wcagLevel => {
                        const criteriaList = criteriaNumbersByLevels[wcagLevel];
                        return criteriaList.indexOf(tag.split('wcag criterion ')[1]) >= 0;
                      });
                      
                    }
                    return true;
                  }).sort().map(tag => (
                    tag.startsWith("wcag criterion") ?
                    <option key={tag} value={tag}>{tag}</option>
                    : ""
                  ))}
                </select>
                </td>
              </tr>
            </table>
          
          {/* <div className='d-flex align-items-center me-4 my-2'>
            Tags
            <select
              name='tags'
              data-control='select3'
              data-hide-search='true'
              className='form-select form-select-sm w-125px mx-2'
              defaultValue={filterTag}
              onChange={(e) => {
                const options: HTMLOptionsCollection = e.target.options;
                const selectedValues = [];
                for (let i = 0; i < options.length; i++) {
                  if (options[i].selected) {
                    selectedValues.push(options[i].value);
                  }
                }
                setFilterTag(selectedValues);
              }}
              multiple
            >
              {Array.from(tags).sort().map(tag => (
                <option value={tag} selected={filterTag.includes(tag)}>{tag}</option>
              ))}
            </select>
          </div>*/}
          </div> 

          <div className='separator mt-5' />

          <div className='card-body pt-5'>
            <div className='mb-5'>
              <h4>
              Issues
              </h4>
              Click on an issue to view the details on the right pane
              <div className='separator mt-3'></div>
            </div>
            <div
              className='scroll-y me-n5 pe-5 h-500px'
              data-kt-scroll='true'
              data-kt-scroll-activate='{default: false, lg: true}'
              data-kt-scroll-max-height='auto'
              data-kt-scroll-dependencies='#kt_header, #kt_toolbar, #kt_footer, #kt_chat_contacts_header'
              data-kt-scroll-wrappers='#kt_content'
              data-kt-scroll-offset='0px'
            >
              <Sidebar auditsFiltered={auditsFiltered} selectedAudit={selectedAudit} testReportingDetails={testReportingDetails} setSelectedAudit={setSelectedAudit} />
            </div>
          </div>
        </div>
      </div>
      )}

      {selectedAudit == null ? (
      <div className={`flex-lg-row-fluid ${selectedTest != null ? '' : 'ms-lg-7 ms-xl-10'}`}>
      <div className='card' id='kt_chat_messenger'>
        <div className='card-body pt-9 pb-0'>
        <div className='text-center mb-13'>
            <h2>Select a test to view details</h2>
          </div>
        </div>
        </div>
        </div>
        ) : null}

      {selectedAudit != null && selectedDetail != null ? (
      <div key={selectedAudit.id} className={`flex-lg-row-fluid ${selectedTest != null ? '' : 'ms-lg-7 ms-xl-10'}`}>
        <div className='card pt-5' id='kt_chat_messenger'>
          <div className='modal-body scroll-y mx-5 mx-xl-18 h-800px'>
            <div className='text-center'>
              
              <h1 className='mb-3'>{selectedDetail.title}</h1>
              <span className={`badge badge-light-${getImpactColor(selectedDetail.impact)} fw-bolder me-auto px-2 py-2`}>Impact: {_.startCase(selectedDetail.impact)}</span>
              <div>
                <span key={Math.random()} className='badge badge-sm badge-light-primary me-3'>
                  {selectedDetail.category}  
                </span>
              {selectedDetail.tags?.map(tag => (
                <span key={Math.random()} className='badge badge-sm badge-light-primary me-3'>
                  {tag}  
                </span>
              ))}
              </div>
        
              <div className='card-body pt-9 pb-0'>
                <div className='h-4px w-100 bg-light mb-5'>
                  <div className={`bg-${getStatusColor(selectedAudit.status)} rounded h-4px`} style={{width: `${100}%`}} />
                </div>
              </div>
              <div className='text-muted fw-bold fs-5 text-left'>
                <ReactMarkdown linkTarget={'_blank'} children={selectedDetail.description?.replaceAll('\t', ' ')} />
              </div>
            </div>
            {filteredNcList.length > 0 ? (
              <>
              <div className='d-flex flex-center mb-8 mt-10 bg-danger text-white'>
                <span className='text-uppercase fs-7 fw-bold py-3'>Resolve all elements in this test criteria</span>
              </div>
              <div>
                <div className='d-flex w-100 mt-auto mb-2 ms-5'>
                  <ChangeStatus showStatusLabel={false} nonCompliance={filteredNcList[0]} nonComplianceIds={filteredNcList.map(x => x.id)} key={selectedDetail.identifier + 'change-status'} 
                    auditId={selectedAudit.id} onChangeNcStatus={(ncIds: number[], status: string) => {
                      const newFilteredNcList = filteredNcList.map(x => {
                        if (ncIds.includes(x.id)) {
                          return {
                            ...x,
                            status
                          };
                        }
                        return x;
                      });
                      ncList.forEach(x => {
                        if (ncIds.includes(x.id)) {
                          x.status = status;
                        }
                      });
                      setFilteredNcList(newFilteredNcList);
                      }} />
                </div>
                <div className='d-flex w-100 mt-auto mb-2 ms-5'>
                  <Rectification showCommentsLabel={false} nonCompliance={filteredNcList[0]} nonComplianceIds={filteredNcList.map(x => x.id)} key={selectedDetail.identifier + 'rectification'} 
                    auditId={selectedAudit.id} onChangeNcComment={(ncIds: number[], comments: string) => {
                      const newFilteredNcList = filteredNcList.map(x => {
                        if (ncIds.includes(x.id)) {
                          return {
                            ...x,
                            comments
                          };
                        }
                        return x;
                      });
                      ncList.forEach(x => {
                        if (ncIds.includes(x.id)) {
                          x.comments = comments;
                        }
                      });
                      setFilteredNcList(newFilteredNcList);
                      }} />
                </div>
              </div>
              <div className='d-flex flex-center mb-8 mt-10 bg-danger text-white'>
                <span className='text-uppercase fs-7 fw-bold py-3'>Non compliant elements: {filteredNcList.length} Found</span>
              </div>
              </>
            ) : null}            
              <div className='me-n7 pe-7'>
                {
                  filteredNcList.map((feDb, idx) => {
                    const fe = feDb.failingElement;
                    let screenshot: any = null;
                    let rect: any = null;
                    if (selectedAudit.src == "lh") {
                      screenshot = screenshotDetails.screenshot;
                      rect = screenshotDetails.nodes[fe.id];
                      
                    } else if (selectedAudit.src == "andi") {
                      screenshot = andiScreenshotDetails.screenshot;
                      rect = andiScreenshotDetails.nodes[fe.id];
                    }
                    const screenshotElement = rect == null ? null : ElementScreenshotRenderer.render(dom, screenshot, rect, maxThumbnailSize);
                    let src = null;
                    if (fe.altFailureData && url) {
                      src = htmlToElement(fe.snippet).src;
                      const directoryPath = window.location.href.substring(0, window.location.href.lastIndexOf("/")+1);
                      src = src.includes(directoryPath) ? src.split(directoryPath)[1] : src;
                      if (!src.startsWith('//') && !src.startsWith('http://') && !src.startsWith('https://')) {
                          src = url.endsWith('/') ? url + src : url + '/' + src;
                      }
                    }
                    return (
                      <div className='border-bottom border-gray-700 border-bottom-dashed'>
                      <div
                        className='d-flex flex-stack py-4'
                        key={Math.random()}
                      >
                        <div className='d-flex align-items-center'>
                          <div className='ms-5'>
                            <span
                              className='fs-5 fw-bolder text-gray-900 mb-2'
                            >
                              {idx + 1}. <br />
                              {fe.label}
                            </span>
                            <div className='fw-bold text-muted' id='explanation'>
                              {fe.explanation}
                            </div>
                            {fe.testData ? (
                              <>
                              <div>
                                Current Constrast Ratio: <b>{fe.testData.contrast.ratio}</b>
                              </div>
                              <table className='w-100'>
                                <tr>
                                  <td style={{paddingRight: 25}}>
                                    Current Foreground
                                  </td>
                                  <td style={{paddingRight: 25}}>
                                    Current Background
                                  </td>
                                  <td style={{paddingRight: 25}}>
                                    Suggested {fe.testData.suggestedColorType}
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <b>{rgbToHex(fe.testData.fgColor.rgba)}</b>
                                    <div className='contrastColor' style={{backgroundColor: rgbToHex(fe.testData.fgColor.rgba), margin: 10}}></div>
                                  </td>
                                  <td>
                                    <b>{rgbToHex(fe.testData.bgColor.rgba)}</b>
                                    <div className='contrastColor' style={{backgroundColor: rgbToHex(fe.testData.bgColor.rgba), margin: 10}}></div>
                                  </td>
                                  <td>
                                    <b>{rgbToHex(fe.testData.suggestedColor.rgba)}</b>
                                    <div className='contrastColor' style={{backgroundColor: rgbToHex(fe.testData.suggestedColor.rgba), margin: 10}}></div>
                                  </td>
                                </tr>
                            </table></>) : null }
                            <pre style={{whiteSpace: 'pre-wrap'}}>
                              <code style={{padding: 0}}>
                              <p>{fe.snippet}</p>
                              </code>
                            </pre>
                            {fe.altFailureData ? (
                                <div>
                                  <div style={{marginBottom: 10}}>
                                    <b>Image Type:</b> {fe.altFailureData.type} <br />
                                    <b>Detected text (Model 1):</b> {fe.altFailureData.asciiText} <br />
                                    <b>Detected text (Model 2):</b> {fe.altFailureData.rawText} <br />
                                    <b>Alt text</b> {fe.label}
                                  </div>
                                <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target={"#kt_modal_" + idx}>
                                    View Image
                                </button>

                                <div className="modal fade" tabIndex={-1} id={"kt_modal_" + idx}>
                                    <div className="modal-dialog">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title">{fe.label}</h5>

                                                <div className="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close">
                                                    <span className="svg-icon svg-icon-2x"></span>
                                                </div>
                                            </div>
                                            <img src={src} />
                                            <div className="modal-body">
                                              <b>Image Type:</b> {fe.altFailureData.type} <br />
                                              <b>Detected text (Model 1):</b> {fe.altFailureData.asciiText} <br />
                                              <b>Detected text (Model 2):</b> {fe.altFailureData.rawText} <br />
                                              <b>Alt text</b> {fe.label}
                                            </div>

                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-primary" data-bs-dismiss="modal">Close</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            ) : null}
                          </div>
                        </div>
                      
                        <div data-container-id={selectedAudit.src == "lh" ? "failing-elements" : "failing-elements"} className='lh-node' ref={(nodeElement) => {nodeElement && (nodeElement.firstChild ? nodeElement.removeChild(nodeElement.firstChild) : true) && screenshotElement != null && nodeElement.appendChild(screenshotElement)}}/>
                      </div>
                        {/* <div className='d-flex w-100 mt-auto mb-2 ms-5'>
                          <ChangePriority showPriorityLabel={true} nonCompliance={feDb} />
                        </div> */}
                        <div className='d-flex w-100 mt-auto mb-2 ms-5'>
                          <ChangeStatus showStatusLabel={true} nonCompliance={feDb} key={feDb.id + feDb.status} onChangeNcStatus={(nonCompliance: NonComplianceModel) => {
                            const newFilteredNcList = filteredNcList.map(x => {
                              if (nonCompliance.id == x.id) {
                                return {
                                  ...x,
                                  status: nonCompliance.status,
                                };
                              }
                              return x;
                            });
                            ncList.forEach(x => {
                              if (nonCompliance.id == x.id) {
                                x.status = nonCompliance.status;
                              }
                            });
                            setFilteredNcList(newFilteredNcList);
                            }} />
                        </div>
                        <div className='d-flex w-100 mt-auto mb-2 ms-5'>
                          <Rectification showCommentsLabel={true} nonCompliance={feDb} key={`${feDb.id}${feDb.comments}`} onChangeNcComment={(nonCompliance: NonComplianceModel) => {
                            const newFilteredNcList = filteredNcList.map(x => {
                              if (nonCompliance.id == x.id) {
                                return {
                                  ...x,
                                  comments: nonCompliance.comments,
                                };
                              }
                              return x;
                            });
                            ncList.forEach(x => {
                              if (nonCompliance.id == x.id) {
                                x.comments = nonCompliance.comments;
                              }
                            });
                            setFilteredNcList(newFilteredNcList);
                            }} />
                        </div>
                      </div>
                    );
                  })
                }
              </div>
            </div>
        </div>
      </div>
      ) : null}
    </div>
  )
}

export {AuditSidebarDetail, ChangeStatus, ChangePriority, Rectification}
