import { FC } from "react";
import { Link } from "react-router-dom";
import { Settings } from "../../utils/config";
import { LandingFooter } from "./Footer";
import { BreadcrumbTitle, LandingHeader } from "./Header";

export const SiteMapPage: FC = () => {
    return (
    <div className="main-body-content">
        <LandingHeader />
        <div id="main-content">
            <BreadcrumbTitle>Site Map</BreadcrumbTitle>

            <section className="content-area">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12">
                            <p className="heading2">Primary Menu</p>
                            <ul className="heading3">
                                <li><Link to="/" title="Home">Home</Link></li>
                                <li><Link to="/background" title="Background">Background</Link></li>
                                <li><Link to="/using-sugamyaweb" title={"Using " + Settings.GamyataLabel}>Using {Settings.GamyataLabel}</Link></li>
                                <li><Link to="/dashboard" title="Dashboard">Dashboard</Link></li>
                                <li><Link to="/login" title="Login">Login</Link></li>
                            </ul>
                        </div>
                        <div className="col-xs-12">
                            <p className="heading2">Footer Menu</p>
                            <ul className="heading3">
                                <li><Link to="/background" title="Background">Background</Link></li>
                                <li><Link to="/using-sugamyaweb" title={"Using " + Settings.GamyataLabel}>Using {Settings.GamyataLabel}</Link></li>
                                <li><Link to="/terms" title="Terms & Conditions">Terms & Conditions</Link></li>
                                <li><Link to="/website-policies" title="Website Policies">Website Policies</Link></li>
                                <li><Link to="/contact" title="Contact Us">Contact Us</Link></li>
                                <li><Link to="/login" title="Login">Login</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <LandingFooter />
    </div>
    );
};