import { TestInstanceAuditDetails, TestRunModel } from "../modules/auth/models/TestRunModel";
import { Settings } from "../utils/config";
import { DOM } from "../utils/renderer/dom";
import { ElementScreenshotRenderer } from "../utils/renderer/element-screenshot-renderer";

const arrayStrStartsWith = (arr: Array<string>, str: string) => {
    if (!str) {
        return false;
    }
    for (const x of arr) {
        if (x.startsWith(str)) {
        return true;
        }
    }
    return false;
}

const colorNameMap: Record<string, string> = {
  'light-success': '#D1FFD3',
  'light-warning': '#FFF8DD',
  'light-danger': '#FFB8B8',
  'light-gray': '#7E8299',
  'light-primary': '#B2DFFF',
  'primary': '#0095E8',
};

export const getBgColorByName = (colorName?: string) => {
  if (colorName && colorNameMap[colorName]) {
    return colorNameMap[colorName];
  }
  return '#FFF';
}

export const getImpactColor = (impact: string) => {
  let impactColor = 'light-primary';
    if (impact == 'critical') {
      impactColor = 'light-danger';
    } else if (impact == 'serious') {
      impactColor = 'light-warning';
    } else if (impact == 'moderate') {
      impactColor = 'light-gray';
    } else if (impact == 'minor') {
      impactColor = 'light-primary';
    }
    return getBgColorByName(impactColor);
}

export const getScoreBgColor = (score?: number) => {
  if (!score) {
    return '#FFF';
  }
  if (score > 90) {
    return getBgColorByName('light-success');
  } else if (score > 40){
    return getBgColorByName('light-warning');
  }
  return getBgColorByName('light-danger');
}

export const getScoreClass = (score?: number) => {
  if (!score) {
    return '';
  }
  if (score > 90) {
    return 'green';
  } else if (score > 40){
    return 'orange';
  }
  return 'red';
}

export const getStatusClass = (status: string) => {
  if (status == 'COMPLETE') {
    return 'green';
  } else if (status == 'PARTIALLY COMPLETE'){
    return 'orange';
  } else if (status == 'RUNNING' || status == 'PENDING'){
    return 'orange';
  } 
  return 'red';
}

export const getScoreHex = (score?: number) => {
  if (!score) {
    return '';
  }
  if (score > 90) {
    return '#32ba7c';
  } else if (score > 40){
    return '#d88727';
  }
  return '#f15249';
}

const domParser = new DOMParser();

export const cleanString = (str?: string): string => !str ? '' : domParser.parseFromString(str, 'text/html').body.textContent || '';

export function getUserRoleLabel(role: string) {
  if (!role) {
      return '';
  }
  if (role.toUpperCase() == 'SUPERADMIN') {
      return "Super Admin";
  }
  if (role.toUpperCase() == 'ADMIN') {
      return Settings.AdminLabel;
  }
  if (role.toUpperCase() == 'TESTER') {
      return Settings.TesterLabel;
  }
}

export const STANDARD_TEST_NAME = 'Standard';

export const getFrequencyText = (cron: string) => {
  if (!cron) {
    return '';
  }
  const parts = cron.split(' ');
  if (parts[2] == '*' && parts[3] == '*') {
      return 'Weekly';
  }
  if (parts[2] != '*' && parts[3] == '*') {
      return 'Monthly';
  }
  return '';
};

export function formatNumber(number: number) {
  const suffixes = ['', 'k', 'M', 'B', 'T']; // Add more suffixes for larger numbers if needed
  const suffixIndex = Math.floor(Math.log10(Math.abs(number)) / 3);
  const scaledNumber = number / Math.pow(1000, suffixIndex);
  const formattedNumber = scaledNumber.toFixed(1).replace(/\.0$/, ''); // Remove decimal if it's zero
  return formattedNumber + suffixes[suffixIndex];
}

export const getProgress = (created: number, pageCnt: number, totalCnt: number): number => {
  const diff = new Date().getTime() - new Date(created).getTime();
  // 1% takes 1 seconds => 100% takes 100 seconds
  // but if 2 pages already complete out of 4 then 50%. 
  return Math.min(Math.max(Math.round((totalCnt - pageCnt) / totalCnt), Math.round(diff / (1000 * pageCnt))), 95);
};

export const getRemainingCnt = (data: TestRunModel) => {
  return data.testInstances ? data.testInstances.filter(x => x.status != 'FAILURE' && x.status != 'COMPLETE').length : 1;
};

export const AUDIT_IMPACT_WEIGHTS: Record<string, number> = {minor: 1, moderate: 2, serious: 3, critical: 4}

export const compileAuditDetailsWithAllTests = (auditDetails: TestInstanceAuditDetails) => {
  const result: Array<AuditDetails> = auditDetails.auditDetails || [];
  const passedTests = auditDetails.passedTests || [];
  const warnedTests = auditDetails.warnedTests || [];
  return result.concat(passedTests.map(x => ({
      id: x,
      failingElements: [],
      status:	'Pass',
  })), warnedTests.map(x => ({
      id: x,
      failingElements: [],
      status:	'Warn',
  })));
}

export const rgbToHex = (rgb: Array<number>): string => {
  return "#" + ((1 << 24) + (rgb[0] << 16) + (rgb[1] << 8) + rgb[2]).toString(16).slice(1);
}

export const installScreenshot = (containerId: string, screenshotDetails: any, tab: number, multiple?: boolean) => {
  if (screenshotDetails == null) {
    return;
  }
  const newElementId = "screenshotDefinition" + (multiple ? Math.random() : '');
  const exist = document.getElementById(newElementId);
  if (exist != null) {
    exist.parentElement?.appendChild(exist.children[0]);
    exist.parentNode?.removeChild(exist);
  }

  const element = document.getElementById(containerId);
  const root: any = document.createElement('div');
  root.setAttribute('id', newElementId)
    if (root != null) {
      const dom = new DOM(root.ownerDocument);
      const stopPropogationEvent = (e: any) => e.stopImmediatePropagation();
      ElementScreenshotRenderer.installFullPageScreenshot(root, screenshotDetails.screenshot);
      if (tab == 0) {
      ElementScreenshotRenderer.installOverlayFeature({
          dom: dom,
          reportEl: root,
          overlayContainerEl: root,
          fullPageScreenshot: screenshotDetails,
      });
      root.removeEventListener('click', stopPropogationEvent);
      } else {
      root.addEventListener('click', stopPropogationEvent, true);
      root.classList.remove('lh-screenshot-overlay--enabled');
      } 
    }
    // set the wrapper as child (instead of the element)
    element?.parentElement?.replaceChild(root, element);
    // set element as child of wrapper
    root.appendChild(element);
}

export function getFilteredAudits(testReportingDetails: TestReportingDetails, audits: Array<AuditDetails>, filter: string, category: string, wcagLevel: string, wcagCriterion: string, selectedTest?: string) {
  return Object.values(audits).filter((audit: AuditDetails) => {
    const x = testReportingDetails[audit.id];
    if (!x) {
      // details not yet loaded
      return true;
    }
    if (selectedTest != null) {
      return x.title == selectedTest;
    }
    let f = filter == "All"  || filter == audit.status;
    if (!f) {
      return f;
    } 

    f = category == "All"  || x.category == category;
    if (!f) {
      return f;
    } 

    if (!x.tags) {
      return true;
    }

    if (wcagLevel == "Best Practices") {
      f = !arrayStrStartsWith(x.tags, "wcag");
      if (!f) {
        return f;
      }
    } else {
      f = wcagLevel == "All" || x.tags.includes(wcagLevel);
      if (!f) {
        return f;
      }
    }

    f = wcagCriterion == "All" || x.tags.includes(wcagCriterion);
    if (!f) {
      return f;
    }
    return true;
    
    //return false;
    /*if (filterTag.length == 0) {
      return true;
    }
    let isTagPresent = false;
    for (const t of filterTag) {
      if (x.tags.includes(t)) {
        isTagPresent = true;
        break;
      }
    }
    return isTagPresent;*/
    
  }).sort((x, y) => (y.failingElements?.length - x.failingElements?.length))
}

export const criteriaNumbersByLevels: Record<string, string[]> = {
  'wcag 2.0 level a': ['1.1.1', '1.2.1', '1.2.2', '1.2.3', '1.3.1', '1.3.2', '1.3.3', '1.4.1', '1.4.2', '2.1.1', '2.1.2', '2.2.1', '2.2.2', '2.3.1', '2.4.1', '2.4.2', '2.4.3', '2.4.4', '3.1.1', '3.2.1', '3.2.2', '3.3.1', '3.3.2', '4.1.1', '4.1.2'],
  'wcag 2.0 level aa': ['1.2.4', '1.2.5', '1.4.3', '1.4.4', '1.4.5', '2.4.5', '2.4.6', '2.4.7', '3.1.2', '3.2.3', '3.2.4', '3.3.3', '3.3.4'],
  'wcag 2.0 level aaa': ['1.2.6', '1.2.7', '1.2.8', '1.2.9', '1.4.6', '1.4.7', '1.4.8', '1.4.9', '2.1.3', '2.2.3', '2.2.4', '2.2.5', '2.3.2', '2.4.8', '2.4.9', '2.4.10', '3.1.3', '3.1.4', '3.1.5', '3.1.6', '3.2.5', '3.3.5', '3.3.6'],
  'wcag 2.1 level a': ['1.1.1', '1.2.1', '1.2.2', '1.2.3', '1.3.1', '1.3.2', '1.3.3', '1.4.1', '1.4.2', '2.1.1', '2.1.2', '2.2.1', '2.2.2', '2.3.1', '2.4.1', '2.4.2', '2.4.3', '2.4.4', '3.1.1', '3.2.1', '3.2.2', '3.3.1', '3.3.2', '4.1.1', '4.1.2', '2.1.4', '2.5.1', '2.5.2', '2.5.3', '2.5.4'],
  'wcag 2.1 level aa': ['1.2.4', '1.2.5', '1.4.3', '1.4.4', '1.4.5', '2.4.5', '2.4.6', '2.4.7', '3.1.2', '3.2.3', '3.2.4', '3.3.3', '3.3.4', '1.3.4', '1.3.5', '1.4.10', '1.4.11', '1.4.12', '1.4.13', '4.1.3'],
  'wcag 2.1 level aaa': ['1.2.6', '1.2.7', '1.2.8', '1.2.9', '1.4.6', '1.4.7', '1.4.8', '1.4.9', '2.1.3', '2.2.3', '2.2.4', '2.2.5', '2.3.2', '2.4.8', '2.4.9', '2.4.10', '3.1.3', '3.1.4', '3.1.5', '3.1.6', '3.2.5', '3.3.5', '3.3.6', '1.3.6', '2.2.6', '2.3.3', '2.5.5', '2.5.6'],
}

export const defaultLineHighChartOptions: Highcharts.Options = {
  chart: {
      polar: true,
      type: 'line'
  },
  xAxis: {
      visible: false,
  },
  yAxis: {
      // min: 0,
      // max: 100,
      title: {
          text: undefined,
          useHTML: true,
          style: {
              fontSize: '13'
          }
      },
      labels: {
          style: {
              fontSize: '13'
          }
      }
  },
  title: {
      text: undefined,
  },

  plotOptions: {
      line: {
          dataLabels: {
              enabled: true,
              color: '#fff',
              style: {
                fontSize: '20px',
              },
              formatter: function () {
                  return formatNumber(this.y || 0);
              }
          },
      },
      series: {
          marker: {
              enabled: true,
              radius: 20,
          },
      }
  },
  series: [{
      type: 'line',
      showInLegend: false,
      color: '#49a0f0',
      lineColor: '#ababab',
      colorByPoint: true,
  }],
  tooltip: {
      formatter: function () {
          return '<b>' + this.series.name + ' :' + '<span className="trend-value">' + '&nbsp;' + formatNumber(this.y || 0) + '</span>' + '</b>' + '<br />' + '<b>' + this.point.name + '</b>';
      }
  },
};

export const defaultLineHighChartOptionsSeries: Highcharts.SeriesOptionsType = {
  type: 'line',
  showInLegend: false,
  color: '#49a0f0',
  lineColor: '#ababab',
  colorByPoint: true,
};
