/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, {FC, Suspense} from 'react'
import {Redirect, Switch, Route, withRouter} from 'react-router-dom'
import {shallowEqual, useSelector} from 'react-redux'
import {PrivateRoutes} from './PrivateRoutes'
import {Logout} from '../modules/auth'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {RootState} from '../../setup'
import { Login } from '../modules/auth/components/Login'
import { HomePage } from '../pages/landing/Home'
import { UsingGamyataPage } from '../pages/landing/UsingGamyata'
import { TermsPage } from '../pages/landing/Terms'
import { WebsitePoliciesPage } from '../pages/landing/WebsitePolicies'
import { OnboardingRequest } from '../pages/landing/OnboardingRequest'
import { BackgroundPage } from '../pages/landing/Background'
import { ContactPage } from '../pages/landing/Contact'
import { MockLogin } from '../modules/auth/components/MockLogin'
import { SiteMapPage } from '../pages/landing/SiteMap'
import { MobileHomePage } from '../../mobile/Home'
import { LayoutProvider, LayoutSplashScreen } from '../../_monic/layout/core'
import AuthInit from '../modules/auth/redux/AuthInit'
import { Settings } from '../utils/config'

const Routes: FC = () => {
  const isAuthorized = useSelector<RootState>(({auth}) => auth.user, shallowEqual)

  return (
    <LayoutProvider>
      <AuthInit>
    <Switch>
      <Route path='/logout'  render={() => <Logout isMobile={false} />} />
      <Route path='/error' component={ErrorsPage} />      
      
      <Route path='/auth/mock' component={MockLogin} /> 
      <Route path='/auth/login' render={() => <Login isMobile={false} />} /> 
      <Redirect from='/auth' exact={true} to='/auth/login' />
      <Redirect from='/login' exact={true} to='/auth/login' />


      {/* Landing pages */}
      <Route path='/contact' component={ContactPage} />
      <Route path='/sitemap' component={SiteMapPage} />
      <Route path='/using-sugamyaweb' component={UsingGamyataPage} />
      <Route path='/terms' component={TermsPage} />
      <Route path='/website-policies' component={WebsitePoliciesPage} />      
      <Route path='/background' exact component={BackgroundPage} />
      <Route path='/onboarding' component={OnboardingRequest} />
      <Route exact path="/" render={() => {
          if (Settings.isGamyata) {
            return <HomePage />;
          }
          window.location.href = '/landing/index.html';
          return null;
      }} />

      {!isAuthorized ? (
        /*Redirect to home when user is not authorized*/
        <Redirect to='/' />
      ) : (
          <PrivateRoutes />
      )}
      
      </Switch>
       </AuthInit>
     </LayoutProvider>
    
    
  )
}

export {Routes}
