import React, { useEffect, useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { RootState } from '../../../setup';
import { getUrlPrefix } from '../../modules/utils';
import { deleteApiKey, getApiKeys } from '../../modules/auth/redux/AuthCRUD';
import { ApiKeyModel } from '../../modules/auth/models/AuthModel';
import moment from 'moment';
import { AddApiKey } from './AddApiKey';

const ManageApiKey: React.FC = () => {
    const { pathname } = useLocation();
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState<Array<ApiKeyModel>>();
    const [dataError, setDataError] = useState<{ error: string }>();
    const isMobile = useSelector<RootState>(({ auth }) => auth.isMobile, shallowEqual)
    const urlPrefix = getUrlPrefix(isMobile, pathname.startsWith('/v2') ? '/v2' : undefined);

    const fetchData = () => {
        setIsLoading(true);
        getApiKeys().then((result) => setData(result.data))
            .catch(e => setDataError({ error: "Server Error! Cannot fetch data!" }))
            .finally(() => setIsLoading(false));
    }

    useEffect(fetchData, []);

    if (isLoading) {
        return (
            <div>
                <br />
                <strong>
                    <span>Loading... Please wait... </span> <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </strong>
            </div>
        );
    }

    if (dataError || data == null) {
        return (
            <div className='card mb-5 mb-xl-10'>
                <div
                    className='card-header border-0'
                >
                    {dataError ? (
                        <div>
                            <br />
                            <div className='mb-lg-15 alert alert-danger d-flex flex-column-fluid flex-center'>
                                <div className='alert-text font-weight-bold'>{dataError.error}</div>
                            </div>
                        </div>
                    ) : (
                        <div>
                            <br />
                            <span>Loading... </span> <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </div>
                    )}
                </div>
            </div>
        );
    }

    const onDelete = (id: string) => {
        if (!window.confirm('Are you sure want to delete?')) {
            return;
        }
        deleteApiKey(id).then(() => {
            setData(data?.filter(x => x.id != id));
        });
    }

    return (
        <>
            <div className="bredcrumb-cnntr">
                <ul>
                    <li><Link to={`${urlPrefix}/dashboard`}>Home</Link></li>
                    <li>Manage API Keys</li>
                </ul>
            </div>
            <div className="site-head">
                <h1 className="main-title">Manage API Keys</h1>
                <Link to={`${urlPrefix}/core/apikeys/add`} className="btn btn-red" data-target="#details-popup">
                    <em className="fa fa-plus" aria-hidden="true"></em> Add API Key
                </Link>
            </div>
            <div className="project-list">
                <table className="weblist-table add-manage-test">
                    <thead>
                        <tr>
                            <th scope="col">Service Name</th>
                            <th scope="col">Service Key</th>
                            <th scope="col">API Key</th>
                            <th scope="col">Created</th>
                            <th scope="col">Expiration</th>
                            <th scope="col" className="action">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data?.length > 0 ? null : 'No data to display'}
                        {data?.map(row => {
                            const created = row.created ? moment(row.created).tz('Asia/Kolkata') : null;
                            const expiration = row.expiration ? moment(row.created).tz('Asia/Kolkata') : null;
                            return (
                            <tr>
                                <td className="website-name add-test">
                                    {row.serviceName} <br />
                                </td>
                                <td>{row.serviceKey}</td>
                                <td>{row.key}</td>
                                <td>{row.created}</td>
                                <td>{row.expiration}</td>
                                <td className="action">
                                    <Link className='edit'
                                        to={`#`}
                                        onClick={() => {
                                            onDelete(row.id);
                                        }}>
                                        <em className="fa fa-trash-o" aria-hidden="true"></em>
                                    </Link>
                                </td>
                            </tr>
                        )})}
                    </tbody>
                </table>
            </div>
            <AddApiKey onSave={fetchData} />
        </>
    )
}

export { ManageApiKey }
