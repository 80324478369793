/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import {KTSVG} from '../../../../_monic/helpers'
import {getWebsites} from '../../auth/redux/TestRunCRUD';
import {Link, useLocation} from 'react-router-dom';
import {Website} from '../../auth/models/TestRunModel';
import {getScoreColor} from './../../testmgmt/components/TestResult';
import _ from 'lodash';
import { Fetcher } from './OverviewTab';
import { useSelector, shallowEqual } from 'react-redux';
import { RootState } from '../../../../setup';
import { UserModel } from '../../auth/models/UserModel';
import { getUrlPrefix } from '../../utils';

const MyWebsites: React.FC = () => {
  const {pathname}: any = useLocation();
  const [data, setData] = useState<Array<Website>>();
  const [dataError, setDataError] = useState<{error: string}>();
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const isMobile = useSelector<RootState>(({auth}) => auth.isMobile, shallowEqual)
  const urlPrefix = getUrlPrefix(isMobile);
  const isHeatMap = pathname.split(urlPrefix + '/activities/reports/mywebsites/')[1] == 'heatmap';
  useEffect(() => {
    const where: any = {};
    if (isHeatMap) {
      where.latestScore = {neq: null};
    }
    getWebsites(where, ['latestScore DESC']).then((result) => setData(result.data)).catch(e => setDataError({error: "Server Error! Cannot fetch data!"}));
  }, [isHeatMap]);

  if (dataError || data == null) {
    return (
      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          {dataError ? (
            <div>
              <br />
                <div className='mb-lg-15 alert alert-danger d-flex flex-column-fluid flex-center'>
                  <div className='alert-text font-weight-bold'>{dataError.error}</div>
                </div>
                </div>
          ) : (
            <div>
              <br />
              <span>Loading... </span> <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
           </div>
        )}
        </div>
      </div>
      );
  }

  return (
    <div className={`card`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>Websites {isHeatMap ? 'Heatmap' : 'Dashboard'}</span>
        </h3>        
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      
      {/* begin::Table container */}
      <div className='card-body py-3'>
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-full-bordered table-row-gray-100 align-middle gs-0 gy-3'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bolder'>
                <th className='min-w-150px'>URL</th>
                <th className='min-w-70px'>Score</th>
                <th className='min-w-140px'>Last Tested</th>
                <th className='min-w-100px text-end'>Page Count</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
            {data?.map((row) => {
              const encodedUrl = encodeURIComponent(row.url);
              return ( 
                       <tr key={encodedUrl} className={`row-light-${isHeatMap && row.latestScore ? getScoreColor(row.latestScore) : 'gray'}`}>
                       <td>
                         <Link title={row.url} to={urlPrefix + `/activities/testmgmt/manageresults/${row.id}/${row.name}`} className='fw-bolder text-primary-900 text-hover-info fs-6'>
                          {row.url}
                         </Link>
                       </td>
                       <td>
                        <b>{row.latestScore}</b>
                       </td>
                       <td>
                         <Link title={row.url} to={urlPrefix + `/activities/testmgmt/testresult/${row.latestTestRunId}`} className='text-primary-900 text-hover-info d-block mb-1 fs-6'>
                           {row.latestTestTime && new Date(row.latestTestTime).toLocaleString('en-IN')}
                         </Link>
                       </td>
                       <td>
                        {row.latestCntPages}
                       </td>
                       {/* <td className='text-end'>
                           <Link title={row.baseUrl} className='test' to={urlPrefix + `/activities/testmgmt/manageresults/${t.id}/${t.name}`}>
                            <KTSVG path='/media/icons/duotune/communication/com012.svg' className='svg-icon-3' />
                           </Link>
                       </td> */}
                     </tr>
                     );
                     })}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export {MyWebsites}
