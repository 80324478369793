import React from 'react'
import {PageLink, PageTitle} from '../../../_monic/layout/core'
import {Redirect, Route, Switch} from 'react-router-dom'
import {CreateUser} from './components/CreateUser'
import {ManageUser} from './components/ManageUser'
import { EditUser } from './components/EditUser'
import { ActivityLog } from './components/ActivityLog'
import { LoadPdf } from '../../pages/landing/LoadPdf'
import { WelcomePageTitle } from '../../pages/dashboard/DashboardWrapper'
import { ViewProfile } from './components/ViewProfile'

const breadCrumbs: Array<PageLink> = [
  {
    title: 'Users',
    path: '/core/users/manage',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const UserManagementPage: React.FC = (props: any) => {
  return (
    <>
    <Switch>
        <Route path='/core/users/create/:role/:organizationId'>
        <WelcomePageTitle />
        {/* <PageTitle breadcrumbs={breadCrumbs}>Create User</PageTitle> */}
          <CreateUser />
        </Route>
        <Route path='/core/users/create/:role'>
        <WelcomePageTitle />
        {/* <PageTitle breadcrumbs={breadCrumbs}>Create User</PageTitle> */}
          <CreateUser />
        </Route>
        <Route path='/core/users/create'>
        <WelcomePageTitle />
        {/* <PageTitle breadcrumbs={breadCrumbs}>Create User</PageTitle> */}
          <CreateUser />
        </Route>
        <Route path='/core/users/view'>
        <WelcomePageTitle />
        {/* <PageTitle breadcrumbs={breadCrumbs}>Create User</PageTitle> */}
          <ViewProfile />
        </Route>
        <Route path='/core/users/edit/:id'>
        <WelcomePageTitle />
        {/* <PageTitle breadcrumbs={breadCrumbs}>Edit User</PageTitle> */}
          <EditUser/>
        </Route>
        <Route path='/core/users/onboarding'>
        <WelcomePageTitle />
        {/* <PageTitle breadcrumbs={breadCrumbs}>Manage Onboarding</PageTitle> */}
          <ManageUser className='mb-5 mb-xl-8' onboarding={true} />
        </Route>
        <Route path='/core/users/downloadonboarding/:id'>
        <WelcomePageTitle />
        {/* <PageTitle breadcrumbs={breadCrumbs}>Load PDF</PageTitle> */}
          <LoadPdf />
        </Route>
        <Route path='/core/users/manage/:role/:organizationId'>
        <WelcomePageTitle />
        {/* <PageTitle breadcrumbs={breadCrumbs}>Manage User</PageTitle> */}
          <ManageUser className='mb-5 mb-xl-8' />
        </Route>
        <Route path='/core/users/manage/:role'>
        <WelcomePageTitle />
        {/* <PageTitle breadcrumbs={breadCrumbs}>Manage User</PageTitle> */}
          <ManageUser className='mb-5 mb-xl-8' />
        </Route>
        <Route path='/core/users/manage'>
        <WelcomePageTitle />
        {/* <PageTitle breadcrumbs={breadCrumbs}>Manage User</PageTitle> */}
          <ManageUser className='mb-5 mb-xl-8' />
        </Route>
        <Route path='/core/users/activitylogs'>
        <WelcomePageTitle />
        {/* <PageTitle breadcrumbs={breadCrumbs}>Activity Logs</PageTitle> */}
          <ActivityLog className='mb-5 mb-xl-8' />
        </Route>
        <Redirect from='/core/users' exact={true} to='/core/users/manage' />
        <Redirect to='/core/users/manage' />
      </Switch>      
    </>
  )
}

export default UserManagementPage
