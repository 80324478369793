import moment from "moment";
import { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap-v5";
import { WebsiteByOrg } from "../../modules/auth/models/OrganizationModel";
import { SortedTh } from "../layout/MasterLayout";
import { cleanString } from "../utils";


export const WebsiteAccordion: React.FC<{ data: Array<WebsiteByOrg>, search: string, renderActions: any, includeTestScheduleCount?: boolean }> = ({ data, search, renderActions, includeTestScheduleCount }) => {
    const [sortColumn, setSortColumn] = useState<string>();
    const [sortDirection, setSortDirection] = useState<string>();
    const [expandedTabs, setExpandedTabs] = useState(new Set());
    const [filteredData, setFilteredData] = useState<Array<WebsiteByOrg>>();
    useEffect(() => {
        const newFilteredData = data?.filter(x => {
            if (search) {
                return x.websites?.some(w => cleanString(w.name.toLowerCase()).includes(search.toLowerCase()) || w.url.toLowerCase().includes(search.toLowerCase()));
            }
            return true;
        });
        const newExpandedTabs = search ? new Set(Array.from(Array(newFilteredData.length).keys())) : new Set();
        setFilteredData(newFilteredData);
        setExpandedTabs(newExpandedTabs);
    }, [search]);
    
    
    const toggleAccordion = (index: number) =>  {
        expandedTabs.has(index) ? expandedTabs.delete(index) : expandedTabs.add(index);
        setExpandedTabs(new Set(Array.from(expandedTabs)));
    }
    return (
        <Accordion>
            {data?.length > 0 ? null : <div className='row'><div className="col-md-12">No data to display</div></div>}
            {filteredData?.map((websiteByOrg, dataIndex) => {
                const filteredWebsites = websiteByOrg.websites?.filter(w => search ? cleanString(w.name.toLowerCase()).includes(search.toLowerCase()) || w.url.toLowerCase().includes(search.toLowerCase()) : true);
                return (
                    <div key={'org-' + websiteByOrg.department.id + dataIndex} className="projlist-item">
                        <Accordion.Toggle eventKey={`${dataIndex}`} className={`project ${expandedTabs.has(dataIndex) ? 'active' : ''}`} onClick={() => toggleAccordion(dataIndex)}>
                            <strong>{cleanString(websiteByOrg.department.name)}</strong>
                            <span className="websites">
                                <strong>{filteredWebsites?.length || 0}</strong> Websites
                            </span>
                        </Accordion.Toggle>
                        <Accordion.Collapse in={expandedTabs.has(dataIndex)} eventKey={`${dataIndex}`}>
                            <div className="accord-panel" style={{ display: 'block' }}>
                                <table className="weblist-table manage-webpages-table">
                                    <thead>
                                        <tr>
                                            <SortedTh fieldName={'name'}
                                                sortColumn={sortColumn}
                                                setSortColumn={setSortColumn}
                                                sortDirection={sortDirection}
                                                setSortDirection={setSortDirection}>Name</SortedTh>
                                            <th scope="col">Assigned</th>
                                            <SortedTh fieldName={'latestTestTime'}
                                                sortColumn={sortColumn}
                                                setSortColumn={setSortColumn}
                                                sortDirection={sortDirection}
                                                setSortDirection={setSortDirection}>Latest Run</SortedTh>
                                            <SortedTh fieldName={'latestScore'}
                                                sortColumn={sortColumn}
                                                setSortColumn={setSortColumn}
                                                sortDirection={sortDirection}
                                                setSortDirection={setSortDirection}>Score</SortedTh>
                                            {includeTestScheduleCount ? <th scope="col">Test Count</th> : null}
                                            <th scope="col" className="action">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredWebsites?.map(website => {
                                            const latestTestTime = website.latestTestTime ? moment(website.latestTestTime).tz('Asia/Kolkata') : null;
                                            return (
                                                <tr key={'website-' + website.id}>
                                                    <td>
                                                        {cleanString(website.name)} <br />
                                                        <small>{website.url}</small>
                                                    </td>
                                                    <td>{website.user?.parichay?.fullName || website.user?.email}</td>
                                                    <td>{latestTestTime ? <><strong>{latestTestTime.format('DD MMM YYYY')}</strong><br />{latestTestTime.format('h:mm:ss A')}</> : null}</td>
                                                    <td><strong className="red">{website.latestScore}</strong></td>
                                                    {includeTestScheduleCount ? <td><strong className="red">{website.testScheduleCount}</strong></td> : null}
                                                    <td>
                                                        {renderActions(website)}
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </Accordion.Collapse>
                    </div>
                )
            })}
        </Accordion>
    );
}
