/* eslint-disable jsx-a11y/anchor-is-valid */
import {Card2} from './Card2';
import {DetailModal} from './DetailModal';
import {AuditSidebarDetail} from './AuditSidebarDetail';
import {KTSVG} from '../../../../_monic/helpers';
import {useParams} from 'react-router';
import {retryTestInstance} from '../../auth/redux/TestRunCRUD';
import { NonComplianceModel } from '../../auth/models/TestRunModel';

type Props = {
  instanceId: number | undefined,
  url: string | undefined,
  status: string | undefined,
  auditDetails: Array<AuditDetails>, 
  testReportingDetails: TestReportingDetails,
  tab: number, 
  setTab: React.Dispatch<React.SetStateAction<number>>, 
  screenshotDetails: any,
  andiScreenshotDetails: any,
  prevStep: () => void,
  cntTotalIssues: number,
  tags: Array<string>,
  categories: Array<string>,
  ncList: NonComplianceModel[],
  printOnly?: boolean,
  selectedTest?: string
}

export const ResultDetails: React.FC<Props> = ({instanceId, url, status, cntTotalIssues, auditDetails, testReportingDetails, tags, categories, tab, screenshotDetails, andiScreenshotDetails, ncList, prevStep, printOnly, selectedTest}) => {
  return (
    <div className='flex-lg-row-fluid'>
      <div className='d-flex flex-wrap flex-stack mb-6 bg-white' style={{padding: 5}}>
        <div className='mr-2'>
          <button
            onClick={prevStep}
            type='button'
            className='btn btn-lg text-primary fw-bolder me-3'
            data-kt-stepper-action='previous'
          >
            {'<<'} Back
          </button>
        </div>

        <h3 className='fw-bolder my-2'>
          {url}
          {/* {!selectedTest && cntTotalIssues > 0 ? (<span className='badge badge-lg badge-light-danger mx-2'>{cntTotalIssues} Issues</span>): null} */}
        </h3>  

        

        <div className='d-flex flex-wrap my-2'>
          {/* <div className='me-4'>
          <ul className='nav nav-pills nav-pills-custom'>
              <li className='nav-item mb-3'>
              <a onClick={() => setTab(0)} href='#' className={`btn btn-sm btn-icon btn-light btn-color-muted btn-active-primary me-3 ${tab == 0 ? 'active': ''}`}>
              <KTSVG
                path='/media/icons/duotune/abstract/abs015.svg'
                className='svg-icon-4 me-1'
              />
              </a>
              </li>
              <li className='nav-item mb-3'>
              <a onClick={() => setTab(1)} href='#' className={`btn btn-sm btn-icon btn-light btn-color-muted btn-active-primary me-3 ${tab == 1 ? 'active': ''}`}>
              <KTSVG
                path='/media/icons/duotune/general/gen024.svg'
                className='svg-icon-4 me-1'
              />
              </a>
              </li>
              </ul>
          </div> */}
        </div>
      </div>

      <div id='failing-elements' className='row g-6 g-xl-9'>
      {status != "COMPLETE" && status != "PARTIALLY COMPLETE" ? (
            <div className='card mb-5 mb-xl-10'>
            <div
                className='card-header border-0 cursor-pointer'
                role='button'
                data-bs-toggle='collapse'
                data-bs-target='#kt_account_profile_details'
                aria-expanded='true'
                aria-controls='kt_account_profile_details'
            >
                {status == "FAILURE" ? (
                <div>
                    <br />
                    <div className='mb-lg-15 alert alert-danger d-flex flex-column-fluid flex-center'>
                        <div className='alert-text font-weight-bold'>Server Error! Cannot complete the test! Click <a href="#" onClick={() => instanceId && retryTestInstance(instanceId)}>here</a> to retry again</div>
                    </div>
                    </div>
                ) : (
                <div>
                    <br />
                    <span>Loading... </span> <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </div>
            )}
            </div>
            </div>
        ) : (
          <AuditSidebarDetail url={url} audits={auditDetails} testReportingDetails={testReportingDetails} ncList={ncList} screenshotDetails={screenshotDetails} andiScreenshotDetails={andiScreenshotDetails} tags={tags} categories={categories} selectedTest={selectedTest} /> 
        )}
      </div>
    </div>
  )
}
