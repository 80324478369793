import React, { useEffect, useState } from 'react'
import {AsideDefault} from './components/aside/AsideDefault'
import {Toolbar} from './components/toolbar/Toolbar'
import {Content} from './components/Content'
import {MasterInit} from './MasterInit'
import {PageDataProvider, useLayout} from './core'
import {
  Main,
} from '../partials'
import clsx from 'clsx'
import { useQuery } from '../../app/modules/utils'
import { LandingHeader } from '../../app/pages/landing/Header'
import { LandingFooter } from '../../app/pages/landing/Footer'


const MasterLayout: React.FC<{useV2?: boolean}> = ({children, useV2}) => {
  const {classes} = useLayout()
  const queryParams: URLSearchParams = useQuery();
  const utmSource = decodeURIComponent(queryParams.get('utm_source') || '');
  const isExtensionView = utmSource == 'gamyata-chrome-ext';
  const [stylesLoaded, setStylesLoaded] = useState(false);

  useEffect(() => {
    Promise.all([
      // @ts-ignore
      import('../assets/sass/style.scss'),
      // @ts-ignore
      import('../assets/sass/style.react.scss')
    ]).then(() => {
      setStylesLoaded(true);
    });
  }, []);

  if (!stylesLoaded) {
    return null;
  }

  return (
    <PageDataProvider>
      <div className='page d-flex flex-row flex-column-fluid'>
        <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
          <LandingHeader aside={true} />
          <Toolbar />
          <div id='main-content' className='content d-flex flex-column flex-column-fluid'>
            <div
              className={clsx(
                'd-flex flex-column-fluid align-items-start',
                classes.contentContainer.join(' ')
              )}
              id='kt_post'
            >
            {isExtensionView ? null : (
              <AsideDefault />
            )}
              <Content>{children}</Content>
            </div>
          </div>
          
          <LandingFooter />
        </div>
      </div>

      {/* begin:: Drawers */}
      {/* <ActivityDrawer /> */}
      
      {/* <DrawerMessenger /> */}
      {/* end:: Drawers */}

      {/* begin:: Modals */}
      <Main />
      {/* <InviteUsers />
      <UpgradePlan /> */}
      {/* end:: Modals */}

      <MasterInit />
      {/* <ScrollTop /> */}
    </PageDataProvider>
  )
}

export {MasterLayout}
