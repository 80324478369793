import React, {useState} from 'react'
import {Redirect} from 'react-router-dom'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {createOrganization} from '../../auth/redux/OrganizationCRUD'
import {FORM_STATUSES} from '../../utils'

const organizationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
})

const CreateOrganization: React.FC = () => {
  const initialValues = {name: ''};
  const [loading, setLoading] = useState(false)
  const formik = useFormik<any>({
    initialValues,
    validationSchema: organizationSchema,
    onSubmit: (values, {setStatus}) => {
      setLoading(true);
      createOrganization(values.name).then(({data: {id}}) => {
        setLoading(false);
        setStatus({state: FORM_STATUSES.SUCCESS, response: {id}});
      }).catch(() => {
        setLoading(false);
        setStatus({state: FORM_STATUSES.FAILURE, response: {error: 'Server Error!'}});
      });
    },
  })

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_create_organization'
        aria-expanded='true'
        aria-controls='kt_create_organization'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Create Organization</h3>
        </div>
      </div>

      {formik.status && formik.status.state == FORM_STATUSES.SUCCESS ? (
        <Redirect to={'manage'} />
      ) : null}

      {formik.status && formik.status.state == FORM_STATUSES.FAILURE ? (
        <div className='mb-lg-15 alert-danger'>
            <div className='alert-text font-weight-bold'>{formik.status.response?.error}</div>
        </div>
      ) : null}

      <div id='kt_create_organization' className='collapse show'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>
            
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Organization Name</label>

              <div className='col-lg-8'>
                <div className='row'>
                  <div className='col-lg-6 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      placeholder='Organization name'
                      {...formik.getFieldProps('name')}
                    />
                    {formik.touched.name && formik.errors.name && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.name}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>              

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-primary' disabled={loading}>
              {!loading && 'Save'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export {CreateOrganization}
