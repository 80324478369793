import React from 'react'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router'
import {checkIsActive, KTSVG} from '../../../helpers'
import {useLayout} from '../../core'

type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
  skipReactRouter?: boolean
  externalLink?: boolean
}

const AsideMenuItem: React.FC<Props> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet = false,
  externalLink = false,
  skipReactRouter= false,
}) => {
  const {pathname} = useLocation()
  const isActive = checkIsActive(pathname, to)
  const {config} = useLayout()
  const {aside} = config

  const menuContent = (
    <>
        {hasBullet && (
          <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
        )}
        {icon && aside.menuIcon === 'svg' && (
          <span className='menu-icon'>
            <KTSVG path={icon} className='svg-icon-2' />
          </span>
        )}
        {fontIcon && aside.menuIcon === 'font' && <i className={clsx('bi fs-3', fontIcon)}></i>}
        <span className='menu-title'>{title}</span>
    </>
  )
  let content;
  if (externalLink) {
    content = (
      <a className={clsx('menu-link without-sub', {active: isActive})} 
      href={to}
      title={title}
      target="_blank"
      onClick={(e) => !window.confirm('You are being redirected to an external website. Please note that SugamyaWeb cannot be held responsible for external websites content & privacy policies.') && e.preventDefault()}>
        {menuContent}
      </a>
    )
  } else if (skipReactRouter) {
    content = (
      <a className={clsx('menu-link without-sub', {active: isActive})} 
      href={to}
      title={title}>
        {menuContent}
      </a>
    )
  } else {
    content = (
    <Link className={clsx('menu-link without-sub', {active: isActive})} to={to} title={title}>
      {menuContent} 
    </Link>
    )
  }

  return (
    <div className='menu-item'>
      {content}
      {children}
    </div>
  )
}

export {AsideMenuItem}
