import { FC } from "react";
import { Link, useLocation } from "react-router-dom";
import { checkIsActive } from "../_monic/helpers";

export const Footer: FC<{isLoggedIn: boolean}> = ({isLoggedIn}) => {
    const {pathname} = useLocation()
    return (
        <div id="footer-bar" className="footer-bar-1">
            <Link to="/m" className={checkIsActive(pathname, "/m") ? "active-nav" : ""}><i className="fa fa-home"></i><span>Home</span></Link>
            <Link to="/m/about"><i className="fa fa-book"></i><span>About</span></Link>
            {isLoggedIn ? <Link to="/m/dashboard" className={checkIsActive(pathname, "/m/dashboard") ? "active-nav" : ""}><i className="fa fa-area-chart"></i><span>Dash</span></Link> : null}
            {isLoggedIn ? <Link to="/m/logout" data-menu="menu-settings"><i className="fa fa-user"></i><span>Logout</span></Link> : 
            <Link to="/m/login" data-menu="menu-settings"><i className="fa fa-user"></i><span>Login</span></Link>}
        </div>
    );
};