/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {KTSVG} from '../../../../_monic/helpers'
import {Link} from 'react-router-dom'
import {getTestRuns} from '../../auth/redux/TestRunCRUD'
import {TestRunModel} from '../../auth/models/TestRunModel'
import {AxiosResponse} from 'axios'
import {TestRunsTab} from './TestRunsTab'
import { OverviewTab } from './OverviewTab'
import _ from 'lodash'
import { useSelector, shallowEqual } from 'react-redux'
import { RootState } from '../../../../setup'
import { getUrlPrefix } from '../../utils'

const tabs = [
  "overview",
  "testresults",
  "mediainventory"
];

const WebsiteDetail: React.FC = () => {
  const {url: encodedUrl, activeTab}: any = useParams();
  const [data, setData] = useState<Array<TestRunModel>>();
  const [dataError, setDataError] = useState<DataError>();
  const url = decodeURIComponent(encodedUrl);
  
  useEffect(() => {
    getTestRuns({baseUrl: url}).then((result: AxiosResponse) => setData(result.data)).catch(e => setDataError({error: "Server Error! Cannot fetch data!"}));
  }, []);

  const isMobile = useSelector<RootState>(({auth}) => auth.isMobile, shallowEqual)
  const urlPrefix = getUrlPrefix(isMobile) + "/activities/reports/website";

  if (dataError || data == null) {
    return (
      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          {dataError ? (
            <div>
              <br />
                <div className='mb-lg-15 alert alert-danger d-flex flex-column-fluid flex-center'>
                  <div className='alert-text font-weight-bold'>{dataError.error}</div>
                </div>
                </div>
          ) : (
            <div>
              <br />
              <span>Loading... </span> <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
           </div>
        )}
        </div>
      </div>
      );
  }
 
  const totalIssueCount = data.reduce((max, testRun) => Math.max(max,(testRun.stats?.cntTotalIssues || 0)), 0);

  let badgeClass = "badge-primary";

  let tabContent = <OverviewTab />;
  if (activeTab == tabs[0]) {
    tabContent = <OverviewTab />
  } else if (activeTab == tabs[1]) {
    tabContent = <TestRunsTab testRuns={data}  />
  }

  return (
    <div>
    <div className='card mb-5 mb-xl-10' style={{
        marginLeft: -13,
        marginRight: -13,
      }}>
      <div className='card-body pt-9 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
          <div className='flex-grow-1'>
            <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
              <div className='d-flex flex-column'>
                <div className='d-flex align-items-center mb-2'>
                  <a className='text-gray-800 fs-2 fw-bolder me-1'>
                    {url}
                  </a>
                </div>
              </div>
              <div className='d-flex flex-wrap flex-stack'>
              
                <div className='d-flex flex-column flex-grow-1'>
                  <div className='d-flex flex-wrap'>
                      <div className='bg-danger rounded py-3 px-4 mb-3'>
                        <div className='d-flex align-items-center min-w-125px text-white'>
                          <div className='fs-2 fw-bolder'>{totalIssueCount}</div>
                          <div className='fw-bold fs-6 mx-5'>Issues</div>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
            </div>
            <div className='d-flex overflow-auto h-55px'>
          <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  ((activeTab == tabs[0] || activeTab == null) && 'active')
                }
                to={`${urlPrefix}/${encodedUrl}/${tabs[0]}`}
              >
                Historical Overview
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (activeTab == tabs[1] && 'active')
                }
                to={`${urlPrefix}/${encodedUrl}/${tabs[1]}`}
              >
                Test Results
              </Link>
            </li>
          </ul>
        </div>
            
          </div>
        </div>
      </div>
    </div>

    {tabContent}
    
    </div>
  )
}

export {WebsiteDetail}
