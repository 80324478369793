import { FC } from "react";
import { Link } from "react-router-dom";
import { Settings } from "../../utils/config";

export const LandingFooter: FC = () => {
    return (
        <>
        {Settings.isGamyata ? null : 
        <section className="footer-crausel text-center">
            <div className="container">
                <ul className="logo-slider owl-carousel owl-theme">
                <li className="item">
                    <a href="https://data.gov.in/"
                        title="https://data.gov.in/ - External site that opens in a new window" target="_blank"
                        onClick={(e) => !window.confirm('You are being redirected to an external website. Please note that SugamyaWeb cannot be held responsible for external websites content & privacy policies.') && e.preventDefault()}>
                        <img src="/assets/images/data-gov.png" alt="Data Gov" /></a>
                </li>
                <li className="item"><a href="https://www.india.gov.in/"
                        title="https://www.india.gov.in/ - External site that opens in a new window"
                        onClick={(e) => !window.confirm('You are being redirected to an external website. Please note that SugamyaWeb cannot be held responsible for external websites content & privacy policies.') && e.preventDefault()}
                        target="_blank">
                            <img src="/assets/images/India-Gov.png" alt="India Gov" /></a>
                </li>
                <li className="item"><a href="https://www.mygov.in/"
                        title="https://www.mygov.in/ - External site that opens in a new window"
                        onClick={(e) => !window.confirm('You are being redirected to an external website. Please note that SugamyaWeb cannot be held responsible for external websites content & privacy policies.') && e.preventDefault()}
                        target="_blank">
                            <img src="/assets/images/my-gov.png" alt="My Gov" /></a>
                </li>
                <li className="item"><a href="https://www.pmindia.gov.in/"
                        title="https://www.pmindia.gov.in/ - External site that opens in a new window"
                        onClick={(e) => !window.confirm('You are being redirected to an external website. Please note that SugamyaWeb cannot be held responsible for external websites content & privacy policies.') && e.preventDefault()}
                        target="_blank">
                            <img src="/assets/images/PM-India.png" alt="PM India" /></a>
                </li>
                <li className="item"><a href="https://www.incredibleindia.org/"
                        title="https://www.incredibleindia.org/ - External site that opens in a new window"
                        onClick={(e) => !window.confirm('You are being redirected to an external website. Please note that SugamyaWeb cannot be held responsible for external websites content & privacy policies.') && e.preventDefault()}
                        target="_blank">
                            <img src="/assets/images/Incredible-India.png" alt="Incredible India" /></a></li>
                <li className="item"><a href="https://www.makeinindia.com/"
                        title="https://www.makeinindia.com/ - External site that opens in a new window"
                        onClick={(e) => !window.confirm('You are being redirected to an external website. Please note that SugamyaWeb cannot be held responsible for external websites content & privacy policies.') && e.preventDefault()}
                        target="_blank">
                            <img src="/assets/images/Make-in-India.png" alt="Make in India" /></a></li>
                </ul>
            </div>
        </section>
        }
        <footer className="footer-section text-center">
            <div className="footer-nav">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12">
                            <ul>
                                {Settings.isGamyata ? null : <li><Link to="/terms">Terms & Conditions</Link></li>}
                                <li><Link to="/website-policies">Website Policies</Link></li>
                                <li><Link to="/contact">Contact Us</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-xs-12">
                        <div className="footer-content">
                            <p>Managed and Hosted by 
                                {Settings.isGamyata ? (
                                    <>
                                        <a href="https://sumatak.com/" target="_blank"
                                            aria-label="Sumatak Technologies - External site that opens in a new window"
                                            title="Sumatak Technologies - External site that opens in a new window"> Sumatak Technologies</a>
                                    </>
                                ) : (
                                    <>
                                    <a href="http://www.nic.in/" target="_blank"
                                        onClick={(e) => !window.confirm('You are being redirected to an external website. Please note that SugamyaWeb cannot be held responsible for external websites content & privacy policies.') && e.preventDefault()}
                                        aria-label="National Informatics Centre - External site that opens in a new window"
                                        title="National Informatics Centre - External site that opens in a new window">National
                                        Informatics Centre</a>,
                                    <br /><a href="http://meity.gov.in/" target="_blank"
                                        onClick={(e) => !window.confirm('You are being redirected to an external website. Please note that SugamyaWeb cannot be held responsible for external websites content & privacy policies.') && e.preventDefault()}
                                        aria-label="Ministry of Electronics &amp; Information Technology - External site that opens in a new window"
                                        title="Ministry of Electronics &amp; Information Technology - External site that opens in a new window">Ministry
                                        of Electronics &amp; Information Technology</a>, Government of India  
                                    </>
                                )}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <ul className="footer-logo">
                {Settings.isGamyata ? <>
                    <li><a href="https://sumatak.com/"
						title="https://sumatak.com/ - External site that opens in a new window" target="_blank">
						<img src="/assets/images/sumatak.png" alt="Sumatak Technologies" /></a></li>
                </> : <>
                    <li><a href="https://www.nic.in/" title="https://www.nic.in/ - External site that opens in a new window" target="_blank"
						onClick={(e) => !window.confirm('You are being redirected to an external website. Please note that SugamyaWeb cannot be held responsible for external websites content & privacy policies.') && e.preventDefault()}>
                            <img src="/assets/images/nic-logo.png" alt="National Informatics Centre" /></a></li>
				    <li><a href="https://digitalindia.gov.in/"
						title="https://digitalindia.gov.in/ - External site that opens in a new window" target="_blank"
						onClick={(e) => !window.confirm('You are being redirected to an external website. Please note that SugamyaWeb cannot be held responsible for external websites content & privacy policies.') && e.preventDefault()}>
						<img src="/assets/images/digital-india.png" alt="Digital India" /></a></li>
                    <li><a href="https://sumatak.com/"
						title="https://sumatak.com/ - External site that opens in a new window" target="_blank"
						onClick={(e) => !window.confirm('You are being redirected to an external website. Please note that SugamyaWeb cannot be held responsible for external websites content & privacy policies.') && e.preventDefault()}>
						<img src="/assets/images/sumatak.png" alt="Sumatak Technologies" /></a></li>
                </>}
				
				
			</ul>
            <a className="top" href="#top" title="Scroll to Top"><i className="fa fa-chevron-up" aria-hidden="true" style={{color: '#FFFFFF'}}></i></a>
        </footer>
        </>
    );
}