import { FC, useEffect } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { useHistory } from "react-router-dom";
import { UserModel } from "../app/modules/auth/models/UserModel";
import { RootState } from "../setup";
import { Footer } from "./Footer";
import { Sidebar } from "./Sidebar";
import { customScriptOnLoadFn } from "./utils";

export const MobileLayout: FC<{}> = ({children}) => {
    useEffect(() => {
        var loadHighlight = document.createElement("link");
        loadHighlight.rel = "stylesheet";
        loadHighlight.className = "page-highlight";
        loadHighlight.type = "text/css";
        loadHighlight.href = '/styles/style.css';
        document.getElementsByTagName("head")[0].appendChild(loadHighlight);

        document.body.setAttribute('data-gradient', "body-default");
        const scripts = [
            '/scripts/bootstrap.min.js',
        ]
        scripts.forEach(url => {
            const script = document.createElement("script");
            script.src = url;
            script.async = false;
            script.addEventListener('load', () => {
                customScriptOnLoadFn();
            })
            document.body.appendChild(script);
        });        
      }, []);

      const history = useHistory()

      
      useEffect(() => {
        return history.listen((location) => {
            document.body.setAttribute('data-gradient', "body-default");
            customScriptOnLoadFn();
            const menuClose = document.querySelectorAll('.close-menu, .menu-hider');
            menuClose.forEach((el: any) => el.click());
        })
    },[history])

    const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
    const isLoggedIn = user != null;

    return (
        <div id="applyCSS" className="mobile-layout">
            {/* <div id="preloader"><div className="spinner-border color-highlight" role="status"></div></div> */}

            <div id="page">

                <Footer isLoggedIn={isLoggedIn} />

                <div className="header header-fixed header-logo-center">
                    {isLoggedIn ? (
					<div className="align-self-center me-2">
						<a href="#" data-menu="menu-sidebar-left-5" className="icon icon-xs gradient-red"><i className="fa fa-bars color-white font-18"></i></a>
					</div>
                    ) : null}
                    <img src='/assets/images/emblem.png' className='ms-2' />
                    <img src='/assets/images/logo1.png' />
					<div className="align-self-center w-100 ">
						<h2 className="font-700 mb-0 mx-2 color-highlight">SugamyaWeb</h2>
					</div>
				</div>
                
                <div className="page-content header-clear-medium">
                    {children}
                </div>

            </div>
        
            {isLoggedIn ? <Sidebar /> : null}
        </div>
    );
};