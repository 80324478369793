export const Settings = process.env.REACT_APP_GENERIC_GAMYATA ? {
    isGamyata: true,
    AdminLabel: "Admin",
    TesterLabel: "Tester",
    GamyataLabel: "Gamyata",
} : {
    isGamyata: false,
    AdminLabel: "Nodal Officer",
    TesterLabel: "QA Tester",
    GamyataLabel: "SugamyaWeb",
};
