 /* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import {KTSVG} from '../../../../_monic/helpers'
import {UserDbModel, UserModel} from '../../auth/models/UserModel';
import {deleteUser, viewUser} from '../../auth/redux/AuthCRUD';
import { OrganizationModel } from '../../auth/models/OrganizationModel';
import { viewOrganization } from '../../auth/redux/OrganizationCRUD';
import { Link, useParams } from 'react-router-dom';
import { getUrlPrefix, getUserLabel } from '../../utils';
import { useSelector, shallowEqual } from 'react-redux';
import { RootState } from '../../../../setup';

type Props = {
  className: string
  onboarding?: boolean
}

const ManageUser: React.FC<Props> = ({className, onboarding}) => {
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const isAdmin = user.gamyata?.role == "ADMIN";
  let {role, organizationId}: any = useParams();  
  const [data, setData] = useState<Array<UserDbModel>>();
  const [orgData, setOrgData] = useState<Record<number, OrganizationModel>>({});
  const [dataError, setDataError] = useState<{error: string}>();
  const roleFromUrl = role;
  let status = 'ACTIVE';
  let title = getUserLabel(role);
  if (onboarding) {
    role = 'ADMIN';
    status = 'INACTIVE';
    title = 'Onboarding'
  }
  useEffect(() => {
    viewUser(role, status, organizationId).then((result) => setData(result.data)).catch(e => setDataError({error: "Server Error! Cannot fetch user data!"}));
    viewOrganization().then((result) => {
      const orgs: Record<number, OrganizationModel> = {};
      for (const org of result.data) {
        orgs[org.id] = org;
      }
      setOrgData(orgs);
    }).catch(e => setDataError({error: "Server Error! Cannot fetch org data!"}));
  }, [role]);

  const isMobile = useSelector<RootState>(({auth}) => auth.isMobile, shallowEqual)
  const urlPrefix = getUrlPrefix(isMobile);

  const onDelete = (id: string) => {
    if (!window.confirm('Are you sure want to delete?')) {
      return;
    }
    deleteUser(id).then(() => {
      setData(data?.filter(x => x.id != id));
    });
  }

  if (dataError) {
    return (
      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          {dataError ? (
            <div>
              <br />
                <div className='mb-lg-15 alert alert-danger d-flex flex-column-fluid flex-center'>
                  <div className='alert-text font-weight-bold'>{dataError.error}</div>
                </div>
                </div>
          ) : (
            <div>
              <br />
              <span>Loading... </span> <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
           </div>
        )}
        </div>
      </div>
      );
  }

  return (
    <div className={`card ${className}`} key={role}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>{title} Details</span>
        </h3>
        <div className='card-toolbar'>
          {/* begin::Menu */}
          <div className='card-toolbar'>
          <Link to={urlPrefix + '/core/users/create/' + (roleFromUrl ? roleFromUrl + '/' + (organizationId || '') : '')} className='btn btn-sm btn-light-primary'>
            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
            Create
          </Link>
        </div>
          {/* end::Menu */}
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-full-bordered table-row-gray-100 align-middle gs-0 gy-3'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bolder'>
                <th className='min-w-150px'>Email</th>
                {role ? null : 
                <th className='min-w-150px'>Role</th>}
                {onboarding ? null : 
                <th className='min-w-150px'>Organization</th>}
                <th className='min-w-150px'>Name</th>
                <th className='min-w-150px'>Designation</th>
                <th className='min-w-150px'>Mobile No</th>
                {onboarding ?  
                <>
                <th className='min-w-150px'>Department</th>
                <th className='min-w-100px'>Onboarding PDF</th>
                </> : null}
                <th className='min-w-100px text-end'>Actions</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
            {data?.map((row) => (   
              <tr>
                <td>
                  <span className='text-dark fw-bolder fs-6'>
                    {row.email}
                  </span>
                </td>
                {role ? null : 
                <td>
                  <span className='text-dark fw-bolder fs-6'>
                    {row.role}
                  </span>
                </td>}
                {onboarding ? null : 
                <td>
                  <span className='text-dark fw-bolder fs-6'>
                    {orgData[row.organizationId]?.name}
                  </span>
                </td> }
                <td>
                  <span className='text-dark fw-bolder fs-6'>
                    {row.parichay?.fullName}
                  </span>
                </td>
                <td>
                  <span className='text-dark fw-bolder fs-6'>
                    {row.parichay?.designation}
                  </span>
                </td>
                <td>
                  <span className='text-dark fw-bolder fs-6'>
                    {row.parichay?.mobileNo}
                  </span>
                </td>
                {onboarding && !isMobile ? 
                <>
                <td>
                  <span className='text-dark fw-bolder fs-6'>
                    {row.parichay?.departmentName}
                  </span>
                </td>
                <td>
                  <Link title='View Authorization Letter' target='_blank'
                      to={urlPrefix + `/core/users/downloadonboarding/${row.id}`}
                      className='btn btn-primary btn-sm me-1'
                    >
                    View
                  </Link>
                </td>
                </> : null }
                <td className='text-end'>
                  <Link title='Edit User'
                    to={urlPrefix + `/core/users/edit/${row.id}`}
                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                  >
                    <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                  </Link>
                  <Link title='Delete User' to='#' onClick={() => onDelete(row.id)} className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'>
                    <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
                  </Link>
                </td>
              </tr>
              ))}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export {ManageUser}
