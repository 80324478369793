import { Component, FC, useEffect, useState } from "react";
import { LandingFooter } from "./Footer";
import { LandingHeader } from "./Header";
//import 'owl.carousel/dist/assets/owl.theme.default.css';

import $ from 'jquery';
import { getPublicStats } from "../../modules/auth/redux/OrganizationCRUD";
import { PublicStatsModel } from "../../modules/auth/models/OrganizationModel";
import { HistoricalStats, HistoricalStatsChartData } from "../../../_monic/partials/widgets/mixed/HistoricalStats";
import { LineChart } from "../../../_monic/partials/widgets/mixed/LineChart";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { Settings } from "../../utils/config";
import { Link } from "react-router-dom";
/* @ts-ignore */
window.$ = window.jQuery = $;

//const OwlCarousel = require('react-owl-carousel');


class DemoCarousel extends Component {
    render() {
        if (Settings.isGamyata) {
            return (
                <Carousel showStatus={false} showThumbs={false} showIndicators={false}>
                <div>
                    <img src="/assets/images/Banner.jpg" alt="Accessibility could reduce cost when it is integrated into existing development cycles" />
                </div>
                <div>
                    <img src="/assets/images/Banner.jpg" alt="Accessibility could reduce cost when it is integrated into existing development cycles" />
                </div>
            </Carousel>
            );
        }

        return (
            <Carousel showStatus={false} showThumbs={false} showIndicators={false}>
                <div onClick={() => window.open('https://cbpssubscriber.mygov.in/aff/3vSi2rBaJFCl2DMu')} style={{cursor: "pointer"}}>
                <a href="https://cbpssubscriber.mygov.in/aff/3vSi2rBaJFCl2DMu" title="Mann Ki Baatt"> 
                    <img src="https://cbpssubscriber.mygov.in/assets/uploads/juGajmc1gOVBUtt5" alt="Mann Ki Baatt" id="_3vSi2rBaJFCl2DMu" />
                </a>
                </div>
                <div>
                    <img src="/assets/images/Banner1.jpg" alt="Accessibility India Campaign" />
                </div>
                <div>
                    <img src="/assets/images/Banner.jpg" alt="Accessibility could reduce cost when it is integrated into existing development cycles" />
                </div>
            </Carousel>
        );
    }
};

export const HomePage: FC = () => {
    const [top5ScoreData, setTop5ScoreData] = useState<HistoricalStatsChartData>();
    const [top5ImprovedData, setTop5ImprovedData] = useState<HistoricalStatsChartData>();
    const [scoreTrendMonthly, setScoreTrendMonthly] = useState<HistoricalStatsChartData>();
    const [data, setData] = useState<PublicStatsModel>();

    useEffect(() => {
        getPublicStats().then((result) => {
            setData(result.data);
            if (result.data) {
                const {top5Score, top5Improved, scoreTrendMonthly} = result.data;
                setTop5ScoreData({
                    xAxis: top5Score.map((x: any) => x.name),
                    yAxis: top5Score.map((x: any) => x.latestScore),
                    max: ``
                  });
                setTop5ImprovedData({
                    xAxis: top5Improved.map((x: any) => x.name),
                    yAxis: top5Improved.map((x: any) => x.improvementPct),
                    max: ``
                  });
                const res = scoreTrendMonthly.map((x: any) => ({label: x.month, value: Math.round(x.avgScore)}));
                setScoreTrendMonthly({
                    xAxis: res.map((x: any) => x.label),
                    yAxis: res.map((x: any) => x.value),
                    max: ''
                });
            }
            
        }).catch(e => e);
    }, []);

    return (
        <div className="main-body-content">
            <LandingHeader />
            <div id="main-content">
                {/* <OwlCarousel className="banner-section banner-slider owl-carousel owl-theme" autoplay={false}>
                    <div className="item">
                        <img src="/assets/images/Banner.jpg" alt="SugamyaWeb banner" />
                    </div>
                    <div className="item">
                        <img src="/assets/images/Banner.jpg" alt="SugamyaWeb banner" />
                    </div>
                </OwlCarousel> */}
                {/* <section className="banner-section banner-slider owl-carousel owl-theme">
                    <div className="item">
                        <img src="/assets/images/Banner.jpg" alt="SugamyaWeb banner" />
                    </div>
                    <div className="item">
                        <img src="/assets/images/Banner.jpg" alt="SugamyaWeb banner" />
                    </div>
                </section> */}

                <DemoCarousel />

                <section className="short-desp">
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12">
                                <p className="text-center">Equal access to the internet and digital experiences across the web
                                    is a principle
                                    enshrined in the
                                    Rights of Persons with Disabilities Act, 2016. Building accessible tools and technology
                                    is necessary
                                    to break down barriers and deliver equal access to the Indian government web space.
                                    {Settings.GamyataLabel} will
                                    empower Indian Government entities to assess the accessibility of their respective
                                    websites and
                                    barrier free access to all</p>
                            </div>
                        </div>
                    </div>
                </section>

                {data ? <>
                <section className="accessibility-dashboard site-stats">
                    <div className="container">
                        <div className="row">
                            <h1 className="text-center heading1">ACCESSIBILITY DASHBOARD</h1>
                            <div className="col-md-5 col-xs-12">
                                <div className="site-stats-count">
                                    <ul>
                                        <li className="bg-blue">
                                            <img src="/assets/images/file-folder-svgrepo-com 1.png" alt="Department" />
                                            <strong>{data?.orgCount}</strong>
                                            <span>Department</span>
                                        </li>
                                        <li className="bg-orange">
                                            <img src="/assets/images/file-folder-svgrepo-com 1.png" alt="Websites" />
                                            <strong>{data?.websiteCount}</strong>
                                            <span>Websites</span>
                                        </li>
                                        <li className="bg-green">
                                            <img src="/assets/images/file-folder-svgrepo-com 1.png" alt="Issues Resolved" />
                                            <strong>{data?.issuesResolveCount}</strong>
                                            <span>Issues Resolved</span>
                                        </li>
                                        <li className="bg-red">
                                            <img src="/assets/images/file-folder-svgrepo-com 1.png" alt="Test Run" />
                                            <strong>{data?.testRunCount}</strong>
                                            <span>Test Run</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-7 col-xs-12">
                                <div className="graph-colm">
                                    <h2 className="heading2">Monthly Accessibility Trend</h2>
                                    {scoreTrendMonthly ? <LineChart className='mb-5 mb-xl-8' chartHeight='250px' chartData={scoreTrendMonthly} hideBackground={true} title='' /> : null}                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </section> 

                <section className="tpweb-accessibility top5-web">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 col-xs-12">
                                <h2>TOP 5 WEBSITES</h2>
                                {top5ScoreData ? <HistoricalStats className='mb-5 mb-xl-8' chartHeight='250px' chartData={top5ScoreData} title='' hideBackground={true} yAxisName='Score' /> : null}
                            </div>
                            <div className="col-md-6 col-xs-12">
                                <h2>MOST IMPROVED ACCESSIBILITY</h2>
                                {top5ImprovedData ? <HistoricalStats className='mb-5 mb-xl-8' chartHeight='250px' chartData={top5ImprovedData} title='' hideBackground={true} yAxisName='Improved %' /> : null}
                            </div>
                        </div>
                    </div>
                </section></>: null}

                <section className="features-section">
                    <div className="container">
                        <div className="row">
                            <h2 className="text-center">FEATURES</h2>
                            <div className="col-md-6 col-xs-12">
                                <div className="features-widget">
                                    <span className="feature-icon fa fa-cubes"></span>
                                    <div className="feature-desc">
                                        <h3>COMPREHENSIVE TESTING</h3>
                                        <p>Covers wide range of test rules & intelligence. Scans through all the web pages
                                            like a human, assessing all elements of
                                            a web page and across the entire website. Any accessibility issues detected are
                                            reported so that they can be remedied
                                            promptly.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-xs-12">
                                <div className="features-widget">
                                    <span className="feature-icon fa fa-area-chart"></span>
                                    <div className="feature-desc">
                                        <h3>SMART REPORTING, INSIGHTS & ANALYSIS</h3>
                                        <p>Typical accessibility tools find 100s or 1000s of issues in a website. But we
                                            classify and prioritize all those issues
                                            based on impact along with guidance on how to fix them. So your teams can work
                                            smarter, instead of being buried by bug
                                            reports.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-xs-12">
                                <div className="features-widget">
                                    <span className="feature-icon fa fa-handshake-o"></span>
                                    <div className="feature-desc">
                                        <h3>EASE OF USE</h3>
                                        <p>Intuitive and easy-to-use interface. You need not more than 3 clicks to achieve
                                            the end goals. A lesser learning curve &
                                            shorter time to come up to speed increases productivity, and also lowers the
                                            training and support costs. The
                                            accessibility reports come along with fix recommendations, further speeding up
                                            accessibility compliance.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-xs-12">
                                <div className="features-widget">
                                    <span className="feature-icon fa fa-lightbulb-o"></span>
                                    <div className="feature-desc">
                                        <h3>AUTOMATION & INTELLIGENCE</h3>
                                        <p>Usual accessibility tools just read the code and look for basic syntax issues in
                                            html and css. But we use Artificial
                                            Intelligence (AI) and Machine Learning (ML) to see each screen the way a sighted
                                            human would. This enables us to
                                            identify more complex issues and pave the way for more innovations in future..
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="tpweb-accessibility genesis">
                    <div className="container">
                        <div className="row">
                            <h1 className="text-center">GENESIS OF {Settings.GamyataLabel.toUpperCase()}</h1>
                            <div className="col-xs-12">
                                <p>To enhance the accessibility of the Indian Govt. webspace, the <a
                                        href="http://meity.gov.in" data-type="URL" data-id="meity.gov.in"
                                        style={{color: '#0263f2'}}
                                        onClick={(e) => !window.confirm('You are being redirected to an external website. Please note that SugamyaWeb cannot be held responsible for external websites content & privacy policies.') && e.preventDefault()}
                                        target="_blank">Ministry of
                                        Electronics &amp; Information Technology (MeitY)</a> organized an <a style={{color: '#0263f2'}}
                                        href="https://guidelines.india.gov.in/about-the-challenge/"
                                        onClick={(e) => !window.confirm('You are being redirected to an external website. Please note that SugamyaWeb cannot be held responsible for external websites content & privacy policies.') && e.preventDefault()}
                                        target="_blank">Innovation Challenge</a> in 2021-22. Many Indian
                                    startups participated in multiple stages of the challenge – Ideation, Building a Minimum
                                    Viable Product (MVP) and
                                    Functional Product. {Settings.GamyataLabel} emerged as the <a
                                        style={{color: '#0263f2'}}
                                        onClick={(e) => !window.confirm('You are being redirected to an external website. Please note that SugamyaWeb cannot be held responsible for external websites content & privacy policies.') && e.preventDefault()}
                                        href="https://guidelines.india.gov.in/final-outcome/" target="_blank">winner</a> of
                                    the Innovation Challenge in 2022.</p>
                                <p>{Settings.GamyataLabel} is an automated tool for improving the accessibility of web pages. It leverages
                                    automation and system
                                    intelligence to drive testing and decision making ensuring accuracy. It generates audit
                                    reports with guidance and
                                    recommendations, root cause analysis and advanced visualization.</p>
                                <p><strong><em>To use {Settings.GamyataLabel}, Indian Government entities must nominate an Accessibility
                                            Compliance Nodal Officer, by
                                            sharing details at the email address support-sugamya[at]nic[dot]in. Accessibility Compliance Nodal Officers can log in to {Settings.GamyataLabel} and 
                                            <Link to="/using-sugamyaweb" style={{color: '#0263f2'}}>&nbsp;easily test</Link> the accessibility of their
                                            respective websites.</em> </strong></p>
                                {Settings.isGamyata ? (
                                    <>
                                    <div className="text-center">
                                        <Link className="btn-default big" to="/login" title="Try Demo">Try Demo</Link>
                                    </div>
                                    </>
                                ) : (
                                    <>
                                    <h2 className="text-center">READY TO ONBOARD</h2>
                                    <div className="text-center"><Link className="btn-default big" to="/login" title="SUBMIT ONBOARDING
                                        REQUEST">SUBMIT ONBOARDING REQUEST</Link>
                                    </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </section>

                {/* <section className="features-section testimonial">
                    <div className="container">
                        <div className="row">
                            <h2 className="text-center">TESTIMONIALS</h2>
                            <p className="text-center short-desc">Lorem ipsum dolor sit amet, consectetur adipiscing
                                elit, sed do eiusmod tempor incididunt</p>
                            <div className="post-slider owl-carousel owl-theme">
                                <div className="item">
                                    <div className="testimonial-widgt">
                                        <img src="/assets/images/testimonial.png" alt="" />
                                        <div className="writer-text">
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                                tempor
                                                incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                                                quis
                                                nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                                consequat.
                                                Duis aute irure dolor in reprehenderit in voluptate velit esse cillum
                                                dolore
                                                eu
                                                fugiat nulla pariatur.</p>
                                            <div className="writer-name">Name of the Writer</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="testimonial-widgt">
                                        <img src="/assets/images/testimonial.png" alt="" />
                                        <div className="writer-text">
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                                tempor
                                                incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                                                quis
                                                nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                                consequat.
                                                Duis aute irure dolor in reprehenderit in voluptate velit esse cillum
                                                dolore
                                                eu
                                                fugiat nulla pariatur.</p>
                                            <div className="writer-name">Name of the Writer</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="testimonial-widgt">
                                        <img src="/assets/images/testimonial.png" alt="" />
                                        <div className="writer-text">
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                                tempor
                                                incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                                                quis
                                                nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                                consequat.
                                                Duis aute irure dolor in reprehenderit in voluptate velit esse cillum
                                                dolore
                                                eu
                                                fugiat nulla pariatur.</p>
                                            <div className="writer-name">Name of the Writer</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="testimonial-widgt">
                                        <img src="/assets/images/testimonial.png" alt="" />
                                        <div className="writer-text">
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                                tempor
                                                incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                                                quis
                                                nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                                consequat.
                                                Duis aute irure dolor in reprehenderit in voluptate velit esse cillum
                                                dolore
                                                eu
                                                fugiat nulla pariatur.</p>
                                            <div className="writer-name">Name of the Writer</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
            </div>

            <LandingFooter />
        </div>
    );
}
