 /* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { toAbsoluteUrl } from '../../../../_monic/helpers';
import {generateLoginUrl} from '../redux/AuthCRUD';
import * as auth from '../../../modules/auth/redux/AuthRedux'
import { useLocation } from 'react-router-dom';
  
const Login: React.FC<{isMobile: boolean}> = ({isMobile}) => {
   const [url, setUrl] = useState<string | null>(null);
   const [error, setError] = useState<string | null>(null);
   const dispatch = useDispatch();
   const { search } = useLocation();
   const query = new URLSearchParams(search);
   const redirectTo = query.get('redirectTo');
   const encodedRedirectTo = redirectTo ? redirectTo : undefined;
   useEffect(() => {
     dispatch(auth.actions.preLogin(isMobile, encodedRedirectTo));
     generateLoginUrl().then(({data}) => setUrl(data.url)).catch(e => setError('Internal server error. Contact admin'));
   }, []);
 
   if (url != null) {
      window.location.replace(url);
   }

   return (
    <div
      className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
    >
      {/* begin::Content */}
      <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
        {/* begin::Logo */}
        <img alt='SugamyaWeb Logo' role={'img'} src={toAbsoluteUrl('/assets/images/logo1.png')} className='h-100px' />
        {/* end::Logo */}
        {/* begin::Wrapper */}
        <div className='w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>
              <div role='form' aria-label='submit' className='text-center'>
              {error ? (
                <span style={{display: 'block'}}>
                  {error}
                </span>
              ) : (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
          </div>
        </div>
        {/* end::Wrapper */}
      </div>
      {/* end::Content */}
    </div>
   )
 }
 
 export {Login}
 