import React from 'react'
import {PageLink, PageTitle} from '../../../_monic/layout/core'
import {Redirect, Route, Switch} from 'react-router-dom'
import {RunTest} from './components/RunTest'
import {TestResult} from './components/TestResult'
import {ManageTestResults} from './components/ManageTestResults'
import { ManageTestSchedules } from './components/ManageTestSchedules'
import { TestSchedule } from './components/TestSchedule'
import { ManageNonCompliance } from './components/ManageNonCompliance'
import { WelcomePageTitle } from '../../pages/dashboard/DashboardWrapper'

const breadCrumbs: Array<PageLink> = [
  {
    title: 'Test Management',
    path: '/activities/testmgmt/runtest',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const TestManagementPage: React.FC = (props: any) => {
  return (
    <>
    <Switch>
        <Route path='/activities/testmgmt/runtest'>
        <WelcomePageTitle />
          <RunTest />
        </Route>
        <Route path='/activities/testmgmt/runtest/schedule'>
        <WelcomePageTitle />
          <RunTest />
        </Route>
        <Route path='/activities/testmgmt/testresult/:id/:activeTab?'>
          <WelcomePageTitle />
          <TestResult />
        </Route>
        <Route path='/activities/testmgmt/testschedule/:id/:activeTab?'>
          <WelcomePageTitle />
          <TestSchedule />
        </Route>
        <Route path='/activities/testmgmt/manageSchedules'>
          <WelcomePageTitle />
          <ManageTestSchedules />
        </Route>
        
        <Route path='/activities/testmgmt/manageResults/:websiteId/:websiteName'>
          <WelcomePageTitle />
          <ManageTestResults />
        </Route>
        <Route path='/activities/testmgmt/manageResults'>
          <WelcomePageTitle />
          <ManageTestResults />
        </Route>
        <Route path='/activities/testmgmt/manageNonCompliance'>
          <WelcomePageTitle />
          <ManageNonCompliance />
        </Route>
        <Redirect from='/activities/testmgmt' exact={true} to='/activities/testmgmt/runtest' />
        <Redirect to='activities/testmgmt/runtest' />
      </Switch>      
    </>
  )
}

export default TestManagementPage
