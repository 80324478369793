import React, { useEffect, useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { RootState } from '../../../setup';
import { WebsiteByOrg } from '../../modules/auth/models/OrganizationModel';
import { getWebsitesByOrg } from '../../modules/auth/redux/OrganizationCRUD';
import { getUrlPrefix } from '../../modules/utils';
import { WebsiteModel } from '../../modules/auth/models/OrganizationModel';
import { WebsiteAccordion } from './WebsiteAccordion';
import { BreadcrumbData, Breadcrumbs } from '../layout/MasterLayout';

const ManageTest: React.FC = () => {
    const { pathname } = useLocation();
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();
    const [data, setData] = useState<Array<WebsiteByOrg>>();
    const [dataError, setDataError] = useState<{ error: string }>();
    const [search, setSearch] = useState<string>('');
    const isMobile = useSelector<RootState>(({ auth }) => auth.isMobile, shallowEqual)
    const urlPrefix = getUrlPrefix(isMobile, pathname.startsWith('/v2') ? '/v2' : undefined);
    const includeTestScheduleCount = true;
    const fetchData = () => {
        setIsLoading(true);
        getWebsitesByOrg({}, includeTestScheduleCount).then((r) => setData(r.data))
            .catch(e => setDataError({ error: "Server Error! Cannot fetch data!" }))
            .finally(() => setIsLoading(false));
    };
    useEffect(fetchData, []);

    if (isLoading) {
        return (
            <div>
                <br />
                <strong>
                    <span>Loading... Please wait... </span> <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </strong>
            </div>
        );
    }

    if (dataError || data == null) {
        return (
            <div className='card mb-5 mb-xl-10'>
                <div
                    className='card-header border-0'
                >
                    {dataError ? (
                        <div>
                            <br />
                            <div className='mb-lg-15 alert alert-danger d-flex flex-column-fluid flex-center'>
                                <div className='alert-text font-weight-bold'>{dataError.error}</div>
                            </div>
                        </div>
                    ) : (
                        <div>
                            <br />
                            <span>Loading... </span> <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </div>
                    )}
                </div>
            </div>
        );
    }

    let defaultBreadcrumbs: BreadcrumbData[];
    //@ts-ignore
    if (history.location.state && history.location.state.previousBreadcrumbs) {
        //@ts-ignore
        defaultBreadcrumbs = history.location.state.previousBreadcrumbs.concat([{ text: 'Manage Tests' }]);
    }
    else {
        defaultBreadcrumbs = [
            {
                text: 'Manage Tests'
            }
        ];
    }

    return (
        <>
            <Breadcrumbs data={defaultBreadcrumbs} />
            <div className="site-head">
                <h1 className="main-title">Manage Tests</h1>
                <div className="site-head-right">
                    <div className="search-web-form">
                        <span className="icon"><i className="fa fa-search" aria-hidden="true" /></span>
                        <input type="search" id="search" placeholder="Search Website/Orgnization" value={search} onChange={(e) => setSearch(e.target.value)} />
                        {search?.trim().length > 0 ? <span className="searchBox-clear js-clearSearchBox" onClick={() => setSearch('')}>clear search</span> : null}
                    </div>
                </div>
            </div>
            <div className="project-list">
                <WebsiteAccordion search={search} data={data} includeTestScheduleCount={includeTestScheduleCount} renderActions={
                    (website: WebsiteModel) =>
                        <>
                            <a href='#' onClick={e => { history.push(urlPrefix + `/activities/testmgmt/schedules-by-website/${website.id}`,
                            { previousBreadcrumbs: defaultBreadcrumbs.map((x, i) => i == defaultBreadcrumbs.length - 1 ? ({ ...x, href: history.location.pathname }) : x) });e.preventDefault();}} className="btn">View Tests</a>
                        </>
                } />
            </div>
        </>
    )
}

export { ManageTest }
