/* eslint-disable jsx-a11y/anchor-is-valid */
import { AxiosResponse } from 'axios'
import React, {useEffect, useState} from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { Link, useHistory, useParams } from 'react-router-dom'
import { RootState } from '../../../../setup'
import {KTSVG} from '../../../../_monic/helpers'
import {WebpageModel, WebpagePagesModel} from '../../auth/models/OrganizationModel'
import {setWebsiteStatus, viewWebpages} from '../../auth/redux/OrganizationCRUD'
import {decodeURISafe, getUrlPrefix} from '../../utils'

type Props = {
  className?: string
}

const ManageWebpage: React.FC<Props> = ({className}) => {
  const {websiteId}: any = useParams();
  const history = useHistory();
  const [data, setData] = useState<WebpagePagesModel>();
  const [dataError, setDataError] = useState<{error: string}>();
  const isMobile = useSelector<RootState>(({auth}) => auth.isMobile, shallowEqual)
  const urlPrefix = getUrlPrefix(isMobile);
  useEffect(() => {
    viewWebpages(websiteId).then((r) => setData(r.data)).catch(e => setDataError({error: "Server Error! Cannot fetch data!"}));
  }, []);

  const onToggle = (row: WebpageModel) => {
    if (!window.confirm(`Are you sure want to ${row.status == 'ACTIVE' ? 'deactivate' : 'activate'}?`)) {
      return;
    }
    const status = row.status == 'ACTIVE' ? 'INACTIVE' : 'ACTIVE';
    setWebsiteStatus(row.id, status).then(() => {
      if (data) {
        setData({website: data.website, pages: data.pages.map(x => x.id == row.id ? {...x, status} : x)});
      }
    });
  }

  if (dataError || data == null) {
    return (
      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header border-0'
        >
          {dataError ? (
            <div>
              <br />
                <div className='mb-lg-15 alert alert-danger d-flex flex-column-fluid flex-center'>
                  <div className='alert-text font-weight-bold'>{dataError.error}</div>
                </div>
                </div>
          ) : (
            <div>
              <br />
              <span>Loading... </span> <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
           </div>
        )}
        </div>
      </div>
      );
  }

  if (data.pages.length == 0) {
    return (
      <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>Manage Webpages of {data.website.name}</span>
        </h3>
      </div>
      <div className='card-body py-3'>
        <p>
          Pages have not been crawled yet. Run at least one test to crawl the pages.
        </p>
      </div>
      </div>
    )
  }

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>Manage Webpages of {data.website.name}</span>
        </h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-full-bordered table-row-gray-100 align-middle gs-0 gy-3'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bolder'>
                <th className='min-w-150px'>Webpage URL</th>
                <th className='min-w-150px'>Status</th>
                <th className='min-w-100px'>Actions</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {data.pages?.map((row) => {
                const statusText = row.status == 'ACTIVE' ? 'Deactivate Page' : 'Activate Page';
                return (
                <tr>
                  <td>
                    {decodeURISafe(row.url)}
                  </td>
                  <td className='fw-bolder'>
                      {row.status}
                  </td>
                  <td>
                    <button
                        title={statusText}
                        onClick={() => onToggle(row)}
                        type='button'
                        className='btn btn-sm btn-light-primary me-3'
                      >
                      {statusText}
                    </button>
                  </td>
                </tr>
                )
              })}
              </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export {ManageWebpage}
