/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { getWebsites } from '../../modules/auth/redux/TestRunCRUD';
import { useHistory, useLocation } from 'react-router-dom';
import { Website } from '../../modules/auth/models/TestRunModel';
import _ from 'lodash';
import { useSelector, shallowEqual } from 'react-redux';
import { RootState } from '../../../setup';
import { UserModel } from '../../modules/auth/models/UserModel';
import { decodeURISafe, getUrlPrefix } from '../../modules/utils';
import moment from 'moment';
import { cleanString, getScoreBgColor } from '../utils';
import { BreadcrumbData, Breadcrumbs } from '../layout/MasterLayout';

const WebsiteHeatmap: React.FC = () => {
  const { pathname }: any = useLocation();
  const history = useHistory();
  const [data, setData] = useState<Array<Website>>();
  const [dataError, setDataError] = useState<{ error: string }>();
  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
  const isMobile = useSelector<RootState>(({ auth }) => auth.isMobile, shallowEqual)
  const urlPrefix = getUrlPrefix(isMobile, pathname.startsWith('/v2') ? '/v2' : undefined);
  const isHeatMap = pathname.split(urlPrefix + '/activities/reports/mywebsites/')[1] == 'heatmap';
  useEffect(() => {
    const where: any = {};
    if (isHeatMap) {
      where.latestScore = { neq: null };
    }
    getWebsites(where, ['latestScore DESC']).then((result) => setData(result.data)).catch(e => setDataError({ error: "Server Error! Cannot fetch data!" }));
  }, [isHeatMap]);

  if (dataError || data == null) {
    return (
      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          {dataError ? (
            <div>
              <br />
              <div className='mb-lg-15 alert alert-danger d-flex flex-column-fluid flex-center'>
                <div className='alert-text font-weight-bold'>{dataError.error}</div>
              </div>
            </div>
          ) : (
            <div>
              <br />
              <span>Loading... </span> <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </div>
          )}
        </div>
      </div>
    );
  }

  const defaultBreadcrumbs: BreadcrumbData[] = [
      {
          text: 'Heatmap'
      }
  ];

  return (
    <>
      <Breadcrumbs data={defaultBreadcrumbs} />
      <div className="site-head">
        <h1 className="main-title">Websites Heatmap</h1>
      </div>
      <div className="project-list">
        <table className="weblist-table website-heatmap">
          <thead>
            <tr>
              <th scope="col" style={{width: "40%"}}>Name</th>
              <th scope="col">Score</th>
              <th scope="col">Last Tested</th>
              <th scope="col">Page Count</th>
              <th scope="col" className="action">Action</th>
            </tr>
          </thead>
          <tbody>
            {data.map(w => {
              const latestTestTime = w.latestTestTime ? moment(w.latestTestTime).tz('Asia/Kolkata') : null;
              return (
              <tr style={{backgroundColor: getScoreBgColor(w.latestScore)}}>
              <td className="website-name">
                <a href="#"
                  title={`${w.url} - External site that opens in a new window`} target="_blank"
                  onClick={(e) => !window.confirm('You are being redirected to an external website. Please note that SugamyaWeb cannot be held responsible for external websites content & privacy policies.') && e.preventDefault()}>
                  {cleanString(w.name)}
                </a> <br />
                <small>{decodeURISafe(w.url)}</small> 
              </td>
              <td><strong>{w.latestScore}</strong></td>
              <td>{latestTestTime ? <><strong>{latestTestTime.format('DD MMM YYYY')}</strong><br /><small>{latestTestTime.format('h:mm:ss A')}</small></> : null}</td>
              <td>{w.latestCntPages}</td>
              <td>
                {w.latestTestRunId ? <a title={'Last test report of ' + cleanString(w.name)} href='#'
                  onClick={e => {history.push(`${urlPrefix}/core/test/${w.latestTestRunId}`, { previousBreadcrumbs: defaultBreadcrumbs.map((x, i) => i == defaultBreadcrumbs.length - 1 ? ({ ...x, href: history.location.pathname }) : x) });e.preventDefault();}} 
                  className="btn">Latest Test Report</a> : null}
              </td>
            </tr>)
            })}
          </tbody>
        </table>
      </div>
    </>
  )
}

export { WebsiteHeatmap }
