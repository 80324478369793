import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { Cron } from 'react-js-cron';
import { useFormik } from 'formik'
import { AxiosResponse } from 'axios'
import { SettingsModel } from '../auth/models/SettingsModel';
import { getSettings, updateSettings } from '../auth/redux/SettingsCRUD';
import { FORM_STATUSES } from '../utils';
import { AUDIT_IMPACT_WEIGHTS } from '../../modulesv2/utils';

const EditSettings: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [stylesLoaded, setStylesLoaded] = useState(false);
  const [settingsData, setSettingsData] = useState<Array<SettingsModel>>();
  const [dataError, setDataError] = useState<DataError>();
  const initialValues = {};

  useEffect(() => {
    Promise.all([
      // @ts-ignore
      import('antd/dist/reset.css'),
      // @ts-ignore
      import('react-js-cron/dist/styles.css'),
    ]).then(() => {
      setStylesLoaded(true);
    });
  }, []);

const shapeWithDynamicFields = settingsData?.reduce((obj: any, setting) => {
  obj[setting.identifier] = Yup.string().required('This value is required!');
  return obj;
}, {});

const formik = useFormik<any>({
  initialValues,
  validationSchema: Yup.object().shape(shapeWithDynamicFields),
  onSubmit: (values, { setStatus }) => {
    setLoading(true);
    if (!settingsData) {
      setLoading(false);
      setStatus({ state: FORM_STATUSES.FAILURE, response: { error: 'Server Error!' } });
      return;
    }
    const newSettings = settingsData.map(setting => ({
      ...setting,
      value: values[setting.identifier],
    }));
    updateSettings(newSettings).then(({ data: { id } }) => {
      setLoading(false);
      setStatus({ state: FORM_STATUSES.SUCCESS, response: { id } });
    }).catch(() => {
      setLoading(false);
      setStatus({ state: FORM_STATUSES.FAILURE, response: { error: 'Server Error!' } });
    });
  },
});

useEffect(() => {
  getSettings().then((result: AxiosResponse) => {
    result.data.map((setting: SettingsModel) => {
      formik.setFieldValue(setting.identifier, setting.value);
    });
    setSettingsData(result.data);
  }).catch(e => setDataError({ error: "Server Error! Cannot fetch data!" }));
}, []);

if (dataError || !stylesLoaded || settingsData == null) {
  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        {dataError ? (
          <div>
            <br />
            <div className='mb-lg-15 alert alert-danger d-flex flex-column-fluid flex-center'>
              <div className='alert-text font-weight-bold'>{dataError.error}</div>
            </div>
          </div>
        ) : (
          <div>
            <br />
            <span>Loading... </span> <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
          </div>
        )}
      </div>
    </div>
  );
}

return (
  <div className='card mb-5 mb-xl-10'>
    <div
      className='card-header border-0 cursor-pointer'
      role='button'
      data-bs-toggle='collapse'
      data-bs-target='#kt_edit_website'
      aria-expanded='true'
      aria-controls='kt_edit_website'
    >
      <div className='card-title m-0'>
        <h3 className='fw-bolder m-0'>Edit Application Settings</h3>
      </div>
    </div>

    {formik.status && formik.status.state == FORM_STATUSES.SUCCESS ? (
      <div className='mx-5 py-3 alert-success'>
        <div className='mx-5 alert-text font-weight-bold'>Settings updated successfully</div>
      </div>
    ) : null}

    {formik.status && formik.status.state == FORM_STATUSES.FAILURE ? (
      <div className='mb-lg-15 alert-danger'>
        <div className='alert-text font-weight-bold'>{formik.status.response?.error}</div>
      </div>
    ) : null}

    <div id='kt_edit_settings' className='collapse show'>
      <form onSubmit={formik.handleSubmit} noValidate className='form'>
        <div className='card-body border-top p-9'>
          {settingsData.map(setting => {
            let content = null;
            if (setting.identifier == "cronTabForStaleNcAlertCadence") {
              content = (
                <Cron
                  value={formik.values[setting.identifier]}
                  setValue={(value: string) => formik.setFieldValue(setting.identifier, value)}
                  clearButtonAction={'empty'}
                  allowedDropdowns={['period', 'months', 'month-days', 'week-days']}
                  allowedPeriods={['month', 'week']} />
              );
            } else if (setting.identifier == "hideImpact") {
              content = (
                <>
                {/* <input type="checkbox"
                  onChange={() => onToggle(row)}
                  checked={row.status == 'ACTIVE'} /> */}
                <select 
                  id='s_org'
                  className='form-select form-select-solid form-select-lg'
                  {...formik.getFieldProps('organizationId')}
                >
                  <option value="-1">Select Org</option>
                  {Object.keys(AUDIT_IMPACT_WEIGHTS).map(impact => (
                    <option value={impact}>{impact}</option>
                  ))}
                </select>
                </>
              );
            } else if (setting.identifier == "hideWarnings") {
              content = (
                <>
                  <input
                    type='radio'
                    className='form-check-input'
                    {...formik.getFieldProps(setting.identifier)}
                    value='true'
                    id='cb_true'
                  />
                  <label htmlFor='cb_true'>
                    <span className='text-gray-800 fw-bold mx-3'>True</span>
                  </label>
                  <input
                    type='radio'
                    className='form-check-input'
                    {...formik.getFieldProps(setting.identifier)}
                    value='false'
                    id='cb_false'
                  />
                  <label htmlFor='cb_false'>
                    <span className='text-gray-800 fw-bold mx-3'>False</span>
                  </label>
                </>
              );
            } else {
              content = (
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  {...formik.getFieldProps(setting.identifier)}
                />
              );
            }

            return (
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>{setting.label}</label>

                <div className='col-lg-8'>
                  <div className='row'>
                    <div className='col-lg-6 fv-row'>
                      {content}
                      {formik.errors[setting.identifier] && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors[setting.identifier]}</div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>

        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          <button type='submit' className='btn btn-primary' disabled={loading}>
            {!loading && 'Save Changes'}
            {loading && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </div>
  </div>
)
}

export { EditSettings }
