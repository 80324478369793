/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { KTSVG } from '../../../../_monic/helpers';
import { FailuresCount, TestInstanceModel } from '../../auth/models/TestRunModel';
import { TestResultTab } from './TestResultsTab';

type Props = {
  failuresCount: FailuresCount[],
  testInstances: TestInstanceModel[] | undefined
  status: string
  tags: Array<string>
  categories: Array<string>
  testReportingDetails: TestReportingDetails
}

const FailuresList: React.FC<Props> = ({failuresCount, testInstances, status, tags, categories, testReportingDetails}) => {
  const [selectedFailuresCount, setSelectedFailuresCount] = useState<FailuresCount>();
  if (status != 'COMPLETE' && status != "PARTIALLY COMPLETE") {
    return (
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body pt-9 pb-0'>
        {status == 'FAILURE' ? <h3 className='fw-bolder'>Test failed due to server error</h3> : 
        <div> <span>The test is in progress... Please wait </span> <span className="spinner-border spinner-border-sm align-middle ms-2"></span></div>}
        <br />
        </div>
      </div>
    );
  }
  if (selectedFailuresCount) {
    testInstances = testInstances?.filter(i => selectedFailuresCount.testInstanceIds.includes(i.id + ''));
    return (
    <div>
      <div className='my-5'>
        <button
              onClick={() => setSelectedFailuresCount(undefined)}
              type='button'
              className='btn btn-lg text-primary fw-bolder me-3'
              data-kt-stepper-action='previous'
            >
             {'<<'} Test Failures List
            </button>
      </div>
      <TestResultTab testInstances={testInstances} status={status} selectedTest={testReportingDetails[selectedFailuresCount.auditId].title} 
       tags={tags} categories={categories} testReportingDetails={testReportingDetails}/>
    </div>
    );
  }

  return (
    <div className='flex-column flex-lg-row-auto w-100  mb-10 mb-lg-0'>
      <div className='row card card-flush' style={{
      marginLeft: -13,
      marginRight: -13,
    }}>
        <div className='card-body pt-5' id='kt_chat_contacts_body'>
          <div
          className='scroll-y me-n5 pe-5 h-200px h-lg-auto'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_header, #kt_toolbar, #kt_footer, #kt_chat_contacts_header'
          data-kt-scroll-wrappers='#kt_content, #kt_chat_contacts_body'
          data-kt-scroll-offset='0px'
          id="failures-list"
          >
          {
              failuresCount.map(x => {   
              const details = testReportingDetails[x.auditId];         
              return (
                  <>
                  <div className='d-flex flex-stack py-4'>
                  <div className='d-flex align-items-center'>
                      <div className='ms-5'>
                      <span className='fs-5  text-gray-900 mb-2'>
                        <a href='#' className='fs-5 fw-bolder text-primary-900 text-hover-info mb-2' onClick={() => setSelectedFailuresCount(x)}>
                          {details.title}     
                        </a>
                      </span>
                      </div>
                  </div>
                  
                  <div className='d-flex  align-items-end ms-2 pb-2'>                  
                  <span className='badge badge-sm badge-light-danger mx-2'>Pages Affected: {x.testInstanceIds.length}</span>
                  <span className='badge badge-sm badge-light-danger mx-2'>Instance Count: {x.instanceCount}</span>
                  </div>
                  </div>
                  <div className='separator separator-dashed'></div>
                  </>
              );
              })   
          }
          </div>
      </div>
      </div>
  </div>
  )
}

export {FailuresList}
