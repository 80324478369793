import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useSelector, shallowEqual } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { RootState } from '../../../setup';
import { FORM_STATUSES, getUrlPrefix } from '../../modules/utils';
import { DetailsPopup } from '../websites/DetailsPopup';
import { useFormik } from 'formik';
import { WebsiteModel } from '../../modules/auth/models/OrganizationModel';
import { viewWebsite } from '../../modules/auth/redux/OrganizationCRUD';
import { ErrorMsg } from '../layout/MasterLayout';
import { AxiosResponse } from 'axios';
import _ from 'lodash';
import { addTestSchedule, editTestSchedule, getTestSchedule } from '../../modules/auth/redux/TestRunCRUD';
import { Cron } from 'react-js-cron';
import { CronType } from 'react-js-cron';
import { STANDARD_TEST_NAME, getFrequencyText } from '../utils';
import { UserModel } from '../../modules/auth/models/UserModel';

const schema = Yup.object().shape({
    url: Yup.lazy(value => {
        if (/((http([s]){0,1}:\/\/){0,1}(localhost|127.0.0.1){1}(([:]){0,1}[\0-9]{4}){0,1}\/{0,1}){1}/.test(value)) {
            return Yup.string().required('URL is required');
        }
        return Yup.string().required().url('Invalid URL format');
    }),
    name: Yup.string().required('Name is required'),
    testMode: Yup.string()
        .required('Test Mode is required'),
    cron: Yup.string()
        .required('Select valid test frequency')
        .test("cronTest", "The test frequency is too frequent. Please try a less frequent option", 
        value => {
            if (!value) {
                return false;
            }
            if (value == '* * * * *') {
                return false;
            }
            const parts = value.split(' ');
            if (parts.length != 5) {
                return false;
            }
            return (parts[0] != '*' && parts[1] != '*') && (parts[2] != '*' || parts[4] != '*');
        }),
    wcagVersion: Yup.string()
        .required('WCAG Version is required'),
    wcagLevel: Yup.string()
        .required('WCAG Level is required'),
    disableExperimental: Yup.bool().required('Invalid disable experimental flag'),
    startDate: Yup.date().typeError('Invalid date. Click on the calendar button to set the date')
        .test('validateStartDate', 'Select start date in the future', function (obj) {
            return this.parent.startType == 'now' || (obj != null && obj?.getTime() > new Date().getTime());
        }),
});

const setCronValue = (value: string, formik: any) => {
    const parts = value.split(' ');
    if (getFrequencyText(value) == 'Monthly') {
        // monthly
        parts[4] = '*';
    }
    const cron = parts.join(' ');
    formik.setFieldValue('cron', cron);
};

const getAllowedDropdowns = (cron: string, allowedDropdowns: CronType[]): CronType[] => {
    const frequencyText = getFrequencyText(cron);
    const allowedSet = new Set(allowedDropdowns);
    if (frequencyText == 'Weekly') {
        allowedSet.add('week-days');
    }
    if (frequencyText == 'Monthly') {
        allowedSet.delete('week-days');
    }
    return Array.from(allowedSet);
};

const AddEditTestSchedule: React.FC<{onSave: any}> = ({onSave}) => {
    const history = useHistory();
    const { pathname } = useLocation();
    const { action, testScheduleId, websiteId }: any = useParams();
    const [showPopup, setShowPopup] = useState(false);
    const [loading, setLoading] = useState(false);
    const [hasAuth, setHasAuth] = useState(false);
    const [websiteData, setWebsiteData] = useState<Record<string, WebsiteModel>>({});
    const [dataError, setDataError] = useState<DataError>();
    const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel;
    const currentDate = new Date();
    const initialValues = { url: '', testMode: 'single_page', name: '', startType: 'now', cron: `${currentDate.getMinutes()} ${currentDate.getHours()} ${currentDate.getDate()} * *`, wcagVersion: '', wcagLevel: '', disableExperimental: true, startDate: new Date() };
    let id: number | undefined = undefined;
    if (action == 'edit' && testScheduleId && !Number.isNaN(parseInt(testScheduleId))) {
        id = parseInt(testScheduleId);
    }
    const isActive = action == 'add' || action == 'edit';
    const isSuperAdmin = user.gamyata && user.gamyata.role == 'SUPERADMIN';

    const [stylesLoaded, setStylesLoaded] = useState(false);

    useEffect(() => {
        Promise.all([
            // @ts-ignore
            import('react-js-cron/dist/styles.css')
        ]).then(() => {
        setStylesLoaded(true);
        });
    }, []);

    const formik = useFormik<any>({
        initialValues,
        validationSchema: schema,
        onSubmit: (values, { setStatus }) => {
            setLoading(true);
            const startTimeEpoch = values.startType == 'now' ? -1 : values.startDate.getTime();
            const testSettings: any = { testMode: values.testMode, wcagVersion: values.wcagVersion, wcagLevel: values.wcagLevel, disableExperimental: JSON.parse(values.disableExperimental) };
            if (hasAuth) {
                testSettings.auth = {username: values.authUsername, password: values.authPassword};
            }
            const promise = id ? editTestSchedule(id, values.name, values.url, testSettings, values.cron, startTimeEpoch + '', websiteData[values.url].id) :
                addTestSchedule(values.name, values.url, testSettings, values.cron, startTimeEpoch + '', websiteData[values.url].id);

            promise.then(({ data: { id } }) => {
                setLoading(false);
                setStatus({ state: FORM_STATUSES.SUCCESS, response: { id } });
                setShowPopup(true);
            }).catch((e) => {
                setLoading(false);
                const errorData = e?.response?.data;
                const lbErrorCode = errorData?.error?.code;
                const errorMsg = lbErrorCode == "ER_UNREACHABLE_URL" ? errorData?.error?.message : 'Server Error! Cannot save the test schedule';
                setStatus({ state: FORM_STATUSES.FAILURE, response: { error: errorMsg } });
            });
        },
    });

    useEffect(() => {
        formik.resetForm();
        viewWebsite().then((result) => {
            const websiteByUrl: Record<string, WebsiteModel> = {};
            if (result.data) {
                for (const w of result.data) {
                    websiteByUrl[w.url] = w;
                }
            }
            setWebsiteData(websiteByUrl);
        }).catch(e => setDataError({ error: "Server Error! Cannot fetch website data!" }));
        if (websiteId) {
            const website = Object.values(websiteData).find(w => w.id == websiteId);
            if (website) {
                formik.setFieldValue('url', website.url);
            }
        }
        if (id) {
            getTestSchedule(id).then((result: AxiosResponse) => {
                formik.setFieldValue('url', result.data.baseUrl);
                formik.setFieldValue('name', result.data.name);
                formik.setFieldValue('cron', result.data.cron);
                const startType = result.data.startTimeEpoch == -1 ? 'now' : 'startAt';
                formik.setFieldValue('startType', startType);
                formik.setFieldValue('startDate', new Date(parseInt(result.data.startTimeEpoch)));
                formik.setFieldValue('testMode', result.data.testSettings.testMode);
                formik.setFieldValue('wcagVersion', result.data.testSettings.wcagVersion);
                formik.setFieldValue('wcagLevel', result.data.testSettings.wcagLevel);
                formik.setFieldValue('disableExperimental', result.data.testSettings.disableExperimental);
                setHasAuth(result.data.testSettings.auth != undefined);
                formik.setFieldValue('authUsername', result.data.testSettings.auth?.username);
                formik.setFieldValue('authPassword', result.data.testSettings.auth?.password);
            }).catch(e => setDataError({ error: "Server Error! Cannot fetch data!" }));
        }
    }, [pathname]);

    const isMobile = useSelector<RootState>(({ auth }) => auth.isMobile, shallowEqual)
    const urlPrefix = getUrlPrefix(isMobile, pathname.startsWith('/v2') ? '/v2' : undefined);
    const closePopup = () => history.push(websiteId ? `${urlPrefix}/activities/testmgmt/schedules-by-website/${websiteId}` : `${urlPrefix}/activities/testmgmt/schedules`);

    if (!stylesLoaded) {
        return null;
    }

    if (dataError) {
        return (
            <DetailsPopup key={'is: ' + isActive} title={`${_.startCase(action)} Test Schedule`} isActive={isActive} onCloseBtnClick={closePopup}>
            <div className='card mb-5 mb-xl-10'>
                <div
                    className='card-header border-0'
                >
                    <div>
                        <br />
                        <div className='mb-lg-15 alert alert-danger d-flex flex-column-fluid flex-center'>
                            <div className='alert-text font-weight-bold'>{dataError.error}</div>
                        </div>
                    </div>
                </div>
            </div>
            </DetailsPopup>
        );
    }

    const shouldDisableCriticalFields = !isSuperAdmin && formik.values.name == STANDARD_TEST_NAME;

    return (
        <>
            <DetailsPopup key={'is: ' + isActive} title={`${_.startCase(action)} Test Schedule`} isActive={isActive} onCloseBtnClick={closePopup}
                onSave={onSave} closePopup={closePopup} showPopup={showPopup} setShowPopup={setShowPopup} popupMessage={`Test Schedule is ${id ? 'updated' : 'created'} successfully`}>
                <div className="row">
                    {formik.status && formik.status.state == FORM_STATUSES.FAILURE ? (
                        <div className='alert-danger' style={{ padding: 5 }}>
                            <div className='alert-text font-weight-bold'>{formik.status.response?.error}</div>
                        </div>
                    ) : null}
                    <div className="col-md-12">
                        {!isSuperAdmin && action == 'edit' ? <i>Note: only the test schedule can be edited for existing tests.</i> : null}
                        <form onSubmit={formik.handleSubmit} noValidate className='test-form'>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <label htmlFor='s_website'>Enter URL</label>
                                    <select
                                        id='s_website'
                                        className='form-select form-select-solid form-select-lg'
                                        {...formik.getFieldProps('url')}
                                        disabled={shouldDisableCriticalFields && websiteId != null}
                                    >
                                        <option value="">Select Url</option>
                                        {Object.values(websiteData)?.map(website => (
                                            <option value={website.url}>{website.url}</option>
                                        ))}
                                    </select>
                                    {formik.touched.url && formik.errors.url && (
                                        <ErrorMsg>
                                            <div className='fv-help-block'>{formik.errors.url}</div>
                                        </ErrorMsg>
                                    )}
                                </div>

                                <div className='col-md-6'>
                                    <label htmlFor='test-name'>Test Name for reference</label>
                                    <input
                                        id='test-name'
                                        type='text'
                                        placeholder='Enter name'
                                        {...formik.getFieldProps('name')}
                                        disabled={shouldDisableCriticalFields}
                                    />
                                    {formik.touched.name && formik.errors.name && (
                                        <ErrorMsg>
                                            <div className='fv-help-block'>{formik.errors.name}</div>
                                        </ErrorMsg>
                                    )}
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-md-12'>
                                    <div role="radiogroup" aria-labelledby="start_at" className="testing_mode">
                                        <span id='start_at' className="testingmode1">Test Frequency</span>
                                        <Cron
                                            defaultPeriod='month'
                                            value={formik.values.cron}
                                            setValue={(value: string) => setCronValue(value, formik)}
                                            clearButtonAction={'empty'}
                                            periodicityOnDoubleClick={false}
                                            clockFormat='12-hour-clock'
                                            mode='single'
                                            allowedDropdowns={getAllowedDropdowns(formik.values.cron, ['period', 'months', 'month-days', 'week-days', 'hours', 'minutes'])}
                                            allowedPeriods={['week', 'month']} 
                                            />
                                        {formik.touched.cron && formik.errors.cron && (
                                            <ErrorMsg>
                                                <div className='fv-help-block'>{formik.errors.cron}</div>
                                            </ErrorMsg>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-md-12'>
                                    <div role="radiogroup" aria-labelledby="testingmodel" className="testing_mode">
                                        <span className="testingmode1" id="testingmodel">Testing Mode</span>

                                        <input type="radio" id="singlewebpage" {...formik.getFieldProps('testMode')}
                                            style={{ marginTop: -1, verticalAlign: 'middle' }}
                                            value='single_page' disabled={!isSuperAdmin && action == 'edit'} checked={formik.values.testMode == 'single_page'} />
                                        <label htmlFor="singlewebpage" style={{ marginLeft: 5 }}>Single Webpage</label>

                                        <input type="radio" id="entirewebpage" {...formik.getFieldProps('testMode')}
                                            style={{ marginTop: -1, verticalAlign: 'middle' }}
                                            value='full_site' disabled={!isSuperAdmin && action == 'edit'} checked={formik.values.testMode == 'full_site'} />
                                        <label htmlFor="entirewebpage" style={{ marginLeft: 5, marginRight: 10 }}>Entire Webpage</label>
                                        <span className="dropdown help-link">
                                            <a href="#" className="dropdown-toggle"
                                                data-toggle="dropdown" role="button"
                                                aria-haspopup="true" aria-expanded="false"
                                                title="help"><i className="fa fa-question-circle-o"
                                                    aria-hidden="true"></i></a>
                                            <div className="dropdown-menu">
                                                <small>All pages under this top level URL</small>
                                            </div>
                                        </span>
                                        {formik.touched.testMode && formik.errors.testMode && (
                                            <ErrorMsg>
                                                <div className='fv-help-block'>{formik.errors.testMode}</div>
                                            </ErrorMsg>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-md-12'>
                                    <div role="radiogroup" aria-labelledby="testingmodel2" className="testing_mode">
                                        <span className="testingmode1" id="testingmodel2">Auth Credentials</span>

                                        <input type="radio" 
                                            id='has-auth'
                                            name='has-auth'
                                            onChange={(e) => setHasAuth(e.target.checked)}
                                            style={{ marginTop: -1, verticalAlign: 'middle' }}
                                            value='yes' disabled={!isSuperAdmin && action == 'edit'} checked={hasAuth} />
                                        <label htmlFor="has-auth" style={{ marginLeft: 5 }}>Yes</label>

                                        <input type="radio" 
                                            style={{ marginTop: -1, verticalAlign: 'middle' }}
                                            id='has-no-auth'
                                            name='has-auth'
                                            onChange={(e) => setHasAuth(!e.target.checked)}
                                            value='no' disabled={!isSuperAdmin && action == 'edit'} checked={!hasAuth} />
                                        <label htmlFor="has-no-auth" style={{ marginLeft: 5, marginRight: 10 }}>No</label>
                                        <span className="dropdown help-link">
                                            <a href="#" className="dropdown-toggle"
                                                data-toggle="dropdown" role="button"
                                                aria-haspopup="true" aria-expanded="false"
                                                title="help"><i className="fa fa-question-circle-o"
                                                    aria-hidden="true"></i></a>
                                            <div className="dropdown-menu">
                                                <small>Do you need credentials to access the page?</small>
                                            </div>
                                        </span>
                                        {formik.touched.testMode && formik.errors.testMode && (
                                            <ErrorMsg>
                                                <div className='fv-help-block'>{formik.errors.testMode}</div>
                                            </ErrorMsg>
                                        )}
                                    </div>
                                </div>
                            </div>

                            {hasAuth ? 
                            <div className='row'>
                            <div className='col-md-6'>
                                    <label htmlFor='test-username'>Username</label>
                                    <input
                                        id='test-username'
                                        type='text'
                                        placeholder='Enter username'
                                        {...formik.getFieldProps('authUsername')}
                                    />
                                    {formik.touched.authUsername || formik.errors.authUsername && (
                                        <ErrorMsg>
                                            <div className='fv-help-block'>{formik.errors.authUsername}</div>
                                        </ErrorMsg>
                                    )}
                                </div>
                                <div className='col-md-6'>
                                    <label htmlFor='test-password'>Password</label>
                                    <input
                                        id='test-password'
                                        type='text'
                                        placeholder='Enter password'
                                        {...formik.getFieldProps('authPassword')}
                                    />
                                    {formik.touched.authPassword || formik.errors.authPassword && (
                                        <ErrorMsg>
                                            <div className='fv-help-block'>{formik.errors.authPassword}</div>
                                        </ErrorMsg>
                                    )}
                                </div>
                            </div> : null}

                            <div className="row">
                                <div className="col-md-6">
                                    <label htmlFor="select_compliance">Compliance</label>
                                    <select
                                        id='select_compliance'
                                        className='form-select form-select-solid form-select-lg fs-7'
                                        {...formik.getFieldProps('wcagVersion')}
                                        disabled={!isSuperAdmin && action == 'edit'}
                                    >
                                        <option value="">Select WCAG Version</option>
                                        <option value='WCAG 2.0 without ARIA'>WCAG 2.0 without ARIA</option>
                                        <option value='WCAG 2.1 without ARIA'>WCAG 2.1 without ARIA</option>
                                        <option value='WCAG 2.0'>WCAG 2.0</option>
                                        <option value='WCAG 2.1'>WCAG 2.1</option>
                                    </select>
                                    {formik.touched.wcagVersion && formik.errors.wcagVersion && (
                                        <ErrorMsg>
                                            <div className='fv-help-block'>{formik.errors.wcagVersion}</div>
                                        </ErrorMsg>
                                    )}
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="select_level">WCAG Level</label>
                                    <select
                                        id='select_level'
                                        className='form-select form-select-solid form-select-lg fs-7'
                                        {...formik.getFieldProps('wcagLevel')}
                                        disabled={!isSuperAdmin && action == 'edit'}
                                    >
                                        <option value="">Select WCAG Level</option>
                                        <option value='Level A'>Level A</option>
                                        <option value='Level AA'>Level AA</option>
                                        <option value='Level AAA'>Level AAA</option>
                                    </select>
                                    {formik.touched.wcagLevel && formik.errors.wcagLevel && (
                                        <ErrorMsg>
                                            <div className='fv-help-block'>{formik.errors.wcagLevel}</div>
                                        </ErrorMsg>
                                    )}
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-md-12 testingmode1'>
                                    <button onClick={closePopup} className='btn cancel-form' disabled={loading}>
                                        Cancel
                                    </button>
                                    <button type='submit' className='btn btn-red' disabled={loading}>
                                        {!loading && 'Save'}
                                        {loading && (
                                            <span className='indicator-progress' style={{ display: 'block' }}>
                                                Please wait...{' '}
                                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                            </span>
                                        )}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </DetailsPopup>
        </>
    )
}

export { AddEditTestSchedule }
