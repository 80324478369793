import { Dispatch, SetStateAction, useEffect } from 'react';
import React, { useState } from 'react';

export const Popup: React.FC<{onCloseBtnClick?: any, show: boolean, setShow: Dispatch<SetStateAction<boolean>>}> = ({ children, onCloseBtnClick, show, setShow}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (show) {
      $('#save-test')?.modal('show');
    } else {
      $('#save-test')?.modal('hide');
    }
    setIsModalOpen(show);
  }, [show]);

  return (
    <div className={`modal fade save-test ${isModalOpen ? 'in' : ''}`} id="save-test" tabIndex={-1} role="dialog"
      aria-labelledby="modal-add-projectLabel" data-backdrop='static' style={{ display: isModalOpen ? 'block' : 'none' }}>
      <div className="vertical-alignment-helper">
        <div className="vertical-align-center">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body">
                <button type="button" className="close modal-close" data-dismiss="modal" aria-label="Close" 
                  onClick={() => {
                    setShow(false);
                    setIsModalOpen(false);
                    if (onCloseBtnClick) {
                      onCloseBtnClick();
                    }
                  }}>
                  <span aria-hidden="true">&times;</span>
                </button>
                <div className="text-center">
                  <img src="/assetsv2/images/success.png" alt="success icon" />
                  <p className="h2"><strong>{children}</strong></p>
                  <p>
                    <a 
                      onClick={() => {
                        setShow(false);
                        setIsModalOpen(false);
                        
                        if (onCloseBtnClick) {
                          onCloseBtnClick();
                        }
                      }} href="#" data-dismiss="modal" className="btn">Ok</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};