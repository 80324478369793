import React from 'react'
import {PageLink, PageTitle} from '../../../_monic/layout/core'
import {Redirect, Route, Switch} from 'react-router-dom'
import { MyWebsites } from './components/MyWebsites'
import { WebsiteDetail } from './components/WebsiteDetail'
import { WelcomePageTitle } from '../../pages/dashboard/DashboardWrapper'

const breadCrumbs: Array<PageLink> = [
  {
    title: 'Reports Management',
    path: '/activities/testmgmt/runtest',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ReportsManagementPage: React.FC = (props: any) => {
  return (
    <>
    <Switch>
        <Route path='/activities/reports/mywebsites/heatmap'>
        {/* <PageTitle breadcrumbs={breadCrumbs}>Websites Heatmap</PageTitle> */}
        <WelcomePageTitle />
          <MyWebsites />
        </Route>
        <Route path='/activities/reports/mywebsites'>
        {/* <PageTitle breadcrumbs={breadCrumbs}>Websites Dashboard</PageTitle> */}
        <WelcomePageTitle />
          <MyWebsites />
        </Route>
        <Route path='/activities/reports/website/:url/:activeTab?'>
        {/* <PageTitle breadcrumbs={breadCrumbs}>Website Details</PageTitle> */}
        <WelcomePageTitle />
          <WebsiteDetail />
        </Route>
        {/* <Redirect to='activities/reports/mywebsites' /> */}
      </Switch>      
    </>
  )
}

export default ReportsManagementPage
