/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import {KTSVG} from '../../../../_monic/helpers'
import {TestRunModel} from '../../auth/models/TestRunModel';
import {getTestRuns} from '../../auth/redux/TestRunCRUD';
import {Link, useParams} from 'react-router-dom';
import _ from 'lodash';
import { useSelector, shallowEqual } from 'react-redux';
import { RootState } from '../../../../setup';
import { UserModel } from '../../auth/models/UserModel';
import { Fetcher } from '../../reports/components/OverviewTab';
import { getUrlPrefix } from '../../utils';

const ManageTestResults: React.FC = () => {
  const {websiteId, websiteName}: any = useParams();
  const [data, setData] = useState<Array<TestRunModel>>();
  const [dataError, setDataError] = useState<{error: string}>();
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const testMode = '-1', wcagVersion = '-1', wcagLevel = '-1', status = '-1';
  const organizationId = user.gamyata.role == 'SUPERADMIN' ? "-1" : undefined;

  const fetchData = (testMode?: string, wcagVersion?: string, wcagLevel?: string, organizationId?: string, status?: string) => {
    const where: any = {
      testMode,
      wcagVersion,
      wcagLevel,
      status,
      organizationId,
    };
    if (websiteId) {
      where.websiteId = websiteId;
    }
    getTestRuns(where).then((result) => {
      setData(result.data);
      setDataError(undefined);
    }).catch(e => setDataError({error: "Server Error! Cannot fetch data!"}));
  }

  useEffect(() => {
    fetchData();
  }, [websiteId]);

  const isMobile = useSelector<RootState>(({auth}) => auth.isMobile, shallowEqual)
  const urlPrefix = getUrlPrefix(isMobile);

  if (dataError || data == null) {
    return (
      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          {dataError ? (
            <div>
              <br />
                <div className='mb-lg-15 alert alert-danger d-flex flex-column-fluid flex-center'>
                  <div className='alert-text font-weight-bold'>{dataError.error}</div>
                </div>
                </div>
          ) : (
            <div>
              <br />
              <span>Loading... </span> <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
           </div>
        )}
        </div>
      </div>
      );
  }

  return (
    <div className={`card`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>View Test Runs {websiteId && websiteName ? `of ${websiteName}` : ''}</span>
        </h3>        
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      
        <div>
      <Fetcher wcagLevel={wcagLevel} wcagVersion={wcagVersion} testMode={testMode} status={status}
        organization={organizationId} filterButtonText='Filter' filterButtonOnClick={fetchData} />
        </div>
        <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-full-bordered table-row-gray-100 align-middle gs-0 gy-3'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bolder'>
                <th className='min-w-150px'>Website</th>
                <th className='min-w-150px'>Test Mode</th>
                <th className='min-w-150px'>WCAG Version</th>
                <th className='min-w-150px'>WCAG Level</th>
                <th className='min-w-140px'>Status</th>
                <th className='min-w-70px'>Score</th>
                <th className='min-w-140px'>Created At</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
            {data?.map((row) => ( 
                       <tr key={row.id}>
                       <td>
                         <Link title={row.baseUrl} to={urlPrefix + `/activities/testmgmt/testresult/${row.id}`} className='text-primary-900 text-hover-info fs-6'>
                          {row.website?.name}
                         </Link>
                       </td>
                       <td>
                        {_.startCase(row.testMode)}
                       </td>
                       <td>
                        {row.wcagVersion}
                       </td>
                       <td>
                        {row.wcagLevel}
                       </td>
                       <td className='fw-bolder'>
                           {row.status}
                       </td>
                       <td>
                        {row.stats?.score}
                       </td>
                       <td>
                          {new Date(row.created).toLocaleString('en-IN')}
                       </td>
                     </tr>
                     ))}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export {ManageTestResults}
