import React, {useEffect, useState} from 'react'
import { useSelector, shallowEqual, useDispatch, connect, ConnectedProps } from 'react-redux'
import * as auth from './../../auth/redux/AuthRedux'
import { RootState } from '../../../../setup'
import { Settings } from '../../../utils/config'
import { UserDbModel, UserModel } from '../../auth/models/UserModel'
import { getUserMyself } from '../../auth/redux/AuthCRUD'
import { useHistory } from 'react-router-dom'

const mapState = (state: RootState) => ({auth: state.auth})
const connector = connect(mapState, auth.actions)
type PropsFromRedux = ConnectedProps<typeof connector>

const ViewProfile: React.FC<PropsFromRedux> = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [data, setData] = useState<UserDbModel>();
  const [dataError, setDataError] = useState<DataError>();
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const isMocked = user.gamyata?.originalRole != null;
  useEffect(() => {
    getUserMyself().then((result: any) => {
      setData(result.data);
    }).catch(e => {
      console.error(e);
      setDataError({error: "Server Error! Cannot fetch user data!"})
    });
  }, [user.gamyata?.role]);

  if (dataError || data == null) {
    return (
      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          {dataError ? (
            <div>
              <br />
                <div className='mb-lg-15 alert alert-danger d-flex flex-column-fluid flex-center'>
                  <div className='alert-text font-weight-bold'>{dataError.error}</div>
                </div>
                </div>
          ) : (
            <div>
              <br />
              <span>Loading... </span> <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
           </div>
        )}
        </div>
      </div>
      );
  }

  const changeRole = (role: string) => {
    if (!user.gamyata) {
      return;
    }
    if (!user.gamyata.originalRole) {
      user.gamyata.originalRole = user.gamyata.role;
    }
    user.gamyata.role = role;
    dispatch(props.login({...user}));
    history.go(0);
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_edit_user'
        aria-expanded='true'
        aria-controls='kt_edit_user'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>View Profile</h3>
        </div>
      </div>      

      <div id='kt_edit_user' className='collapse show'>
      <form noValidate className='form'>
          <div className='card-body border-top p-9'>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Name</label>

              <div className='col-lg-8 fv-row'>
                {data.parichay.fullName}
              </div>
            </div>
            
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Email</label>

              <div className='col-lg-8 fv-row'>
                {data.email}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Role</label>

              <div className='col-lg-8 fv-row' key={user.gamyata?.role}>
                {isMocked && data.role != user.gamyata?.role ? `${user.gamyata.role}(Mocked) ${user.gamyata.originalRole} (Original)` : data.role}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Organization</label>

              <div className='col-lg-8 fv-row'>
                {data.organization?.name}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Designation</label>

              <div className='col-lg-8 fv-row'>
                {data.parichay.designation}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Mobile No</label>

              <div className='col-lg-8 fv-row'>
                {data.parichay.mobileNo}
              </div>
            </div>

            {user.gamyata?.originalRole == 'SUPERADMIN' || user.gamyata?.role == 'SUPERADMIN' ? 
            <div className='row mb-6'>
              <label htmlFor='s_role' className='col-lg-4 col-form-label fw-bold fs-6'>Mock Role</label>

              <div className='col-lg-8 fv-row'>
                <select
                  id='s_role'
                  className='form-select form-select-solid form-select-lg'
                  value={user.gamyata.role}
                  onChange={(e) => changeRole(e.target.value)}
                >
                    <option value='TESTER'>{Settings.TesterLabel}</option>
                    <option value='ADMIN'>{Settings.AdminLabel}</option>
                    <option value='SUPERADMIN'>Super Admin</option>
                  </select>
              </div>
            </div>
            : null}
          </div>
        </form>
      </div>
    </div>
  )
}

const connectedViewProfile = connector(ViewProfile);
export {connectedViewProfile as ViewProfile}
//export default connector(ViewProfile)
