import React, {useEffect} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import { RootState } from '../../../setup'
import { logoutAll } from './redux/AuthCRUD'
import * as auth from './redux/AuthRedux'

export const Logout: React.FC<{isMobile: boolean}> = ({isMobile}) => {
  const history = useHistory();
  const loggedUser: any = useSelector<RootState>(({auth}) => auth.user, shallowEqual)
  const dispatch = useDispatch()
  const urlPrefix = isMobile ? '/m' : '';

  useEffect(() => {
    if (loggedUser) {
      logoutAll(loggedUser.userName, loggedUser.sessionId, loggedUser.browserId, loggedUser.localTokenId).then(() => dispatch(auth.actions.logout()));
    } else {
      dispatch(auth.actions.logout());
    }
    history.push(urlPrefix + "/");
  }, [dispatch])
  return null;
}
