 /* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import Markdown from 'react-markdown'
import { OrganizationModel } from '../../auth/models/OrganizationModel';
import { getActivityLogs } from '../../auth/redux/TestRunCRUD';
import { ActivityLogs } from '../../auth/models/TestRunModel';

type Props = {
  className: string
  onboarding?: boolean
}

const ActivityLog: React.FC<Props> = ({className, onboarding}) => {
  const [data, setData] = useState<Array<ActivityLogs>>();
  const [orgData, setOrgData] = useState<Record<number, OrganizationModel>>({});
  const [dataError, setDataError] = useState<{error: string}>();

  useEffect(() => {
    getActivityLogs({order: ['created DESC']}).then((result) => setData(result.data)).catch(e => setDataError({error: "Server Error! Cannot fetch user data!"}));
    
  }, []);

  if (dataError) {
    return (
      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          {dataError ? (
            <div>
              <br />
                <div className='mb-lg-15 alert alert-danger d-flex flex-column-fluid flex-center'>
                  <div className='alert-text font-weight-bold'>{dataError.error}</div>
                </div>
                </div>
          ) : (
            <div>
              <br />
              <span>Loading... </span> <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
           </div>
        )}
        </div>
      </div>
      );
  }

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>Activity Logs</span>
        </h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-full-bordered table-row-gray-100 align-middle gs-0 gy-3'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bolder'>
                <th className='min-w-150px'>Activity</th>
                <th className='min-w-150px'>Priority Level</th>
                <th className='min-w-150px'>Time</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
            {data?.map((row) => (   
              <tr>
                <td>
                  <span className='text-dark fw-bolder fs-6'>
                    <Markdown children={row.content} />
                  </span>
                </td>
                <td>
                  <span className='text-dark fw-bolder fs-6'>
                    {row.priority}
                  </span>
                </td>
                <td>
                  <span className='text-dark fw-bolder fs-6'>
                    {new Date(row.created).toLocaleString('en-IN')}
                  </span>
                </td>
              </tr>
              ))}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export {ActivityLog}
