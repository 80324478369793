/* eslint-disable jsx-a11y/anchor-is-valid */
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { TestRunModel } from '../../../../app/modules/auth/models/TestRunModel'
import { getTestRuns, getTopIssuesByOrg } from '../../../../app/modules/auth/redux/TestRunCRUD'
import { getScoreColor } from '../../../../app/modules/testmgmt/components/TestResult'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import { LastNRunsDropdown } from '../../content/dropdown/LastNRunsDropdown'

type Props = {
  className: string
  style?: any
}

const Top5Issues: React.FC<Props> = ({className, style}) => {
  const [data, setData] = useState<Array<{title: string, count: number}>>([]);
  const [dataError, setDataError] = useState<{error: string}>();

  useEffect(() => {
    getTopIssuesByOrg().then(result => setData(result.data)).catch(e => setDataError({error: 'Server Error'}));
  }, []);

  if (dataError || data == null) {
    return (
      <div className='card mb-5 mb-xl-10' style={style}>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          {dataError ? (
            <div>
              <br />
                <div className='mb-lg-15 alert alert-danger d-flex flex-column-fluid flex-center'>
                  <div className='alert-text font-weight-bold'>{dataError.error}</div>
                </div>
                </div>
          ) : (
            <div>
              <br />
              <span>Loading... </span> <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
           </div>
        )}
        </div>
      </div>
      );
  }

  return (
    <div className={`card ${className}`} style={style}>
      {/* begin::Header */}
      <div className='card-header border-0'>
        <h3 className='card-title fw-bolder text-dark'>Top 5 Issues</h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body pt-2'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bolder'>
                <th scope="col" className='min-w-150px'>Title</th>
                <th scope="col" className='min-w-120px'>Issue Count</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {data.length == 0 ? <span>No data found</span> : null}
              {data.slice(0, 5).map(t => {
                return (
                  <tr>
                    <td className='fw-bold'>
                     {t.title}
                    </td>
                    <td className='text-end fw-bold'>
                      {t.count}
                    </td>
                  </tr>
                );
              })}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export {Top5Issues}
