/* eslint-disable jsx-a11y/anchor-is-valid */
import { AxiosResponse } from 'axios';
import React, {FC, useEffect, useState} from 'react'
import { KTSVG } from '../../../../_monic/helpers';
import { TestInstanceModel } from '../../auth/models/TestRunModel';
import { getTestInstanceForMediaInventory, getTestInstancesForMediaInventory } from '../../auth/redux/TestRunCRUD';
import { decodeURISafe } from '../../utils';

type Props = {
  testInstances: TestInstanceModel[] | undefined
  status: string
  mediaInventoryTotals?: any
  printOnly?: boolean
}

const MediaInventoryTab: FC<Props> = ({
  testInstances, status, mediaInventoryTotals,
}) => {
  const [selectedPage, setSelectedPage] = useState<TestInstanceModel | null>(null);
  const [data, setData] = useState<TestInstanceModel>();
  const [printData, setPrintData] = useState<Array<TestInstanceModel>>();
  const [dataError, setDataError] = useState<DataError>();
  const [isConsolidated, setIsConsolidated] = useState<boolean>(false);
  const [filter, setFilter] = useState<string>();
  const [selectedCriteria, setSelectedCriteria] = useState<string>();

  useEffect(() => {
    if (testInstances == null) {
      return;
    }
    getTestInstancesForMediaInventory(testInstances.map(i => i.id)).then((result: AxiosResponse) => setPrintData(result.data)).catch(e => setDataError({error: "Server Error! Cannot fetch data!"}));
  }, []);
  
  const onPageUrlClick = (testInstance: TestInstanceModel) => {
    getTestInstanceForMediaInventory(testInstance.id).then((result: AxiosResponse) => setData(result.data)).catch(e => setDataError({error: "Server Error! Cannot fetch data!"}));
    setIsConsolidated(false); 
    setSelectedPage(testInstance);
    setSelectedCriteria('');
  }

  const imageIconByCat: any = {
    imageLink: '/media/icons/duotune/coding/cod006.svg', 
    imageButton: '/media/icons/duotune/general/gen006.svg', 
    decorative: '/media/icons/duotune/art/art007.svg', 
    background: '/media/icons/duotune/technology/teh009.svg', 
    fontIcon: '/media/icons/duotune/art/art001.svg', 
    inline: '/media/icons/duotune/layouts/lay003.svg',
    videos: '/media/icons/duotune/arrows/arr027.svg',
    audios: '/media/icons/duotune/communication/com004.svg',
    pdfs: '/media/icons/duotune/files/fil004.svg',
    fonts: '/media/icons/duotune/art/art001.svg',
    others: '/media/icons/duotune/general/gen038.svg',
  };

  const dataErrorElement = selectedPage == null || (data != null && (data.status == "COMPLETE" || data.status == "PARTIALLY COMPLETE")) ? null : (
    <div className='card mb-5 mb-xl-10'>
    <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
    >
        {dataError ? (
        <div>
            <br />
            <div className='mb-lg-15 alert alert-danger d-flex flex-column-fluid flex-center'>
                <div className='alert-text font-weight-bold'>{dataError.error}</div>
            </div>
            </div>
        ) : (
        <div>
            <br />
            <span>Loading... </span> <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
        </div>
    )}
    </div>
    </div>
    );

    if (status != 'COMPLETE' && status != "PARTIALLY COMPLETE") {
      return (
        <div className='card mb-5 mb-xl-10'>
          <div className='card-body pt-9 pb-0'>
          {status == 'FAILURE' ? <h3 className='fw-bolder'>Test failed due to server error</h3> : 
          <div> <span>The test is in progress... Please wait </span> <span className="spinner-border spinner-border-sm align-middle ms-2"></span></div>}
          <br />
          </div>
        </div>
      );
    }

    let totals: any = null;
    if (selectedPage == null && isConsolidated) {
      totals = mediaInventoryTotals;
    } else if (selectedPage != null) {
      totals = data?.mediaInventory.images.totals;
    }

  return (
    <div className='d-flex flex-column flex-lg-row' key={selectedPage?.id || 'default'}>
      <div className='flex-column flex-lg-row-auto w-100 w-lg-300px w-xl-400px mb-10 mb-lg-0' style={{
        marginLeft: -13
      }}>
        <div className='card card-flush'>
          <div className='card-header pt-7 mx-n3' id='kt_chat_contacts_header'>
          <div className='d-flex align-items-center me-4'>
          <input name="filterUrl" type="text" className="form-control form-control-lg form-control-solid" placeholder="Filter" onChange={(e) => setFilter(e.target.value)} />
      </div>
          </div> 

          <div className='card-body mx-n3 pt-5'>
            <div
              className='scroll-y me-n5 h-500px'
              data-kt-scroll='true'
              data-kt-scroll-activate='{default: false, lg: true}'
              data-kt-scroll-max-height='auto'
              data-kt-scroll-dependencies='#kt_header, #kt_toolbar, #kt_footer, #kt_chat_contacts_header'
              data-kt-scroll-wrappers='#kt_content, #kt_chat_contacts_body'
              data-kt-scroll-offset='0px'
            >
              {mediaInventoryTotals && testInstances && testInstances.length > 0 ? (
                <div key='consolidated'>
                <div className='d-flex flex-stack py-4'>
                  <div className='d-flex align-items-center'>
                    <div>
                      <a href='#' className='fs-5 fw-bolder text-primary-900 text-hover-info mb-2' onClick={() => {
                        setIsConsolidated(true); 
                        setSelectedPage(null);
                        }}>
                        All pages cumulative
                      </a>
                    </div>
                  </div>
                  <div className='d-flex  align-items-end ms-2 pb-2'>
                    
                  </div>
                  </div>
                  <div className='separator separator-dashed'></div>
                </div>
              ) : null}
              {
                testInstances?.map((testInstance: TestInstanceModel) => {            
                  if (filter != null && filter.length > 0 && !testInstance.url.startsWith(filter)) {
                    return;
                  }
                  return (
                    <div key={decodeURISafe(testInstance.url)}>
                    <div className='d-flex flex-stack py-4'>
                      <div className='d-flex align-items-center'>
                        <div>
                          <a href='#' className='fs-5 fw-bolder text-primary-900 text-hover-info mb-2' onClick={() => onPageUrlClick(testInstance)}>
                            {decodeURISafe(testInstance.url)}
                          </a>
                        </div>
                      </div>
                      <div className='d-flex  align-items-end ms-2 pb-2'>
                        {testInstance.status == "COMPLETE" ? <span className={"badge badge-success"}>{testInstance.status}</span>: null}
                        {testInstance.status == "PENDING" || testInstance.status == "RUNNING" ? <span className={"badge badge-primary"}>{testInstance.status}</span>: null}
                        {testInstance.status == "FAILURE" ? <span className={"badge badge-danger"}>{testInstance.status}</span>: null}
                      </div>
                      </div>
                      <div className='separator separator-dashed'></div>
                    </div>
                  );
                })   
              }
            </div>
          </div>
        </div>
      </div>

      {selectedPage == null && !isConsolidated? (
      <div className='flex-lg-row-fluid ms-lg-7 ms-xl-5' style={{
        marginRight: -13
      }}>
      <div className='card' id='kt_chat_messenger'>
        <div className='card-body pt-9 pb-0'>
        <div className='text-center mb-13'>
            <h2>Select a page to view details</h2>
          </div>
        </div>
        </div>
        </div>
        ) : null}

      {(selectedPage == null && !isConsolidated) || dataErrorElement != null ? dataErrorElement : (
      <div className='flex-lg-row-fluid ms-lg-7 ms-xl-5' key={selectedPage?.id || 'default'} style={{
        marginRight: -13
      }}>
        <div className='card pt-5' id='kt_chat_messenger'>
          <div className='modal-body scroll-y mx-5 mx-xl-18 h-600px'>
            <div className='text-center'>
              
              <h1 className='mb-3'>{isConsolidated ? 'Media Inventory (Cumulative)' : decodeURISafe(data?.url || '')}</h1>
              <div className='card-body pt-9 pb-0'>
                <div className='h-4px w-100 bg-light mb-5'>
                  
                </div>
              </div>
              
              <div className='text-muted fw-bold fs-5'>
                <div className='mb-5'>Select one of the following to view the snippets below</div>
                <div className="d-flex flex-wrap">
                {Object.keys(imageIconByCat).map(i => (
                  <a href='javascript:void()' onClick={() => setSelectedCriteria(i)} className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'
                    key={i + "stats"}>
                    <div className='d-flex align-items-center'>
                      <KTSVG
                        path={imageIconByCat[i]}
                        className={`svg-icon-3 ${selectedCriteria == i ? 'svg-icon-danger' : 'svg-icon-primary'} me-2`}
                      />
                      <div className={`fs-2 fw-bolder ${selectedCriteria == i ? 'text-danger' : 'text-gray-700'}`}>{totals[i]}</div>
                    </div>
                    <div className={`fw-bold fs-6 ${selectedCriteria == i ? 'text-danger' : 'text-gray-700'}`}>{i.charAt(0).toUpperCase() + i.slice(1)}</div>
                  </a>
                ))}
                </div>                  
              </div>
            </div>
            {Object.keys(imageIconByCat).map(i => data?.mediaInventory?.images.snippets[i]?.length > 0 && i == selectedCriteria ? (
              <div>
              <div className='separator d-flex flex-center mb-8 mt-10'>
                <span className='text-uppercase bg-body fs-7 fw-bold px-3'>{i.charAt(0).toUpperCase() + i.slice(1)}</span>
              </div>
            
              <div className='me-n7 pe-7'>
                {
                  data?.mediaInventory?.images.snippets[i].map((snippet: any) => {
                    return (
                      <div
                        className='d-flex flex-stack py-4 border-bottom border-gray-300 border-bottom-dashed'
                        key={i + "snippets"}
                      >
                        <div className='d-flex align-items-center'>
                          <div className='ms-5'>
                            <pre style={{whiteSpace: 'pre-wrap'}}>
                              <code style={{padding: 0}}>
                              <p>{snippet}</p>
                              </code>
                            </pre>
                          </div>
                        </div>
                      </div>
                    );
                  })
                }
              </div>
              </div>
            ) : null)}
            </div>
        </div>
      </div>
      )}
    </div>
  )
}

export {MediaInventoryTab}
