import React, {Suspense} from 'react'
import {BrowserRouter, Route, Switch} from 'react-router-dom'
import {I18nProvider} from '../_monic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_monic/layout/core'
import AuthInit from './modules/auth/redux/AuthInit'
import {Routes} from './routing/Routes'
import './utils/lh-styles.css';
import './utils/custom.scss';
import { MobileRoutes } from './routing/MobileRoutes'
import { RoutesV2 } from './routing/RoutesV2'

type Props = {
  basename: string
}

const App: React.FC<Props> = ({basename}) => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}> 
      <BrowserRouter basename={basename}>
        {/* <I18nProvider> */}
        
        
          <Switch>
            <Route path="/m" component={MobileRoutes}/>
            <Route path="/v2" component={RoutesV2}/>
            <Routes />  
          
          </Switch>
          
        {/* </I18nProvider> */}
      </BrowserRouter>
      </Suspense>
  )
}

export {App}
