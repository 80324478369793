
import { FC} from "react";
import { Route, RouteProps } from "react-router-dom";
import { MobileLayout } from "./MobileLayout";

export const MobileRoute: FC<RouteProps<string> & {componentProps?: any}> = (props) => {
    const { component, componentProps,  ...mProps } = props;
    if (component == undefined) {
        return null;
    }
    const MobileComponent: React.ElementType = component;
    return (
        <Route {...mProps} render={() => 
            <MobileLayout>
                 <MobileComponent {...componentProps} />
            </MobileLayout>
          } />
    )
}
