/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import React, {FC, useEffect} from 'react'
import {KTSVG} from '../../../helpers'
import {useLayout} from '../../core'
import {DefaultTitle} from '../header/page-title/DefaultTitle'
import {MenuComponent} from '../../../assets/ts/components'
import {useLocation} from 'react-router-dom'

const Toolbar1: FC = () => {
  const {classes} = useLayout()
  const {pathname} = useLocation()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [pathname])

  return (
    <div>
      <DefaultTitle />
    </div>
  )
}

export {Toolbar1}
