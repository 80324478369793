/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {Redirect, useLocation, useParams} from 'react-router-dom'
import {KTSVG} from '../../../../_monic/helpers'
import {Link} from 'react-router-dom'
import {getTestSchedule} from '../../auth/redux/TestRunCRUD'
import {TestScheduleModel} from '../../auth/models/TestRunModel'
import {AxiosResponse} from 'axios'
import _ from 'lodash'
import { TestRunsTab } from '../../reports/components/TestRunsTab'
import { OverviewTab } from '../../reports/components/OverviewTab'
import { TestScore } from './TestResult'
import { ProgressBar } from 'react-bootstrap-v5'
import { useSelector, shallowEqual } from 'react-redux'
import { RootState } from '../../../../setup'
import { getUrlPrefix } from '../../utils'
import { HashLink } from 'react-router-hash-link'

const tabs = [
  "overview",
  "testruns",
];

const fetchData = (id: number, setData: React.Dispatch<React.SetStateAction<TestScheduleModel | undefined>>, setDataError: React.Dispatch<React.SetStateAction<DataError | undefined>>) => 
  getTestSchedule(id).then((result: AxiosResponse) => {
    setData(result.data);
    setDataError(undefined);
  }).catch(e => setDataError({error: "Server Error! Cannot fetch data!"}));

const TestSchedule: React.FC = () => {
  const {id, activeTab}: any = useParams();
  const location = useLocation();
  // const [progress, setProgress] = useState<number>(0);
  const [data, setData] = useState<TestScheduleModel>();
  const [dataError, setDataError] = useState<DataError>();
  
  useEffect(() => {
    getTestSchedule(parseInt(id)).then((result: AxiosResponse) => {
      setData(result.data);
      setDataError(undefined);
    }).catch(e => setDataError({error: "Server Error! Cannot fetch data!"}));
  }, []);
   
  useEffect(() => {
    const timer = setTimeout(() => {
      if (data == null) {
        return false;
      }
      //setProgress(getProgress(data.created, 1));
      return data.status != "COMPLETE" && data.status != "INACTIVE" && fetchData(id, setData, setDataError);
    }, 2000);
    return () => clearTimeout(timer);
  });

  const isMobile = useSelector<RootState>(({auth}) => auth.isMobile, shallowEqual)
  const urlPrefix = getUrlPrefix(isMobile) + "/activities/testmgmt/testschedule";
  const testResultUrlPrefix = getUrlPrefix(isMobile) + "/activities/testmgmt/testresult";

  if (dataError || data == null) {
    return (
      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          {dataError ? (
            <div>
              <br />
                <div className='mb-lg-15 alert alert-danger d-flex flex-column-fluid flex-center'>
                  <div className='alert-text font-weight-bold'>{dataError.error}</div>
                </div>
                </div>
          ) : (
            <div>
              <br />
              <span>Loading... </span> <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
           </div>
        )}
        </div>
      </div>
      );
  }
  const lastTestRun = data.testRuns && data.testRuns[data.testRuns?.length - 1];
  if (lastTestRun && data.status == 'ACTIVE' && data.testRuns && ['RUNNING', 'PENDING'].indexOf(lastTestRun.status) >= 0) {
    return (
      <Redirect to={{...location, pathname: `${testResultUrlPrefix}/${lastTestRun.id}/testresults`}}  />
    );
  }
  const isRecentlyCreated = (new Date().getTime() - new Date(data.created).getTime()) < 30 * 60 * 1000;

  let badgeClass = "badge-primary";
  if (data.status == "COMPLETE") {
    badgeClass = "badge-success";
  } else if (data.status == "FAILURE") {
    badgeClass = "badge-danger";
  } 

  let tabContent = <OverviewTab testScheduleId={id} />;
  if (activeTab == tabs[0]) {
     tabContent = <OverviewTab testScheduleId={id} />;
  } else if (activeTab == tabs[1]) {
    tabContent = <TestRunsTab testRuns={data.testRuns} status={data.status} startTimeEpoch={data.startTimeEpoch} repeatFrequencyMs={data.repeatFrequencyMs} created={data.created} testSettings={data.testSettings} />
  }
  
  return (
    <div>
    <div className='card mb-5 mb-xl-10'>
      <div className='card-body pt-9 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
          <div className='flex-grow-1'>
            <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
              <div className='d-flex flex-column'>
                <div className='d-flex align-items-center mb-2'>
                  <span className='text-gray-800 fs-2 fw-bolder me-1'>
                    {data.baseUrl}
                  </span>
                </div>
                
                <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                  {data.name == null || data.name.trim() == '' ? null :   (
                  <span className='d-flex align-items-center text-gray-700 me-5 mb-2'>
                    <KTSVG
                  path='/media/icons/duotune/communication/com006.svg'
                  className='svg-icon-4 me-1'
                    />
                    Name: {data.name}
                  </span>
                  )}
                  <span className='d-flex align-items-center text-gray-700 me-5 mb-2'>
                    <KTSVG
                      path='/media/icons/duotune/communication/com006.svg'
                      className='svg-icon-4 me-1'
                    />
                    Trigger Time: {data.startTimeEpoch == "-1" || data.startTimeEpoch == null ? new Date(data.created).toLocaleString('en-IN') : new Date(parseInt(data.startTimeEpoch)).toLocaleString('en-IN')}
                  </span>
                  {data.status == "COMPLETE" ? (
                  <span
                    className='d-flex align-items-center text-gray-700 me-5 mb-2'
                  >
                    <KTSVG
                      path='/media/icons/duotune/general/gen018.svg'
                      className='svg-icon-4 me-1'
                    />
                    Created: {new Date(data.created).toLocaleString('en-IN')}
                   </span>
                  ) : null }

                </div>
              </div>
              <div className='d-flex flex-wrap flex-stack'>
              {lastTestRun && lastTestRun.stats && lastTestRun.score ?  (
                <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                  <div className='d-flex flex-column'>
                    <TestScore score={data.testRuns && data.testRuns[data.testRuns?.length - 1].stats.score} />
                    </div>
                    <div className='d-flex flex-column'>
                  <div className='d-flex flex-wrap fw-bold fs-6'>  
                      <div className='bg-danger rounded hoverable py-3 px-4 me-6 mb-3'>
                      <HashLink to={`${testResultUrlPrefix}/${lastTestRun.id}/failures#failures-list`} className='text-gray-900'> 
                        <div className='d-flex align-items-center min-w-125px text-white'>
                          <div className='fs-2 fw-bolder'>{lastTestRun.stats.issueCntByTest.length}</div>
                          <div className='fw-bold fs-6 mx-5'>Failures</div>
                        </div>
                        
                        </HashLink>
                      </div>

                     
                  </div>
                  <div className='d-flex flex-wrap fw-bold fs-6'>
                  <div className='bg-warning rounded hoverable py-3 px-4 me-6 mb-3'>
                      <HashLink to={`${testResultUrlPrefix}/${lastTestRun.id}/warnings#warnings-list`} className='text-gray-900'> 
                        <div className='d-flex align-items-center min-w-125px text-white'>
                          <div className='fs-2 fw-bolder'>{Math.round(lastTestRun.stats.cntTotalWarns/lastTestRun.stats.cntPages)}</div>
                          <div className='fw-bold fs-6 mx-5'>Warnings</div>
                        </div>
                        </HashLink>
                        </div>
                    </div>
                    </div>
                </div>
              ) : null }
            </div>
              <div className="d-flex flex-column">
                <div className='d-flex flex-wrap fw-bold fs-6'>
                  <span className='d-flex align-items-center text-gray-700 me-5 mb-2'>
                    <KTSVG
                      path='/media/icons/duotune/communication/com006.svg'
                      className='svg-icon-4 me-1'
                    />
                    Repeat Frequency: {data.repeatFrequencyMs == -1 || data.repeatFrequencyMs == null ? 'Once' : `Every ${Math.round(data.repeatFrequencyMs/(24 * 60 * 60000))} days`}
                  </span>
                </div>
                <div className='d-flex w-100 mt-auto mb-2' style={{justifyContent: 'flex-end'}}>
                  <span className='fw-bold fs-6 text-gray-700'>Test Progress &nbsp;</span>
                  <span className={"badge " + badgeClass}>{data.status}</span>
                </div>
              </div>
            </div>
            <div className='d-flex overflow-auto h-55px'>
          <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  ((activeTab == tabs[0] || activeTab == null) && 'active')
                }
                to={`${urlPrefix}/${id}/${tabs[0]}`}
              >
                Test Schedule Overview
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (activeTab == tabs[1] && 'active')
                }
                to={`${urlPrefix}/${id}/${tabs[1]}`}
              >
                Test Runs
              </Link>
            </li>
          </ul>
        </div>
            
          </div>
        </div>
                    
         {data.status == 'ACTIVE' && isRecentlyCreated && !lastTestRun ? (
            <div className='my-5'>
              <ProgressBar>
                <ProgressBar isChild={true} now={100} striped={false} animated label={`Test is being initiated. Please wait...`} aria-label="test progress" title='test progress' />
              </ProgressBar>
            </div>
          )
          : null}
        
        {/* {data.status == 'ACTIVE' && data.testRuns && ['RUNNING', 'PENDING'].indexOf(data.testRuns[data.testRuns?.length - 1].status) >= 0 ? (
            <div className='my-5'>            
              <ProgressBar>
                <ProgressBar isChild={true} key={progress} now={progress} animated label={`${progress}%`} aria-label="test progress" title='test progress' />
              </ProgressBar>
            </div>
            ) : null} */}
      </div>
    </div>

    {tabContent}
    
    </div>
  )
}

export {TestSchedule}
