import { useMemo } from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

const DOTS = '...';

const range = (start: number, end: number) => {
    let length = end - start + 1;
    /*
        Create an array of certain length and set the elements within it from
      start value to end value.
    */
    return Array.from({ length }, (_, idx) => idx + start);
};

interface PaginationProps {
    totalCount: number
    pageSize: number
    siblingCount: number
    currentPage: number
    infinite: boolean
}

const usePagination: (prosp: PaginationProps) => any = ({
    totalCount,
    pageSize,
    siblingCount = 1,
    currentPage,
    infinite
}) => {
    const paginationRange = useMemo(() => {
        if (infinite) {
            if (currentPage == 1 && totalCount > pageSize) {
                return [1, DOTS]
            }
            if (currentPage == 1 && totalCount <= pageSize) {
                return [1]
            }
            if (currentPage > 1 && totalCount > pageSize) {
                return [DOTS, currentPage, DOTS];
            }
            if (currentPage > 1 && totalCount <= pageSize) {
                return [DOTS, currentPage];
            }
        }

        const totalPageCount = infinite ? null : Math.ceil(totalCount / pageSize);

        // Pages count is determined as siblingCount + firstPage + lastPage + currentPage + 2*DOTS
        const totalPageNumbers = infinite ? null : siblingCount + 5;

        /*
        Case 1:
        If the number of pages is less than the page numbers we want to show in our
        paginationComponent, we return the range [1..totalPageCount]
        */
        if (totalPageNumbers && totalPageCount && totalPageNumbers >= totalPageCount) {
            return range(1, totalPageCount);
        }

        /*
            Calculate left and right sibling index and make sure they are within range 1 and totalPageCount
        */
        const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
        const rightSiblingIndex = totalPageCount == null ? null : Math.min(
            currentPage + siblingCount,
            totalPageCount
        );

        /*
        We do not show dots just when there is just one page number to be inserted between the extremes of sibling and the page limits i.e 1 and totalPageCount. Hence we are using leftSiblingIndex > 2 and rightSiblingIndex < totalPageCount - 2
        */
        const shouldShowLeftDots = leftSiblingIndex > 2;
        const shouldShowRightDots = rightSiblingIndex && totalPageCount ? rightSiblingIndex < totalPageCount - 2 : null;

        const firstPageIndex = 1;
        const lastPageIndex = totalPageCount;

        /*
            Case 2: No left dots to show, but rights dots to be shown
        */
        if (!shouldShowLeftDots && shouldShowRightDots) {
            let leftItemCount = 3 + 2 * siblingCount;
            let leftRange = range(1, leftItemCount);

            return [...leftRange, DOTS, totalPageCount];
        }

        /*
            Case 3: No right dots to show, but left dots to be shown
        */
        if (shouldShowLeftDots && !shouldShowRightDots) {

            let rightItemCount = 3 + 2 * siblingCount;
            let rightRange = totalPageCount ? range(
                totalPageCount - rightItemCount + 1,
                totalPageCount
            ) : [];
            return [firstPageIndex, DOTS, ...rightRange];
        }

        /*
            Case 4: Both left and right dots to be shown
        */
        if (shouldShowLeftDots && shouldShowRightDots) {
            let middleRange = rightSiblingIndex ? range(leftSiblingIndex, rightSiblingIndex) : [];
            return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
        }

        return [];
    }, [totalCount, pageSize, siblingCount, currentPage]);

    return paginationRange;
};

const Pagination: (props: any) => any = props => {
    const {
        onPageChange,
        totalCount,
        siblingCount = 1,
        currentPage,
        pageSize,
        infinite,
        onNext: _onNext,
        onPrevious: _onPrev,
    } = props;

    const paginationRange = usePagination({
        currentPage,
        totalCount,
        siblingCount,
        pageSize,
        infinite
    });

    // If there are less than 2 times in pagination range we shall not render the component
    if (currentPage === 0 || paginationRange.length < 2) {
        return null;
    }

    const onNext = () => {
        if (currentPage === lastPage) {
            return;
        }
        _onNext ? _onNext() : onPageChange(currentPage + 1);
    };

    const onPrevious = () => {
        if (currentPage === 1) {
            return;
        }
        _onPrev ? _onPrev() : onPageChange(currentPage - 1);
    };

    let lastPage = paginationRange[paginationRange.length - 1];
    return (
        <ul className="pagination pagination-lg">
            {/* Left navigation arrow */}
            <li className={classnames('page-item', {
                disabled: currentPage === 1
            })}>
                <Link to="#" className="page-link" href="#" aria-label="Previous" onClick={onPrevious}>
                    <span aria-hidden="true"><i className="fa fa-angle-left" aria-hidden="true"></i></span>
                    <span className="sr-only">Previous</span>
                </Link>
            </li>
            {paginationRange.map((pageNumber: number | string) => {
                // If the pageItem is a DOT, render the DOTS unicode character
                if (pageNumber === DOTS) {
                    return <li className="page-item gap disabled">
                        <Link to="#" className="page-link" style={{background: 'transparent'}}>...</Link>
                    </li>;
                }

                // Render our Page Pills
                return (
                    <li className={classnames('page-item', {
                        disabled: pageNumber === currentPage
                    })}>
                        <Link to="#" className="page-link" onClick={() => onPageChange && onPageChange(pageNumber)}>{pageNumber}</Link>
                    </li>
                );
            })}
            {/*  Right Navigation arrow */}
            <li className={classnames('page-item', {
                disabled: currentPage === lastPage
            })}>
                <Link className="page-link" to="#" aria-label="Next" onClick={onNext}>
                    <span aria-hidden="true"><i className="fa fa-angle-right" aria-hidden="true"></i></span>
                    <span className="sr-only">Next</span>
                </Link>
            </li>
        </ul>
    );
};

export default Pagination;