/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {TestInstanceModel} from '../../auth/models/TestRunModel';
import { decodeURISafe } from '../../utils';
import { getScoreColor } from './TestResult';

type Props = {
  testInstances: TestInstanceModel[] | undefined,
  onPageUrlClick: (id: number) => void,
  showIssueCnt?: boolean,
}

const PagesList: React.FC<Props> = ({testInstances, onPageUrlClick, showIssueCnt}) => {
  return (
    <div className='flex-column flex-lg-row-auto w-100  mb-10 mb-lg-0'>
      <div className='card card-flush'>
      <div className='card-body pt-5'>
      <div className='separator separator-dashed'></div>
          <div
          className='scroll-y me-n5 pe-5 h-200px h-lg-auto'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_header, #kt_toolbar, #kt_footer, #kt_chat_contacts_header'
          data-kt-scroll-wrappers='#kt_content'
          data-kt-scroll-offset='0px'
          >
          {
              testInstances?.map((testInstance: TestInstanceModel) => {       
              return (
                  <>
                  <div className='d-flex flex-stack py-4'>
                  <div className='d-flex align-items-center'>
                      <div className='ms-5'>
                      <a href='#' className='fs-5 fw-bolder text-primary-900 text-hover-info mb-2' onClick={() => onPageUrlClick(testInstance.id)}>
                          {decodeURISafe(testInstance.url)}
                      </a>
                      </div>
                  </div>
                  
                  <div className='d-flex  align-items-end ms-2 pb-2'>
                    {testInstance.stats != null ? (<span className={`badge badge-sm badge-light-${getScoreColor(testInstance.stats.score)} mx-2`}>Score: {testInstance.stats.score}</span>): null}
                    {testInstance.stats != null && showIssueCnt ? (<span className='badge badge-sm badge-light-danger mx-2'>{testInstance.stats.cntTotalIssues} Issues</span>): null}
                  
                    {testInstance.status == "COMPLETE" ? <span className={"badge badge-success"}>{testInstance.status}</span>: null}
                    {testInstance.status == "PENDING" || testInstance.status == "RUNNING" ? <span className={"badge badge-primary"}>{testInstance.status}</span>: null}
                    {testInstance.status == "FAILURE" ? <span className={"badge badge-danger"}>{testInstance.status}</span>: null}
                  </div>
                  </div>
                  <div className='separator separator-dashed'></div>
                  </>
              );
              })   
          }
          </div>
      </div>
      </div>
  </div>
  )
}

export {PagesList}
