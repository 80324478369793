import React, { useEffect, useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { RootState } from '../../../setup';
import { FORM_STATUSES, getUrlPrefix } from '../../modules/utils';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ErrorMsg } from '../layout/MasterLayout';
import { AxiosResponse } from 'axios';
import { SettingsModel } from '../../modules/auth/models/SettingsModel';
import { updateSettings, getSettings } from '../../modules/auth/redux/SettingsCRUD';
import Cron from 'react-js-cron';
import { AUDIT_IMPACT_WEIGHTS } from '../utils';

const Settings: React.FC = () => {
    const location = useLocation();
    const { pathname } = location;
    const [loading, setLoading] = useState(false)
    const [settingsData, setSettingsData] = useState<Array<SettingsModel>>();
    
    const [dataError, setDataError] = useState<DataError>();
    const initialValues = {};

    require('react-js-cron/dist/styles.css');

    const shapeWithDynamicFields = settingsData?.reduce((obj: any, setting) => {
        if (setting.identifier != 'hideCriteriaFromReport') {
            obj[setting.identifier] = Yup.string().required('This value is required!');
        }
        return obj;
    }, {});

    const formik = useFormik<any>({
        initialValues,
        validationSchema: Yup.object().shape(shapeWithDynamicFields),
        onSubmit: (values, { setStatus }) => {
            setLoading(true);
            if (!settingsData) {
                setLoading(false);
                setStatus({ state: FORM_STATUSES.FAILURE, response: { error: 'Server Error!' } });
                return;
            }
            const newSettings = settingsData.map(setting => ({
                ...setting,
                value: values[setting.identifier],
            }));
            updateSettings(newSettings).then(({ data: { id } }) => {
                setLoading(false);
                setStatus({ state: FORM_STATUSES.SUCCESS, response: { id } });
            }).catch(() => {
                setLoading(false);
                setStatus({ state: FORM_STATUSES.FAILURE, response: { error: 'Server Error!' } });
            });
        },
    });

    useEffect(() => {
        getSettings().then((result: AxiosResponse) => {
            result.data.map((setting: SettingsModel) => {
                formik.setFieldValue(setting.identifier, setting.value);
            });
            setSettingsData(result.data);
        }).catch(e => setDataError({ error: "Server Error! Cannot fetch data!" }));
    }, []);

    const isMobile = useSelector<RootState>(({ auth }) => auth.isMobile, shallowEqual)
    const urlPrefix = getUrlPrefix(isMobile, pathname.startsWith('/v2') ? '/v2' : undefined);

    if (dataError) {
        return (
            <div className='card mb-5 mb-xl-10'>
                <div
                    className='card-header border-0'
                >
                    <div>
                        <br />
                        <div className='mb-lg-15 alert alert-danger d-flex flex-column-fluid flex-center'>
                            <div className='alert-text font-weight-bold'>{dataError.error}</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <>
            <div className="bredcrumb-cnntr">
                <ul>
                    <li><Link to={`${urlPrefix}/dashboard`}>Home</Link></li>
                    <li>Application Settings</li>
                </ul>
            </div>
            <div className="site-head">
                <h1 className="main-title">Application Settings</h1>
            </div>
            <div className="row">
                {formik.status && formik.status.state == FORM_STATUSES.SUCCESS ? (
                    <div className='alert-success' style={{ padding: 5 }}>
                        <div className='alert-text font-weight-bold'>Settings updated successfully</div>
                    </div>
                ) : null}
                {formik.status && formik.status.state == FORM_STATUSES.FAILURE ? (
                    <div className='alert-danger' style={{ padding: 5 }}>
                        <div className='alert-text font-weight-bold'>{formik.status.response?.error}</div>
                    </div>
                ) : null}
                <div className="col-md-12">
                    <form onSubmit={formik.handleSubmit} noValidate className='test-form'>
                        {settingsData?.map(setting => {
                            let content = null;
                            if (setting.identifier == "cronTabForStaleNcAlertCadence") {
                                content = (
                                    <Cron
                                        value={formik.values[setting.identifier]}
                                        setValue={(value: string) => formik.setFieldValue(setting.identifier, value)}
                                        clearButtonAction={'empty'}
                                        allowedDropdowns={['period', 'months', 'month-days', 'week-days']}
                                        allowedPeriods={['month', 'week']} />
                                );
                            } else if (setting.identifier == "hideCriteriaFromReport") {
                                content = (
                                    <>
                                        <table>
                                            {['warnings', ...Object.keys(AUDIT_IMPACT_WEIGHTS)].map(impact => (
                                                <tr>
                                                    <td>
                                                        <span style={{ marginRight: 10 }}>
                                                            {impact}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <input type="checkbox"
                                                            defaultValue={setting.value}
                                                            onChange={(e) => {
                                                                let csv = setting.value.split(',').filter(x => x.length > 0);
                                                                if (e.target.checked && !csv.includes(impact)) {
                                                                    csv.push(impact);
                                                                }
                                                                if (!e.target.checked) {
                                                                    csv = csv.filter(x => x != impact);
                                                                }
                                                                
                                                                setting.value = csv.join(',');
                                                                formik.setFieldValue(setting.identifier, setting.value);
                                                            }}
                                                            defaultChecked={setting.value.includes(impact)} />
                                                    </td>
                                                </tr>
                                            ))}
                                        </table>
                                    </>
                                );
                            } else {
                                content = (
                                    <input
                                        type='text'
                                        {...formik.getFieldProps(setting.identifier)}
                                    />
                                );
                            }
                            return (
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <label htmlFor='s_website'>{setting.label}</label>
                                        {content}
                                        {formik.errors[setting.identifier] && (
                                            <ErrorMsg>
                                                <div className='fv-help-block'>{formik.errors[setting.identifier]}</div>
                                            </ErrorMsg>
                                        )}
                                        {formik.touched.url && formik.errors.url && (
                                            <ErrorMsg>
                                                <div className='fv-help-block'>{formik.errors.url}</div>
                                            </ErrorMsg>
                                        )}
                                    </div>
                                </div>
                            );
                        })}

                        <div className='row'>
                            <div className='col-md-12' style={{ marginTop: 5 }}>
                                <button type='submit' className='btn btn-red' disabled={loading}>
                                    {!loading && 'Save'}
                                    {loading && (
                                        <span className='indicator-progress' style={{ display: 'block' }}>
                                            Please wait...{' '}
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        </span>
                                    )}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export { Settings }
