/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_monic/layout/core'
import {
  MixedWidget2,
  MixedWidget10,
  MixedWidget11,
  ListsWidget2,
  ListsWidget3,
  ListsWidget4,
  ListsWidget5,
  ListsWidget6,
  TablesWidget5,
  TablesWidget10,
  MixedWidget8,
} from '../../../_monic/partials/widgets'
import { ActivitiesWidget } from '../../../_monic/partials/widgets/lists/ActivitiesWidget'
import { LastNRuns } from '../../../_monic/partials/widgets/lists/LastNRuns'
import { NotificationWidgetTester } from '../../../_monic/partials/widgets/lists/NotificationWidget-Tester'
import { TodoWidget } from '../../../_monic/partials/widgets/lists/TodoWidget-Tester'
import { TrendsWidget } from '../../../_monic/partials/widgets/lists/TrendsWidget'
import { NCStatsWidget } from '../../../_monic/partials/widgets/tables/NCStatsWidget'
import { MyWebsiteWidget } from '../../../_monic/partials/widgets/tables/MyWebsiteWidget'
import { WebsiteDashboardNc } from '../../modules/auth/models/TestRunModel'
import { getOutstandingNonCompliances, getScoreTrendMonthly, getTopIssuesByOrg } from '../../modules/auth/redux/TestRunCRUD'
import { HistoricalStatsChartData } from '../../../_monic/partials/widgets/mixed/HistoricalStats'
import { LineChart } from '../../../_monic/partials/widgets/mixed/LineChart'
import { Top5Issues } from '../../../_monic/partials/widgets/lists/Top5Issues'

export const AdminDashboardPage: FC = () => {
  const [ncData, setNcData] = useState<Array<WebsiteDashboardNc>>();
  const [scoreTrendMonthly, setScoreTrendMonthly] = useState<HistoricalStatsChartData>();

  useEffect(() => {
    getOutstandingNonCompliances().then((result) => setNcData(result.data));
    getScoreTrendMonthly().then((result) => {
      if (!result.data) {
        return;
      }
      const res = result.data.map(x => ({label: `${x.year}-${x.month}`, value: Math.round(x.avgScore)}));
      setScoreTrendMonthly({
        xAxis: res.map(x => x.label),
        yAxis: res.map(x => x.value),
        max: ''
      });
    });
  }, []);

  return (
    <>
      {/* begin::Row */}
      <div className='col-xxl-stretch'>
        <MyWebsiteWidget className='card-xxl-stretch pb-3' showAddWebsite={true} />
      </div>
      <div className='row gy-3 pe-0'>
        <div className='col-xxl-4 pe-0'>
          <LastNRuns className='card-xl-stretch' />
        </div>
        <div className='col-xxl-4 pe-0'>
          {scoreTrendMonthly ? <LineChart className='card-xl-stretch' chartHeight='250px' chartData={scoreTrendMonthly}  title='Monthly Accessibility Trend' /> : null}
        </div>
        <div className='col-xxl-4 pe-0'>
          <Top5Issues className='card-xl-stretch' />
        </div>
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      {/* <div className='col-xxl-stretch'> */}
      <div className='col-xxl-stretch px-3'>
        <NCStatsWidget showUserCol={true} className='card-xxl-stretch px-3' ncList={ncData || []} showBaseUrlCol={true} title='Non Compliance Issues' subTitle='Top outstanding issues' />
      </div>
      {/* end::Row */}
    </>
  )
}
