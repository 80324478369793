/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSSVariableValue} from '../../../assets/ts/_utils'
import { Website } from '../../../../app/modules/auth/models/TestRunModel';

export type TreeMapChartData = {
  seriesData: Array<{x: string, y: number, data: {org?: string, url?: string, score?: number, websitesCnt?: number}}>
  max: string,
  colors?: Array<string>
}

type Props = {
  title: string
  className: string
  chartHeight: string
  chartData: TreeMapChartData
}

const TreeMapChart: React.FC<Props> = ({title, className, chartHeight, chartData}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (!chartRef.current) {
      return
    }

    const chart = new ApexCharts(chartRef.current, chartOptions(chartHeight, chartData))
    if (chart) {
      chart.render()
    }

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef])

  return (
    <div className={`card ${className}`}>
      {/* begin::Body */}
      <div className='card-body p-0 d-flex flex-column overflow-hidden'>
        {/* begin::Hidden */}
        <div className='d-flex flex-stack flex-wrap px-9 pt-9 pb-3'>
          <div className='me-2'>
            <span className='fw-bolder text-gray-800 d-block fs-3'>{title}</span>

            {/* <span className='text-gray-400 fw-bold'>Oct 8 - Oct 26 2021</span> */}
          </div>

          <div className={`fw-bolder fs-3 text-primary`}>{chartData.max}</div>
        </div>
        {/* end::Hidden */}

        {/* begin::Chart */}
        <div ref={chartRef} className='mixed-widget-10-chart'></div>
        {/* end::Chart */}
      </div>
    </div>
  )
}

const chartOptions = (chartHeight: string, chartData: TreeMapChartData): ApexOptions => {
  return {
    series: [
      {
        data: chartData.seriesData,
      }
    ],
    chart: {
      height: 650,
      type: 'treemap'
    },
    tooltip: {
      custom: function(op) {
        return '<div style="margin: 10px; position: relative;">' +
        '<b>Org</b>: ' + chartData.seriesData[op.dataPointIndex].data.org + '<br />' +
        '<b>Website</b>: ' + chartData.seriesData[op.dataPointIndex].x + '<br />' +
        (chartData.seriesData[op.dataPointIndex].data.score ? '<b>Score</b>: ' + chartData.seriesData[op.dataPointIndex].data.score + '<br />' : '<b>Avg Score</b>: ' + chartData.seriesData[op.dataPointIndex].y + '<br />') +
        (chartData.seriesData[op.dataPointIndex].data.url ? '<b>URL</b>: ' + chartData.seriesData[op.dataPointIndex].data.url + '<br />' : '') +
        (chartData.seriesData[op.dataPointIndex].data.websitesCnt ? '<b>Websites Owned</b>: ' + chartData.seriesData[op.dataPointIndex].data.websitesCnt + '<br />' : '<b>Pages</b>: ' + chartData.seriesData[op.dataPointIndex].y + '<br />') +
        '</div>';
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        fontSize: '12px',
      },
      formatter: function(text: string, op: any): any {
        return [text, chartData.seriesData[op.dataPointIndex].data.score || '']
      },
      //offsetY: -4
    },
    colors: chartData.colors,
    plotOptions: {
      treemap: {
        distributed: true,
        enableShades: true,
        shadeIntensity: 0,
        reverseNegativeShade: false,
        // colorScale: {
        //   ranges: [
        //     {
        //       from: 0,
        //       to: 50,
        //       color: getCSSVariableValue('--bs-danger')
        //     },
        //     {
        //       from: 51,
        //       to: 90,
        //       color: getCSSVariableValue('--bs-warning')
        //     },
        //     {
        //       from: 91,
        //       to: 100,
        //       color: getCSSVariableValue('--bs-success')
        //     }
        //   ]
        // }
      }
    }
  };
}

export {TreeMapChart}
