import React, {useEffect, useState} from 'react'
import {Redirect, useLocation, useParams} from 'react-router-dom'
import * as Yup from 'yup'
import clsx from 'clsx';
import {useFormik} from 'formik'
import {editWebsite, getWebsite, viewOrganization, viewOrganizationById} from '../../auth/redux/OrganizationCRUD'
import {FORM_STATUSES, getUrlPrefix} from '../../utils'
import { OrganizationModel, WebsiteModel } from '../../auth/models/OrganizationModel'
import { AxiosResponse } from 'axios'
import { viewUser } from '../../auth/redux/AuthCRUD';
import { useSelector, shallowEqual } from 'react-redux';
import { RootState } from '../../../../setup';
import { UserDbModel, UserModel } from '../../auth/models/UserModel';

const schema = Yup.object().shape({
  url: Yup.lazy(value => {
    if (/((http([s]){0,1}:\/\/){0,1}(localhost|127.0.0.1){1}(([:]){0,1}[\0-9]{4}){0,1}\/{0,1}){1}/.test(value)) {
      return Yup.string().required('URL is required');
    }
    return Yup.string().required().url('Invalid URL format');
  }),
  userId: Yup.string().required('Please select an assigned user').min(3, 'Please select an assigned user'),
  name: Yup.string().required('Name is required'),
  organizationId: Yup.number()
    .test("orgTest", "Please select an organization", function (value) {
      return value != null && value > 0;
    }),
})

const EditWebsite: React.FC = () => {
  const [loading, setLoading] = useState(false)
  const [orgData, setOrgData] = useState<Array<OrganizationModel>>();
  const [users, setUsers] = useState<Array<UserDbModel>>();
  const [dataError, setDataError] = useState<DataError>();
  const {id, orgId: orgIdFromUrl}: any = useParams();  
  const [data, setData] = useState<WebsiteModel>();
  const location = useLocation();
  const isMobile = useSelector<RootState>(({auth}) => auth.isMobile, shallowEqual)
  const urlPrefix = getUrlPrefix(isMobile);
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const isAdmin = user.gamyata?.role == "ADMIN";
  const isSuperAdmin = user.gamyata?.role == "SUPERADMIN";
  const orgId = orgIdFromUrl;
  const initialValues = {name: '', organizationId: orgId};

  const formik = useFormik<any>({
    initialValues,
    validationSchema: schema,
    onSubmit: (values, {setStatus}) => {
      setLoading(true);
      editWebsite(id, values.name, values.url, parseInt(values.organizationId), values.userId).then(({data: {id}}) => {
        setLoading(false);
        setStatus({state: FORM_STATUSES.SUCCESS, response: {id}});
      }).catch(() => {
        setLoading(false);
        setStatus({state: FORM_STATUSES.FAILURE, response: {error: 'Server Error!'}});
      });
    },
  });
  
  useEffect(() => {
    if (!isAdmin) {
      viewOrganization().then((result) => setOrgData(result.data)).catch(e => setDataError({error: "Server Error! Cannot fetch org data!"}));
    }
    getWebsite(parseInt(id)).then((result: AxiosResponse) => {
      formik.setFieldValue('name', result.data.name);
      formik.setFieldValue('url', result.data.url);
      formik.setFieldValue('organizationId', result.data.organizationId);
      formik.setFieldValue('userId', result.data.userId);      
      setData(result.data);
      viewUser('TESTER', undefined, isSuperAdmin || orgId == -1 ? undefined : orgId).then((result) => setUsers(result.data)).catch(e => setDataError({error: "Server Error! Cannot fetch org data!"}));
    }).catch(e => setDataError({error: "Server Error! Cannot fetch data!"}));
  }, []);

  if (dataError || data == null) {
    return (
      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          {dataError ? (
            <div>
              <br />
                <div className='mb-lg-15 alert alert-danger d-flex flex-column-fluid flex-center'>
                  <div className='alert-text font-weight-bold'>{dataError.error}</div>
                </div>
                </div>
          ) : (
            <div>
              <br />
              <span>Loading... </span> <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
           </div>
        )}
        </div>
      </div>
      );
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_edit_website'
        aria-expanded='true'
        aria-controls='kt_edit_website'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Edit Website</h3>
        </div>
      </div>

      {formik.status && formik.status.state == FORM_STATUSES.SUCCESS ? (
        <Redirect to={{...location, pathname: urlPrefix + `/core/website/manage/${orgIdFromUrl && orgIdFromUrl != -1 ? orgIdFromUrl : ''}`}}  />
      ) : null}

      {formik.status && formik.status.state == FORM_STATUSES.FAILURE ? (
        <div className='mb-lg-15 alert-danger'>
            <div className='alert-text font-weight-bold'>{formik.status.response?.error}</div>
        </div>
      ) : null}

      <div id='kt_edit_website' className='collapse show'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>

          <div className='row mb-6'>
                <label htmlFor='tb_url' className='col-lg-4 col-form-label required fw-bold fs-6'>Enter URL</label>
                <div className='col-lg-8 fv-row'>
                  <input
                    id='tb_url'
                    type='text'
                    placeholder='https://example.com'
                    autoComplete='off'
                    {...formik.getFieldProps('url')}
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      {
                        'is-invalid': formik.touched.url && formik.errors.url,
                      },
                      {
                        'is-valid': formik.touched.url && !formik.errors.url,
                      }
                    )}
                  />
                  {formik.errors.url && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.url}</div>
                    </div>
                  )}
                </div>
            </div>
            
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Website Name</label>

              <div className='col-lg-8'>
                <div className='row'>
                  <div className='col-lg-6 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      placeholder='Website name'
                      {...formik.getFieldProps('name')}
                    />
                    {formik.errors.name && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.name}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {isAdmin ? null : (
            <div className='row mb-6'>
              <label htmlFor='s_org' className='col-lg-4 col-form-label required fw-bold fs-6'>Organization</label>

              <div className='col-lg-8 fv-row'>
                <select 
                  id='s_org'
                  className='form-select form-select-solid form-select-lg'
                  {...formik.getFieldProps('organizationId')}
                >
                  <option value="-1">Select Org</option>
                  {orgData?.map(org => (
                    <option value={org.id}>{org.name}</option>
                  ))}
                </select>
                {formik.errors.organizationId && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.organizationId}</div>
                  </div>
                )}
              </div>
          </div>    
          )}

          <div className='row mb-6'>
              <label htmlFor='s_user' className='col-lg-4 col-form-label required fw-bold fs-6'>Assigned To</label>

              <div className='col-lg-8 fv-row'>
                <select 
                  id='s_user'
                  className='form-select form-select-solid form-select-lg'
                  {...formik.getFieldProps('userId')}
                >
                  <option value=''>Select User</option>
                  {users?.filter(user => user.organizationId == formik.values.organizationId).map(user => (
                    <option value={user.id}>{user.email}</option>
                  ))}
                </select>
                {formik.errors.userId && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.userId}</div>
                  </div>
                )}
              </div>
          </div>    


          </div>              

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-primary' disabled={loading}>
              {!loading && 'Save Changes'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export {EditWebsite}
