import React, {Suspense, lazy} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {FallbackView} from '../../_monic/partials'
import TestManagementPage from '../modules/testmgmt/TestManagement'
import OrganizationManagementPage from '../modules/organization/OrganizationManagement'
import UserManagementPage from '../modules/users/UserManagement'
import ReportsManagement from '../modules/reports/ReportsManagement'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import WebsitesManagement from '../modules/websites/WebsitesManagement'
import { useSelector, shallowEqual } from 'react-redux'
import { RootState } from '../../setup'
import SettingsManagementPage from '../modules/settings/SettingsManagementPage'
import { MasterLayout } from '../../_monic/layout/MasterLayout'

export function PrivateRoutes() {
  const loggedUser: any = useSelector<RootState>(({auth}) => auth.user, shallowEqual)
  const isOnboarded = loggedUser.gamyata;

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
      {!isOnboarded ? (
        <Redirect to='/onboarding' />
      ) : (
        <MasterLayout>
        <Switch>
        <Route path='/dashboard' component={DashboardWrapper} />
        <Route path='/dashboard/:role?' component={DashboardWrapper} />

        <Route path='/activities/testmgmt/runtest' component={TestManagementPage} />
        <Route path='/activities/testmgmt/testresult/:id' component={TestManagementPage} />
        <Route path='/activities/testmgmt/testresult' component={TestManagementPage} />
        <Route path='/activities/testmgmt/manageresults' component={TestManagementPage} />
        <Route path='/activities/testmgmt/testschedule/:id' component={TestManagementPage} />
        <Route path='/activities/testmgmt/manageschedules' component={TestManagementPage} />
        <Route path='/activities/testmgmt/managenoncompliance' component={TestManagementPage} />

        <Route path='/activities/reports/mywebsites' component={ReportsManagement} />
        <Route path='/activities/reports/website/:url' component={ReportsManagement} />

        <Route path='/core/org' component={OrganizationManagementPage} />
        <Route path='/core/website' component={WebsitesManagement} />

        <Route path='/core/users' component={UserManagementPage} />

        <Route path='/core/settings' component={SettingsManagementPage} />

        <Redirect from='/auth' to='/dashboard' />
        </Switch>
        </MasterLayout>
      )}
        <Redirect to='error/404' />
      </Switch>
    </Suspense>
  )
}
