import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useSelector, shallowEqual } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { RootState } from '../../../setup';
import { FORM_STATUSES, getUrlPrefix, useQuery } from '../../modules/utils';
import { DetailsPopup } from '../websites/DetailsPopup';
import { useFormik } from 'formik';
import { bulkCreateOrganization, createDepartment, editDepartment, getDepartment } from '../../modules/auth/redux/OrganizationCRUD';
import { ErrorMsg, HelpLink } from '../layout/MasterLayout';
import { AxiosResponse } from 'axios';
import _ from 'lodash';

const AddEditOrganization: React.FC<{ onSave: any }> = ({ onSave }) => {
    const history = useHistory();
    const { pathname } = useLocation();
    const queryParams: URLSearchParams = useQuery();
    const enableBulk = decodeURIComponent(queryParams.get('enableBulk') || '');
    const { action, orgId }: any = useParams();
    const [showPopup, setShowPopup] = useState(false);
    const [loading, setLoading] = useState(false);
    const [file, setFile] = useState(null);
    const [csvUpload, setCsvUpload] = useState(false);
    const [managedByGigw, setManagedByGigw] = useState(false);
    const [dataError, setDataError] = useState<DataError>();
    const initialValues = { name: '', csvFile: undefined };
    let id: number | undefined = undefined;
    if (action == 'edit' && orgId && !Number.isNaN(parseInt(orgId))) {
        id = parseInt(orgId);
    }
    const isActive = action == 'add' || action == 'edit';

    const schema = Yup.object().shape({
        name: Yup.string().required('Name is required'),
        csvFile: Yup.mixed()
            .test("FILE_EXISTS", "File is missing",
                value => csvUpload ? value && value.size != 0 : true)
            .test("FILE_SIZE", "File size is too big.",
                value => csvUpload ? value && value.size <= 2000000 : true)
            .test("FILE_FORMAT", "Uploaded file has unsupported format.",
                value => csvUpload ? value && ['text/csv'].includes(value.type) : true)
    })

    const formik = useFormik<any>({
        initialValues,
        validationSchema: schema,
        onSubmit: (values, { setStatus }) => {
            setLoading(true);
            let promise;
            if (csvUpload) {
                promise = bulkCreateOrganization(file);
            } else {
                promise = id ? editDepartment(id, values.name) : createDepartment(values.name);
            }

            promise.then(({ data }) => {
                setLoading(false);
                setShowPopup(true);
                setStatus({ state: FORM_STATUSES.SUCCESS, response: { id: data.id, errors: data.errors, success: data.success } });
            }).catch(() => {
                setLoading(false);
                setStatus({ state: FORM_STATUSES.FAILURE, response: { error: 'Server Error! Cannot save organization' } });
            });
        },
    });

    useEffect(() => {
        formik.resetForm();
        if (id) {
            getDepartment(id).then((result: AxiosResponse) => {
                setManagedByGigw(result.data.managedByGigw);
                formik.setFieldValue('name', result.data.name);
            }).catch(e => setDataError({ error: "Server Error! Cannot fetch data!" }));
        }
    }, [pathname]);

    const isMobile = useSelector<RootState>(({ auth }) => auth.isMobile, shallowEqual)
    const urlPrefix = getUrlPrefix(isMobile, pathname.startsWith('/v2') ? '/v2' : undefined);
    const closePopup = () => history.push(`${urlPrefix}/core/org/manage`);

    if (dataError) {
        return (
            <DetailsPopup key={'is: ' + isActive} title={`${_.startCase(action)} Organization`} isActive={isActive} onCloseBtnClick={closePopup}>
                <div className='card mb-5 mb-xl-10'>
                    <div
                        className='card-header border-0'
                    >
                        <div>
                            <br />
                            <div className='mb-lg-15 alert alert-danger d-flex flex-column-fluid flex-center'>
                                <div className='alert-text font-weight-bold'>{dataError.error}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </DetailsPopup>
        );
    }

    const onFileChange = (e: any) => {
        setFile(e.target.files[0])
        formik.setFieldValue('csvFile', e.currentTarget.files[0]);
    };

    let popupContent;
    if (csvUpload) {
        if (formik.status?.response?.success === false) {
            popupContent = 'CSV is imported partially. Some rows had errors.';
        } else {
            popupContent = 'CSV is imported successfully';
        }
    } else {
        popupContent = `Organization is ${id ? 'updated' : 'created'} successfully`;
    }

    let content;
    if (managedByGigw) {
        content = (
            <>
        <div className='row'>
        <div className='col-md-12' style={{paddingTop: 20}}>
            <span>
            The following fields cannot be modified here. Please email your request to s3waas.support@nic.in to update it
            </span>
        </div>
        </div>
        <div className='row'>
            <div className='col-md-12' style={{paddingTop: 20}}>
                <label htmlFor='test-email'>Organization Name</label>
                <div>
                    {formik.values.name}
                </div>
            </div>
        </div>
        </>
        );
    } else {
        content = (
            <div className="col-md-12">
                        <form onSubmit={formik.handleSubmit} noValidate className='test-form'>
                            {enableBulk == 'true' ?
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <div role="radiogroup" aria-labelledby="testingmodel" className="testing_mode">
                                            <span className="testingmode1" id="testingmodel">Creation Mode</span>

                                            <input type="radio" id="singlewebpage" defaultChecked={true}
                                                value='single'
                                                checked={!csvUpload}
                                                onClick={() => {
                                                    setCsvUpload(false);
                                                    formik.setFieldValue('name', '');
                                                }}
                                                style={{ marginTop: -1, verticalAlign: 'middle' }} />
                                            <label htmlFor="singlewebpage" style={{ marginLeft: 5 }}>Create Single Organization</label>

                                            <input type="radio" id="entirewebpage"
                                                value='multiple'
                                                checked={csvUpload}
                                                onClick={() => {
                                                    setCsvUpload(true);
                                                    formik.setFieldValue('name', 'csv');
                                                }}
                                                style={{ marginTop: -1, verticalAlign: 'middle' }} />
                                            <label htmlFor="entirewebpage" style={{ marginLeft: 5, marginRight: 10 }}>Create Multiple Organizations</label>
                                            <span className="dropdown help-link">
                                                <a href="#" className="dropdown-toggle"
                                                    data-toggle="dropdown" role="button"
                                                    aria-haspopup="true" aria-expanded="false"
                                                    title="help"><i className="fa fa-question-circle-o"
                                                        aria-hidden="true"></i></a>
                                                <div className="dropdown-menu">
                                                    <small>
                                                        Upload CSV file with organization, website, WIM and HoD details for bulk creation. <br />
                                                        <HelpLink href='/data/csvtemplate.csv' target='_blank'>Download template</HelpLink>
                                                    </small>
                                                </div>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                : null}
                            {csvUpload ?
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <label htmlFor='test-name'>Upload CSV file</label>
                                        <input
                                            type='file'
                                            name='agreement'
                                            className='form-control form-control-lg'
                                            placeholder='Upload File'
                                            // {...formik.getFieldProps('agreement')}
                                            onChange={onFileChange}
                                            disabled={!csvUpload} />
                                        {formik.touched.csvFile && formik.errors.csvFile && (
                                            <ErrorMsg>
                                                <div className='fv-help-block'>{formik.errors.csvFile}</div>
                                            </ErrorMsg>
                                        )}
                                    </div>
                                </div> :
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <label htmlFor='test-name'>Organization Name</label>
                                        <input
                                            id='test-name'
                                            type='text'
                                            placeholder='Enter name'
                                            {...formik.getFieldProps('name')}
                                        />
                                        {formik.touched.name && formik.errors.name && (
                                            <ErrorMsg>
                                                <div className='fv-help-block'>{formik.errors.name}</div>
                                            </ErrorMsg>
                                        )}
                                    </div>
                                </div>
                            }

                            <div className='row'>
                                <div className='col-md-12 testingmode1'>
                                    <button onClick={closePopup} className='btn cancel-form' disabled={loading}>
                                        Cancel
                                    </button>
                                    <button type='submit' className='btn btn-red' disabled={loading}>
                                        {!loading && 'Save'}
                                        {loading && (
                                            <span className='indicator-progress' style={{ display: 'block' }}>
                                                Please wait...{' '}
                                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                            </span>
                                        )}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
        );
    }
        

    return (
        <>
            <DetailsPopup key={'is: ' + isActive} title={`${_.startCase(action)} Organization`} isActive={isActive} onCloseBtnClick={closePopup}
                onSave={onSave} closePopup={closePopup} showPopup={showPopup} setShowPopup={setShowPopup} popupMessage={popupContent}>
                <div className="row">
                    {formik.status && formik.status.state == FORM_STATUSES.FAILURE ? (
                        <div className='alert-danger' style={{ padding: 5 }}>
                            <div className='alert-text font-weight-bold'>{formik.status.response?.error}</div>
                        </div>
                    ) : null}
                    {content}
                </div>
            </DetailsPopup>
        </>
    )
}

export { AddEditOrganization }
