/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import {useParams} from 'react-router-dom'
import {KTSVG} from '../../../../_monic/helpers'
import {Link} from 'react-router-dom'
import { HashLink } from 'react-router-hash-link';
import {getCategories, getFailuresList, getTags, getTestReportingDetails, getTestRun} from '../../auth/redux/TestRunCRUD'
import {FailuresCount, TestRunModel} from '../../auth/models/TestRunModel'
import {AxiosResponse} from 'axios'
import {TestResultTab} from './TestResultsTab'
import {MediaInventoryTab} from './MediaInventoryTab'
import { OverviewTab } from './OverviewTab'
import { OverviewTab as HistoricalOverview } from './../../reports/components/OverviewTab'
import _ from 'lodash'
import ApexCharts, {ApexOptions} from 'apexcharts'
import { getCSSVariableValue } from '../../../../_monic/assets/ts/_utils'
import { useReactToPrint } from 'react-to-print';
import './../../../utils/print.css';
import { FailuresList } from './FailuresList'
import { WarningsList } from './WarningsList'
import { ProgressBar } from 'react-bootstrap-v5'
import { getDownloadXlsReportUrl } from '../../auth/redux/AuthCRUD'
import { useSelector, shallowEqual } from 'react-redux'
import { RootState } from '../../../../setup'
import { getUrlPrefix } from '../../utils'

export const getProgress = (created: number, pageCnt: number, totalCnt: number): number => {
  const diff = new Date().getTime() - new Date(created).getTime();
  // 1% takes 1 seconds => 100% takes 100 seconds
  // but if 2 pages already complete out of 4 then 50%. 
  return Math.min(Math.max(Math.round((totalCnt - pageCnt) / totalCnt), Math.round(diff / (1000 * pageCnt))), 95);
};

const tabs = [
  "historicaldash",
  "overview",
  "failures",
  "warnings",
  "testresults",
  "testresultsallpages",
  "mediainventory",
];

const getScoreColor = (score: number) => {
  if (score > 89) {
    return 'success';
  } else if (score > 39){
    return 'warning';
  }
  return 'danger';
}

const scoreOptions = (score: number): ApexOptions => {
  let chartColor = getScoreColor(score);
  const baseColor = getCSSVariableValue('--bs-' + chartColor)
  const lightColor = getCSSVariableValue('--bs-light-' + chartColor)
  const labelColor = getCSSVariableValue('--bs-gray-700')

  return {
    series: [score],
    chart: {
      fontFamily: 'inherit',
      height: '150px',
      type: 'radialBar',
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: '55%',
        },
        dataLabels: {
          name: {
            show: false,
            fontWeight: '700',
          },
          value: {
            color: labelColor,
            fontSize: '20px',
            fontWeight: '700',
            offsetY: 7,
            show: true,
            formatter: function (val) {
              return val + '';
            },
          },
        },
        track: {
          background: lightColor,
          strokeWidth: '100%',
        },
      },
    },
    colors: [baseColor],
    stroke: {
      lineCap: 'round',
    },
    labels: ['Progress'],
  }
};

const fetchData = (id: number, setData: React.Dispatch<React.SetStateAction<TestRunModel | undefined>>, setDataError: React.Dispatch<React.SetStateAction<DataError | undefined>>) => 
  getTestRun(id).then((result: AxiosResponse) => {
    setData(result.data);
    setDataError(undefined);
  }).catch(e => setDataError({error: "Server Error! Cannot fetch data!"}));

const TestScore: React.FC<{score: number}> = ({score}) => {
  const chartRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (!chartRef.current || !score) {
      return
    }
    const chart = new ApexCharts(chartRef.current, scoreOptions(score))
    if (chart) {
      chart.render()
    }

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef]);
  return (
    <div ref={chartRef} className='mixed-widget-4-chart' style={{margin: -20, width: 160}}></div>
  );
};

const TestResult: React.FC = () => {
  const {id, activeTab}: any = useParams();
  const [data, setData] = useState<TestRunModel>();
  const [dataError, setDataError] = useState<DataError>();
  const [failuresCount, setFailuresCount] = useState<FailuresCount[]>();
  const [categories, setCategories] = useState<string[]>();
  const [testReportingDetails, setTestReportingDetails] = useState<TestReportingDetails>();
  const [tags, setTags] = useState<string[]>();
  const [progress, setProgress] = useState<number>(0);
  const [downloading, setDownloading] = useState(false)
  const componentRef = useRef<HTMLDivElement| null>(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  let timer: any = null;
  const getRemainingCnt = (data: TestRunModel) => {
    return data.testInstances ? data.testInstances.filter(x => x.status != 'FAILURE' && x.status != 'COMPLETE').length : 1;
  };
  
  useEffect(() => {
    Promise.all([getTestRun(parseInt(id)), getFailuresList(parseInt(id)), getCategories(), getTags(), getTestReportingDetails()]).then(([result, failuresList, categories, tags, details]) => {
      setFailuresCount(failuresList.data);
      setCategories(categories);
      setTags(tags);
      const detailsResult: any = {};
      Object.keys(details).forEach(x => {
        const value = details[x];
        value.tags = Array.from(new Set(value.tags || []));
        detailsResult[x] = value;
      });
      setTestReportingDetails(detailsResult);
      const data = result.data;
      setData(data);
      setDataError(undefined);
      setProgress(getProgress(data.created, getRemainingCnt(data), data.testInstances?.length || 1));
      if (timer) {
        clearTimeout(timer);
      }
    }).catch(e => setDataError({error: "Server Error! Cannot fetch data!"}));
  }, []);
  
  
  useEffect(() => {
    timer = setTimeout(() => {
      if (data == null) {
        return false;
      }
      setProgress(getProgress(data.created, getRemainingCnt(data), data.testInstances?.length || 1));
      return data.status != "COMPLETE" && data.status != "PARTIALLY COMPLETE" && data.status != "FAILURE" && fetchData(id, setData, setDataError);
    }, 10000);
    return () => clearTimeout(timer);
  });

  const isMobile = useSelector<RootState>(({auth}) => auth.isMobile, shallowEqual)
  const urlPrefix = getUrlPrefix(isMobile) + "/activities/testmgmt/testresult";

  if (dataError || data == null) {
    return (
      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          {dataError ? (
            <div>
              <br />
                <div className='mb-lg-15 alert alert-danger d-flex flex-column-fluid flex-center'>
                  <div className='alert-text font-weight-bold'>{dataError.error}</div>
                </div>
                </div>
          ) : (
            <div>
              <br />
              <span>Loading... </span> <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
           </div>
        )}
        </div>
      </div>
      );
  }

  let badgeClass = "badge-primary";
  if (data.status == "COMPLETE") {
    badgeClass = "badge-success";
  } else if (data.status == "PARTIALLY COMPLETE") {
    badgeClass = "badge-warning";
  } else if (data.status == "FAILURE") {
    badgeClass = "badge-danger";
  } 

  let tabContent;
  if (activeTab == tabs[0]) {
    tabContent = <HistoricalOverview url={data.baseUrl} testMode={data.testMode} wcagVersion={data.wcagVersion} wcagLevel={data.wcagLevel} />
  } else if (activeTab == tabs[1]) {
    tabContent = <OverviewTab stats={data.stats} status={data.status}/>
  } else if (activeTab == tabs[2]) {
    tabContent = <FailuresList failuresCount={failuresCount || []} status={data.status} testInstances={data.testInstances}
                  tags={tags || []} categories={categories || []} testReportingDetails={testReportingDetails || {}} />
  } else if (activeTab == tabs[3]) {
    tabContent = <WarningsList stats={data.stats} status={data.status} testInstances={data.testInstances} tabUrlPrefix={`${urlPrefix}/${id}`} 
                  tags={tags || []} categories={categories || []} testReportingDetails={testReportingDetails || {}} />
  } else if (activeTab == tabs[4]) {
    tabContent = <TestResultTab status={data.status} testInstances={data.testInstances} tags={tags || []} categories={categories || []} testReportingDetails={testReportingDetails || {}} showIssueCnt={true} />
  } else if (activeTab == tabs[5]) {
    tabContent = <TestResultTab status={data.status} testInstances={data.testInstances} tags={tags || []} categories={categories || []} testReportingDetails={testReportingDetails || {}} showIssueCnt={true} />
  } else if (activeTab == tabs[6]) {
    tabContent = <MediaInventoryTab status={data.status} testInstances={data.testInstances}  mediaInventoryTotals={data.stats?.mediaInventoryTotals} />
  } else {
    tabContent = <OverviewTab stats={data.stats} status={data.status}/>
  }

  const onClickDownload = () => {
    setDownloading(true);
    getDownloadXlsReportUrl(id).then((response) => {
      const href = URL.createObjectURL(response.data);
      const link = document.createElement('a');
      link.href = href;
      const fileName = response.headers["content-disposition"]?.split("filename=")[1];
      if (fileName) {
        link.setAttribute('download', response.headers["content-disposition"].split("filename=")[1]);
      }
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
      setDownloading(false);
    }).catch(() => {
      setDownloading(false);
      console.error('Server Error! Cannot download the report');
    });
  }

  const renderTabs = (
    <div className='d-flex overflow-auto mb-5'>
          <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder '> 
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary mx-6 ` +
                  ((activeTab == tabs[0]) && 'active')
                }
                to={`${urlPrefix}/${id}/${tabs[0]}`}
              >
                Historical Dashboard
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary mx-6 ` +
                  ((activeTab == tabs[1] || activeTab == null) && 'active')
                }
                to={`${urlPrefix}/${id}/${tabs[1]}`}
              >
                Test Overview
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary mx-6 ` +
                  (activeTab == tabs[2] && 'active')
                }
                to={`${urlPrefix}/${id}/${tabs[2]}`}
              >
                Failures
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary mx-6 ` +
                  (activeTab == tabs[3] && 'active')
                }
                to={`${urlPrefix}/${id}/${tabs[3]}`}
              >
                Warnings
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary mx-6 ` +
                  (activeTab == tabs[4] && 'active')
                }
                to={`${urlPrefix}/${id}/${tabs[4]}`}
              >
                Page Wise Report
              </Link>
            </li>
            {/* <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary mx-6 ` +
                  (activeTab == tabs[5] && 'active')
                }
                to={`${urlPrefix}/${id}/${tabs[5]}`}
              >
                Full Report
              </Link>
            </li> */}
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary mx-6 ` +
                  (activeTab == tabs[6] && 'active')
                }
                to={`${urlPrefix}/${id}/${tabs[6]}`}
              >
                Media Inventory
              </Link>
            </li>
          </ul>
          {/* <div className='d-flex' style={{marginLeft: 'auto', justifyContent: 'flex-end'}}>
          <button
            onClick={(handlePrint)}
            type='button'
            className='btn btn-lg btn-primary me-3'
          >
            Print
          </button>
          </div> */}
        </div>
  );

  const cntPages = data.stats && data.stats.cntPages ? data.stats.cntPages : data.testInstances?.length;
  const cntPagesComplete = data.testInstances ? data.testInstances.filter(x => x.status == 'COMPLETE').length : 0;

  return (
    <div>
    <div className='card mb-5' style={{
      marginLeft: -13,
      marginRight: -13,
    }}>
      <div className='card-body pt-9 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
          <div className='flex-grow-1'>
            <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
              <div className='d-flex flex-column'>
                <div className='d-flex align-items-center mb-2'>
                  <span className='text-gray-800 fs-2 fw-bolder me-1'>
                    {data.website?.name}
                  </span>
                </div>   
                <div className='d-flex align-items-center mb-2'>
                  <span className='text-gray-800 fs-3 me-1'>
                    {data.baseUrl}
                  </span>
                </div>       
              </div>

              <div className="d-flex flex-column">
              <div className='d-flex flex-wrap fw-bold fs-6'>
                  <span className='fw-bold fs-6 text-gray-700'>Test Progress &nbsp;</span>
                  <span className={"badge " + badgeClass}>{data.status}</span>
                </div>
              <div className='d-flex flex-wrap fw-bold fs-6'>
                  <span className='d-flex align-items-center text-gray-700 me-5 mb-2'>
                    <KTSVG
                      path='/media/icons/duotune/communication/com006.svg'
                      className='svg-icon-4 me-1'
                    />
                    Test Mode: {_.startCase(data.testMode)}
                  </span>

                  <span
                    className='d-flex align-items-center text-gray-700 me-5 mb-2'
                  >
                    <KTSVG
                      path='/media/icons/duotune/general/gen018.svg'
                      className='svg-icon-4 me-1'
                    />
                    {new Date(data.created).toLocaleString('en-IN')}
                   </span>
                </div>
                <div className='d-flex flex-wrap fw-bold fs-6'>
                  <span className='d-flex align-items-center text-gray-700 me-5 mb-2'>
                    <KTSVG
                      path='/media/icons/duotune/communication/com006.svg'
                      className='svg-icon-4 me-1'
                    />
                    WCAG Version: {data.wcagVersion}
                  </span>
                  <span className='d-flex align-items-center text-gray-700 me-5 mb-2'>
                    <KTSVG
                      path='/media/icons/duotune/communication/com006.svg'
                      className='svg-icon-4 me-1'
                    />
                    WCAG Level: {data.wcagLevel}
                  </span>
                </div>
                <div className='d-flex flex-wrap fw-bold fs-6'>
                  <span className='d-flex align-items-center text-gray-700 me-5 mb-2'>
                  <KTSVG
                    path='/media/icons/duotune/communication/com006.svg'
                    className='svg-icon-4 me-1'
                  />
                    Completed Time: {data.stats && data.stats.completedTime ? new Date(data.stats.completedTime).toLocaleString('en-IN') : null}
                  </span>
                </div>
                <div className='border border-gray-400 border-dashed rounded min-w-125px py-3 px-4 mb-3'>
                      <Link to={`${urlPrefix}/${id}/testresults`} className='text-gray-900'> 
                        <div className='d-flex align-items-center'>
                          {/* <KTSVG
                            path='/media/icons/duotune/general/gen050.svg'
                            className='svg-icon-3 svg-icon-warning me-2'
                          /> */}
                          <div className='fs-2 fw-bolder text-primary-900 text-hover-info mx-5'>{data.status != 'COMPLETE' && cntPages ? `${cntPagesComplete} / ` : ''}{cntPages}</div>
                          <div className='fw-bold fs-6 text-primary-900 text-hover-info text-gray-700'>{cntPages ? `Page${cntPages > 1 ? 's' : ''} Tested` : null}</div>
                        </div>
                        </Link>
                      </div>
              </div>

              <div className='border border-gray-400 border-dashed rounded d-flex flex-column' style={{padding: 10}}>
              <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                      
                {/* <div className='d-flex flex-column flex-grow-1 pe-8'> */}
                {/* <div className='d-flex flex-wrap'> */}
                  {data.status == "COMPLETE" || data.status == "PARTIALLY COMPLETE" ?  (
                    <>
                    <div className='d-flex flex-column'>
                  <div className='text-center fw-bold fs-6'>
                    {data.stats && data.stats.score ? 
                    <>
                      <h5>Score</h5>
                      <TestScore score={data.stats.score} /> 
                    </>
                    : null }
                    </div>
                  </div>

                  <div className='d-flex flex-column'>
                  <div className='d-flex flex-wrap fw-bold fs-6'>  
                      <div className='bg-danger rounded hoverable py-3 px-4 me-6 mb-3'>
                      <HashLink to={`${urlPrefix}/${id}/failures#failures-list`} className='text-gray-900'> 
                        <div className='d-flex align-items-center min-w-125px text-white'>
                          <div className='fs-2 fw-bolder'>{data.stats && data.stats.issueCntByTest?.length}</div>
                          <div className='fw-bold fs-6 mx-5'>Failures</div>
                        </div>
                        
                        </HashLink>
                      </div>

                     
                  </div>
                  <div className='d-flex flex-wrap fw-bold fs-6'>
                  <div className='bg-warning rounded hoverable py-3 px-4 me-6 mb-3'>
                      <HashLink to={`${urlPrefix}/${id}/warnings#warnings-list`} className='text-gray-900'> 
                        <div className='d-flex align-items-center min-w-125px text-white'>
                          <div className='fs-2 fw-bolder'>{data.stats && Math.round(data.stats.cntTotalWarns/data.stats.cntPages)}</div>
                          <div className='fw-bold fs-6 mx-5'>Warnings</div>
                        </div>
                        </HashLink>
                        </div>
                    </div>
                    </div>
                      </>
                      ) : null }
                    {/* </div> */}
                {/* </div> */}
                </div>
                {(data.status == 'COMPLETE' || data.status == 'PARTIALLY COMPLETE') && cntPagesComplete > 0 ? (
                  <>
                {downloading ? (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Downloading... Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
                ) : (
                  <a title='Download Test Report' target='_blank'
                        onClick={onClickDownload}
                        className='btn btn-primary btn-sm me-1'
                      >
                    {'Download Report'}
                  </a>
                )}
                </>
                ) : null}
              </div>
            </div>
            {data.status == 'RUNNING' ? (
            <div className='mb-5'>
              <ProgressBar>
                <ProgressBar isChild={true} key={progress} now={progress} animated label={`${progress}%`} aria-label="test progress" title='test progress' />
              </ProgressBar>
            </div>
            ) : null}
            
            
          </div>
          
        </div>
        
      </div>
      
    </div>
    {renderTabs}
    {tabContent}
    
    </div>
  )
}

export {TestResult, TestScore, getScoreColor}
