/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import { getCSSVariableValue } from '../../../_monic/assets/ts/_utils'
import { LastNRuns } from '../../../_monic/partials/widgets/lists/LastNRuns'
import { Top5Issues } from '../../../_monic/partials/widgets/lists/Top5Issues'
import { PieChart, PieChartData } from '../../../_monic/partials/widgets/mixed/PieChart'
import { MyWebsiteWidget } from '../../../_monic/partials/widgets/tables/MyWebsiteWidget'
import { NCStatsWidget } from '../../../_monic/partials/widgets/tables/NCStatsWidget'
import { WebsiteDashboardNc } from '../../modules/auth/models/TestRunModel'
import { getOutstandingNonCompliances } from '../../modules/auth/redux/TestRunCRUD'

export const TesterDashboardPage: FC = () => {
  const [ncData, setNcData] = useState<Array<WebsiteDashboardNc>>();
  const [issueStatusData, setIssueStatusData] = useState<PieChartData>();

  useEffect(() => {
    getOutstandingNonCompliances().then((result) => {
      setNcData(result.data);
      const countByStatus = result.data.reduce((p: any, c) => {
        var status = c.status;
        if (!p.hasOwnProperty(status)) {
          p[status] = 0;
        }
        p[status]++;
        return p;
      }, {});
      const xAxis = ['RESOLVED', 'UNRESOLVED', 'FIX IN PROGRESS'];

      setIssueStatusData({
        xAxis,
        yAxis: xAxis.map(x => countByStatus[x] || 0),
        colors: [getCSSVariableValue('--bs-success'), getCSSVariableValue('--bs-danger'), getCSSVariableValue('--bs-warning')],
        max: ``
      });
    });
  }, []);

  return (
    <>
      {/* begin::Row */}
      <div className='col-xxl-stretch'>
        <MyWebsiteWidget className='card-xxl-stretch' />
      </div>
      <div className='row gy-3 pe-0'>
        <div className='col-xxl-4 pe-0'>
          <LastNRuns className='card-xl-stretch' />
        </div>
        <div className='col-xxl-4 pe-0'>
          {issueStatusData ? <PieChart className='card-xl-stretch' chartHeight='250px' chartData={issueStatusData} title='Accessibility Status' /> : null}
        </div>
        <div className='col-xxl-4 pe-0'>
          <Top5Issues className='card-xl-stretch' />
          {/* <NotificationWidgetTester className='card-xl-stretch mb-xl-8' /> */}
        </div>
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      {/* <div className='row gy-3'> */}
        {/* <div className='col-xxl-4'>
          <TodoWidget className='card-xxl-stretch mb-xl-3' />
        </div> */}
        <div className='col-xxl-stretch px-3'>
          <NCStatsWidget className='card-xxl-stretch px-3' ncList={ncData || []} showBaseUrlCol={true} title='Non Compliance Issues' subTitle='Top outstanding issues' />
          </div>
      {/* </div> */}
      {/* end::Row */}

      {/* begin::Row */}
      {/* <div className='row gy-5 gx-xl-8'>
        <div className='col-xxl-4'>
          <ListsWidget6 className='card-xl-stretch mb-xl-8' />
        </div>
        <div className='col-xl-4'>
          <ScheduledTestStatsWidget className='card-xxl-stretch mb-5 mb-xl-3' />
        </div>
        <div className='col-xl-4'>
          <TrendsWidget className='card-xl-stretch mb-5 mb-xl-8' items={5} />
        </div>
      </div> */}
      {/* end::Row */}
    </>
  )
}
