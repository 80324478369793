import { FC } from "react";
import { Settings } from "../../utils/config";
import { LandingFooter } from "./Footer";
import { BreadcrumbTitle, LandingHeader } from "./Header";

export const WebsitePoliciesPage: FC = () => {
    return (
    <div className="main-body-content">
        <LandingHeader />
        <div id="main-content">
            <BreadcrumbTitle>Website Policies</BreadcrumbTitle>

            <section className="content-area">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12">
                            <h2>
                            Hyperlinking Policy
                            </h2>
                            <p>
                            <p className="heading4">
                            Links from external websites/portals:
                            </p>
                            We do not object to you linking directly to the information that is hosted on our site and no prior permission is required for the same. However, we would like you to inform us about any links provided to our site so that you can be informed of any changes or updates therein. Also, we do not permit our pages to be loaded into frames on your site. Our website’s pages must load into a newly opened browser window of the user.
                            </p>
                            <h2>
                            Intellectual Property Rights Policy
                            </h2>
                            <p>
                            The intellectual property rights of the {Settings.GamyataLabel} framework, logos, website, themes, related content such as user manuals, FAQs, Help, Website Policies, Terms of Use are exclusively owned by Sumatak. Any use or distribution may be done with explicit permission from Sumatak. Any infringements of the same, any misuse or inappropriate use will be liable for prosecution.
                            </p>
                            <h2>
                            Copyright Policy
                            </h2>
                            <p>
                            Material featured on this website may be reproduced free of charge. However, the material has to be reproduced accurately and not to be used in a derogatory manner or in a misleading context. Wherever the material is being published or issued to others, the source must be prominently acknowledged. However, the permission to reproduce this material shall not extend to any material which is identified as being copyright of a third party. Authorization to reproduce such material must be obtained from the departments/copyright holders concerned.
                            <br />
                            These terms and conditions shall be governed by and construed in accordance with the Indian Laws. Any dispute arising under these terms and conditions shall be subject to the exclusive jurisdiction of the courts of India.
                            </p>
                            <h2>
                            Privacy policy
                            </h2>
                            <p>
                            {Settings.GamyataLabel} website does not automatically capture any specific personal information from you (like name, phone number or e-mail address), that allows us to identify you individually.
                            <br /><br />
                            We do not sell or share any personally identifiable information shared on the {Settings.GamyataLabel} portal to any third party (public/private). Any information provided to this website will be protected from loss, misuse, unauthorized access or disclosure, alteration, or destruction.
                            <br /><br />
                            We gather certain information about the User, such as Internet protocol (IP) addresses, domain name, browser type, operating system, the date and time of the visit and the pages visited. We make no attempt to link these addresses with the identity of individuals visiting our site unless an attempt to damage the site has been detected.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </div>

        <LandingFooter />
    </div>
    );
};