import { FC } from "react";
import { Settings } from "../../utils/config";
import { LandingFooter } from "./Footer";
import { BreadcrumbTitle, LandingHeader } from "./Header";

export const TermsPage: FC = () => {
    return (
    <div className="main-body-content">
        <LandingHeader />
        <div id="main-content">
            <BreadcrumbTitle>Terms and Conditions</BreadcrumbTitle>

            <section className="content-area">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12">
                            <p>
                            {Settings.GamyataLabel} is an online service used by entities to test and validate the accessibility for the websites. 
                            It enables government entities to ensure their websites are accessible without any technical know-how, thus empowering them to providing an accessible online presence to everyone.
                            <br /><br />

                            This website is Designed, Developed, Hosted and Maintained by  National Informatics Centre, Ministry of Electronics & Information Technology, Government of India.
                            <br /><br />
                            Government entities who would like to maintain their websites accessible can use {Settings.GamyataLabel} framework to test and generate the reports and. In order to onboard and create website users need to contact {Settings.GamyataLabel}[dot]support[at]gov[dot]in. The different onboarding models are as under
                            <br /><br />
                            <ul>
                                <li>
                                Free of cost: {Settings.GamyataLabel} is offered free of cost for websites of District administration, Raj Bhawan, State Portal, Divisional Commissioner and Chief Ministers
                                </li>
                                <li>
                                Paid Model: Offered for bulk websites in slabs of upto 25, 50, 75 or 100.
                                </li>
                            </ul>
                            Support desk could be contacted in case of any problems on mail to {Settings.GamyataLabel}[dot]support[at]gov[dot]in.
                            <br /><br />
                            <ol>
                                <li>
                                It must be ensured that all the contact details provided at the time of registration and in the authorization letter are correct and the same must be updated as and when there is any change.
                                </li><li>
                                Contact details verification is mandatory for both Technical Contact as well as Administrative Contact. Authorization Letter can only be generated after Administrative contact details are verified.
                                </li><li>
                                Content is the sole responsibility of the Website Owners. {Settings.GamyataLabel} is not responsible for the content being published on the website. The website is tested for accessibility by the tool; however, adding accessibility compliant content, pdf, images, videos, removing outdated content, getting certification for GIGW compliance etc. are the sole responsibility of the Website Owners.
                                </li><li>
                                No Personal Identification Details such as Aadhaar Number, Bank Account Number etc. should be listed in any of the uploaded documents or HTML pages of the website. In case of non-compliance, penal action in accordance with the Aadhaar Act and subsequent regulations thereunder may be initiated by the concerned competent authorities.
                                </li><li>
                                For security reasons, it is advised to keep all login Credentials confidential and change password at least once in three months. Follow other cyber security best practices to protect your login credentials. Users are requested to install personal firewall software to secure their machine and website management also install the Antivirus software with the latest pattern update periodically and OS patches in their system.
                                </li><li>
                                NIC and {Settings.GamyataLabel} will take all possible measures to prevent data loss, however, due to unforeseen technical issues, or human error if the same happens, NIC or {Settings.GamyataLabel} cannot be held responsible.
                                </li>
                            </ol>
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </div>

        <LandingFooter />
    </div>
    );
};