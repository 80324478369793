import React from 'react'
import {PageLink, PageTitle} from '../../../_monic/layout/core'
import {Redirect, Route, Switch} from 'react-router-dom'
import { WelcomePageTitle } from '../../pages/dashboard/DashboardWrapper'
import { EditSettings } from './EditSettings'

const breadCrumbs: Array<PageLink> = [
  {
    title: 'Settings',
    path: '/core/settings/edit',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const SettingsManagementPage: React.FC = () => {
  return (
    <>
    <Switch>
        <Route path='/core/settings/edit'>
        <WelcomePageTitle />
        {/* <PageTitle breadcrumbs={breadCrumbs}>Add Website</PageTitle> */}
          <EditSettings />
        </Route>
        <Redirect from='/core/settings' exact={true} to='/core/settings/edit' />
        <Redirect to='/core/settings/edit' />
      </Switch>      
    </>
  )
}

export default SettingsManagementPage
