import React, {useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {createUser} from '../../auth/redux/AuthCRUD'
import {FORM_STATUSES, getUrlPrefix} from '../../utils'
import { OrganizationModel } from '../../auth/models/OrganizationModel'
import { viewOrganization } from '../../auth/redux/OrganizationCRUD'
import { Settings } from '../../../utils/config'
import { useSelector, shallowEqual } from 'react-redux'
import { RootState } from '../../../../setup'
import { UserModel } from '../../auth/models/UserModel'
import { Redirect, useLocation, useParams } from 'react-router-dom'

const userSchema = Yup.object().shape({
  name: Yup.string()
  .matches(/^[A-Za-z ]*$/, 'Please enter valid name')
  .min(3)
  .max(40)
  .required('Name is required'),
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  organizationId: Yup.number()
    .test("orgTest", "Please select an organization", function (value) {
      return this.parent.role == 'SUPERADMIN' || (value != null && value > 0);
    }),
  role: Yup.string()
    .required('Please select a role')
});

const CreateUser: React.FC = () => {
  const {role, organizationId}: any = useParams();
  const [loading, setLoading] = useState(false)
  const [orgData, setOrgData] = useState<Array<OrganizationModel>>();
  const [dataError, setDataError] = useState<DataError>();
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const isAdmin = user.gamyata && user.gamyata.role == 'ADMIN';
  const isSuperAdmin = user.gamyata && user.gamyata.role == 'SUPERADMIN';
  const initialValues = {name: '', email: '', role: role?.toUpperCase(), organizationId};
  const formik = useFormik<any>({
    initialValues,
    validationSchema: userSchema,
    onSubmit: (values,{setStatus}) => {
      setLoading(true);
      const orgId = values.role == 'SUPERADMIN' ? -1 : parseInt(values.organizationId);
      createUser(values.name, values.email, values.role, orgId).then(({data: {id}}) => {
        setLoading(false);
        setStatus({state: FORM_STATUSES.SUCCESS, response: {id}});
      }).catch(e => {
        setLoading(false);
        const errorData = e?.response?.data;
        const lbErrorCode = errorData?.error?.code;
        const errorMsg = lbErrorCode == "ER_DUP_ENTRY" ? 'Duplicate User! Cannot create new user!' : 'Server Error';
        setStatus({state: FORM_STATUSES.FAILURE, response: {error: errorMsg}});
      });
    },
  });

  useEffect(() => {
    if (isSuperAdmin) {
      viewOrganization().then((result) => setOrgData(result.data)).catch(e => setDataError({error: "Server Error! Cannot fetch org data!"}));
    }
  }, []);

  const location = useLocation();
  const isMobile = useSelector<RootState>(({auth}) => auth.isMobile, shallowEqual)
  const urlPrefix = getUrlPrefix(isMobile);

  if (isSuperAdmin && (dataError || orgData == null)) {
    return (
      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          {dataError ? (
            <div>
              <br />
                <div className='mb-lg-15 alert alert-danger d-flex flex-column-fluid flex-center'>
                  <div className='alert-text font-weight-bold'>{dataError.error}</div>
                </div>
                </div>
          ) : (
            <div>
              <br />
              <span>Loading... </span> <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
           </div>
        )}
        </div>
      </div>
      );
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header cursor-pointer'
        role='button'
        aria-expanded='true'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Create User</h3>
        </div>
      </div>

      <div className='' style={{
        backgroundColor: 'var(--color-gray-200)',
        margin: 20,
        border: '1px dashed var(--color-gray-400)',

      }}>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body p-9'>


          {formik.status && formik.status.state == FORM_STATUSES.SUCCESS ? (
            <div className='mb-lg-15 alert alert-success'>
              <div className='alert-text font-weight-bold'>Created Successfully</div>
              <Redirect to={{...location, pathname: urlPrefix + `/core/users/manage/${role ? role + '/' + (organizationId || '') : ''}`}}  />
            </div>
          ) : null}

          {formik.status && formik.status.state == FORM_STATUSES.FAILURE ? (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>{formik.status.response?.error}</div>
            </div>
          ) : null}

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Name</label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Name'
                  {...formik.getFieldProps('name')}
                />
                {formik.touched.name && formik.errors.name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.name}</div>
                  </div>
                )}
              </div>
            </div>
            
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Email</label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Email'
                  {...formik.getFieldProps('email')}
                />
                {formik.touched.email && formik.errors.email && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.email}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label htmlFor='s_role' className='col-lg-4 col-form-label required fw-bold fs-6'>Role</label>

              <div className='col-lg-8 fv-row'>
                <select
                  id='s_role'
                  className='form-select form-select-solid form-select-lg'
                  {...formik.getFieldProps('role')}
                >
                    <option value="">Select Role</option>
                    <option value='TESTER'>{Settings.TesterLabel}</option>
                    <option value='ADMIN'>{Settings.AdminLabel}</option>
                    {isSuperAdmin ? 
                    <option value='SUPERADMIN'>Super Admin</option> : null}
                  </select>
                {formik.touched.role && formik.errors.role && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.role}</div>
                  </div>
                )}
              </div>
            </div>

          {isAdmin ? null : 
          <div className='row mb-6' style={{display: formik.values.role == 'SUPERADMIN' ? 'none' : 'flex'}}>
              <label htmlFor='s_org' className='col-lg-4 col-form-label required fw-bold fs-6'>Organization</label>

              <div className='col-lg-8 fv-row'>
                <select 
                  id='s_org'
                  className='form-select form-select-solid form-select-lg'
                  {...formik.getFieldProps('organizationId')}
                >
                  <option value="-1">Select Org</option>
                  {orgData?.map(org => (
                    <option value={org.id}>{org.name}</option>
                  ))}
                </select>
                {formik.touched.organizationId && formik.errors.organizationId && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.organizationId}</div>
                  </div>
                )}
              </div>
            </div>
            }
            
            </div>

          <div className='d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-primary mx-auto' disabled={loading}>
              {!loading && 'Save'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export {CreateUser}
