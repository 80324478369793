/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, {FC, Suspense, useEffect, useState} from 'react'
import {Redirect, Switch, Route} from 'react-router-dom'
import {shallowEqual, useSelector} from 'react-redux'
import {PrivateRoutes} from './PrivateRoutes'
import {Logout} from '../modules/auth'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {RootState} from '../../setup'
import { Login } from '../modules/auth/components/Login'
import { HomePage } from '../pages/landing/Home'
import { UsingGamyataPage } from '../pages/landing/UsingGamyata'
import { TermsPage } from '../pages/landing/Terms'
import { WebsitePoliciesPage } from '../pages/landing/WebsitePolicies'
import { OnboardingRequest } from '../pages/landing/OnboardingRequest'
import { BackgroundPage } from '../pages/landing/Background'
import { ContactPage } from '../pages/landing/Contact'
import { MockLogin } from '../modules/auth/components/MockLogin'
import { SiteMapPage } from '../pages/landing/SiteMap'
import { MobileHomePage } from '../../mobile/Home'
import { LayoutProvider } from '../../_monic/layout/core'
import AuthInit from '../modules/auth/redux/AuthInit'
import { MobileRoute } from '../../mobile/MobileRoute'
import { FallbackView } from '../../_monic/partials'
import { AddWebsite } from '../modules/websites/components/AddWebsite'
import { EditWebsite } from '../modules/websites/components/EditWebsite'
import { ManageWebsite } from '../modules/websites/components/ManageWebsite'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { RunTest } from '../modules/testmgmt/components/RunTest'
import { ManageNonCompliance } from '../modules/testmgmt/components/ManageNonCompliance'
import { ManageTestResults } from '../modules/testmgmt/components/ManageTestResults'
import { ManageTestSchedules } from '../modules/testmgmt/components/ManageTestSchedules'
import { TestResult } from '../modules/testmgmt/components/TestResult'
import { TestSchedule } from '../modules/testmgmt/components/TestSchedule'
import { MyWebsites } from '../modules/reports/components/MyWebsites'
import { WebsiteDetail } from '../modules/reports/components/WebsiteDetail'
import { CreateUser } from '../modules/users/components/CreateUser'
import { EditUser } from '../modules/users/components/EditUser'
import { ManageUser } from '../modules/users/components/ManageUser'
import { ViewProfile } from '../modules/users/components/ViewProfile'
import { LoadPdf } from '../pages/landing/LoadPdf'
import { CreateOrganization } from '../modules/organization/components/CreateOrganization'
import { EditOrganization } from '../modules/organization/components/EditOrganization'
import { ManageOrganization } from '../modules/organization/components/ManageOrganization'
import { MobileAbout } from '../../mobile/About'

const MobileRoutes: FC = () => {
  const isAuthorized = useSelector<RootState>(({auth}) => auth.user, shallowEqual)
  const [stylesLoaded, setStylesLoaded] = useState(false);

  useEffect(() => {
    Promise.all([
      // @ts-ignore
      import('../../_monic/assets/sass/style.scss'),
      // @ts-ignore
      import('../../_monic/assets/sass/style.react.scss')
    ]).then(() => {
      setStylesLoaded(true);
    });
  }, []);

  if (!stylesLoaded) {
    return null;
  }
  
  return (
    <Suspense fallback={<FallbackView />}>
    <Switch>
      {/* Landing pages */}
      {/* <Route path='/contact' component={ContactPage} />
      <Route path='/sitemap' component={SiteMapPage} />
      <Route path='/using-sugamyaweb' component={UsingGamyataPage} />
      <Route path='/terms' component={TermsPage} />
      <Route path='/website-policies' component={WebsitePoliciesPage} />      
      <Route path='/background' component={BackgroundPage} />
      <Route path='/m/onboarding' component={OnboardingRequest} /> */}

      <Route path='/m/logout'  render={() => <Logout isMobile={true} />} />
      <Route path='/m/login' render={() => <Login isMobile={true} />} /> 
      <Route path='/m/about' component={MobileAbout} exact={true} />
      <Route path='/m/' component={MobileHomePage} exact={true} />


      {!isAuthorized ? (
        /*Redirect to home when user is not authorized*/
        <Redirect to='/m/' />
      ) : (
        <Switch>
          <MobileRoute path='/m/dashboard' component={DashboardWrapper} />

          <MobileRoute path='/m/core/website/add/:orgId' component={AddWebsite} />
          <MobileRoute path='/m/core/website/add' component={AddWebsite} />
          <MobileRoute path='/m/core/website/manage/:orgId' component={ManageWebsite} />
          <MobileRoute path='/m/core/website/manage' component={ManageWebsite} />
          <MobileRoute path='/m/core/website/edit/:id/:orgId' component={EditWebsite} />
          <MobileRoute path='/m/core/website/edit/:id' component={EditWebsite} />

          <MobileRoute path='/m/activities/testmgmt/runtest' component={RunTest} />
          <MobileRoute path='/m/activities/testmgmt/runtest/schedule' component={RunTest} />
          <MobileRoute path='/m/activities/testmgmt/testresult/:id/:activeTab?' component={TestResult} />
          <MobileRoute path='/m/activities/testmgmt/testschedule/:id/:activeTab?' component={TestSchedule} />
          <MobileRoute path='/m/activities/testmgmt/manageSchedules' component={ManageTestSchedules} />
          <MobileRoute path='/m/activities/testmgmt/manageResults/:websiteId/:websiteName' component={ManageTestResults} />
          <MobileRoute path='/m/activities/testmgmt/manageResults' component={ManageTestResults} />
          <MobileRoute path='/m/activities/testmgmt/manageNonCompliance' component={ManageNonCompliance} />

          <MobileRoute path='/m/activities/reports/mywebsites/heatmap' component={MyWebsites} />
          <MobileRoute path='/m/activities/reports/mywebsites' component={MyWebsites} />
          <MobileRoute path='/m/activities/reports/website/:url/:activeTab?' component={WebsiteDetail} />

          <MobileRoute path='/m/core/org/create' component={CreateOrganization} />
          <MobileRoute path='/m/core/org/manage' component={ManageOrganization} />
          <MobileRoute path='/m/core/org/edit/:id' component={EditOrganization} />

          <MobileRoute path='/m/core/users/create/:role/:organizationId' component={CreateUser} />
          <MobileRoute path='/m/core/users/create/:role' component={CreateUser} />
          <MobileRoute path='/m/core/users/create' component={CreateUser} />
          <MobileRoute path='/m/core/users/view' component={ViewProfile} />
          <MobileRoute path='/m/core/users/edit/:id' component={EditUser} />
          <MobileRoute path='/m/core/users/onboarding' component={ManageUser} componentProps={{onboarding: true}} />
          {/* <MobileRoute path='/m/core/users/downloadonboarding/:id' component={LoadPdf} /> */}
          <MobileRoute path='/m/core/users/manage/:role/:organizationId' component={ManageUser} />
          <MobileRoute path='/m/core/users/manage/:role' component={ManageUser} />
          <MobileRoute path='/m/core/users/manage' component={ManageUser} />
        </Switch>
      )}
      
    </Switch>
    </Suspense>
  )
}

export {MobileRoutes}
