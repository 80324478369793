import React, {FC} from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import {Link} from 'react-router-dom'
import { UserModel } from '../../../../../app/modules/auth/models/UserModel'
import { RootState } from '../../../../../setup'
import {useLayout} from '../../../core/LayoutProvider'
import {usePageData} from '../../../core/PageData'

const DefaultTitle: FC = () => {
  const {pageTitle, pageDescription, pageBreadcrumbs} = usePageData()
  const {config, attributes} = useLayout()
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  return (
    <div {...attributes.pageTitle} className='page-title d-flex flex-column' style={{width: '100%'}}>
      <section className="breadcrumb-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12">
                              <h2 className='d-flex text-white fw-bolder my-1 fs-3'>
                                {user.gamyata?.givenName ? user.gamyata.givenName : user.fullName}
                              </h2>
                              <h2 className='d-flex text-white fw-bolder my-1 fs-3'>
                                {user.email}
                              </h2>
                              <h2 className='d-flex text-white fw-bolder my-1 fs-3'>
                                {user.gamyata?.role}
                              </h2>
                                {pageTitle && (
                                <h1 className='d-flex text-white fw-bolder my-1 fs-3'>
                                  {pageTitle}
                                  {pageDescription && config.pageTitle && config.pageTitle.description && (
                                    <>
                                      <span className='h-20px border-gray-200 border-start ms-3 mx-2'></span>
                                      <small className='text-muted fs-7 fw-bold my-1 ms-1'>{pageDescription}</small>
                                    </>
                                  )}
                                </h1>
                              )}
                              {pageBreadcrumbs &&
                                pageBreadcrumbs.length > 0 &&
                                config.pageTitle &&
                                config.pageTitle.breadCrumbs && (
                                  <span className='breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1'>
                                    {Array.from(pageBreadcrumbs).map((item, index) => (
                                      <li className={'breadcrumb-item text-white opacity-75'} key={`${item.path}${index}`}>
                                        {!item.isSeparator ? (
                                          <Link className='text-white text-hover-primary' to={item.path}>
                                            {item.title}
                                          </Link>
                                        ) : (
                                          <span className='bullet bg-white opacity-75 w-5px h-2px'></span>
                                        )}
                                      </li>
                                    ))}
                                    <span className='text-white text-hover-primary'>{pageTitle}</span>
                                  </span>
                                )}
                            </div>
                        </div>
                    </div>
                </section>  
    </div>
  )
}

export {DefaultTitle}
