/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { NonComplianceModel, WebsiteDashboardNc } from '../../../../app/modules/auth/models/TestRunModel'
import { ChangeStatus, ChangePriority, Rectification } from '../../../../app/modules/testmgmt/components/AuditSidebarDetail'
import { dashNctoNc, getUrlPrefix } from '../../../../app/modules/utils'
import { RootState } from '../../../../setup'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'

type Props = {
  className: string
  ncList: Array<WebsiteDashboardNc>
  latestTestRunId?: number
  title?: string
  subTitle?: string
  showBaseUrlCol?: boolean
  showUserCol?: boolean
}

const NCStatsWidget: React.FC<Props> = ({className, ncList, latestTestRunId, title, subTitle, showBaseUrlCol, showUserCol}) => {
  const history = useHistory();
  const [statusFilter, setStatusFilter] = useState<string>("UNRESOLVED");
  const [countFilter, setCountFilter] = useState<string>("5");
  const [rerender, setRerender] = useState(false);
  const filteredNc = ncList.filter(nc => statusFilter == 'ALL' || nc.status == statusFilter).slice(0, countFilter == "ALL" ? undefined : parseInt(countFilter));
  const isMobile = useSelector<RootState>(({auth}) => auth.isMobile, shallowEqual)
  const urlPrefix = getUrlPrefix(isMobile);
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5 my-5' style={{
      backgroundColor: 'var(--color-gray-200)',
      padding: 20
    }}>
        {title == null && subTitle == null ? null : 
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>{title}</span>
          <span className='text-muted mt-1 fw-bold fs-7'>{subTitle}</span>
        </h3>}
        <div className='d-flex align-items-center me-4'>
            <label htmlFor='s_status'>
              Status
            </label>
            <select
              id='s_status'
              name='status'
              data-hide-search='true'
              className='form-select form-select-sm w-200px mx-2'
              defaultValue={statusFilter}
              onChange={e => setStatusFilter(e.target.value)}
            >
              <option value='ALL'>ALL</option>
              <option value='UNRESOLVED'>UNRESOLVED</option>
              <option value='FIX IN PROGRESS'>FIX IN PROGRESS</option>
              <option value='RESOLVED'>RESOLVED</option>
            </select>
          </div>
          <div className='d-flex align-items-center me-4'>
            <label htmlFor='s_show'>
              Show 
            </label>
            <select
              id='s_show'
              name='count'
              data-hide-search='true'
              className='form-select form-select-sm w-200px mx-2'
              defaultValue={countFilter}
              onChange={e => setCountFilter(e.target.value)}
            >
              <option value='ALL'>ALL</option>
              <option value='5'>5</option>
              <option value='10'>10</option>
              <option value='15'>15</option>
            </select>
          </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-full-bordered table-row-gray-300 align-middle gs-0 gy-3'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bolder'>
                {showUserCol ? <th className='min-w-140px'>Owner</th> : null }
                {showBaseUrlCol ? <th className='min-w-140px'>Website</th> : null }
                <th className='min-w-150px'>First Reported</th>
                <th className='min-w-150px'>Explanation</th>
                <th className='min-w-140px'>Snippet</th>
                <th className='min-w-120px'>Notes</th>
                <th className='min-w-100px'>Actions</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {filteredNc.length == 0 ? <span>No data found</span> : null}
              {filteredNc.map(nc => (
                <tr key={nc.id}>
                  {showUserCol ? <td className='min-w-140px'>{nc.users?.email}</td> : null }
                  {showBaseUrlCol ? <td className='min-w-140px'>{nc.website?.name}</td> : null }
                  <td>{new Date(nc.created).toLocaleString('en-IN')}</td>
                  <td>{nc.failingElement.explanation}</td>
                  <td>
                    <pre style={{whiteSpace: 'pre-wrap'}}>
                      <code style={{padding: 0}}>
                      <p>{nc.failingElement.snippet}</p>
                      </code>
                    </pre>
                  </td>
                  <td>{nc.comments}</td>
                  <td>
                    <div className='d-flex  mt-auto mb-2 ms-5'>
                      <ChangeStatus key={nc.id} nonCompliance={dashNctoNc(nc)} onChangeNcStatus={(_nc: NonComplianceModel) => {
                        const item = ncList.find(n => n.id == _nc.id);
                        if (item != null) {
                          item.status = _nc.status;
                        }
                        setRerender(!rerender);
                        }} showStatusLabel={false} />
                    </div>
                    {/* <div className='d-flex w-100 mt-auto mb-2 ms-5'>
                      <ChangePriority key={nc.id} nonCompliance={dashNctoNc(nc)} onChangeNcPriority={(_nc: NonComplianceModel) => {
                        const item = ncList.find(n => n.id == _nc.id);
                        if (item != null) {
                          item.status = _nc.status;
                        }
                        setRerender(!rerender);
                        }} showPriorityLabel={false} />
                    </div> */}
                    <div className='d-flex mt-auto mb-2 ms-5'>
                      <Rectification key={nc.id} nonCompliance={dashNctoNc(nc)} onChangeNcComment={(_nc: NonComplianceModel) => {
                        const item = ncList.find(n => n.id == _nc.id);
                        if (item != null) {
                          item.comments = _nc.comments;
                        }
                        setRerender(!rerender);
                        }} showCommentsLabel={false} />
                    </div>
                    {showBaseUrlCol ? 
                    <div className='d-flex w-100 mt-auto mb-2 ms-5'>
                      <button
                        onClick={() => {
                          history.push(urlPrefix + `/activities/testmgmt/testresult/${nc.website?.latestTestRunId}/testresults`);
                        }}
                        type='button'
                        className='btn btn-sm btn-light-primary me-3'
                        data-kt-stepper-action='previous'
                      >
                        View Latest Test Results
                      </button>  
                    </div>
                    : null }
                  </td>
              </tr>
              ))}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export {NCStatsWidget}
