import { FC } from "react";
import { Settings } from "../../utils/config";
import { LandingFooter } from "./Footer";
import { BreadcrumbTitle, LandingHeader } from "./Header";

export const BackgroundPage: FC = () => {
    return (
    <div className="main-body-content">
        <LandingHeader />
        <div id="main-content">
            <BreadcrumbTitle>Background</BreadcrumbTitle>

            <section className="short-desp">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12">
                            <p className="text-center">India is the 2nd largest online market in the world with 56 crore
                                internet users</p>

                            <p className="text-center">By 2023, it would be over 65 crores</p>

                            <p className="text-center">Around 2% of Indian population suffer some kind of disability</p>

                            <p className="text-center">Neglecting the disability population by denying equal access to
                                technology means that these people may lose easy access
                                to government services, and other critical infrastructure such as safety, logistics,
                                health, education etc
                                </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="accessibility-dashboard site-stats roles">
                <div className="container">
                    <div className="row">
                        <h2 className="text-center">WHAT IS ACCESSIBILITY?</h2>
                        <div className="col-md-12 what-is-accessbility">
                            <h3 className="text-center heading4"><strong><em>The power of the Web is in its universality.
                                        Access by everyone regardless of disability is an essential aspect<br />
                                        As the web reshapes our world, we have a responsibility to make sure it is
                                        recognized as a human right and built for the public good</em></strong></h3>
                            <h3 className="text-center heading4">Tim Berners-Lee, W3C Director</h3>
                        </div>
                        <div className="col-md-12 accessibility-concept text-center">
                            <ul>
                                <li><img src="/assets/images/cognitive.png" alt="Cognitive Icon" /><br />
                                    <span>Cognitive</span>
                                </li>
                                <li><img src="/assets/images/visual.png" alt="Visual Icon" /><br />
                                    <span>Visual</span>
                                </li>
                                <li><img src="/assets/images/auditory.png" alt="Auditory Icon" /><br />
                                    <span>Auditory</span>
                                </li>
                                <li><img src="/assets/images/motor.png" alt="Motor Icon" /><br />
                                    <span>Motor</span>
                                </li>
                                <li><img src="/assets/images/speech.png" alt="Speech Icon" /><br />
                                    <span>Speech</span>
                                </li>
                            </ul>
                            <h3 className="text-center heading4"><strong>Accessibility is a concept to ensure a product,
                                    service or
                                    environment can be used by everyone, however they encounter
                                    it. Accessible websites ensure that even people with disabilities can access the
                                    information and services online.</strong></h3>
                        </div>
                    </div>
                </div>
            </section>

            <section className="features-section howedo-accessible">
                <div className="container">
                    <div className="row">
                        <h2 className="text-center">HOW DO WE MAKE WEBSITE ACCESSIBLE?</h2>
                        <div className="equal-height">
                            <div className="col-md-9 col-xs-12">
                                <p className="heading4">Sites are tested for compliance with GIGW (Guidelines for Indian
                                    Government Websites) which include guidelines, a subset
                                    of which are specific to accessibility. These guidelines are in conformance with
                                    Web Content Accessibility Guidelines
                                    (WCAG) which is a set of web standards that aim to make the Internet a more
                                    inclusive and accessible space for all.<br /><br />
                                    At a high level, WCAG sets out 4 key principles - Perceivable, Operable, Robust,
                                    Understandable. They provide a solid
                                    foundation to create web content that is accessible and usable for all.</p>
                            </div>
                            <div className="col-md-3 col-xs-12">
                                <img src="/assets/images/wcag-chart.png" alt="WCAG Chart" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="tpweb-accessibility genesis action-frm-rprt no-border">
                <div className="container">
                    <div className="row">
                        <h2 className="text-center">HOW WAS {Settings.GamyataLabel.toUpperCase()} BORN?</h2>
                        <div className="col-md-12">
                            <p>To enhance the accessibility of the Indian Govt. webspace, the&nbsp;<a style={{color: '#0263f2'}}
                                    onClick={(e) => !window.confirm('You are being redirected to an external website. Please note that SugamyaWeb cannot be held responsible for external websites content & privacy policies.') && e.preventDefault()}
                                    href="http://meity.gov.in/" target="_blank">Ministry of Electronics &amp;
                                    Information Technology (MeitY)</a>&nbsp;organized an&nbsp;<a style={{color: '#0263f2'}}
                                    onClick={(e) => !window.confirm('You are being redirected to an external website. Please note that SugamyaWeb cannot be held responsible for external websites content & privacy policies.') && e.preventDefault()}
                                    href="https://guidelines.india.gov.in/about-the-challenge/"
                                    target="_blank">Innovation Challenge</a>&nbsp;in 2021-22. Many Indian startups
                                participated in multiple stages of the challenge</p>
                            <p><strong>Ideation:</strong><br /> Startups submitted ideas about how they proposed to
                                address the challenge of assessing Indian Government websites for
                                accessibility.</p>
                            <p><strong>Building a Minimum Viable Product (MVP):</strong><br /> Shortlisted startups
                                developed their independent versions of a Minimum Viable Product to demonstrate the
                                capability of
                                their respective tool to detect accessibility issues.</p>
                            <p><strong>Building a Functional Product:</strong><br /> The finalist startups built their
                                respective functional products to present the full capabilities of their respective
                                tool to not only detect non-compliances w.r.t., accessibility but also features such as
                                scheduling of tests to run at a
                                pre-set frequency, workflow capabilities for developers to mark the rectification status
                                of each non-compliance etc
                            </p>
                            <p>{Settings.GamyataLabel} emerged as the <a href="https://guidelines.india.gov.in/final-outcome/" style={{color: '#0263f2'}}
                                    onClick={(e) => !window.confirm('You are being redirected to an external website. Please note that SugamyaWeb cannot be held responsible for external websites content & privacy policies.') && e.preventDefault()}
                                    target="_blank"> winner</a> of the Innovation Challenge in 2022.</p>
                        </div>
                    </div>
                </div>
            </section>
        </div>

        <LandingFooter />
    </div>
    );
};