import { FC } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { Link } from "react-router-dom";
import { Settings } from "../app/utils/config";
import { UserModel } from "../app/modules/auth/models/UserModel";
import { RootState } from "../setup";

export const Sidebar: FC = () => {
    const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
    return (
        <div id="menu-sidebar-left-5" className="menu menu-box-left" data-menu-width="320" data-menu-effect="menu-push">
            <div className="card card-style">
				<div className="content my-0">
					<h5 className="font-700 text-uppercase opacity-40 font-12 pt-2 mb-0">Navigation</h5>
					<div className="list-group list-custom-small list-icon-0">
						<Link to="/m/dashboard">
							<i className="fa font-12 fa-area-chart gradient-green rounded-sm color-white"></i>
							<span>Dashboard</span>
							<i className="fa fa-angle-right"></i>
						</Link>
					</div>
                    <h5 className="font-700 text-uppercase opacity-40 font-12 pt-2 mb-0">Activities</h5>
					<div className="list-group list-custom-small list-icon-0">
					{user.gamyata?.role == 'ADMIN' ? (
						<>
						<Link to="/m/core/website/add">
							<i className="fa font-12 fa-area-chart gradient-green rounded-sm color-white"></i>
							<span>Add Website</span>
							<i className="fa fa-angle-right"></i>
						</Link>
						<Link to="/m/core/website/manage">
							<i className="fa font-12 fa-cog gradient-red rounded-sm color-white"></i>
							<span>Manage Website</span>
							<i className="fa fa-angle-right"></i>
						</Link>
						</>
					) : null}

						<Link to="/m/activities/testmgmt/runtest">
							<i className="fa font-12 fa-file gradient-blue rounded-sm color-white"></i>
							<span>Run Test</span>
							<i className="fa fa-angle-right"></i>
						</Link>
						<Link to="/m/activities/testmgmt/runtest/schedule">
							<i className="fa font-12 fa-file gradient-blue rounded-sm color-white"></i>
							<span>Add Standard Test</span>
							<i className="fa fa-angle-right"></i>
						</Link>
						<Link to="/m/activities/testmgmt/manageschedules">
							<i className="fa font-12 fa-camera gradient-yellow rounded-sm color-white"></i>
							<span>Manage Standard Test</span>
							<i className="fa fa-angle-right"></i>
						</Link>
						<Link to="/m/activities/testmgmt/manageresults">
							<i className="fa font-12 fa-image gradient-teal rounded-sm color-white"></i>
							<span>View Test Runs</span>
							<i className="fa fa-angle-right"></i>
						</Link>
						<Link to="/m/activities/reports/mywebsites/heatmap">
							<i className="fa font-12 fa-image gradient-teal rounded-sm color-white"></i>
							<span>Website Heatmap</span>
							<i className="fa fa-angle-right"></i>
						</Link>

						{user.gamyata?.role == 'ADMIN' ? (
						<Link to="/m/core/users/manage/tester">
							<i className="fa font-12 fa-image gradient-teal rounded-sm color-white"></i>
							<span>{'Manage ' + Settings.TesterLabel}</span>
							<i className="fa fa-angle-right"></i>
						</Link>
						) : null}

					</div>
					{user.gamyata?.role == 'SUPERADMIN' ? (
					<>
					<h5 className="font-700 text-uppercase opacity-40 font-12 pt-2 mb-0">Core</h5>
					<div className="list-group list-custom-small list-icon-0">
					
						<>
						<Link to="/m/core/org/manage">
							<i className="fa font-12 fa-area-chart gradient-green rounded-sm color-white"></i>
							<span>View Organization</span>
							<i className="fa fa-angle-right"></i>
						</Link>
						<Link to="/m/core/users/onboarding">
							<i className="fa font-12 fa-cog gradient-red rounded-sm color-white"></i>
							<span>Manage Onboarding</span>
							<i className="fa fa-angle-right"></i>
						</Link>
						<Link to="/m/core/users/manage/superadmin">
							<i className="fa font-12 fa-cog gradient-red rounded-sm color-white"></i>
							<span>Manage Superadmin</span>
							<i className="fa fa-angle-right"></i>
						</Link>
						</>
					</div>
					</>
					) : null}
					<div className="list-group list-custom-small list-icon-0">
						<Link to="/m/core/users/view">
							<i className="fa font-12 fa-area-chart gradient-green rounded-sm color-white"></i>
							<span>View Profile</span>
							<i className="fa fa-angle-right"></i>
						</Link>
					</div>
				</div>
			</div>

            
        </div>
    );
};