import {FC, useRef, useEffect, useState} from 'react'
import {shallowEqual, useSelector, connect, useDispatch, ConnectedProps} from 'react-redux'
import {LayoutSplashScreen} from '../../../_monic/layout/core'
import * as auth from '../../modules/auth/redux/AuthRedux'
import {handshake, isTokenValid} from '../../modules/auth/redux/AuthCRUD'
import {RootState} from '../../../setup'
import { useHistory, useLocation } from 'react-router-dom'

const mapState = (state: RootState) => ({auth: state.auth})
const connector = connect(mapState, auth.actions)
type PropsFromRedux = ConnectedProps<typeof connector>

const AuthInit: FC<PropsFromRedux> = (props) => {
  const didRequest = useRef(false)
  const history = useHistory();
  const dispatch = useDispatch()
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  const loggedUser = useSelector<RootState>(({auth}) => auth.user, shallowEqual)
  const isMobile = useSelector<RootState>(({auth}) => auth.isMobile, shallowEqual)
  const redirectTo = useSelector<RootState, string | undefined>(({auth}) => auth.redirectTo, shallowEqual)
  const { search, pathname } = useLocation();
  const query = new URLSearchParams(search);
  const handshakeId = query.get('string');
  const email = query.get('email');
  const password = query.get('password');
  const urlPrefix = isMobile ? '/m' : '';

  // We should request user by authToken before rendering the application
  useEffect(() => {
    const handshakeUser = async (handshakeId: string, email?: string, password?: string) => {
      try {
        const {data} = await handshake(handshakeId, email, password);
        if (data.user != null) {
          dispatch(props.login(data.user));
          if (data.user.gamyata) {
            if (redirectTo) {
              history.push(urlPrefix + decodeURIComponent(redirectTo))  
            } else {
              history.push(urlPrefix + '/v2/dashboard')
            }
            //window.location.href = urlPrefix + '/dashboard';
          } else {
            history.push(urlPrefix + '/v2/onboarding')
            //window.location.href = urlPrefix + '/onboarding';
          }
        } else {
          dispatch(props.logout());  
        }
      } catch (error) {
        dispatch(props.logout());
      } finally {
        if (isMobile) {
          return;
        }
        setShowSplashScreen(false)
      }
    }

    const validateToken = async (loggedUser: any) => {
      try {
        if (!didRequest.current) {
          let retryCount = 0;
          let isValid = false;
          while (retryCount < 3 && !isValid) {
            const {data} = await isTokenValid(loggedUser.userName, loggedUser.sessionId, loggedUser.browserId, loggedUser.localTokenId);
            isValid = data.isValid;
            retryCount++;
          }
          if (!isValid) {
            dispatch(props.logout());  
          }
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          dispatch(props.logout())
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (pathname == '/' && handshakeId != null) {
      handshakeUser(handshakeId, email || '', password || '');
    } else {
      if (loggedUser) {
        validateToken(loggedUser)
      } else {
        //dispatch(props.logout())
        setShowSplashScreen(false)
      }
    }
    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{props.children}</>
}

export default connector(AuthInit)
