/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSSVariableValue} from '../../../assets/ts/_utils'
import { getScoreColor } from '../../../../app/modules/testmgmt/components/TestResult'

export type PieChartData = {
  xAxis: Array<string>
  yAxis: Array<number>
  max: string,
  colors?: Array<string>
}

type Props = {
  title: string
  className: string
  chartHeight: string
  chartData: PieChartData
}

const PieChart: React.FC<Props> = ({title, className, chartHeight, chartData}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (!chartRef.current) {
      return
    }

    const chart = new ApexCharts(chartRef.current, chartOptions(chartHeight, chartData))
    if (chart) {
      chart.render()
    }

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef])

  return (
    <div className={`card ${className}`}>
      {/* begin::Body */}
      <div className='card-body p-0 d-flex flex-column overflow-hidden'>
        {/* begin::Hidden */}
        <div className='d-flex flex-stack flex-wrap  px-9 pt-9 pb-3'>
          <div className='me-2'>
            <span className='fw-bolder text-gray-800 d-block fs-3'>{title}</span>

            {/* <span className='text-gray-400 fw-bold'>Oct 8 - Oct 26 2021</span> */}
          </div>

          <div className={`fw-bolder fs-3 text-${getScoreColor(chartData.yAxis[0])}`}>{chartData.max}</div>
        </div>
        {/* end::Hidden */}

        {/* begin::Chart */}
        <div ref={chartRef} className='mixed-widget-10-chart'></div>
        {/* end::Chart */}
      </div>
    </div>
  )
}

const chartOptions = (chartHeight: string, chartData: PieChartData): ApexOptions => {
  return {
    chart: {
      type: 'pie',
      fontFamily: 'inherit',
      height: chartHeight,
      toolbar: {
        show: false,
      },
    },
    colors: chartData.colors,
    series: chartData.yAxis,
    labels: chartData.xAxis,
  };
}

export {PieChart}
