/* eslint-disable jsx-a11y/anchor-is-valid */
import { AxiosResponse } from 'axios'
import React, {useEffect, useState} from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { Link, useHistory, useParams } from 'react-router-dom'
import { RootState } from '../../../../setup'
import {KTSVG} from '../../../../_monic/helpers'
import {OrganizationModel, WebsiteModel} from '../../auth/models/OrganizationModel'
import { UserModel } from '../../auth/models/UserModel'
import {deleteWebsite, getOrganization, viewWebsite} from '../../auth/redux/OrganizationCRUD'
import {getUrlPrefix} from '../../utils'

type Props = {
  className?: string
}

const ManageWebsite: React.FC<Props> = ({className}) => {
  const {orgId: orgIdFromUrl}: any = useParams();
  const history = useHistory();
  const [data, setData] = useState<Array<WebsiteModel>>();
  const [org, setOrg] = useState<OrganizationModel>();
  const [dataError, setDataError] = useState<{error: string}>();
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const isMobile = useSelector<RootState>(({auth}) => auth.isMobile, shallowEqual)
  const urlPrefix = getUrlPrefix(isMobile);
  const isTester = user.gamyata?.role == "TESTER";
  const orgId = orgIdFromUrl;
  useEffect(() => {
    viewWebsite(orgId).then((r) => setData(r.data)).catch(e => setDataError({error: "Server Error! Cannot fetch data!"}));
    if (orgId) {
      getOrganization(orgId).then((result: AxiosResponse) => {
        setOrg(result.data);
      }).catch(e => setDataError({error: "Server Error! Cannot fetch data!"}));
    }
  }, []);

  const onDelete = (id: number) => {
    if (!window.confirm('Are you sure want to delete?')) {
      return;
    }
    deleteWebsite(id).then(() => {
      setData(data?.filter(x => x.id != id));
    });
  }

  if (dataError || data == null) {
    return (
      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header border-0'
        >
          {dataError ? (
            <div>
              <br />
                <div className='mb-lg-15 alert alert-danger d-flex flex-column-fluid flex-center'>
                  <div className='alert-text font-weight-bold'>{dataError.error}</div>
                </div>
                </div>
          ) : (
            <div>
              <br />
              <span>Loading... </span> <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
           </div>
        )}
        </div>
      </div>
      );
  }

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>Manage Website{orgIdFromUrl ? ` of ${org?.name}` : null}</span>
        </h3>
        <div className='card-toolbar'>
          {/* begin::Menu */}
          <div className='card-toolbar'>
          {isTester ? null : 
          <Link to={urlPrefix + '/core/website/add/' + (orgIdFromUrl ? orgIdFromUrl : '')} className='btn btn-sm btn-light-primary'>
            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
            Create
          </Link>}
        </div>
          {/* end::Menu */}
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-full-bordered table-row-gray-100 align-middle gs-0 gy-3'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bolder'>
                <th className='min-w-150px'>Website</th>
                <th>Latest Score</th>
                <th className='min-w-150px'>Last Test Run Time</th>
                <th className='min-w-140px'>Organization Name</th>
                <th className='min-w-140px'>Assigned To</th>
                <th className='min-w-100px text-end'>Actions</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {data?.map((row) => (
              <tr>
                <td>
                  {row.name}
                </td>
                <td>
                  <span className='text-primary-900 d-block mb-1 fs-6'>
                    {row.latestScore}
                  </span>
                </td>
                <td>
                  <span className='text-primary-900 d-block mb-1 fs-6'>
                    {row.latestTestTime ? new Date(row.latestTestTime).toLocaleString('en-IN'): ''}
                  </span>
                </td>
                <td>
                  <span className='text-primary-900 d-block mb-1 fs-6'>
                    {row.organization?.name}
                  </span>
                </td>
                <td>
                  <span className='text-primary-900 d-block mb-1 fs-6'>
                    {row.user?.parichay?.fullName || row.user?.email}
                  </span>
                </td>
                <td className='text-end'>
                <button
                    onClick={() => {
                      history.push(urlPrefix + `/core/website/${row.id}/webpage/manage`);
                    }}
                    type='button'
                    className='btn btn-sm btn-light-primary me-3'
                    data-kt-stepper-action='previous'
                  >
                    Manage Webpages
                  </button>
                 <button
                    onClick={() => {
                      history.push(urlPrefix + `/activities/testmgmt/manageresults/${row.id}/${row.name}`);
                    }}
                    type='button'
                    className='btn btn-sm btn-light-primary me-3'
                    data-kt-stepper-action='previous'
                  >
                    View Test Runs
                  </button>
                  {isTester ? null : <>
                  <Link
                    title='Edit'
                    to={urlPrefix + `/core/website/edit/${row.id}/${orgIdFromUrl ? orgIdFromUrl : ''}`}
                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                  >
                    <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                  </Link>
                  <Link title='Delete' to='#' onClick={() => onDelete(row.id)} className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'>
                    <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
                  </Link>
                  </>}
                </td>
              </tr>
              ))}
              </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export {ManageWebsite}
