/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import {KTSVG} from '../../../../_monic/helpers'
import {TestScheduleModel} from '../../auth/models/TestRunModel';
import {deleteTestSchedule, getTestSchedules} from '../../auth/redux/TestRunCRUD';
import {Link, useHistory} from 'react-router-dom';
import { getUrlPrefix, useQuery } from '../../utils';
import { useSelector, shallowEqual } from 'react-redux';
import { RootState } from '../../../../setup';

const ManageTestSchedules: React.FC = () => {
  const history = useHistory();
  const queryParams: URLSearchParams = useQuery();
  const websiteId = decodeURIComponent(queryParams.get('websiteId') || '');
  const websiteName = decodeURIComponent(queryParams.get('websiteName') || '');
  const [data, setData] = useState<Array<TestScheduleModel>>();
  const [dataError, setDataError] = useState<{error: string}>();

  useEffect(() => {
    const f: any = {include: ['website']};
    if (websiteId && websiteId.length > 0) {
      f.where = {websiteId: parseInt(websiteId)};
    } else {
      f.where = {};
    }
    f.where.repeatFrequencyMs = {neq: -1};
    f.where.status = 'ACTIVE';
    getTestSchedules(f).then((result) => setData(result.data)).catch(e => setDataError({error: "Server Error! Cannot fetch data!"}));
  }, []);

  const isMobile = useSelector<RootState>(({auth}) => auth.isMobile, shallowEqual)
  const urlPrefix = getUrlPrefix(isMobile);

  const onDelete = (id: number) => {
    if (!window.confirm('Are you sure want to delete?')) {
      return;
    }
    deleteTestSchedule(id).then(() => {
      setData(data?.filter(x => x.id != id));
    });
  }

  if (dataError || data == null) {
    return (
      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          {dataError ? (
            <div>
              <br />
                <div className='mb-lg-15 alert alert-danger d-flex flex-column-fluid flex-center'>
                  <div className='alert-text font-weight-bold'>{dataError.error}</div>
                </div>
                </div>
          ) : (
            <div>
              <br />
              <span>Loading... </span> <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
           </div>
        )}
        </div>
      </div>
      );
  }

  return (
    <div className={`card`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h2 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>Manage Standard Tests {websiteId && websiteName ? `of ${websiteName}` : ''}</span>
        </h2>        
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-full-bordered table-row-gray-100 align-middle gs-0 gy-3'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bolder'>
              <th scope='col' className='min-w-150px'>Test Name</th>
                <th scope='col' className='min-w-150px'>Website</th>
                <th className='min-w-150px'>WCAG Version</th>
                <th className='min-w-150px'>WCAG Level</th>
                <th scope='col' className='min-w-140px'>Repeat Frequency</th>
                <th className='min-w-150px'>Actions</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
            {data && data.length > 0 ? data.map(row => ( 
                       <tr key={row.id}>
                       <td>
                         <Link title={`Test schedule id ${row.id} for ${row.name || row.baseUrl}`} to={urlPrefix + `/activities/testmgmt/testschedule/${row.id}`} className='text-primary-900 text-hover-info fs-6'>
                          {row.name}
                         </Link>
                       </td>
                       <td>
                         <Link title={`Test schedule id ${row.id} for ${row.name || row.baseUrl}`} to={urlPrefix + `/activities/testmgmt/testschedule/${row.id}`} className='text-primary-900 text-hover-info fs-6'>
                          {row.website?.name}
                         </Link>
                       </td>
                       <td>
                        <Link title={`Test schedule id ${row.id} for ${row.name || row.baseUrl}`} to={urlPrefix + `/activities/testmgmt/testschedule/${row.id}`} className='text-primary-900 text-hover-info d-block mb-1 fs-6'>
                          {row.testSettings.wcagLevel}
                        </Link>
                       </td>
                       <td>
                        <Link title={`Test schedule id ${row.id} for ${row.name || row.baseUrl}`} to={urlPrefix + `/activities/testmgmt/testschedule/${row.id}`} className='text-primary-900 text-hover-info d-block mb-1 fs-6'>
                          {row.testSettings.wcagVersion}
                        </Link>
                       </td>
                       <td>
                         <Link title={`Test schedule id ${row.id} for ${row.name || row.baseUrl}`} to={urlPrefix + `/activities/testmgmt/testschedule/${row.id}`} className='text-primary-900 text-hover-info d-block mb-1 fs-6'>
                           {row.repeatFrequencyMs == -1 || row.repeatFrequencyMs == null ? 'Once' : `Every ${Math.round(row.repeatFrequencyMs/(24 * 60 * 60000))} days`}
                         </Link>
                       </td>
                       <td>
                        <button
                          onClick={() => {
                            history.push(urlPrefix + `/activities/testmgmt/runtest?url=${row.baseUrl}&testMode=${row.testSettings.testMode}&wcagVersion=${row.testSettings.wcagVersion}&wcagLevel=${row.testSettings.wcagLevel}`);
                          }}
                          type='button'
                          className='btn btn-sm btn-light-primary me-3'
                        >
                          Run Now
                        </button>
                        <button
                          onClick={() => {
                            history.push(urlPrefix + `/activities/testmgmt/testschedule/${row.id}/edit`);
                          }}
                          type='button'
                          className='btn btn-sm btn-light-primary me-3'
                          data-kt-stepper-action='previous'
                        >
                          Edit
                        </button>  
                        <button
                          onClick={() => {
                            onDelete(row.id);
                          }}
                          type='button'
                          className='btn btn-sm btn-light-primary me-3'
                          data-kt-stepper-action='previous'
                        >
                          Delete
                        </button>
                       </td>
                     </tr>
                     )) : 'No data to display'}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export {ManageTestSchedules}
