import React from 'react'
import {PageLink, PageTitle} from '../../../_monic/layout/core'
import {Redirect, Route, Switch} from 'react-router-dom'
import {CreateOrganization} from './components/CreateOrganization'
import {ManageOrganization} from './components/ManageOrganization'
import {EditOrganization} from './components/EditOrganization'
import { WelcomePageTitle } from '../../pages/dashboard/DashboardWrapper'

const breadCrumbs: Array<PageLink> = [
  {
    title: 'Organization',
    path: '/core/org/manage',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const OrganizationManagementPage: React.FC = () => {
  return (
    <>
    <Switch>
        <Route path='/core/org/create'>
        {/* <PageTitle breadcrumbs={breadCrumbs}>Create Organization</PageTitle> */}
        <WelcomePageTitle />
          <CreateOrganization />
        </Route>
        <Route path='/core/org/manage'>
        {/* <PageTitle breadcrumbs={breadCrumbs}>Manage Organization</PageTitle> */}
        <WelcomePageTitle />
          <ManageOrganization className='mb-5 mb-xl-8' />
        </Route>
        <Route path='/core/org/edit/:id'>
        {/* <PageTitle breadcrumbs={breadCrumbs}>Edit Organization</PageTitle> */}
        <WelcomePageTitle />
          <EditOrganization/>
        </Route>
        <Redirect from='/core/org' exact={true} to='/core/org/manage' />
        <Redirect to='/core/org/manage' />
      </Switch>      
    </>
  )
}

export default OrganizationManagementPage
