/* eslint-disable jsx-a11y/anchor-is-valid */
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { Link } from 'react-router-dom'
import { TestRunModel } from '../../../../app/modules/auth/models/TestRunModel'
import { getTestRuns } from '../../../../app/modules/auth/redux/TestRunCRUD'
import { getScoreColor } from '../../../../app/modules/testmgmt/components/TestResult'
import { getUrlPrefix } from '../../../../app/modules/utils'
import { RootState } from '../../../../setup'

type Props = {
  className: string
  style?: any
}

const LastNRuns: React.FC<Props> = ({className, style}) => {
  const [data, setData] = useState<Array<TestRunModel>>([]);
  const [dataError, setDataError] = useState<{error: string}>();
  const [status, setStatus] = useState<string>('');

  const fetchData = () => {
    const filter = status.length > 0 ? {status} : {};
    getTestRuns(filter, 'created desc', 5).then((result) => setData(result.data)).catch(e => setDataError({error: "Server Error! Cannot fetch data!"}));
  }

  useEffect(() => {
    fetchData();
  }, [status]);

  const isMobile = useSelector<RootState>(({auth}) => auth.isMobile, shallowEqual)
  const urlPrefix = getUrlPrefix(isMobile);

  if (dataError || data == null) {
    return (
      <div className='card mb-5 mb-xl-10' style={style}>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          {dataError ? (
            <div>
              <br />
                <div className='mb-lg-15 alert alert-danger d-flex flex-column-fluid flex-center'>
                  <div className='alert-text font-weight-bold'>{dataError.error}</div>
                </div>
                </div>
          ) : (
            <div>
              <br />
              <span>Loading... </span> <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
           </div>
        )}
        </div>
      </div>
      );
  }

  return (
    <div className={`card ${className}`} style={style}>
      {/* begin::Header */}
      <div className='card-header border-0'>
        <h3 className='card-title fw-bolder text-dark'>Last 5 Runs</h3>
        <div className='card-toolbar'>
          <div className='d-flex align-items-center me-4'>
          <label htmlFor='s_status' className='form-label fw-bold'>Status:</label>

          <div>
            <select
              id='s_status'
              className='form-select form-select-sm w-200px mx-2'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              defaultValue={status}
              onChange={e => setStatus(e.target.value)}
            >
              <option value=''>ALL</option>
              <option value='COMPLETE'>COMPLETE</option>
              <option value='RUNNING'>RUNNING</option>
              <option value='PENDING'>PENDING</option>
              <option value='FAILURE'>FAILURE</option>
            </select>
          </div>
        </div>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body pt-2'>
        {data.length == 0 ? <span>No data found</span> : null}
        {data.map(run => {
          let statusClass = 'primary';
          if (run.status == 'COMPLETE') {
            statusClass = 'success'
          } else if (run.status == 'FAILURE') {
            statusClass = 'danger'
          }
          return (
            <div key={run.id} className='d-flex align-items-center mb-7'>
              <div className='me-5'>
                <Link title={`Test Run Id${run.id} for ${run.baseUrl}`} to={urlPrefix + `/activities/testmgmt/testresult/${run.id}`} className='fw-bolder text-primary-900 text-hover-info fs-6' style={{wordBreak: 'break-word'}}>
                {run.baseUrl}
                </Link>
                <span className='text-muted d-block fw-bold'>{run.wcagVersion}, {run.wcagLevel}, {_.startCase(run.testMode)}</span>
              </div>
              <span className={`badge badge-${statusClass} fw-bolder my-2`} style={{minWidth: run.status == 'PARTIALLY COMPLETE' ? 140 : 80}}>{run.status}</span>
              {run.score ? <span className={`badge badge-${getScoreColor(run.score)} fw-bolder mx-2`} style={{minWidth: 70}}>Score: {run.score}</span> : null}
            </div>
          );
        })}
      </div>
      {/* end::Body */}
    </div>
  )
}

export {LastNRuns}
