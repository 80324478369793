/* eslint-disable jsx-a11y/anchor-is-valid */
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { Link } from 'react-router-dom'
import { TestScheduleModel, Website } from '../../../../app/modules/auth/models/TestRunModel'
import { getTestSchedules, getWebsites } from '../../../../app/modules/auth/redux/TestRunCRUD'
import { getUrlPrefix } from '../../../../app/modules/utils'
import { RootState } from '../../../../setup'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'

type Props = {
  className: string
  showAddWebsite?: boolean
}

const MyWebsiteWidget: React.FC<Props> = ({className, showAddWebsite}) => {
  const [data, setData] = useState<Array<Website>>();
  const [dataError, setDataError] = useState<{error: string}>();
  const isMobile = useSelector<RootState>(({auth}) => auth.isMobile, shallowEqual)
  const urlPrefix = getUrlPrefix(isMobile);

  useEffect(() => {
    getWebsites().then((result) => setData(result.data)).catch(e => setDataError({error: "Server Error! Cannot fetch data!"}));
  }, []);

  if (dataError || data == null) {
    return (
      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          {dataError ? (
            <div>
              <br />
                <div className='mb-lg-15 alert alert-danger d-flex flex-column-fluid flex-center'>
                  <div className='alert-text font-weight-bold'>{dataError.error}</div>
                </div>
                </div>
          ) : (
            <div>
              <br />
              <span>Loading... </span> <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
           </div>
        )}
        </div>
      </div>
      );
  }

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>My Websites</span>
        </h3>
        {showAddWebsite ? (
        <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
        >
          <Link to={urlPrefix + '/core/website/manage'}
            className='btn btn-sm btn-primary'
          >
            View All Websites
          </Link>
        </div> ) : null}
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-full-bordered table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bolder'>
                <th scope="col" className='min-w-150px'>Name</th>
                <th scope="col" className='min-w-150px'>URL</th>
                <th scope="col" className='min-w-150px'>Last Run</th>
                <th scope="col">Configure Test</th>
                <th scope="col">Run Test</th>
                <th scope="col" className='min-w-120px'>Score</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {data.map(t => {
                return (
                  <tr key={'website-' + t.id}>
                    <td>
                      <Link title={t.name} to={urlPrefix + `/activities/testmgmt/manageresults/${t.id}/${t.name}`} className='text-primary-900 text-hover-info fs-6'>
                        {t.name}
                      </Link>
                    </td>
                    <td>
                        {t.url}
                    </td>
                    <td>
                        {t.latestTestTime && new Date(t.latestTestTime).toLocaleString('en-IN')}
                    </td>
                    <td>
                    <Link
                      to={urlPrefix + '/activities/testmgmt/manageschedules?websiteId=' + t.id + '&websiteName=' + t.name}
                      className='btn btn-sm btn-primary'
                    >
                      Configure
                    </Link>
                    </td>
                    <td>
                    <Link
                      to={urlPrefix + '/activities/testmgmt/runtest?url=' + t.url}
                      className='btn btn-sm btn-primary'
                    >
                      Run
                    </Link>
                    </td>
                    <td>
                        {t.latestScore}
                    </td>
                  </tr>
                );
              })}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export {MyWebsiteWidget}
