import { FC } from "react";
import { Settings } from "../../utils/config";
import { LandingFooter } from "./Footer";
import { BreadcrumbTitle, LandingHeader } from "./Header";

export const ContactPage: FC = () => {
    return (
    <div className="main-body-content">
        <LandingHeader />
        <div id="main-content">
            <BreadcrumbTitle>Contact</BreadcrumbTitle>

            <section className="content-area">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12">
                            <h2>
                            Contact Us
                            </h2>
                            {Settings.isGamyata ? (
                                <p>
                                    For any information regarding using {Settings.GamyataLabel} or partner with us, you may contact us through <b>admin[at]sumatak[dot]com</b>
                                </p>
                            ) : (
                                <>
                                <p>
                                    For any information regarding using {Settings.GamyataLabel} for Indian Government entities, you may contact <b>support-sugamya[at]nic[dot]in</b>
                                </p>
                                <p>
                                    For any information regarding a similar tool for other entities, you may contact Sumatak Technologies through <b>admin[at]sumatak[dot]com</b>
                                </p>
                                </>
                            )}
                            
                            
                        </div>
                    </div>
                </div>
            </section>
        </div>

        <LandingFooter />
    </div>
    );
};