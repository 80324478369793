import { Component, FC, useEffect, useState } from "react";
import { PublicStatsModel } from "../app/modules/auth/models/OrganizationModel";
import { getPublicStats } from "../app/modules/auth/redux/OrganizationCRUD";
import { HistoricalStats, HistoricalStatsChartData } from "../_monic/partials/widgets/mixed/HistoricalStats";
import { LineChart } from "../_monic/partials/widgets/mixed/LineChart";
import { MobileLayout } from "./MobileLayout";

export const MobileHomePage: FC = () => {
    const [top5ScoreData, setTop5ScoreData] = useState<HistoricalStatsChartData>();
    const [top5ImprovedData, setTop5ImprovedData] = useState<HistoricalStatsChartData>();
    const [scoreTrendMonthly, setScoreTrendMonthly] = useState<HistoricalStatsChartData>();
    const [data, setData] = useState<PublicStatsModel>();

    useEffect(() => {
        getPublicStats().then((result) => {
            setData(result.data);
            if (result.data) {
                const {top5Score, top5Improved, scoreTrendMonthly} = result.data;
                setTop5ScoreData({
                    xAxis: top5Score.map((x: any) => x.name),
                    yAxis: top5Score.map((x: any) => x.latestScore),
                    max: ``
                  });
                setTop5ImprovedData({
                    xAxis: top5Improved.map((x: any) => x.name),
                    yAxis: top5Improved.map((x: any) => x.improvementPct),
                    max: ``
                  });
                const res = scoreTrendMonthly.map((x: any) => ({label: x.month, value: Math.round(x.avgScore)}));
                setScoreTrendMonthly({
                    xAxis: res.map((x: any) => x.label),
                    yAxis: res.map((x: any) => x.value),
                    max: ''
                });
            }
            
        }).catch(e => e);
    }, []);

    return (
        <MobileLayout>

                <div className="splide single-slider slider-no-arrows slider-no-dots" id="single-slider-home">
                        <div className="splide__track">
                            <div className="splide__list">
                                <div className="splide__slide">
                                    <div className="card rounded-m shadow-l mx-3">
                                    <img className="img-fluid" src="/assets/images/Banner1-mobile.jpg" />
                                    </div>
                                </div>
                                <div className="splide__slide">
                                    <div className="card rounded-m shadow-l mx-3">
                                        <img className="img-fluid" src="/assets/images/Banner-mobile.jpg" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>   
                    

                <div className="content mt-0">
                    <div className="row mb-n3">
                        <div className="col-6 pe-2">
                            <div className="card card-style gradient-green shadow-bg shadow-bg-m mx-0 mb-3" data-card-height="130">
                                <div className="card-top p-3">
                                    <h2 className="color-white d-block pt-1">Departments</h2>
                                </div>
                                <div className="card-bottom p-3">
                                    <h1 className="color-white mb-2 text-right font-28">{data?.orgCount}</h1>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 ps-2">
                            <div className="card card-style gradient-red shadow-bg shadow-bg-m mx-0 mb-3" data-card-height="130">
                                <div className="card-top p-3">
                                    <h2 className="color-white d-block pt-1">Websites</h2>
                                </div>
                                <div className="card-bottom p-3">
                                    <h1 className="color-white mb-2 text-right font-28">{data?.websiteCount}</h1>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 pe-2">
                            <div className="card card-style gradient-yellow shadow-bg shadow-bg-m mx-0 mb-3" data-card-height="130">
                                <div className="card-top p-3">
                                    <h2 className="color-white d-block pt-1">Issues Resolved</h2>
                                </div>
                                <div className="card-bottom p-3">
                                    <h1 className="color-white mb-2 text-right font-28">{data?.issuesResolveCount}</h1>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 ps-2">
                            <div className="card card-style gradient-blue shadow-bg shadow-bg-m mx-0 mb-3" data-card-height="130">
                                <div className="card-top p-3">
                                    <h2 className="color-white d-block pt-1">Test Runs</h2>
                                </div>
                                <div className="card-bottom p-3">
                                    <h1 className="color-white mb-2 text-right font-28">{data?.testRunCount}</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card card-style">
                    <div className="content">
                        <h1 className="mb-3">Monthly Accessibility Trend</h1>
                        {scoreTrendMonthly ? <LineChart className='mb-5 mb-xl-8' chartHeight='250px' chartData={scoreTrendMonthly} hideBackground={true} title='' /> : null}
                    </div>
                </div>

                <div className="card card-style">
                    <div className="content">
                        <h1 className="mb-3">Top 5 Websites</h1>
                        {top5ScoreData ? <HistoricalStats className='mb-5 mb-xl-8' chartHeight='250px' chartData={top5ScoreData} title='' hideBackground={true} yAxisName='Score' /> : null}
                    </div>
                </div>

                <div className="card card-style">
                    <div className="content">
                        <h1 className="mb-3">Most Improved Accessibility</h1>
                        {top5ImprovedData ? <HistoricalStats className='mb-5 mb-xl-8' chartHeight='250px' chartData={top5ImprovedData} title='' hideBackground={true} yAxisName='Improved %' /> : null}
                    </div>
                </div>
      </MobileLayout>
    );
}
