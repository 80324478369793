import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { useSelector, shallowEqual } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { RootState } from '../../../setup';
import { decodeURISafe, getUrlPrefix } from '../../modules/utils';
import { getCategories, getFailuresList, getHistoricalDashboard, getNCListByAudit, getScreenshots, getTags, getTestInstanceWithNC, getTestInstancesForMediaInventory, getTestReportingDetails, getTestRun, patchNc, patchNcBulk } from '../../modules/auth/redux/TestRunCRUD';
import { ConsolidatedNC, ConsolidatedNCReport, FailuresCount, NonComplianceModel, TestInstanceModel, TestRunModel, WebsiteDashboard } from '../../modules/auth/models/TestRunModel';
import _ from 'lodash';
import * as Yup from 'yup';
import { AxiosResponse } from 'axios';
import { getDownloadXlsReportUrl } from '../../modules/auth/redux/AuthCRUD';
import { AUDIT_IMPACT_WEIGHTS, cleanString, compileAuditDetailsWithAllTests, defaultLineHighChartOptions, defaultLineHighChartOptionsSeries, getFilteredAudits, getProgress, getRemainingCnt, getScoreClass, getScoreHex, getStatusClass, installScreenshot, rgbToHex } from '../utils';
import { DOM } from '../../utils/renderer/dom';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import { ElementScreenshotRenderer } from '../../utils/renderer/element-screenshot-renderer';
import { useFormik } from 'formik';
import { getSetting } from '../../modules/auth/redux/SettingsCRUD';
import { DetailsPopup } from '../websites/DetailsPopup';
import { BreadcrumbData, Breadcrumbs, ErrorMsg, NcTd } from '../layout/MasterLayout';
import { criteriaNumbersByLevels } from '../utils';
import Pagination from '../layout/Pagination';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { viewWebpages } from '../../modules/auth/redux/OrganizationCRUD';
import { WebpagePagesModel } from '../../modules/auth/models/OrganizationModel';

const ImpactSpan: React.FC<{ impact: string }> = ({ impact }) => {
    let impactColor;
    let impactStyle = {};
    if (impact == 'critical') {
        impactColor = 'red-dot';
    } else if (impact == 'serious') {
        impactColor = 'green-dot';
    } else if (impact == 'moderate') {
        impactColor = 'blue-dot';
    } else if (impact == 'minor') {
        impactColor = null;
        impactStyle = { backgroundColor: 'grey' };
    } else {
        impactColor = null;
        impactStyle = { backgroundColor: 'grey' };
    }
    return <span className={impactColor || 'blue-dot'} style={impactStyle}></span>;
};

const fetchData = (id: number, attemptCount: number, setData: React.Dispatch<React.SetStateAction<TestRunModel | undefined>>, setDataError: React.Dispatch<React.SetStateAction<DataError | undefined>>, setFailuresCount: React.Dispatch<React.SetStateAction<FailuresCount[] | undefined>>, setAttemptCount: React.Dispatch<React.SetStateAction<number>>) =>
    getTestRun(id).then((result: AxiosResponse) => {
        setAttemptCount(0);
        setData(result.data);
        if (result.data && result.data.status == 'COMPLETE' || result.data.status == 'PARTIALLY COMPLETE') {
            getFailuresList(id).then((failuresList: AxiosResponse) => setFailuresCount(failuresList.data));
        }
        setDataError(undefined);
    }).catch(e => {
        if (attemptCount >= 2) {
            setDataError({ error: "Server Error! Cannot fetch data!" });
            setAttemptCount(0);
        } else {
            setAttemptCount(prevCount => prevCount + 1);
        }
    });

const TestOverview: React.FC<{ stats: any }> = ({ stats }) => {
    if (!stats) {
        return <div id="test_overview" className="tab-content">
            Test Overview not available
        </div>;
    }
    const { cntPages, cntTests, cntTotalIssues, cntTotalWarns, issueCntByPages, issueCntByTest, issueCntByCat } = stats;

    if (cntTotalIssues == 0) {
        return (
            <h2 className="tab-title">No issues found</h2>
        )
    }

    return (
        <div id="test_overview" className="tab-content">
            {cntTotalIssues > 0 && issueCntByTest.length > 0 ? (
                <>
                    <h2 className="tab-title">Most frequently seen issues</h2>
                    <ul className="tab-row">
                        {issueCntByTest.slice(0, 3).map((item: any, i: number) => (
                            <li key={'issueCntByTest-' + i}>
                                <div className="issue-count">#{i + 1} most seen issue</div>
                                <div className="url">{item.title}</div>
                                <strong className="tot-count red">{item.count}</strong>
                            </li>
                        ))}
                    </ul>
                </>
            ) : null}

            {cntTotalIssues > 0 && issueCntByPages.length > 0 ? (
                <>
                    <h2 className="tab-title">Pages with the highest issue count</h2>
                    <ul className="tab-row">
                        {issueCntByPages.slice(0, 3).map((item: any, i: number) => (
                            <li key={'issueCntByPages-' + i}>
                                <div className="issue-count">#{i + 1} page with highest issue count</div>
                                <div className="url">{decodeURISafe(item.title)}</div>
                                <strong className="tot-count red">{item.count}</strong>
                            </li>
                        ))}
                    </ul>
                </>
            ) : null}

            {cntTotalIssues > 0 && issueCntByCat.length > 0 ? (
                <>
                    <h2 className="tab-title">Categories with the highest issue count</h2>
                    <ul className="tab-row">
                        {issueCntByCat.slice(0, 3).map((item: any, i: number) => (
                            <li key={'issueCntByCat-' + i}>
                                <div className="issue-count">#{i + 1} category with highest issue count</div>
                                <div className="url">{decodeURISafe(item.category)}</div>
                                <strong className="tot-count red">{item.count}</strong>
                            </li>
                        ))}
                    </ul>
                </>
            ) : null}
        </div>
    );
}

const NcActions: React.FC<{ id: number, nonCompliance: NonComplianceModel, onNcChange: any }> = ({ id, nonCompliance, onNcChange }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [showStatus, setShowStatus] = useState<boolean>(false);
    const [showComments, setShowComments] = useState<boolean>(false);
    const [status, setStatus] = useState<string>(nonCompliance.status);
    const [comments, setComments] = useState<string | undefined>(nonCompliance.comments);
    const initialValues = { comments: comments || '', status };
    const formik = useFormik<any>({
        initialValues,
        validationSchema: Yup.object().shape({
            status: Yup.string().required('Status is required'),
            comments: showComments ? Yup.string().required('Note is required') : Yup.string().optional(),
        }),
        onSubmit: (values) => {
            setLoading(true);
            nonCompliance.comments = values.comments;
            if (nonCompliance.comments == null) {
                delete nonCompliance.comments;
            }
            nonCompliance.status = values.status;
            patchNc({ id: nonCompliance.id, comments: nonCompliance.comments, status: nonCompliance.status }).then(() => {
                setLoading(false);
                setComments(nonCompliance.comments);
                setStatus(nonCompliance.status);
                setShowComments(false);
                setShowStatus(false);
                onNcChange(nonCompliance);
            }).catch(() => {
                setLoading(false);
            });
        },
    });

    useEffect(() => {
        formik.resetForm();
    }, [showStatus, showComments]);

    if (nonCompliance == null) {
        return null;
    }

    if (loading) {
        return (
            <div className="view-note" key={id}>
                Loading...
            </div>
        )
    }

    return (
        <div className="view-note" key={id}>
            <form onSubmit={formik.handleSubmit} className='form' style={{ width: '100%' }}>
                <a href="#" onClick={() => setShowStatus(true)} className="show-error-note btn">CHANGE STATUS</a>
                <div className={classnames('error-notbox change-status', {
                    show: showStatus,
                })} style={{ minHeight: 170 }}>
                    <em className="fa fa-file-text orange" aria-hidden="true"></em> <strong>Change Status</strong>
                    <select
                        {...formik.getFieldProps('status')}
                    >
                        <option value='UNRESOLVED'>UNRESOLVED</option>
                        <option value='FIX IN PROGRESS'>FIX IN PROGRESS</option>
                        <option value='RESOLVED'>RESOLVED</option>
                    </select>
                    {formik.touched.status && formik.errors.status && (
                        <ErrorMsg>
                            <div className='fv-help-block'>{formik.errors.status}</div>
                        </ErrorMsg>
                    )}
                    <div className="alert-cancelsave">
                        <button className="btn pop-close" onClick={() => setShowStatus(false)}>Cancel</button>
                        <button className="btn btn-red">Set Status</button>
                    </div>
                    <a href="#" onClick={() => setShowStatus(false)} className="alert-close"><em className="fa fa-times" aria-hidden="true"></em></a>
                </div>
                <br />
                <a href="#" onClick={() => setShowComments(true)} className="btn show-error-note">ADD NOTES</a>
                <div className={classnames('error-notbox set-note', {
                    show: showComments,
                })} style={{ minHeight: 170 }}>

                    <em className="fa fa-file-text orange" aria-hidden="true"></em> <strong>Add Notes</strong>
                    <textarea rows={2} placeholder='Comments' {...formik.getFieldProps('comments')} />
                    {formik.touched.comments && formik.errors.comments && (
                        <ErrorMsg>
                            <div className='fv-help-block'>{formik.errors.comments}</div>
                        </ErrorMsg>
                    )}
                    <div className="alert-cancelsave">
                        <button className="btn pop-close" onClick={() => setShowComments(false)}>Cancel</button>
                        <button className="btn btn-red">Save</button>
                    </div>
                    <a href="#" onClick={() => setShowComments(false)} className="alert-close"><em className="fa fa-times" aria-hidden="true"></em></a>
                </div>
            </form>
        </div>
    );
}

const HistoricalDashboard: React.FC<{ testRunId: number }> = ({ testRunId }) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [pgNo, setPgNo] = useState<number>(1);
    const [data, setData] = useState<WebsiteDashboard>();
    const [dataError, setDataError] = useState<{ error: string }>();
    const limit = 10;
    useEffect(() => {
        setIsLoading(true);
        getHistoricalDashboard(testRunId, { limit: limit + 1, skip: (pgNo - 1) * limit }).then((result: AxiosResponse) => setData(result.data))
            .catch(e => setDataError({ error: "Server Error! Cannot fetch data!" }))
            .finally(() => setIsLoading(false));
    }, [testRunId, pgNo]);

    if (isLoading) {
        return (
            <div>
                <br />
                <span>Loading... </span> <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </div>
        );
    } else if (dataError || data == null) {
        return (
            <div className='card mb-5 mb-xl-10'>
                <div
                    className='card-header border-0'
                >
                    {dataError ? (
                        <div>
                            <br />
                            <div className='mb-lg-15 alert alert-danger d-flex flex-column-fluid flex-center'>
                                <div className='alert-text font-weight-bold'>{dataError.error}</div>
                            </div>
                        </div>
                    ) : (
                        <div>
                            <br />
                            <span>Loading... </span> <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </div>
                    )}
                </div>
            </div>
        );
    }

    return (
        <div id="historical_dashboard" className="tab-content">
            <h2>Historical Dashboard</h2>
            <div className='historical-db-control'>
                <div className="filter-by">
                    {/* <!-- <strong>Filter By: </strong>
                                                <select id="full-site" className="full-site">
                                                    <option value="1" selected>Full Site</option>
                                                    <option value="2">Site 2</option>
                                                    <option value="3">Site 3</option>
                                                </select>
                                                <select id="wcag">
                                                    <option value="1" selected>WCAG 2.1</option>
                                                    <option value="2">WCAG</option>
                                                    <option value="3">WCAG</option>
                                                </select>
                                                <select id="level">
                                                    <option value="1" selected>Level AAA</option>
                                                    <option value="2">Level AA</option>
                                                    <option value="3">Level A</option>
                                                </select> --> */}
                    <div className="row">
                        <div className="col-md-4">
                            <div className="graph-title">
                                <h3 className="h2">Score Trends</h3>
                                <p>Score trend over the period time</p>
                            </div>
                            <div className="trends-graph-box">
                                {data.trends.length >= 0 ? <>
                                    <div className="graph-title-inner text-center">
                                        Latest Score: <span className="score-circle score " style={{ background: getScoreHex(data.trends[0].score) }}>{data.trends[0].score}</span>
                                    </div>
                                    <div id="score_trends">
                                        <HighchartsReact
                                            highcharts={Highcharts}
                                            options={{
                                                ...defaultLineHighChartOptions,
                                                series: [Object.assign({}, defaultLineHighChartOptionsSeries, {
                                                    name: 'Score',
                                                    type: 'line',
                                                    data: data.trends.map(x => ({ y: x.score, name: moment(x.created).tz('Asia/Kolkata').format('MMMM Do YYYY') })),
                                                    colors: data.trends.map(x => getScoreHex(x.score)),
                                                })],
                                            }}
                                        />
                                    </div>
                                </> : null}
                            </div>
                        </div>


                        <div className="col-md-4">
                            <div className="graph-title">
                                <h3 className="h2">Issue Trends</h3>
                                <p>Issue trend over the period time</p>
                            </div>
                            <div className="trends-graph-box">
                                {data.trends.length >= 0 ? <>
                                    <div className="graph-title-inner text-center">
                                        Latest Issue Count: <span className="score-circle score ">{data.trends[0].cntTotalIssues}</span>
                                    </div>
                                    <div id="issue_trends">
                                        <HighchartsReact
                                            highcharts={Highcharts}
                                            options={{
                                                ...defaultLineHighChartOptions,
                                                series: [Object.assign({}, defaultLineHighChartOptionsSeries, {
                                                    name: 'Issues',
                                                    type: 'line',
                                                    data: data.trends.map(x => ({ y: x.cntTotalIssues, name: moment(x.created).tz('Asia/Kolkata').format('MMMM Do YYYY') })),
                                                    colors: ['#49a0f0', ...Array(data.trends.length).fill('#000')],
                                                })],
                                            }}
                                        />
                                    </div>
                                </> : null}
                            </div>
                        </div>


                        <div className="col-md-4">
                            <div className="graph-title">
                                <h3 className="h2">Test Failure Trends</h3>
                                <p>Test Failure trend over the period time</p>
                            </div>
                            <div className="trends-graph-box">
                                {data.trends.length >= 0 ? <>
                                    <div className="graph-title-inner text-center">
                                        Latest Failure Count: <span className="score-circle lastrun">{data.trends[0].failureCnt}</span>
                                    </div>
                                    <div id="testfailure_trends">
                                        <HighchartsReact
                                            highcharts={Highcharts}
                                            options={{
                                                ...defaultLineHighChartOptions,
                                                series: [Object.assign({}, defaultLineHighChartOptionsSeries, {
                                                    name: 'Failures',
                                                    type: 'line',
                                                    data: data.trends.map(x => ({ y: x.failureCnt, name: moment(x.created).tz('Asia/Kolkata').format('MMMM Do YYYY') })),
                                                    colors: ['#f05249', ...Array(data.trends.length).fill('#000')],
                                                })],
                                            }}
                                        />
                                    </div>
                                </> : null}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="compliance-issue">
                    <div className="issue-text">
                        <h2>Critical Non Compliant Elements</h2>
                        <small>Critical impact non compliant elements identified in this test</small>
                    </div>
                    {/* <div className="status-show">
                        <label htmlFor="status">Status:</label>
                        <select id="status">
                            <option value="1" selected>Unresolved</option>
                            <option value="2">Resolved</option>
                        </select>
                    </div> */}
                </div>
            </div>
            <div className="table-responsive">
                <table className="weblist-table historical-db-table">
                    <thead>
                        <tr>
                            <th scope="col" className="single-page">First Reported</th>
                            <th scope="col">Explanation</th>
                            <th scope="col">Snippet</th>
                            <th scope="col">Notes</th>
                            <th scope="col" className="action">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.ncList.slice(0, limit).map(x => {
                            const created = moment(x.created).tz('Asia/Kolkata');
                            return (
                                <tr>
                                    <NcTd className='resolved' ncStatus={x.status.toUpperCase()}>
                                        <span className="current-status">{x.status}</span>
                                        <strong>{created.format('DD MMM YYYY')}</strong><br />{created.format('h:mm:ss A')}
                                        <br />
                                        {x.pagesAffected > 1 ? `Affects ${x.pagesAffected} pages` : null}
                                    </NcTd>
                                    <td>{x.failingElement.explanation}</td>
                                    <td>
                                        <pre style={{ whiteSpace: 'pre-wrap' }}>
                                            <code style={{ padding: 0 }}>
                                                <p>{decodeURISafe(x.failingElement.snippet)}</p>
                                            </code>
                                        </pre>
                                    </td>
                                    <td>{x.comments}</td>
                                    <td className="view-box">
                                        <NcActions key={x.id} id={x.id} nonCompliance={x} onNcChange={(nonCompliance: NonComplianceModel) => {
                                            x.status = nonCompliance.status;
                                            x.comments = nonCompliance.comments;
                                            setData({ ...data });
                                        }} />
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                <nav aria-label="Page navigation" className="pagination-wrap">
                    {data ?
                        <Pagination
                            currentPage={pgNo}
                            totalCount={data.ncList.length}
                            pageSize={limit}
                            infinite={true}
                            onPageChange={(page: number) => setPgNo(page)}
                        /> : null}
                </nav>
            </div>
        </div>
    );
}

const ConsolidatedReport: React.FC<{
    failuresCount: FailuresCount[], testRunId: number,
    testReportingDetails: TestReportingDetails, tags: Array<string>,
    categories: Array<string>, totalPageCnt: number, hideAria: boolean
}> = ({ failuresCount, testRunId, totalPageCnt, testReportingDetails, tags, categories, hideAria }) => {
    const { testInstanceId: auditId }: any = useParams();
    const defaultSelectedFailuresCount = failuresCount.find(x => x.auditId == auditId);
    const [selectedFailuresCount, setSelectedFailuresCount] = useState<FailuresCount | undefined>(defaultSelectedFailuresCount);
    const [category, setCategory] = useState<string>("All");
    const [showMultiSelectCheckbox, setShowMultiSelectCheckbox] = useState<boolean>(false);
    const [wcagLevels, setWcagLevels] = useState<string[]>([]);
    const [wcagCriterion, setWcagCriterion] = useState<string>("All");
    const failuresCountFiltered = failuresCount.filter(fc => {
        const x = testReportingDetails[fc.auditId];
        if (!x) {
            // details not yet loaded
            return true;
        }

        let f = category == "All" || x.category == category;
        if (!f) {
            return f;
        }

        if (!x.tags) {
            return true;
        }

        if (wcagLevels.length > 0 && !wcagLevels.some(wcagLevel => x.tags.includes(wcagLevel))) {
            return false;
        }

        f = wcagCriterion == "All" || x.tags.includes(wcagCriterion);
        if (!f) {
            return f;
        }
        return true;
    }).sort((x, y) => AUDIT_IMPACT_WEIGHTS[testReportingDetails[y.auditId].impact] - AUDIT_IMPACT_WEIGHTS[testReportingDetails[x.auditId].impact]);

    const allWcagCombo = Array.from(tags).sort().filter(tag => tag.startsWith("wcag 2.0 level") || tag.startsWith("wcag 2.1 level"));
    const generateDropdownText = () => {
        if (wcagLevels.length === 0 || wcagLevels.length === allWcagCombo.length) {
            return 'All WCAG Levels';
        }

        const selectedLabels = wcagLevels.map((option) => option.toUpperCase());

        return selectedLabels.join(', ');
    };
    const categoriesFiltered = categories.filter(cat => !(hideAria && cat.includes('aria')));

    return (
        <div id="failures" className="tab-content failuers">
            <form action="" className="filter-issues-by">
                <label htmlFor="fail">Filter Issues By:</label>
                <div id="check_box" className="dropdown-check-list2" onClick={() => setShowMultiSelectCheckbox(!showMultiSelectCheckbox)}>
                    <a href="JavaScript:void(0)" className="anchor" style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                    }}>{generateDropdownText()}</a>
                    <ul id="itemscheck" className="itemss visible" style={{ display: showMultiSelectCheckbox ? 'block' : 'none' }}>
                        {allWcagCombo.map(tag => (
                            <li>
                                <input className="chekbx" type="checkbox" id={tag} checked={wcagLevels.includes(tag)} onChange={(e) => {
                                    if (wcagLevels.includes(tag)) {
                                        setWcagLevels(wcagLevels.filter(item => item !== tag));
                                    } else {
                                        setWcagLevels([...wcagLevels, tag]);
                                    }
                                }} />
                                <label className="level2" htmlFor={tag}> {tag.toUpperCase()} </label>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="select-issue">
                    <select className="wcag2"
                        aria-label='select categoryb'
                        onChange={e => setCategory(e.target.value)}>
                        <option value='All'>All Categories</option>
                        {Array.from(categoriesFiltered).sort().map(cat => <option key={cat} value={cat}>{_.startCase(cat)}</option>)}
                    </select>
                    <select className="single-page"
                        aria-label='select wcag criterion'
                        onChange={e => setWcagCriterion(e.target.value)}>
                        <option value='All'>All WCAG Criterion</option>
                        {Array.from(tags).filter(tag => {
                            if (!tag.startsWith('wcag criterion ')) {
                                return false;
                            }
                            if (wcagLevels.length > 0) {
                                return wcagLevels.some(wcagLevel => {
                                    const criteriaList = criteriaNumbersByLevels[wcagLevel];
                                    return criteriaList.indexOf(tag.split('wcag criterion ')[1]) >= 0;
                                });

                            }
                            return true;
                        }).sort().map(tag => (
                            tag.startsWith("wcag criterion") ?
                                <option key={tag} value={tag}>{tag.toUpperCase()}</option>
                                : ""
                        ))}
                    </select>
                </div>
            </form>
            <ul className="impact-denote">
                <li><strong>Impact:</strong></li>
                <li><span className="red-dot"></span> Critical</li>
                <li><span className="green-dot"></span> Serious</li>
                <li><span className="blue-dot"></span> Moderate</li>
                <li><span className="blue-dot" style={{ backgroundColor: 'grey' }}></span> Minor</li>
            </ul>
            {failuresCountFiltered.length == 0 ? <h2> No failures found </h2> : null}
            <ul className="tab-row full-report-list">
                {
                    failuresCountFiltered.map((x, i: number) => {
                        const details = testReportingDetails[x.auditId];
                        const tags = new Set(details.tags || []);
                        return (
                            <>
                                <li key={'issuecnt' + i}>
                                    <div className="fail-issue">
                                        <div className="fail-listitle">
                                            <ImpactSpan impact={details.impact} />
                                            <p><strong>{details.title}</strong></p>
                                            <small className="list-smallink">{details.category}</small>
                                            {Array.from(tags).map((tag, idx) => (
                                                <small key={'small-details-' + tag + idx} className="list-smallink">{tag}</small>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="affectedpage">
                                        <span className="red" style={{ marginRight: 10 }}>Pages Affected<strong className="h2"> {x.testInstanceIds.length}</strong></span>
                                        <span className="red">Instance Count<strong className="h2"> {x.instanceCount}</strong></span>
                                        <a href="#" onClick={() => setSelectedFailuresCount(x)} className="btn btn-red"><em className="fa fa-eye" aria-hidden="true"></em> View</a>
                                    </div>
                                </li>
                            </>
                        )
                    })
                }
            </ul>
            <div>
                {selectedFailuresCount ? <ConsolidatedAuditInfo auditId={selectedFailuresCount.auditId} testRunId={testRunId} totalPageCnt={totalPageCnt}
                    testReportingDetails={testReportingDetails} onCloseBtnClick={() => setSelectedFailuresCount(undefined)} selectedFailuresCount={selectedFailuresCount} /> : null}
            </div>
        </div>
    );
}

const Report: React.FC<{
    failuresCount: FailuresCount[], testRunId: number,
    testReportingDetails: TestReportingDetails, tags: Array<string>,
    categories: Array<string>, totalPageCnt: number, hideAria: boolean,
}> = ({ failuresCount, testRunId, totalPageCnt, testReportingDetails, tags, categories, hideAria }) => {
    const { testInstanceId: auditId }: any = useParams();
    const defaultSelectedFailuresCount = failuresCount.find(x => x.auditId == auditId);
    const [selectedFailuresCount, setSelectedFailuresCount] = useState<FailuresCount | undefined>(defaultSelectedFailuresCount);
    const [filterBy, setFilterBy] = useState<string>("Guidelines");
    const [category, setCategory] = useState<string>("All");
    const [showMultiSelectCheckbox, setShowMultiSelectCheckbox] = useState<boolean>(false);
    const [wcagLevels, setWcagLevels] = useState<string[]>([]);
    const [wcagCriterion, setWcagCriterion] = useState<string>("All");

    const failuresCountFiltered = failuresCount.filter(fc => {
        const x = testReportingDetails[fc.auditId];
        if (!x) {
            // details not yet loaded
            return true;
        }

        let f = category == "All" || x.category == category;
        if (!f) {
            return f;
        }

        if (!x.tags) {
            return true;
        }

        if (wcagLevels.length > 0 && !wcagLevels.some(wcagLevel => x.tags.includes(wcagLevel))) {
            return false;
        }

        f = wcagCriterion == "All" || x.tags.includes(wcagCriterion);
        if (!f) {
            return f;
        }
        return true;
    }).sort((x, y) => AUDIT_IMPACT_WEIGHTS[testReportingDetails[y.auditId].impact] - AUDIT_IMPACT_WEIGHTS[testReportingDetails[x.auditId].impact]);

    const allWcagCombo = Array.from(tags).sort().filter(tag => tag.startsWith("wcag 2.0 level") || tag.startsWith("wcag 2.1 level"));
    const generateDropdownText = () => {
        if (wcagLevels.length === 0 || wcagLevels.length === allWcagCombo.length) {
            return 'All WCAG Levels';
        }

        const selectedLabels = wcagLevels.map((option) => option.toUpperCase());

        return selectedLabels.join(', ');
    };

    const handleFilterByChange = (e: any) => {
        if (e.target.value == 'Guidelines') {
            setCategory('All');
        } else if (e.target.value == 'Category') {
            setWcagLevels([]);
            setWcagCriterion('All');
        }
        setFilterBy(e.target.value);
    };

    const wcagDropdownText = generateDropdownText();
    const categoriesFiltered = categories.filter(cat => !(hideAria && cat.includes('aria')));

    return (
        <>
            <div className="Filter-Content" id="Filter-Issue-Wise-NCs">
                <form action="" className="filter-issues-by">
                    <div className="mb-5 mt-5">
                        <img className="filter-icn" src="/assetsv2/images/filter.svg"
                            alt="filter icon" aria-hidden="true" />
                        <label id="filterby">Filter By:</label>
                    </div>
                    <div className="select-issue">
                        <div className="filtr-issue-by">
                            <input type="radio" id="GuidelinesBy" name="radio-group-filter" value="Guidelines"
                                checked={filterBy == 'Guidelines'} onChange={handleFilterByChange} />
                            <label htmlFor="GuidelinesBy">Guidelines</label>

                            <input type="radio" id="CategoryBy" name="radio-group-filter" value="Category"
                                checked={filterBy == 'Category'} onChange={handleFilterByChange} />
                            <label htmlFor="CategoryBy">Category</label>
                        </div>
                        {filterBy == 'Guidelines' ? <>
                            <div id="check_box" className="dropdown-check-list2 GuidelineShow" style={{ display: 'inline-block', verticalAlign: 'top' }} onClick={() => setShowMultiSelectCheckbox(!showMultiSelectCheckbox)}>
                                <a href="JavaScript:void(0)" className="anchor" style={{
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}>{wcagDropdownText}</a>
                                <ul id="itemscheck" className="itemss visible" style={{ display: showMultiSelectCheckbox ? 'block' : 'none' }}>
                                    {allWcagCombo.map(tag => (
                                        <li>
                                            <input className="chekbx" type="checkbox" id={tag} checked={wcagLevels.includes(tag)} onChange={(e) => {
                                                if (wcagLevels.includes(tag)) {
                                                    setWcagLevels(wcagLevels.filter(item => item !== tag));
                                                } else {
                                                    setWcagLevels([...wcagLevels, tag]);
                                                }
                                                setWcagCriterion('All');
                                            }} />
                                            <label className="level2" htmlFor={tag} style={{ width: 'auto' }}> {tag.toUpperCase()} </label>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <select className="single-page"
                                aria-label='select wcag criterion'
                                value={wcagCriterion}
                                onChange={e => setWcagCriterion(e.target.value)}>
                                <option value='All'>All WCAG Criterion</option>
                                {Array.from(tags).filter(tag => {
                                    if (!tag.startsWith('wcag criterion ')) {
                                        return false;
                                    }
                                    if (wcagLevels.length > 0) {
                                        return wcagLevels.some(wcagLevel => {
                                            const criteriaList = criteriaNumbersByLevels[wcagLevel];
                                            return criteriaList.indexOf(tag.split('wcag criterion ')[1]) >= 0;
                                        });

                                    }
                                    return true;
                                }).sort().map(tag => (
                                    tag.startsWith("wcag criterion") ?
                                        <option key={tag} value={tag}>{tag.toUpperCase()}</option>
                                        : ""
                                ))}
                            </select>
                        </> : null}
                        {filterBy == 'Category' ? <>
                            <select className="wcag2"
                                aria-label='select categoryc'
                                onChange={e => setCategory(e.target.value)}>
                                <option value='All'>All Categories</option>
                                {Array.from(categoriesFiltered).sort().map(cat => <option key={cat} value={cat}>{_.startCase(cat)}</option>)}
                            </select>
                        </> : null}
                    </div>
                </form>
                <div className="FiterTags">
                    <p>Issue Filtered By</p>
                    {filterBy == 'Guidelines' ? <>
                        {wcagDropdownText == 'All' ? <small className="list-smallink GuidelineShow">All WCAG Levels</small> :
                            wcagDropdownText.split(', ').map(wc => <small className="list-smallink GuidelineShow">{wc}</small>)}
                        <small className="list-smallink GuidelineShow">{wcagCriterion == 'All' ? 'All WCAG Criterion' : wcagCriterion.toUpperCase()}</small>
                    </> : null}
                    {filterBy == 'Category' ? <small className="list-smallink GuidelineShow">{category == 'All' ? 'All Categories' : category}</small> : null}
                </div>
                <hr />
                <ul className="impact-denote">
                    <li><strong>Impact:</strong></li>
                    <li><span className="red-dot"></span> Critical</li>
                    <li><span className="green-dot"></span> Serious</li>
                    <li><span className="blue-dot"></span> Moderate</li>
                    <li><span className="blue-dot" style={{ backgroundColor: 'grey' }}></span> Minor</li>
                </ul>
                {failuresCountFiltered.length == 0 ? <h2> No failures found </h2> : null}
                <ul className="tab-row full-report-list">
                    {
                        failuresCountFiltered.map((x, i: number) => {
                            const details = testReportingDetails[x.auditId];
                            const tags = new Set(details.tags || []);
                            return (
                                <>
                                    <li key={'issuecnt' + i}>
                                        <div className="fail-issue">
                                            <div className="fail-listitle">
                                                <ImpactSpan impact={details.impact} />
                                                <p><strong>{details.title}</strong></p>
                                                <small className="list-smallink">{details.category}</small>
                                                {Array.from(tags).map((tag, idx) => (
                                                    <small key={'small-details-' + tag + idx} className="list-smallink">{tag}</small>
                                                ))}
                                            </div>
                                        </div>
                                        <div className="affectedpage">
                                            Pages Affected<strong> {x.testInstanceIds.length}</strong> <br />
                                            Instance Count<strong> {x.instanceCount}</strong>
                                            <a href="#" onClick={() => setSelectedFailuresCount(x)} className="btn btn-red"><em className="fa fa-eye" aria-hidden="true"></em> View</a>
                                        </div>
                                    </li>
                                </>
                            )
                        })
                    }
                </ul>
            </div>
            <div>
                {selectedFailuresCount ? <ConsolidatedAuditInfo auditId={selectedFailuresCount.auditId} testRunId={testRunId} totalPageCnt={totalPageCnt}
                    testReportingDetails={testReportingDetails} onCloseBtnClick={() => setSelectedFailuresCount(undefined)} selectedFailuresCount={selectedFailuresCount} /> : null}
            </div>
        </>
    );
}

const Screenshot: React.FC<{ id: string, feId: string, screenshots: any, src: string }> = ({ id, feId, src, screenshots }) => {
    const [isInstalled, setIsInstalled] = useState(false);
    useEffect(() => {
        const divElement = document.getElementById(id);
        if (divElement && !isInstalled && screenshots) {
            installScreenshot(id, screenshots[src], 0, true);
            setIsInstalled(true);
        }
    }, []);
    if (!isInstalled) {
        return null;
    }

    let screenshot: any = null;
    let rect: any = null;
    const maxThumbnailSize = { width: 147, height: 100 };
    if (src == 'lh') {
        screenshot = screenshots?.lh?.screenshot;
        rect = screenshots?.lh?.nodes[feId];
    } else if (src == 'andi') {
        screenshot = screenshots?.andi?.screenshot;
        rect = screenshots?.andi?.nodes[feId];
    }

    const root: any = document.getElementById(id);
    const dom = new DOM(root?.ownerDocument);
    const screenshotElement = rect == null ? null : ElementScreenshotRenderer.render(dom, screenshot, rect, maxThumbnailSize);
    return (
        <>
            <div data-container-id={id} className='lh-node' ref={(nodeElement) => { nodeElement && (nodeElement.firstChild ? nodeElement.removeChild(nodeElement.firstChild) : true) && screenshotElement != null && nodeElement.appendChild(screenshotElement) }} />
        </>
    );
};

const ConsolidatedAuditInfo: React.FC<{
    auditId: string,
    testRunId: number,
    testReportingDetails: TestReportingDetails,
    onCloseBtnClick: any,
    selectedFailuresCount: FailuresCount,
    totalPageCnt: number,
}> = ({ testRunId, auditId, testReportingDetails, selectedFailuresCount, onCloseBtnClick, totalPageCnt }) => {
    const [data, setData] = useState<ConsolidatedNCReport>();
    const [screenshotsByTestInstances, setScreenshotsByTestInstances] = useState<any>();
    const [isLoading, setIsLoading] = useState(false);
    const [dataError, setDataError] = useState<{ error: string }>();
    const [staleSettingValue, setStaleSettingValue] = useState<string>();
    const [pgNo, setPgNo] = useState<number>(1);
    const limit = 10;
    useEffect(() => {
        setIsLoading(true);
        getNCListByAudit(testRunId, auditId, { limit: limit + 1, skip: (pgNo - 1) * limit }).then((result: AxiosResponse) => {
            setData(result.data);
            if (!result.data || !result.data.screenshotsByTestInstances) {
                return;
            }
            const tiIds: any[] = [];
            const promises = [];
            for (const tiId of Object.keys(result.data.screenshotsByTestInstances)) {
                const x = result.data.screenshotsByTestInstances[tiId];
                if (x && x.s3Key) {
                    promises.push(getScreenshots(x.s3Key));
                    tiIds.push(tiId);
                }
            }
            return Promise.all(promises).then(results => {
                let i = 0;
                const screenshotsByTestInstanceIds: any = {};
                for (const result of results) {
                    screenshotsByTestInstanceIds[tiIds[i]] = result.data;
                    i += 1;
                }
                setScreenshotsByTestInstances(screenshotsByTestInstanceIds);
            });
        }).catch(e => setDataError({ error: "Server Error! Cannot fetch data!" }))
            .finally(() => setIsLoading(false));

    }, [auditId, pgNo]);

    useEffect(() => {
        getSetting('staleNcAlertThresholdDays').then(setting => {
            setStaleSettingValue(setting?.value);
        });
    }, []);

    const details = testReportingDetails[auditId];
    let content;
    if (isLoading) {
        content = (
            <div>
                <br />
                <span>Loading... </span> <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </div>
        );
    } else if (dataError || data == null) {
        content = (
            <div className='card mb-5 mb-xl-10'>
                <div
                    className='card-header border-0'
                >
                    {dataError ? (
                        <div>
                            <br />
                            <div className='mb-lg-15 alert alert-danger d-flex flex-column-fluid flex-center'>
                                <div className='alert-text font-weight-bold'>{dataError.error}</div>
                            </div>
                        </div>
                    ) : (
                        <div>
                            <br />
                            <span>Loading... </span> <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </div>
                    )}
                </div>
            </div>
        );
    }
    else {
        content = data?.nonCompliances?.slice(0, limit).map((feDb, i) => {
            const fe = feDb.failingElement;
            let statusClass = 'un_resolved';
            if (feDb?.status == 'UNRESOLVED') {
                statusClass = 'un_resolved';
            } else if (feDb?.status == 'RESOLVED') {
                statusClass = 'text_Resolved';
            } else if (feDb?.status == 'FIX IN PROGRESS') {
                statusClass = 'fix_in_progress';
            }
            const age = feDb ? new Date().getTime() - new Date(feDb.created).getTime() : null;
            const stale = age && staleSettingValue ? age > (86400000 * parseInt(staleSettingValue)) : null;
            const created = feDb?.created ? moment(feDb.created).tz('Asia/Kolkata') : null;
            const isTemplateIssue = totalPageCnt >= 10 && feDb.pagesAffected > (totalPageCnt / 2);
            const idx = ((pgNo - 1) * limit) + i + 1;
            const testInstanceId = data.nonComplianceTestInstances[feDb.id];
            const screenshots = screenshotsByTestInstances && screenshotsByTestInstances[testInstanceId] ? screenshotsByTestInstances[testInstanceId] : data.screenshotsByTestInstances[testInstanceId];
            const id = 'fe-' + feDb.id;
            const screenshotContent = (
                <div id={id}>
                    <Screenshot key={'ss-' + feDb.id} id={id} src={details.src} feId={fe.id} screenshots={screenshots} />
                </div>
            )
            return (
                <div key={feDb?.id + ',' + fe.id} className="panel-default faq-sub">
                    <span style={{
                        position: 'absolute',
                        marginTop: 18,
                        fontSize: 18,
                        fontWeight: 600
                    }}>
                        {idx}.
                    </span>
                    <div className="panel-heading">
                        <h4 className="panel-title" style={{ marginLeft: 15 }}>
                            <a href='#' className="accordion-toggle" data-toggle="collapse"
                                onClick={(e) => {
                                    $(e.target).toggleClass("collapsed");
                                    $(e.target).parent().closest('div').next().toggleClass('in');
                                    e.preventDefault();
                                }}
                                aria-expanded="false">
                                <p className="issue-title"><strong>{fe.label}</strong></p>
                                <div style={{ width: 400 }}>
                                    <span className={statusClass} style={{ marginRight: 20 }}>Found in {feDb.pagesAffected} pages</span>
                                    <span className={statusClass}>{feDb.status}</span>
                                    <img src="/assetsv2/images/notification.png" style={{
                                        visibility: stale || isTemplateIssue ? 'visible' : 'hidden'
                                    }} />
                                </div>
                            </a>
                        </h4>
                        <p><em>{fe.explanation}</em></p>
                    </div>
                    <div id="collapseOne" className="panel-collapse collapse">
                        <div className="panel-body">
                            {created ? <p className="last_save" style={{ width: '100%' }}>First Seen: {created?.format('MMMM Do YYYY')}</p> : null}
                            {isTemplateIssue ? <p className="last_save" style={{ width: '100%' }}>This could be a template issue as it is seen in multiple pages</p> : null}
                            {fe.testData ? (
                                <>
                                    <h3><strong>Current Constrast Ratio <span className="span_red">{fe.testData.contrast.ratio}</span></strong></h3>
                                    <div className="col-lg-3 col-md-3 col-sm-6">
                                        <div className="contrast-ratio-widget first">
                                            <div className="box-black">
                                                {screenshotContent}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-3 col-md-3 col-sm-6">
                                            <div className="contrast-ratio-widget">
                                                <span className="suggest-thumb">Current<br /> <strong>Foreground</strong></span>
                                                <div className="h2">{rgbToHex(fe.testData.fgColor.rgba)}</div>
                                                <span className="span_box" style={{ backgroundColor: rgbToHex(fe.testData.fgColor.rgba) }}></span>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-6">
                                            <div className="contrast-ratio-widget">
                                                <span className="suggest-thumb">Current<br /> <strong>Background</strong></span>
                                                <div className="h2">{rgbToHex(fe.testData.bgColor.rgba)}</div>
                                                <span className="span_box" style={{ backgroundColor: rgbToHex(fe.testData.bgColor.rgba) }}></span>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-6">
                                            <div className="contrast-ratio-widget blue">
                                                <span className="suggest-thumb">Suggested<br /> <strong>Background</strong></span>
                                                <div className="h2 black"><strong>{rgbToHex(fe.testData.suggestedColor.rgba)}</strong> <img src="/assetsv2/images/thumb.png" aria-hidden="true" className="pull-right suggest-thumb" /></div>
                                                <span className="span_box" style={{ backgroundColor: rgbToHex(fe.testData.suggestedColor.rgba) }}></span>
                                            </div>
                                        </div>
                                    </div>
                                </>) : screenshotContent}

                            <div className="row">
                                <div className="col-md-12">
                                    <div className="boxgrey mrgn_set">
                                        <p>{decodeURISafe(fe.snippet)}</p>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            {feDb ?
                                <ChangeStatus nonCompliance={feDb} key={feDb.id + feDb.status + feDb.comments} onChangeNcStatus={(nonCompliance: NonComplianceModel) => {
                                    const newFilteredNcList = data.nonCompliances.map(x => {
                                        if (nonCompliance.id == x.id) {
                                            return {
                                                ...x,
                                                status: nonCompliance.status,
                                                comments: nonCompliance.comments,
                                            };
                                        }
                                        return x;
                                    });
                                    setData({ ...data, nonCompliances: newFilteredNcList });
                                }} /> : null}
                        </div>
                    </div>
                </div>
            )
        });
    }
    const tags = new Set(details.tags || []);

    return (
        <DetailsPopup masterClassName='test-runsfor reports show-details-pop' title={details.title} isActive={true} onCloseBtnClick={onCloseBtnClick} titleChildren={(
            <div className="color_lable">
                <span className="spa1" style={{ marginRight: 10, marginTop: 5 }}>{details.category}</span>
                {Array.from(tags).map((tag, idx) => (
                    <span key={'selectedDetail-' + tag + idx} className="spa1" style={{ marginRight: 10, marginTop: 5 }}>{tag}</span>
                ))}
            </div>
        )}>
            <div className="row">
                <div className="col-md-12">
                    <div className="boxgrey">
                        <div className="text_one">
                            <h3><span className="span22">{selectedFailuresCount.instanceCount}</span>non compliant instances found</h3>
                            <br />
                            <h3><span className="span22">{selectedFailuresCount.testInstanceIds.length}</span>pages affected</h3>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <div className="pera_text">
                        <h2>{details.title}</h2>
                        <ReactMarkdown linkTarget={'_blank'} children={details.description?.replaceAll('\t', ' ') || ''} />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="panel-group" id="accordion">
                        {content}
                        <nav aria-label="Page navigation" className="pagination-wrap">
                            {data ?
                                <Pagination
                                    currentPage={pgNo}
                                    totalCount={data.nonCompliances.length}
                                    pageSize={limit}
                                    infinite={true}
                                    onPageChange={(page: number) => setPgNo(page)}
                                /> : null}
                        </nav>
                    </div>
                </div>
            </div>
        </DetailsPopup>
    )
}

const FailuresList: React.FC<{
    failuresCount: FailuresCount[], testInstances?: TestInstanceModel[],
    testReportingDetails: TestReportingDetails, tags: Array<string>,
    categories: Array<string>
}> = ({ failuresCount, testInstances, testReportingDetails, tags, categories }) => {
    const [selectedFailuresCount, setSelectedFailuresCount] = useState<FailuresCount>();
    const [selectedPageId, setSelectedPageId] = useState<number>();
    const [selectedPageTitle, setSelectedPageTitle] = useState<string>('');
    const [defaultSelectedAuditId, setDefaultSelectedAuditId] = useState<string>();

    if (selectedFailuresCount) {
        testInstances = testInstances?.filter(i => selectedFailuresCount.testInstanceIds.includes(i.id + ''));
        return (
            <div key={selectedFailuresCount.auditId} id="full_report" className="tab-content">
                <a href='#' onClick={() => setSelectedFailuresCount(undefined)} className="goback" title="Back">
                    <em className="fa fa-chevron-left" aria-hidden="true"></em>
                </a>
                <PagesList testInstances={testInstances} setSelectedPageTitle={setSelectedPageTitle} setSelectedPageId={(x: any) => { setSelectedPageId(x); setDefaultSelectedAuditId(selectedFailuresCount.auditId); }} />
                <TestInstanceReport id={selectedPageId} selectedPageTitle={selectedPageTitle} setSelectedPageId={setSelectedPageId} testReportingDetails={testReportingDetails} tags={tags} categories={categories}
                    selectedTest={testReportingDetails[selectedFailuresCount.auditId].title} defaultSelectedAuditId={defaultSelectedAuditId} setDefaultSelectedAuditId={setDefaultSelectedAuditId} />
            </div>
        );
    }
    return (
        <div id="failures" className="tab-content failuers">
            <ul className="impact-denote">
                <li><strong>Impact:</strong></li>
                <li><span className="red-dot"></span> Critical</li>
                <li><span className="green-dot"></span> Serious</li>
                <li><span className="blue-dot"></span> Moderate</li>
                <li><span className="blue-dot" style={{ backgroundColor: 'grey' }}></span> Minor</li>
            </ul>
            {failuresCount.length == 0 ? <h2> No failures found </h2> : null}
            <ul className="tab-row full-report-list">
                {
                    failuresCount.map((x, i: number) => {
                        const details = testReportingDetails[x.auditId];
                        const tags = new Set(details.tags || []);
                        return (
                            <>
                                <li key={'issuecnt' + i}>
                                    <div className="fail-issue">
                                        <div className="fail-listitle">
                                            <ImpactSpan impact={details.impact} />
                                            <p><strong>{details.title}</strong></p>
                                            <small className="list-smallink">{details.category}</small>
                                            {Array.from(tags).map((tag, idx) => (
                                                <small key={'small-details-' + tag + idx} className="list-smallink">{tag}</small>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="affectedpage">
                                        <span className="red" style={{ marginRight: 10 }}>Pages Affected<strong className="h2"> {x.testInstanceIds.length}</strong></span>
                                        <span className="red">Instance Count<strong className="h2"> {x.instanceCount}</strong></span>
                                        <a href="#" onClick={() => setSelectedFailuresCount(x)} className="btn btn-red"><em className="fa fa-eye" aria-hidden="true"></em> View</a>
                                    </div>
                                </li>
                            </>
                        )
                    })
                }
            </ul>
        </div>
    );
}

const WarningsList: React.FC<{
    stats: any, testInstances?: TestInstanceModel[],
    testReportingDetails: TestReportingDetails, tags: Array<string>,
    categories: Array<string>
}> = ({ stats, testInstances, testReportingDetails, tags, categories }) => {
    const [selectedTest, setSelectedTest] = useState<string>();
    const [selectedPageId, setSelectedPageId] = useState<number>();
    const [selectedPageTitle, setSelectedPageTitle] = useState<string>('');
    const { warningCntByTest, warnedPagesByTest } = stats;
    if (!warningCntByTest || !warnedPagesByTest) {
        return null;
    }
    if (selectedPageId) {
        return (
            <div>
                <TestInstanceReport id={selectedPageId} selectedPageTitle={selectedPageTitle} setSelectedPageId={setSelectedPageId} testReportingDetails={testReportingDetails} tags={tags} categories={categories} selectedTest={selectedTest} />
            </div>
        )
    }
    if (selectedTest) {
        const instanceIds = warnedPagesByTest[selectedTest].map((x: any) => x.instanceId);
        testInstances = testInstances?.filter(i => instanceIds.includes(i.id));
        return (
            <div id="full_report" className="tab-content">
                <a href='#' onClick={() => setSelectedTest(undefined)} className="goback" title="Back">
                    <em className="fa fa-chevron-left" aria-hidden="true"></em>
                </a>
                <PagesList testInstances={testInstances} setSelectedPageTitle={setSelectedPageTitle} setSelectedPageId={setSelectedPageId} />
            </div>
        );
    }
    return (
        <div id="warnings" className="tab-content failuers">
            {warningCntByTest.length == 0 ? <h2> No warnings found </h2> : null}
            <ul className="tab-row">
                {
                    warningCntByTest.map((x: any, i: number) =>
                        <li key={'warningCnt-' + i}>
                            <div className="fail-issue"><a href="#">{x.title}</a></div>
                            <div className="affectedpage">
                                <span className="war-issue">Pages Affected<strong className="h2"> {warnedPagesByTest[x.title]?.length}</strong></span>
                                <a href="#" onClick={() => setSelectedTest(x.title)} className="btn btn-red"><em className="fa fa-eye" aria-hidden="true"></em> View</a>
                            </div>
                        </li>
                    )
                }
            </ul>
        </div>
    );
}

const mediaInventoryObjTypes = ['imageLink', 'imageButton', 'decorative', 'background', 'fontIcon', 'inline', 'videos', 'audios', 'pdfs', 'fonts', 'others'];

const MediaInventory: React.FC<{
    testInstances?: TestInstanceModel[]
}> = ({ testInstances }) => {
    const [selectedPageId, setSelectedPageId] = useState<number>();
    const [selectedPageTitle, setSelectedPageTitle] = useState<string>('');
    const [selectedObjType, setSelectedObjType] = useState<string>(mediaInventoryObjTypes[0]);
    const [data, setData] = useState<TestInstanceModel[]>();
    const [dataError, setDataError] = useState<DataError>();
    useEffect(() => {
        if (testInstances == null) {
            return;
        }
        getTestInstancesForMediaInventory(testInstances.map(i => i.id)).then((result: AxiosResponse) => setData(result.data)).catch(e => setDataError({ error: "Server Error! Cannot fetch data!" }));
    }, [testInstances]);

    let popup = null;
    if (selectedPageId && data) {
        const page = data.find(x => x.id == selectedPageId);
        const items = page?.mediaInventory?.images.snippets;
        popup = items == null ? null : (
            <div>
                <DetailsPopup masterClassName='media-inventory-comulative' title={"Media Inventory for " + selectedPageTitle} isActive={true} onCloseBtnClick={() => setSelectedPageId(undefined)}>
                    <div className="row">
                        <div className="imageLinkBtnSec">
                            {mediaInventoryObjTypes.sort((a, b) => items[b]?.length - items[a]?.length)
                                .map(x => <a href="#" onClick={() => setSelectedObjType(x)} className={classnames('btn', {
                                    selected: selectedObjType == x
                                })}>{items[x]?.length} <span>{x.toUpperCase()}</span></a>)}
                        </div>
                    </div>
                    <hr />
                    {selectedObjType ? <>
                        <div className="row">
                            <div className="col-md-12">
                                <h2>{selectedObjType.toUpperCase()}</h2>
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-md-12">
                                <div className="LinkBoxCount">
                                    {items[selectedObjType].length == 0 ? 'No Items to display' : null}
                                    {items[selectedObjType].map((snippet: any) => (
                                        <div className="LinkBox-bg mrgn_set">
                                            <div>
                                                <span id="textToCopy">
                                                    {decodeURISafe(snippet)}
                                                </span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </> : null}
                </DetailsPopup>
            </div>
        )
    }
    return (
        <>
            <PagesList testInstances={testInstances} showIssueCount={true} setSelectedPageTitle={setSelectedPageTitle} setSelectedPageId={setSelectedPageId} />
            {popup}
        </>
    );
}

const PagesList: React.FC<{ testInstances?: TestInstanceModel[], setSelectedPageTitle: any, setSelectedPageId: any, showIssueCount?: boolean }> = ({ testInstances, showIssueCount, setSelectedPageTitle, setSelectedPageId }) => {
    return (
        <div id="Filter-page-Wise-NCs" className="tab-content failuers" style={{ display: 'block' }}>
            <h2 className="tab-title">Pages</h2>
            <ul className="tab-row">
                {
                    testInstances?.map(testInstance =>
                        <li key={'pagesList-' + testInstance.id}>
                            <div className="fail-issue">{decodeURISafe(testInstance.url)}</div>
                            <div className="affectedpage">
                                <span className="test-status">Test Status</span> <span className={`status-Media ${getStatusClass(testInstance.status)}`}>{testInstance.status}</span>
                                {testInstance.stats ? (<>
                                    <span>Score <strong> {testInstance.stats.score}</strong></span>
                                    {showIssueCount ? <span>Issues Found <strong> {testInstance.stats.cntTotalIssues}</strong></span> : null}
                                </>) : null}
                                <a href="#" data-target="#details-popup" onClick={() => { setSelectedPageId(testInstance.id); setSelectedPageTitle(decodeURISafe(testInstance.url)) }} className="btn btn-red"><em className="fa fa-eye" aria-hidden="true"></em> View</a>
                            </div>
                        </li>
                    )
                }
            </ul>
        </div>
    );
}

const BrokenLinksReport: React.FC<{
    websiteId: number
}> = ({ websiteId }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<WebpagePagesModel>();
    const [dataError, setDataError] = useState<DataError>();
    useEffect(() => {
        setLoading(true);
        viewWebpages(websiteId).then((r) => setData(r.data)).catch(e => setDataError({ error: "Server Error! Cannot fetch data!" })).finally(() => setLoading(false));
    }, [websiteId]);

    if (loading) {
        return <div>{"Loading..."}</div>;
    }

    if (dataError) {
        return <div>{dataError ? dataError : "Loading..."}</div>;
    }

    const brokenPages = data?.pages.filter(page => page.status == 'PAGE_LOAD_FAILURE');

    if (!brokenPages || brokenPages.length == 0) {
        return <div>{'No broken links found'}</div>;
    }

    const pageUrlById = data?.pages.reduce((map: any, obj) => (map[obj.id] = obj.url, map), {});

    return (
        <div id="broken_links" className="tab-content broken-links" style={{display: "block"}}>                           
        <div className="active-page-box text-right">
            <div className="active-page-counts">
                <div className="h2">{data?.pages.length}</div>
                <span className="active-page-text">Total Pages</span>
            </div>
            <div className="active-page-counts">
                <div className="h2 red">{brokenPages.length}</div>
                <span className="active-page-text">Broken Links</span>
            </div>
        </div>
        <div className='project-list'>
            Note: The following links may have resulted in page load failure.
        </div>
        <table className="weblist-table broken-links-table">
                <thead>
                    <tr>
                        <th scope="col">Broken Link</th>
                        <th scope="col">Found in page</th>
                        <th scope="col">Reason</th>
                    </tr>
                </thead>
                <tbody>
                    {brokenPages.map(page => {
                        const sourcePageUrl = page.sourcePageId && pageUrlById[page.sourcePageId] ? pageUrlById[page.sourcePageId] : '';
                        let status = '';
                        if (page.status == 'PAGE_LOAD_FAILURE') {
                        status = 'Page load failure';
                        } else if (page.status == 'REDIRECT_WITHIN_WEBSITE') {
                        status = 'Duplicate page';
                        } else {
                        status = 'INACTIVE';
                        }
                        return (
                            <tr>
                                <td>{page.url}</td>
                                <td>{sourcePageUrl}</td>
                                <td>{status}</td>
                            </tr>
                        )
                    }
                    )}
                </tbody>
            </table>
            </div>
    );
}


const FullReport: React.FC<{
    testInstances?: TestInstanceModel[], testReportingDetails: TestReportingDetails, tags: Array<string>,
    categories: Array<string>, hideAria: boolean
}> = ({ testInstances, testReportingDetails, tags, categories, hideAria }) => {
    const { testInstanceId }: any = useParams();
    const [selectedPageId, setSelectedPageId] = useState<number>();
    const [selectedPageTitle, setSelectedPageTitle] = useState<string>('');
    const instanceId = parseInt(testInstanceId || '');
    let pageId = !Number.isNaN(instanceId) ? instanceId : selectedPageId;
    if (pageId) {
        return (
            <div>
                <TestInstanceReport id={pageId} selectedPageTitle={selectedPageTitle} setSelectedPageId={setSelectedPageId} testReportingDetails={testReportingDetails} tags={tags} categories={categories} hideAria={hideAria} />
            </div>
        )
    }
    return <PagesList testInstances={testInstances} showIssueCount={true} setSelectedPageTitle={setSelectedPageTitle} setSelectedPageId={setSelectedPageId} />
}

const TestInstanceReport: React.FC<{
    id?: number, selectedPageTitle: string, setSelectedPageId: any, testReportingDetails: TestReportingDetails, selectedTest?: string, tags: Array<string>,
    categories: Array<string>, defaultSelectedAuditId?: string, setDefaultSelectedAuditId?: any, hideAria: boolean
}> = ({ id, selectedPageTitle, setSelectedPageId, testReportingDetails, selectedTest, tags, categories, defaultSelectedAuditId, setDefaultSelectedAuditId, hideAria }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState<TestInstanceModel>();
    const [selectedAudit, setSelectedAudit] = useState<AuditDetails>();
    const [dataError, setDataError] = useState<DataError>();
    const [status, setStatus] = useState<string>("Fail");
    const [category, setCategory] = useState<string>("All");
    const [wcagLevel, setWcagLevel] = useState<string>("All");
    const [wcagCriterion, setWcagCriterion] = useState<string>("All");
    const [staleSettingValue, setStaleSettingValue] = useState<string>();
    const [screenshots, setScreenshots] = useState<any>();
    const { auditId }: any = useParams();
    const auditDetails = page?.auditDetails == null ? [] : compileAuditDetailsWithAllTests(page?.auditDetails);
    const auditsFiltered = getFilteredAudits(testReportingDetails, auditDetails, status, category, wcagLevel, wcagCriterion, selectedTest)
        .sort((x, y) => AUDIT_IMPACT_WEIGHTS[testReportingDetails[y.id].impact] - AUDIT_IMPACT_WEIGHTS[testReportingDetails[x.id].impact]);

    const categoriesFiltered = categories.filter(cat => !(hideAria && cat.includes('aria')));
    useEffect(() => {
        const aid = auditId || defaultSelectedAuditId;
        if (aid && aid.length > 0) {
            const sa = auditDetails.filter(a => {
                return a.id == aid
            });
            if (sa.length > 0) {
                setSelectedAudit(sa[0]);
            }
        }
    }, [auditId, defaultSelectedAuditId, auditDetails]);

    useEffect(() => {
        if (!id) {
            return;
        }
        setIsLoading(true);
        getTestInstanceWithNC(id).then((result: AxiosResponse) => setPage(result.data))
            .catch(e => setDataError({ error: "Server Error! Cannot fetch data!" }))
            .finally(() => setIsLoading(false));
        getSetting('staleNcAlertThresholdDays').then(setting => {
            setStaleSettingValue(setting?.value);
        });
    }, [id]);

    useEffect(() => {
        if (!page) {
            return;
        }
        if (page.screenshots && page.screenshots.s3Key) {
            getScreenshots(page.screenshots.s3Key).then((result: AxiosResponse) => setScreenshots(result.data));
        } else {
            setScreenshots(page.screenshots)
        }
    }, [page]);

    if (!id) {
        return null;
    }

    if (dataError) {
        return <div>{dataError}</div>;
    }

    if (isLoading) {
        return (
            <div>
                <br />
                <strong>
                    <span>Loading... Please wait... </span> <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </strong>
            </div>
        );
    }

    const ncList = page?.nonCompliances || [];
    let screenshotDetails = null;
    let andiScreenshotDetails = null;
    if (screenshots != null && (page?.status == "COMPLETE" || page?.status == "PARTIALLY COMPLETE")) {
        screenshotDetails = screenshots.lh;
        andiScreenshotDetails = screenshots.andi;
    }

    if (defaultSelectedAuditId) {
        return (
            <div id='failing-elements'>
                {selectedAudit ? <AuditInfo selectedAudit={selectedAudit} setSelectedAudit={(e: any) => { setSelectedAudit(e); setDefaultSelectedAuditId(); }} selectedDetail={testReportingDetails[selectedAudit.id]} ncList={ncList} screenshotDetails={screenshotDetails}
                    andiScreenshotDetails={andiScreenshotDetails} staleSettingValue={staleSettingValue} /> : null}
            </div>
        );
    } else if (setDefaultSelectedAuditId) {
        return (
            <div id='failing-elements'>
            </div>
        );
    }

    return (
        <div id="full_report" className="tab-content">
            <div className="LinkbackBox">
                <ol className="backLinkd">
                    <li><a href="#" onClick={() => setSelectedPageId()}> <i className="fa fa-angle-left" /></a></li>
                    <li><a href="#" onClick={() => setSelectedPageId()}>Pages List</a></li>
                    <li className="active">{selectedPageTitle}</li>
                </ol>
                <h2 className="pagewiseSitemapLink h2">{selectedPageTitle}</h2>
            </div>
            <div className='Filter-Content'>
                <form action="" className="filter-issues-by">
                    <div className="mb-5 mt-5">
                        <img className="filter-icn" src="/assetsv2/images/filter.svg"
                            alt="filter icon" aria-hidden="true" />
                        <label id="filterby">Filter By:</label>
                    </div>
                    <div className="select-issue">
                        <select className="partially-comp"
                            aria-label='select status'
                            value={status}
                            onChange={e => setStatus(e.target.value)}>
                            <option value='All'>All Statuses</option>
                            <option value='Fail'>Fail</option>
                            <option value='Pass'>Pass</option>
                            <option value='Warn'>Warn</option>
                        </select>
                        <select className="wcag-level"
                            aria-label='select WCAG level'
                            onChange={e => setWcagLevel(e.target.value)}>
                            <option value='All'>All WCAG Levels</option>
                            {Array.from(tags).sort().map(tag => (
                                tag.startsWith("wcag 2.0 level") || tag.startsWith("wcag 2.1 level") ?
                                    <option key={tag} value={tag}>{tag}</option>
                                    : ""
                            ))}
                        </select>
                        <select className="wcag2"
                            aria-label='select categorya'
                            onChange={e => setCategory(e.target.value)}>
                            <option value='All'>All Categories</option>
                            {Array.from(categoriesFiltered).sort().map(cat => <option key={cat} value={cat}>{_.startCase(cat)}</option>)}
                        </select>
                        <select className="single-page"
                            aria-label='select wcag criterion'
                            onChange={e => setWcagCriterion(e.target.value)}>
                            <option value='All'>All WCAG Criterion</option>
                            {Array.from(tags).sort().map(tag => (
                                tag.startsWith("wcag criterion") ?
                                    <option key={tag} value={tag}>{tag}</option>
                                    : ""
                            ))}
                        </select>
                    </div>
                </form>
                <hr />
                <ul className="impact-denote">
                    <li><strong>Impact:</strong></li>
                    <li><span className="red-dot"></span> Critical</li>
                    <li><span className="green-dot"></span> Serious</li>
                    <li><span className="blue-dot"></span> Moderate</li>
                    <li><span className="blue-dot" style={{ backgroundColor: 'grey' }}></span> Minor</li>
                </ul>
                <ul className="tab-row full-report-list">
                    {auditsFiltered.length == 0 ? <div><b>Nothing to display</b></div> : null}
                    {auditsFiltered.map(audit => {
                        const details = testReportingDetails[audit.id];
                        const tags = new Set(details.tags || []);
                        return (
                            <li key={'audit - ' + audit.id}>
                                <div className="fail-issue">
                                    <div className="fail-listitle">
                                        <ImpactSpan impact={details.impact} />
                                        <p><strong>{details.title}</strong></p>
                                        <small className="list-smallink">{details.category}</small>
                                        {Array.from(tags).map((tag, idx) => (
                                            <small key={'small-details-' + tag + idx} className="list-smallink">{tag}</small>
                                        ))}
                                    </div>
                                </div>
                                <div className="affectedpage">
                                    <span className="status" style={{ marginRight: 10 }}>{audit.status}</span>
                                    {audit.failingElements.length > 0 ? (
                                        <span className="war-issue">Issues <strong className="h2">{audit.failingElements.length}</strong></span>
                                    ) : null}
                                    <a href="#" onClick={() => {
                                        setSelectedAudit(audit)
                                    }} className="btn show-error-details"><em className="fa fa-eye" aria-hidden="true"></em> View</a>
                                </div>
                            </li>
                        );
                    })}
                </ul>
            </div>
            <div id='failing-elements'>
                {selectedAudit ? <AuditInfo selectedAudit={selectedAudit} setSelectedAudit={setSelectedAudit} selectedDetail={testReportingDetails[selectedAudit.id]} ncList={ncList} screenshotDetails={screenshotDetails}
                    andiScreenshotDetails={andiScreenshotDetails} staleSettingValue={staleSettingValue} /> : null}
            </div>
        </div>
    );
}

const ChangeStatus: React.FC<{ nonCompliance: NonComplianceModel | ConsolidatedNC, nonComplianceIds?: number[], auditId?: string, onChangeNcStatus?: any }> = ({ nonCompliance, nonComplianceIds, auditId, onChangeNcStatus }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [status, setStatus] = useState<string>(nonCompliance?.status);
    const [comments, setComments] = useState<string | undefined>(nonCompliance?.comments);
    const [showActions, setShowActions] = useState<boolean>(false);
    const initialValues = { status, comments: comments || '' };
    const formik = useFormik<any>({
        initialValues,
        validationSchema: Yup.object().shape({
            status: Yup.string().required('Status is required'),
            comments: Yup.string().required('Note is required'),
        }),
        onSubmit: (values) => {
            setLoading(true);
            nonCompliance.status = values.status
            nonCompliance.comments = values.comments;
            let promise = null;
            if (nonComplianceIds && auditId && nonComplianceIds.length > 0) {
                promise = patchNcBulk({ status: values.status, comments: values.comments }, nonCompliance.websiteId, auditId, nonComplianceIds);
            } else {
                promise = patchNc({ id: nonCompliance.id, status: values.status, comments: values.comments });
            }
            promise.then(() => {
                setLoading(false);
                setStatus(values.status);
                setComments(values.comments);
                if (onChangeNcStatus) {
                    if (nonComplianceIds) {
                        onChangeNcStatus(nonComplianceIds, values.status, values.comments);
                    } else {
                        onChangeNcStatus(nonCompliance);
                    }
                }
                setShowActions(false);
            }).catch((e) => {
                console.error(e);
                setLoading(false);
            });
        },
    })

    return (
        <>
            {showActions ? null :
                <button type="button" className="save_btn btn btn-red changestatusbtn" onClick={(e) => {
                    setShowActions(true);
                }}>Manage Issue Status</button>
            }
            <br />

            {showActions ?
                <div className="statushide">
                    <form onSubmit={formik.handleSubmit} className="row">
                        <div className="col-md-12">
                            <div className="col-md-2 text-right">
                                <label className="lable_set" htmlFor="changestatus">Change Status:</label>
                            </div>
                            <div className="col-md-5">
                                <div className="status_text">
                                    <select className="Resolved_boxs" {...formik.getFieldProps('status')}>
                                        <option value='UNRESOLVED'>UNRESOLVED</option>
                                        <option value='FIX IN PROGRESS'>FIX IN PROGRESS</option>
                                        <option value='RESOLVED'>RESOLVED</option>
                                    </select>
                                    {formik.touched.status && formik.errors.status && (
                                        <ErrorMsg>
                                            <div className='fv-help-block'>{formik.errors.status}</div>
                                        </ErrorMsg>
                                    )}
                                </div>
                            </div>
                            <div className="col-md-5"></div>
                        </div>
                        <div className="col-md-12">
                            <div className="col-md-2 text-right">
                                <label className="lable_set" htmlFor="comment">Comment:</label>
                            </div>
                            <div className="col-md-5">
                                <div className="status_text">
                                    <input
                                        type='text'
                                        className='form_box'
                                        placeholder='Comments'
                                        {...formik.getFieldProps('comments')}
                                    />
                                    {formik.touched.comments && formik.errors.comments && (
                                        <ErrorMsg>
                                            <div className='fv-help-block'>{formik.errors.comments}</div>
                                        </ErrorMsg>
                                    )}
                                </div>
                                <button type="button" className="cancel_btn cancel_btn_show btn" onClick={(e) => {
                                    setShowActions(false);
                                }}>Cancel</button>
                                <button type="submit" disabled={loading} className="save_btn btn btn-red" id="saveStatus">
                                    {loading ? (
                                        <span className='indicator-progress' style={{ display: 'block' }}>
                                            Please wait...{' '}
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        </span>
                                    ) : 'Save Status'}
                                </button>
                            </div>
                            <div className="col-md-5"></div>
                        </div>
                    </form>
                </div>
                : null}
        </>
    );
};


const AuditInfo: React.FC<{
    selectedAudit: AuditDetails, ncList: NonComplianceModel[], selectedDetail: TestReportingDetail,
    screenshotDetails: any, andiScreenshotDetails: any, setSelectedAudit: any, staleSettingValue?: string
}> = ({ selectedAudit, ncList, screenshotDetails, andiScreenshotDetails, selectedDetail, setSelectedAudit, staleSettingValue }) => {
    const [filteredNcList, setFilteredNcList] = useState<NonComplianceModel[]>([]);
    const history = useHistory();
    const ncListByFeHash = ncList.reduce((map: any, obj) => (map[obj.feHash] = obj, map), {});
    useEffect(() => {
        const newFilteredNcList = selectedAudit ? selectedAudit.failingElements.map(failingElement => {
            return {
                ...ncListByFeHash[failingElement.feHash],
                failingElement,
                hasDb: ncListByFeHash[failingElement.feHash] != null
            }
        }).filter(x => x != null) : [];
        setFilteredNcList(newFilteredNcList);
    }, [selectedAudit]);

    const [isInstalled, setIsInstalled] = useState(false);
    useEffect(() => {
        const divElement = document.getElementById('failing-elements');
        if (divElement && !isInstalled) {
            if (selectedAudit.src == "lh") {
                installScreenshot('failing-elements', screenshotDetails, 0);
            } else if (selectedAudit.src == "andi") {
                installScreenshot('failing-elements', andiScreenshotDetails, 0);
            }
            setIsInstalled(true);
        }
    }, []);

    if (!isInstalled) {
        return null;
    }

    const maxThumbnailSize = { width: 147, height: 100 };
    const root: any = document.getElementById('failing-elements');
    const dom = new DOM(root?.ownerDocument);
    const hasDb = filteredNcList.every(nc => nc.hasDb);
    const tags = new Set(selectedDetail.tags || []);
    return (
        <DetailsPopup masterClassName='test-runsfor reports show-details-pop' title={selectedDetail.title} isActive={selectedAudit != null} onCloseBtnClick={() => {
            setSelectedAudit();
            history.replace({
                search: '',
            })
        }} titleChildren={(
            <div className="color_lable">
                <span className="spa1" style={{ marginRight: 10, marginTop: 5 }}>{selectedDetail.category}</span>
                {Array.from(tags).map((tag, idx) => (
                    <span key={'selectedDetail-' + tag + idx} className="spa1" style={{ marginRight: 10, marginTop: 5 }}>{tag}</span>
                ))}
                <h3>Status: {_.startCase(selectedAudit.status)}</h3>
            </div>
        )}>
            <div className="row">
                <div className="col-md-12">
                    <div className="boxgrey">
                        <div className="text_one">
                            <h3><span className="span22">{filteredNcList.length}</span>non compliant elements found</h3>
                        </div>
                        {hasDb && filteredNcList.length > 1 ?
                            <div className="text_two">
                                <ChangeStatus nonCompliance={filteredNcList[0]} nonComplianceIds={filteredNcList.map(x => x.id)} key={selectedDetail.identifier + 'change-status'}
                                    auditId={selectedAudit.id} onChangeNcStatus={(ncIds: number[], status: string, comments: string) => {
                                        const newFilteredNcList = filteredNcList.map(x => {
                                            if (ncIds.includes(x.id)) {
                                                return {
                                                    ...x,
                                                    status,
                                                    comments
                                                };
                                            }
                                            return x;
                                        });
                                        ncList.forEach(x => {
                                            if (ncIds.includes(x.id)) {
                                                x.status = status;
                                                x.comments = comments;
                                            }
                                        });
                                        setFilteredNcList(newFilteredNcList);
                                    }} />
                            </div> : null}
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <div className="pera_text">
                        <h2>{selectedDetail.title}</h2>
                        <ReactMarkdown linkTarget={'_blank'} children={selectedDetail.description?.replaceAll('\t', ' ') || ''} />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="panel-group" id="accordion">
                        {
                            filteredNcList.map((ncItem, idx) => {
                                const fe = ncItem.failingElement;
                                const feDb = hasDb ? ncItem : null;
                                let screenshot: any = null;
                                let rect: any = null;
                                if (selectedAudit.src == "lh") {
                                    screenshot = screenshotDetails?.screenshot;
                                    rect = screenshotDetails?.nodes[fe.id];
                                } else if (selectedAudit.src == "andi") {
                                    screenshot = andiScreenshotDetails?.screenshot;
                                    rect = andiScreenshotDetails?.nodes[fe.id];
                                }
                                const screenshotElement = rect == null ? null : ElementScreenshotRenderer.render(dom, screenshot, rect, maxThumbnailSize);
                                /*let src = null;
                                if (fe.altFailureData && url) {
                                src = htmlToElement(fe.snippet).src;
                                const directoryPath = window.location.href.substring(0, window.location.href.lastIndexOf("/")+1);
                                src = src.includes(directoryPath) ? src.split(directoryPath)[1] : src;
                                if (!src.startsWith('//') && !src.startsWith('http://') && !src.startsWith('https://')) {
                                    src = url.endsWith('/') ? url + src : url + '/' + src;
                                }
                                }*/

                                let statusClass = 'un_resolved';
                                if (feDb?.status == 'UNRESOLVED') {
                                    statusClass = 'un_resolved';
                                } else if (feDb?.status == 'RESOLVED') {
                                    statusClass = 'text_Resolved';
                                } else if (feDb?.status == 'FIX IN PROGRESS') {
                                    statusClass = 'fix_in_progress';
                                }
                                const age = feDb ? new Date().getTime() - new Date(feDb.created).getTime() : null;
                                const stale = age && staleSettingValue ? age > (86400000 * parseInt(staleSettingValue)) : null;
                                const created = feDb?.created ? moment(feDb.created).tz('Asia/Kolkata') : null;
                                return (
                                    <div key={feDb?.id + ',' + fe.id} className="panel panel-default faq-sub">
                                        <div className="panel-heading">
                                            <h4 className="panel-title" style={{ marginLeft: 15 }}>
                                                <a href='#' className="accordion-toggle" data-toggle="collapse"
                                                    onClick={(e) => {
                                                        $(e.target).toggleClass("collapsed");
                                                        $(e.target).parent().closest('div').next().toggleClass('in');
                                                        e.preventDefault();
                                                    }}
                                                    aria-expanded="false">
                                                    <p className="issue-title"><strong>{fe.label}</strong></p>
                                                    {feDb ?
                                                        <div className="issue-highlight">
                                                            <span className={statusClass}>{feDb.status}</span>
                                                            <img src="/assetsv2/images/notification.png" style={{
                                                                visibility: stale ? 'visible' : 'hidden'
                                                            }} />
                                                        </div> : null}
                                                </a>
                                            </h4>
                                            <p><em>{fe.explanation}</em></p>
                                        </div>
                                        <div id="collapseOne" className="panel-collapse collapse">
                                            <div className="panel-body">
                                                {created ? <p className="last_save" style={{ width: '100%' }}>First Seen: {created?.format('MMMM Do YYYY')}</p> : null}
                                                {fe.testData ? (
                                                    <>
                                                        <h3><strong>Current Constrast Ratio <span className="span_red">{fe.testData.contrast.ratio}</span></strong></h3>
                                                        <div className="row">
                                                            <div className="col-lg-3 col-md-3 col-sm-6">
                                                                <div className="contrast-ratio-widget first">
                                                                    <div className="box-black">
                                                                        <div data-container-id={selectedAudit.src == "lh" ? "failing-elements" : "failing-elements"} className='lh-node' ref={(nodeElement) => { nodeElement && (nodeElement.firstChild ? nodeElement.removeChild(nodeElement.firstChild) : true) && screenshotElement != null && nodeElement.appendChild(screenshotElement) }} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-3 col-md-3 col-sm-6">
                                                                <div className="contrast-ratio-widget">
                                                                    <span className="suggest-thumb">Current<br /> <strong>Foreground</strong></span>
                                                                    <div className="h2">{rgbToHex(fe.testData.fgColor.rgba)}</div>
                                                                    <span className="span_box" style={{ backgroundColor: rgbToHex(fe.testData.fgColor.rgba) }}></span>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-3 col-md-3 col-sm-6">
                                                                <div className="contrast-ratio-widget">
                                                                    <span className="suggest-thumb">Current<br /> <strong>Background</strong></span>
                                                                    <div className="h2">{rgbToHex(fe.testData.bgColor.rgba)}</div>
                                                                    <span className="span_box" style={{ backgroundColor: rgbToHex(fe.testData.bgColor.rgba) }}></span>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-3 col-md-3 col-sm-6">
                                                                <div className="contrast-ratio-widget blue">
                                                                    <span className="suggest-thumb">Suggested<br /> <strong>Background</strong></span>
                                                                    <div className="h2 black"><strong>{rgbToHex(fe.testData.suggestedColor.rgba)}</strong> <img src="/assetsv2/images/thumb.png" aria-hidden="true" className="pull-right suggest-thumb" /></div>
                                                                    <span className="span_box" style={{ backgroundColor: rgbToHex(fe.testData.suggestedColor.rgba) }}></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>) :
                                                    <>
                                                        <div data-container-id={selectedAudit.src == "lh" ? "failing-elements" : "failing-elements"} className='lh-node' ref={(nodeElement) => { nodeElement && (nodeElement.firstChild ? nodeElement.removeChild(nodeElement.firstChild) : true) && screenshotElement != null && nodeElement.appendChild(screenshotElement) }} />
                                                    </>}

                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="boxgrey mrgn_set">
                                                            <p>{decodeURISafe(fe.snippet)}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr />
                                                {feDb ?
                                                    <ChangeStatus nonCompliance={feDb} key={feDb.id + feDb.status + feDb.comments} onChangeNcStatus={(nonCompliance: NonComplianceModel) => {
                                                        const newFilteredNcList = filteredNcList.map(x => {
                                                            if (nonCompliance.id == x.id) {
                                                                return {
                                                                    ...x,
                                                                    status: nonCompliance.status,
                                                                    comments: nonCompliance.comments,
                                                                };
                                                            }
                                                            return x;
                                                        });
                                                        ncList.forEach(x => {
                                                            if (nonCompliance.id == x.id) {
                                                                x.status = nonCompliance.status;
                                                                x.comments = nonCompliance.comments;
                                                            }
                                                        });
                                                        setFilteredNcList(newFilteredNcList);
                                                    }} /> : null}
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                    </div>
                </div>
            </div>
        </DetailsPopup>
    )
}

const ViewTestResult: React.FC = () => {
    const { testRunId, activeTab = 'overview' }: any = useParams();
    const { pathname } = useLocation();
    const history = useHistory();
    const [progress, setProgress] = useState<number>(0);
    const [isLoading, setIsLoading] = useState(false);
    const [attemptCount, setAttemptCount] = useState<number>(0);
    const [data, setData] = useState<TestRunModel>();
    const [dataError, setDataError] = useState<DataError>();
    const [failuresCount, setFailuresCount] = useState<FailuresCount[]>();
    const [categories, setCategories] = useState<string[]>();
    const [testReportingDetails, setTestReportingDetails] = useState<TestReportingDetails>();
    const [reportType, setReportType] = useState<string>("IssueWise");
    const [downloading, setDownloading] = useState(false)
    const [tags, setTags] = useState<string[]>();
    const [hideCriteriaFromReportValue, setHideCriteriaFromReportValue] = useState<string>();
    const isMobile = useSelector<RootState>(({ auth }) => auth.isMobile, shallowEqual)
    const urlPrefix = getUrlPrefix(isMobile, pathname.startsWith('/v2') ? '/v2' : undefined);
    let timer: any = null;

    useEffect(() => {
        setIsLoading(true);
        Promise.all([getTestRun(parseInt(testRunId)), getFailuresList(parseInt(testRunId)), getCategories(), getTags(), getTestReportingDetails(), getSetting('hideCriteriaFromReport')]).then(([result, failuresList, categories, tags, details, setting]) => {
            setFailuresCount(failuresList.data);
            setCategories(categories);
            setTags(tags);
            setTestReportingDetails(details);
            setHideCriteriaFromReportValue(setting?.value || '');
            const data = result.data;
            setData(data);
            setDataError(undefined);
            setProgress(getProgress(data.created, getRemainingCnt(data), data.testInstances?.length || 1));
            if (timer) {
                clearTimeout(timer);
            }
        }).catch(e => setDataError({ error: "Server Error! Cannot fetch data!" }))
            .finally(() => setIsLoading(false));
    }, []);


    useEffect(() => {
        timer = setTimeout(() => {
            if (data == null) {
                return false;
            }
            setProgress(getProgress(data.created, getRemainingCnt(data), data.testInstances?.length || 1));
            return data.status != "COMPLETE" && data.status != "PARTIALLY COMPLETE" && data.status != "FAILURE" && fetchData(testRunId, attemptCount, setData, setDataError, setFailuresCount, setAttemptCount);
        }, 10000);
        return () => clearTimeout(timer);
    });

    if (isLoading) {
        return (
            <div>
                <br />
                <strong>
                    <span>Loading... Please wait... </span> <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </strong>
            </div>
        );
    }

    if (dataError || data == null || !data.website) {
        return (
            <div className='card mb-5 mb-xl-10'>
                <div
                    className='card-header border-0'
                >
                    {dataError || !data?.website ? (
                        <div>
                            <br />
                            <div className='mb-lg-15 alert alert-danger d-flex flex-column-fluid flex-center'>
                                <div className='alert-text font-weight-bold'>{dataError?.error || 'Cannot load the page'}</div>
                            </div>
                        </div>
                    ) : (
                        <div>
                            <br />
                            <span>Loading... </span> <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </div>
                    )}
                </div>
            </div>
        );
    }

    const onClickDownload = () => {
        setDownloading(true);
        getDownloadXlsReportUrl(testRunId).then((response) => {
            const href = URL.createObjectURL(response.data);
            const link = document.createElement('a');
            link.href = href;
            const fileName = response.headers["content-disposition"]?.split("filename=")[1];
            if (fileName) {
                link.setAttribute('download', response.headers["content-disposition"].split("filename=")[1]);
            }
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
            setDownloading(false);
        }).catch(() => {
            setDownloading(false);
            console.error('Server Error! Cannot download the report');
        });
    }

    const created = data.created ? moment(data.created).tz('Asia/Kolkata') : null;
    const completed = data.stats && data.stats.completedTime ? moment(data.stats.completedTime).tz('Asia/Kolkata') : null;
    const cntPages = data.stats && data.stats.cntPages ? data.stats.cntPages : data.testInstances?.length;
    const cntPagesComplete = data.testInstances ? data.testInstances.filter(x => x.status == 'COMPLETE').length : 0;
    let content = null;
    let showTabs = true;
    if (data.status == 'FAILURE') {
        showTabs = false;
        content = (<div>
            Test run has failed due to an error. Please try running it again.
        </div>)
    } else if (data.status == 'PENDING') {
        showTabs = false;
        content = (<div>
            <div className="text-center">
                <img src="/assetsv2/images/report.PNG" alt="report" />
                <p className="h2">The test run is in the queue<br /> for processing, please wait</p>
            </div>
        </div>)
    } else if (data.status == 'RUNNING') {
        showTabs = false;
        content = (<div>
            <div className="text-center">
                <img src="/assetsv2/images/report.PNG" alt="report" />
                <p className="h2">We are in process of generating<br /> reports, please wait</p>
            </div>
        </div>)
    } else if (activeTab == 'overview') {
        content = <TestOverview stats={data.stats} />
    } else if (activeTab == 'historicaloverview') {
        content = <HistoricalDashboard testRunId={data.id} />
    } else if (activeTab == 'failureslist') {
        content = <FailuresList failuresCount={failuresCount || []} testInstances={data.testInstances} testReportingDetails={testReportingDetails || {}} tags={tags || []}
            categories={categories || []} />
    } else if (activeTab == 'warningslist') {
        content = <WarningsList stats={data.stats} testInstances={data.testInstances} testReportingDetails={testReportingDetails || {}} tags={tags || []}
            categories={categories || []} />
    } else if (activeTab == 'mediainventory') {
        content = <MediaInventory testInstances={data.testInstances} />
    } else if (activeTab == 'brokenlinks') {
        content = <BrokenLinksReport websiteId={data.website.id} />
    } else if (activeTab == 'fullreport') {
        content = <FullReport testInstances={data.testInstances} testReportingDetails={testReportingDetails || {}} tags={tags || []}
            categories={categories || []} hideAria={data.wcagVersion.includes('without ARIA')} />
    } else if (activeTab == 'consolidatedreport') {
        content = <ConsolidatedReport failuresCount={failuresCount || []} testRunId={data.id} totalPageCnt={cntPagesComplete} testReportingDetails={testReportingDetails || {}} tags={tags || []}
            categories={categories || []} hideAria={data.wcagVersion.includes('without ARIA')} />
    } else if (activeTab == 'report') {
        content = (
            <>
                <div id="full_report" className="tab-content">

                    <div className="Report_FilterBtn_Sec">
                        <form action="#" className="filter-issues-wise-outer">
                            <label>Showing:</label>
                            <div className="select-issue-wise">
                                <input type="radio" id="IssueWise" name="radio-group-showing" value="IssueWise"
                                    checked={reportType == 'IssueWise'} onChange={e => setReportType(e.target.value)} />
                                <label htmlFor="IssueWise">Issue Wise NCs</label>

                                <input type="radio" id="PageWise" name="radio-group-showing" value="PageWise"
                                    checked={reportType == 'PageWise'} onChange={e => setReportType(e.target.value)} />
                                <label htmlFor="PageWise">Page Wise NCs</label>
                            </div>
                        </form>
                    </div>
                    {reportType == 'IssueWise' ?
                        <Report failuresCount={failuresCount || []} testRunId={data.id} totalPageCnt={cntPagesComplete} testReportingDetails={testReportingDetails || {}} tags={tags || []}
                            categories={categories || []} hideAria={data.wcagVersion.includes('without ARIA')} /> : null}
                    {reportType == 'PageWise' ?
                        <FullReport testInstances={data.testInstances} testReportingDetails={testReportingDetails || {}} tags={tags || []}
                            categories={categories || []} hideAria={data.wcagVersion.includes('without ARIA')} />
                        : null}
                </div>
            </>
        )
    } else {
        content = <TestOverview stats={data.stats} />;
    }

    let defaultBreadcrumbs: BreadcrumbData[];
    //@ts-ignore
    if (history.location.state && history.location.state.previousBreadcrumbs) {
        //@ts-ignore
        defaultBreadcrumbs = history.location.state.previousBreadcrumbs.concat([{ text: 'Test Report' }]);
    }
    else {
        defaultBreadcrumbs = [
            {
                href: `${urlPrefix}/core/website/manage`,
                text: 'Manage Websites'
            }
        ];
        if (data?.website?.id) {
            defaultBreadcrumbs.push({
                href: `${urlPrefix}/core/website/${data.website.id}/testruns`,
                text: 'View Test Runs'
            });
        }
        defaultBreadcrumbs.push({ text: 'Test Report' });
    }

    return (
        <div className='test-runsfor reports'>
            <Breadcrumbs data={defaultBreadcrumbs} />
            <div className="site-head">
                <h1 className="main-title">
                    {cleanString(data.website.name)}
                    <br />
                    <a href={data.website.url} target='_blank'
                        onClick={(e) => !window.confirm('You are being redirected to an external website. Please note that SugamyaWeb cannot be held responsible for external websites content & privacy policies.') && e.preventDefault()}
                        className="report-title-link" style={{ marginLeft: 0 }}>{decodeURISafe(data.website.url)}</a>
                </h1>
            </div>
            <div className="row">
                <div className="site-details-widgt site-details-widgt1 equal-height">
                    <div className="col-md-4">
                        <div className="project-list">
                            <ul className="fail-warn">
                                <li><span className="red">{data.stats && data.stats.issueCntByTest?.length}</span> <strong>Failures</strong></li>
                                {hideCriteriaFromReportValue?.includes('warnings') ? null : 
                                <li><span className="green">{data.stats && data.stats.cntTotalWarns && data.stats.cntPages && Math.round(data.stats.cntTotalWarns / data.stats.cntPages)}</span> <strong>Manual Tests</strong></li>}
                            </ul>
                            <div className={`circlechart ${getScoreClass(data.score || 0)}`} data-percentage={data.score}>
                                <svg className="circle-chart" viewBox="0 0 33.83098862 33.83098862">
                                    <circle className="circle-chart__background" cx="16.9" cy="16.9" r="15.9"></circle>
                                    <circle className={`circle-chart__circle ${getScoreClass(data.score || 0)}`} strokeDasharray={`${data.score},100`} cx="16.9" cy="16.9" r="15.9"></circle>
                                    <g className="circle-chart__info">
                                        <text className="circle-chart__percent" x="17.5" y="15.5">{data.score}</text>
                                        <text className="circle-chart__subline" x="16.91549431" y="22">Score</text>
                                    </g>
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-8">
                        <div className="project-list">
                            <ul className="site-info-detls">
                                <li><span>Status :</span> <strong>{data.status}</strong></li>
                                <li><span>Total Issue :</span> <strong className="red">{data.cntTotalIssues}</strong></li>
                                <li><span>Test Name :</span> {data.name}</li>
                                <li><span>Test Mode :</span> {_.startCase(data.testMode)}</li>
                                <li><span>WCAG Version:</span> {data.wcagVersion}</li>
                                <li><span>WCAG Level:</span> {data.wcagLevel}</li>
                                <li><span>Initiated Time:</span> {created?.format('MMMM Do YYYY, h:mm:ss a')}</li>
                                <li><span>Completed Time:</span> {completed?.format('MMMM Do YYYY, h:mm:ss a')}</li>
                                <li><span>Page Count:</span> {data.status != 'COMPLETE' && cntPages ? `${cntPagesComplete} / ` : ''} {cntPages}</li>
                                {data.stats?.cntTests ? <li><span>Test Count:</span> {data.stats?.cntTests}</li> : null}
                                {data.stats?.cntTotalIssues ? <li><span>Total Issues:</span> {data.stats?.cntTotalIssues}</li> : null}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="site-details-widgt">
                {
                    (data?.status == 'RUNNING') ? (
                        <div className="progressbar-cntr">
                            <div className="report-generating">
                                <img src="/assetsv2/images/report.PNG" alt="report generating" aria-hidden="true" />
                                <span>Generating <br />Report</span>
                            </div>
                            <div className="progress">
                                <div className="progress-bar progress-bar-info" role="progressbar" aria-valuenow={progress}
                                    aria-valuemin={0} aria-valuemax={100} style={{ width: progress + '%' }}>
                                </div>
                            </div>
                            <div className="progressbar-text">
                                <span>Progress</span>
                                <span className="percentage">{progress}%</span>
                            </div>
                        </div>
                    ) : null}

                {(data?.status == 'COMPLETE' || data?.status == 'PARTIALLY COMPLETE') ? (
                    downloading ? (
                        <div className='btn btn-blue disabled'>
                            <span className='indicator-progress' style={{ display: 'block' }}>
                                Downloading... Please wait...{' '}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        </div>
                    ) : (
                        <a title='Download Test Report' target='_blank' onClick={onClickDownload}
                            className="btn btn-blue">Download Report <em className="fa fa-file-text-o" aria-hidden="true"></em></a>
                    )
                ) : null}
            </div>
            <div className="tabs">
                {showTabs ?
                    <ul id="tabs-nav" key={activeTab}>
                        <li className={classnames({
                            active: activeTab == 'overview'
                        })}><a href='#' onClick={e => { history.push(`${urlPrefix}/core/test/${testRunId}/overview`, { previousBreadcrumbs: defaultBreadcrumbs.slice(0, -1) });e.preventDefault();}}>Test Overview</a></li>
                        <li className={classnames({
                            active: activeTab == 'report'
                        })}><a href='#' onClick={e => { history.push(`${urlPrefix}/core/test/${testRunId}/report`, { previousBreadcrumbs: defaultBreadcrumbs.slice(0, -1) });e.preventDefault();}}>Report</a></li>
                        <li className={classnames({
                            active: activeTab == 'failureslist'
                        })}><a href='#' onClick={e => { history.push(`${urlPrefix}/core/test/${testRunId}/failureslist`, { previousBreadcrumbs: defaultBreadcrumbs.slice(0, -1) });e.preventDefault();}}>Failures</a></li>
                        {hideCriteriaFromReportValue?.includes('warnings') ? null : 
                        <li className={classnames({
                            active: activeTab == 'warningslist'
                        })}><a href='#' onClick={e => { history.push(`${urlPrefix}/core/test/${testRunId}/warningslist`, { previousBreadcrumbs: defaultBreadcrumbs.slice(0, -1) });e.preventDefault();}}>Manual Tests</a></li>}
                        <li className={classnames({
                            active: activeTab == 'mediainventory'
                        })}><a href='#' onClick={e => { history.push(`${urlPrefix}/core/test/${testRunId}/mediainventory`, { previousBreadcrumbs: defaultBreadcrumbs.slice(0, -1) });e.preventDefault();}}>Media Inventory</a></li>
                        <li className={classnames({
                            active: activeTab == 'brokenlinks'
                        })}><a href='#' onClick={e => { history.push(`${urlPrefix}/core/test/${testRunId}/brokenlinks`, { previousBreadcrumbs: defaultBreadcrumbs.slice(0, -1) });e.preventDefault();}}>Broken Links</a></li>
                    </ul> : null}
                <div id="tabs-content" className="project-list">
                    {content}
                </div>
            </div>
        </div>
    )
}

export { ViewTestResult }
