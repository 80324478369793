/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import {KTSVG} from '../../../../_monic/helpers'
import {WebsiteDashboardNc} from '../../auth/models/TestRunModel';
import {getOutstandingNonCompliances} from '../../auth/redux/TestRunCRUD';
import {Link} from 'react-router-dom';
import _ from 'lodash';
import { useSelector, shallowEqual } from 'react-redux';
import { RootState } from '../../../../setup';
import { UserModel } from '../../auth/models/UserModel';
import { Fetcher } from '../../reports/components/OverviewTab';
import { NCStatsWidget } from '../../../../_monic/partials/widgets/tables/NCStatsWidget';

const ManageNonCompliance: React.FC = () => {
  const [data, setData] = useState<Array<WebsiteDashboardNc>>();
  const [dataError, setDataError] = useState<{error: string}>();
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const testMode = 'single_page', wcagVersion = 'WCAG 2.1', wcagLevel = 'Level A', disableExperimental = false;
  const organizationId = user.gamyata.role == 'SUPERADMIN' ? "-1" : undefined;
  const userId = user.gamyata.role == 'TESTER' ? undefined : "-1";

  const fetchData = (testMode?: string, wcagVersion?: string, wcagLevel?: string, organizationId?: string) => {
    getOutstandingNonCompliances({
      testMode,
      wcagVersion,
      wcagLevel,
      organizationId,
    }).then((result) => setData(result.data)).catch(e => setDataError({error: "Server Error! Cannot fetch data!"}));
  }

  useEffect(() => {
    fetchData();
  }, []);

  if (dataError || data == null) {
    return (
      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          {dataError ? (
            <div>
              <br />
                <div className='mb-lg-15 alert alert-danger d-flex flex-column-fluid flex-center'>
                  <div className='alert-text font-weight-bold'>{dataError.error}</div>
                </div>
                </div>
          ) : (
            <div>
              <br />
              <span>Loading... </span> <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
           </div>
        )}
        </div>
      </div>
      );
  }

  return (
    <div className={`card`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>Manage Non Compliance</span>
        </h3>        
      </div>
      {/* end::Header */}
      <div className='card-body py-3'>
      <Fetcher wcagLevel={wcagLevel} wcagVersion={wcagVersion} testMode={testMode} 
        organization={organizationId} filterButtonText='Filter' filterButtonOnClick={fetchData} />
        
      <NCStatsWidget className='card-xxl-stretch mb-5 mb-xl-8' ncList={data} showBaseUrlCol={true} />
      </div>      
    </div>
  )
}

export {ManageNonCompliance}
