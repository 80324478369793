import React, {useEffect, useState} from 'react';
import {Redirect, useLocation} from 'react-router-dom';
import * as Yup from 'yup';
import clsx from 'clsx';
import {useFormik} from 'formik';
import {runTest} from '../../auth/redux/TestRunCRUD';
import {compareURLs, FORM_STATUSES, getUrlPrefix, useQuery} from '../../utils';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DateTimePicker from '@mui/lab/DateTimePicker';
import { LocalizationProvider } from '@mui/lab';
import { UserModel } from '../../auth/models/UserModel';
import { viewWebsite } from '../../auth/redux/OrganizationCRUD';
import { WebsiteModel } from '../../auth/models/OrganizationModel';
import { useSelector, shallowEqual } from 'react-redux';
import { RootState } from '../../../../setup';

const runTestSchema = Yup.object().shape({
  url: Yup.lazy(value => {
    if (/((http([s]){0,1}:\/\/){0,1}(localhost|127.0.0.1){1}(([:]){0,1}[\0-9]{4}){0,1}\/{0,1}){1}/.test(value)) {
      return Yup.string().required('URL is required');
    }
    return Yup.string().required().url('Invalid URL format');
  }),
  name: Yup.string().required('Name is required'),
  testMode: Yup.string()
  .required('testMode is required'),
  repeatFrequencyMs: Yup.number().typeError('Invalid frequency. Enter number only')
  .required('Please enter valid repeat frequency')
  .test("repeatFrequencyMsTest", "Please enter valid repeat frequency", function (value) {
    return this.parent.repeatFrequencyType == 'once' || (value != null && value > 0);
  }),
  wcagVersion: Yup.string()
  .required('wcagVersion is required'),
  wcagLevel: Yup.string()
  .required('wcagLevel is required'),
  disableExperimental: Yup.bool().required('Invalid disable experimental flag'),
  startDate: Yup.date().typeError('Invalid date. Click on the calendar button to set the date')
  .test('validateStartDate', 'Select start date in the future', function (obj) {
    return this.parent.startType == 'now' || (obj != null && obj?.getTime() > new Date().getTime());
  }),
});

const RunTest: React.FC = () => {
  const location = useLocation();
  const isSchedule = location.pathname.endsWith('/schedule');
  const queryParams: URLSearchParams = useQuery();
  const url = decodeURIComponent(queryParams.get('url') || '');
  const testMode = decodeURIComponent(queryParams.get('testMode') || 'single_page');
  const wcagVersion = decodeURIComponent(queryParams.get('wcagVersion') || '');
  const wcagLevel = decodeURIComponent(queryParams.get('wcagLevel') || '');
  const [loading, setLoading] = useState(false)
  const [websiteData, setWebsiteData] = useState<Record<string, WebsiteModel>>({});
  const [dataError, setDataError] = useState<DataError>();
  const initialValues = { testMode, name: '', repeatFrequencyType: isSchedule ? 'every' : 'once', startType: 'now', repeatFrequencyMs: isSchedule ? 1 : -1, wcagVersion, wcagLevel, disableExperimental: true, startDate: new Date()};

  const formik = useFormik<any>({
    initialValues,
    validationSchema: runTestSchema,
    onSubmit: (values, {setStatus}) => {
      setLoading(true);
      const repeatFrequencyMs = values.repeatFrequencyMs == -1 ? -1 : values.repeatFrequencyMs * 24 * 60 * 60 * 1000; // 1 day
      const startTimeEpoch = values.startType == 'now' ? new Date().getTime() : values.startDate.getTime();
      const testSettings = {testMode: values.testMode, wcagVersion: values.wcagVersion, wcagLevel: values.wcagLevel, disableExperimental: JSON.parse(values.disableExperimental)};
      runTest(values.name, values.url, testSettings, websiteData[values.url].id).then(({data: {id}}) => {
        setLoading(false);
        setStatus({state: FORM_STATUSES.SUCCESS, response: {id}});
      }).catch((e) => {
        setLoading(false);
        const errorData = e?.response?.data;
        const lbErrorCode = errorData?.error?.code;
        const errorMsg = lbErrorCode == "ER_UNREACHABLE_URL" ? errorData?.error?.message : 'Server Error! Cannot run the test';
        setStatus({state: FORM_STATUSES.FAILURE, response: {error: errorMsg}});
      });
    },
  });
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel;
  useEffect(() => {
    formik.setValues(initialValues);
    formik.setTouched({}, false);
    viewWebsite().then((result) => {
      const websiteByUrl: Record<string, WebsiteModel> = {};
      if (result.data) {
        for (const w of result.data) {
          websiteByUrl[w.url] = w;
        }
      }
      setWebsiteData(websiteByUrl);
    }).catch(e => setDataError({error: "Server Error! Cannot fetch website data!"}));
  }, [isSchedule]);
  const isMobile = useSelector<RootState>(({auth}) => auth.isMobile, shallowEqual)
  const urlPrefix = getUrlPrefix(isMobile);

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0'>
        <div className='card-title m-0'>
          <h2 className='fw-bolder m-0'>{isSchedule ? 'Add Standard Test' : 'Run Test'}</h2>
        </div>
      </div>

      {formik.status && formik.status.state == FORM_STATUSES.SUCCESS ? (
        <Redirect to={{...location, pathname: urlPrefix + `/activities/testmgmt/testresult/${formik.status.response?.id}`}}  />
      ) : null }

      {formik.status && formik.status.state == FORM_STATUSES.FAILURE ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status.response?.error}</div>
        </div>
      ) : null}

      
      <div id='kt_account_profile_details' className='collapse show'>
      
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>
          {/* {isSuperAdmin ? (
            <div className='row mb-6'>
              <label htmlFor='tb_url' className='col-lg-4 col-form-label required fw-bold fs-6'>Enter URL</label>
              <div className='col-lg-8 fv-row'>
                <input
                  id='tb_url'
                  type='text'
                  placeholder='https://example.com'
                  autoComplete='off'
                  {...formik.getFieldProps('url')}
                  className={clsx(
                    'form-control form-control-lg form-control-solid',
                    {
                      'is-invalid': formik.touched.url && formik.errors.url,
                    },
                    {
                      'is-valid': formik.touched.url && !formik.errors.url,
                    }
                  )}
                />
                {formik.touched.url && formik.errors.url && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.url}</div>
                  </div>
                )}
              </div>
            </div>
            ) : null} */}
             
              <div className='row mb-6'>
              <label htmlFor='tb_url' className='col-lg-4 col-form-label required fw-bold fs-6'>Enter URL</label>
              <div className='col-lg-8 fv-row'>
                <select 
                  id='s_website'
                  className='form-select form-select-solid form-select-lg'
                  {...formik.getFieldProps('url')}
                >
                  <option value="">Select Url</option>
                  {Object.values(websiteData)?.map(website => (
                    <option value={website.url} selected={compareURLs(website.url, url)}>{website.url}</option>
                  ))}
                </select>
                {formik.touched.url && formik.errors.url && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.url}</div>
                  </div>
                )}
              </div>
            </div>
            
            <div className='row mb-6'>
                  <label htmlFor='tb_name' className='col-lg-4 col-form-label required fw-bold fs-6'>Test Name for reference</label>
                  <div className='col-lg-8 fv-row'>
                    <input
                      type='text'
                      id='tb_name'
                      autoComplete='off'
                      {...formik.getFieldProps('name')}
                      className={clsx(
                        'form-control form-control-lg form-control-solid',
                        {
                          'is-invalid': formik.touched.name && formik.errors.name,
                        },
                        {
                          'is-valid': formik.touched.name && !formik.errors.name,
                        }
                      )}
                    />
                    {formik.touched.name && formik.errors.name && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.name}</div>
                      </div>
                    )}
                  
                </div>
                    
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Testing Mode</label>
                <div className='col-lg-7 fv-row mt-6 ms-3'>
                  <input
                    type='radio'
                    className='form-check-input'
                    {...formik.getFieldProps('testMode')}
                    value='single_page'
                    id='cb_single_page'
                    defaultChecked={true}
                  />
                  <label htmlFor='cb_single_page'>
                    <span className='text-gray-800 fw-bold mx-3'>Single webpage</span>
                  </label>
                </div>
                <span className='col-lg-4'></span>
                <div className='col-lg-7 fv-row mt-2 ms-3'>
                  <input
                    type='radio'
                    className='form-check-input'
                    {...formik.getFieldProps('testMode')}
                    value='full_site'
                    id='cb_entire_website'
                  />
                  <label htmlFor='cb_entire_website'>
                    <span className='text-gray-800 fw-bold mx-3'>Entire Website (all pages under this top level URL)</span>
                  </label>
                </div>
              </div>

            {isSchedule ? 
            <>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Repeat</label>
                <div className='col-lg-7 fv-row mt-2 ms-3'>
                  {/* <input
                    type='radio'
                    className='form-check-input'
                    {...formik.getFieldProps('repeatFrequencyType')}
                    value='once'
                    id='cb_once'
                    defaultChecked={!isSchedule}
                    onClick={()=>{formik.values.repeatFrequencyMs = -1}}
                  />
                  <label htmlFor='cb_once'>
                    <span className='text-gray-800 fw-bold mx-3'>Once</span>
                  </label>
                  <input
                    type='radio'
                    className='form-check-input'
                    {...formik.getFieldProps('repeatFrequencyType')}
                    value='every'
                    defaultChecked={isSchedule}
                    id='cb_every'
                  />
                  <label htmlFor='cb_every'>
                    <span className='text-gray-800 fw-bold mx-3'>Every</span>
                  </label> */}
                  <span className='text-gray-800 fw-bold me-3'>Every</span>
                  <input
                    type='text'
                    placeholder='20'
                    autoComplete='off'
                    {...formik.getFieldProps('repeatFrequencyMs')}
                    readOnly={formik.values.repeatFrequencyType != 'every'}
                    style={{display: 'inline', width: 'auto', marginTop: -2, padding: 1}}
                    className='form-control form-control-solid'
                  />
                  <span>
                  &nbsp;&nbsp;days
                  </span>
                  {formik.touched.repeatFrequencyMs && formik.errors.repeatFrequencyMs && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.repeatFrequencyMs}</div>
                    </div>
                  )}
              </div>
              </div>
            </>
            : null 
            /*<div className='row mb-6'>
            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Start At</label>
            <div className='col-lg-8 fv-row mt-2'>
            <span className='my-3'>
              <input
                type='radio'
                className='form-check-input'
                {...formik.getFieldProps('startType')}
                value='now'
                id='cb_now'
                defaultChecked={true}
                onClick={()=>{formik.values.startType = 'now'}}
              />
              <label htmlFor='cb_now'>
                <div className='text-gray-800 fw-bold mx-3'>Now</div>
              </label>
            </span>
            <span className='my-3'>
              <input
                type='radio'
                className='form-check-input'
                {...formik.getFieldProps('startType')}
                value='startAt'
                id='cb_startAt'
                onClick={()=>{formik.values.startType = 'startAt'}}
              />
              <label htmlFor='cb_startAt'>
                <div className='text-gray-800 fw-bold mx-3 '>Start At Specific Time</div>
              </label>
            </span>
            {formik.values.startType == 'now' ? null : (
              <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                  renderInput={(props: any) => <TextField variant="filled" sx={{ backgroundColor: "rgba(245, 248, 250, 1) !important", borderColor: "#F5F8FA !important", color: "#5E6278 !important", '& .MuiFilledInput-root': { backgroundColor: "rgba(245, 248, 250, 1) !important", borderColor: "#F5F8FA !important", color: "#5E6278 !important" } }} {...props} />}
                  value={formik.values.startDate}
                  inputFormat="dd/MM/yyyy hh:mm a"
                  InputProps={{
                    disableUnderline: true
                  }}
                  onChange={(newValue: any) => {
                    formik.setFieldValue('startDate', newValue);
                  }}
                />
                </LocalizationProvider>
            )}
              {formik.errors.startDate && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{formik.errors.startDate}</div>
              </div>
              )}
              </div>
        </div>
              */}
            </div>

              <div className='row mb-6'>
                <label htmlFor='select_compliance' className='col-lg-4 col-form-label required fw-bold fs-6'>Compliance</label>
                {formik.touched.compilance && formik.errors.compilance && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.compilance}</div>
                  </div>
                )}
                <div className='col-lg-8 fv-row'>
                <select
                  id='select_compliance'
                  className='form-select form-select-solid form-select-lg fs-7'
                  {...formik.getFieldProps('wcagVersion')}
                >
                    <option value="">Select WCAG Version</option>
                    <option value='WCAG 2.0 without ARIA'>WCAG 2.0 without ARIA</option>
                    <option value='WCAG 2.1 without ARIA'>WCAG 2.1 without ARIA</option>
                    <option value='WCAG 2.0'>WCAG 2.0</option>
                    <option value='WCAG 2.1'>WCAG 2.1</option>
                  </select>
                {formik.touched.wcagVersion && formik.errors.wcagVersion && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.wcagVersion}</div>
                  </div>
                )}
                </div>
              </div>
              <div className='row mb-6'>  
              <label className='col-lg-4 col-form-label required fw-bold fs-6' htmlFor='select_level'>WCAG Level</label>
                <div className='col-lg-8 fv-row mt-2'>
                <select
                  id='select_level'
                  className='form-select form-select-solid form-select-lg fs-7'
                  {...formik.getFieldProps('wcagLevel')}
                >
                   <option value="">Select WCAG Level</option>
                    <option value='Level A'>Level A</option>
                    <option value='Level AA'>Level AA</option>
                    <option value='Level AAA'>Level AAA</option>
                  </select>
                  {formik.touched.wcagLevel && formik.errors.wcagLevel && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.wcagLevel}</div>
                  </div>
                  )}
                </div>
              </div>
                {/* <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6' htmlFor='select_disableExperimental'>Disable Experimental Tests</label>
                  <div className='col-lg-7 fv-row mt-2'>
                  <select
                    id='select_disableExperimental'
                    className='form-select form-select-solid form-select-lg fs-7'
                    {...formik.getFieldProps('disableExperimental')}
                  >
                      <option value='true'>Yes</option>
                      <option value='false'>No</option>
                    </select>
                    {formik.touched.disableExperimental && formik.errors.disableExperimental && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.disableExperimental}</div>
                    </div>
                    )}
                  </div>
              </div> */}
          </div>

          <div className='card-footer d-flex justify-content-end py-6'>
              {loading ? (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              ) : (
                <button type='submit' title={isSchedule ? 'Save Test' : 'Run Test'} className='btn btn-primary'>
                  {isSchedule ? 'Save Test' : 'Run Test'}
                </button>
              )}
            
          </div>
        </form>
      </div>
    </div>
  )
}

export {RunTest}
