import { FC } from "react";
import { Link } from "react-router-dom";
import { Settings } from "../../utils/config";
import { LandingFooter } from "./Footer";
import { BreadcrumbTitle, LandingHeader } from "./Header";

export const UsingGamyataPage: FC = () => {
    return (
        <div className="main-body-content">
            <LandingHeader />
            <div id="main-content">
                <BreadcrumbTitle>USING {Settings.GamyataLabel.toUpperCase()}</BreadcrumbTitle>

                {Settings.isGamyata ? null : 
                <section className="short-desp">
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12">
                                <p className="text-center">Indian Government entities at all levels, Central, State, District
                                    who own and manage one or more websites can nominate
                                    an Accessibility Compliance Nodal Officer to sign up for using {Settings.GamyataLabel}. Details of the
                                    nominated official can be sent by
                                    email to <span>support-sugamya[at]nic[dot]in</span></p>
                            </div>
                        </div>
                    </div>
                </section>}

                <section className="accessibility-dashboard site-stats roles">
                    <div className="container">
                        <div className="row">
                            <h2 className="text-center">ROLES</h2>
                            <p className="text-center short-desc">There are 2 user roles in any Government entity –
                                <strong>{Settings.AdminLabel}</strong> and <strong>{Settings.TesterLabel}.</strong>
                            </p>
                            <div className="col-md-6 col-xs-12">
                                <h3>{Settings.AdminLabel.toUpperCase()}</h3>
                                <p className="heading4">{Settings.AdminLabel} have full access over the tests run in the
                                    organization. They can allow the QA Team members access to fix issues for specific sites
                                    within the organization.</p>
                            </div>
                            <div className="col-md-6 col-xs-12">
                                <h3>{Settings.TesterLabel.toUpperCase()}</h3>
                                <p className="heading4">{Settings.TesterLabel} have limited visibility and can access only the test they
                                    started. They are
                                    responsible for fixing any
                                    accessibility issues identified by {Settings.GamyataLabel}.</p>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="features-section">
                    <div className="container">
                        <div className="row">
                            <h2 className="text-center">RUNNING ACCESSIBILITY TESTS</h2>
                            <div className="equal-height">
                                <div className="col-md-3 col-xs-12">
                                    <div className="runing-access-widget">
                                        <h3 className="text-white">SET TEST MODES</h3>
                                        <p className="heading4">There are 2 test modes – Single Page or Full Site. For a given
                                            URL,
                                            that page alone can be tested or the entire website
                                            with all the pages under that domain can be tested</p>
                                    </div>
                                </div>
                                <div className="col-md-3 col-xs-12">
                                    <div className="runing-access-widget">
                                        <h3 className="text-white">SET GUIDELINES</h3>
                                        <span className="heading4">Tests can be configured to run against a specific
                                            <strong> WCAG</strong> <span><a rel="noreferrer noopener"
                                                href="https://www.w3.org/WAI/standards-guidelines/wcag/#versions"
                                                data-type="URL"
                                                data-id="https://www.w3.org/WAI/standards-guidelines/wcag/#versions"
                                                onClick={(e) => !window.confirm('You are being redirected to an external website. Please note that SugamyaWeb cannot be held responsible for external websites content & privacy policies.') && e.preventDefault()}
                                                target="_blank">Guideline</a></span> (<strong>v2.0 or v2.1</strong>) and <span><a
                                                rel="noreferrer noopener"
                                                href="https://www.w3.org/WAI/WCAG21/Understanding/conformance#levels"
                                                onClick={(e) => !window.confirm('You are being redirected to an external website. Please note that SugamyaWeb cannot be held responsible for external websites content & privacy policies.') && e.preventDefault()}
                                                target="_blank">Level</a></span> (<strong>A or AA or AAA)</strong>
                                        </span>
                                    </div>
                                </div>
                                <div className="col-md-3 col-xs-12">
                                    <div className="runing-access-widget">
                                        <h3 className="text-white">SCHEDULE TEST</h3>
                                        <p className="heading4">Tests can be scheduled to run once or at a pre-set interval
                                            e.g.,
                                            days, weeks, months</p>
                                    </div>
                                </div>
                                <div className="col-md-3 col-xs-12">
                                    <div className="runing-access-widget">
                                        <h3 className="text-white">RUN TEST</h3>
                                        <p className="heading4">Tests can be triggered to start immediately or at a specific
                                            date-time</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="tpweb-accessibility genesis report-gen">
                    <div className="container">
                        <div className="row">
                            <h2 className="text-center">REPORT GENERATION</h2>
                            <div className="col-md-6 col-xs-12">
                                <h3>TEST REPORT</h3>
                                <ul>
                                    <li>High level <strong>Overview</strong> of the test results with stats</li>
                                    <li>Test <strong>Failures</strong> and <strong>Warnings</strong></li>
                                    <li>Tracking <strong>Media Inventory</strong> such as images, audio, video, documents,
                                        pdf, fonts etc. Intelligently captures and classifies the images as inline images,
                                        decorative, buttons, background etc</li>
                                    <li>Detailed <strong>Test Results</strong></li>
                                </ul>
                            </div>
                            <div className="col-md-6 col-xs-12">
                                <h3>TEST SCORE</h3>
                                <p>The scoring model is based on the impact, number of failures for each test.</p>
                                <ul>
                                    <li>0 to 40 means <strong>critical</strong></li>
                                    <li>41 to 90 means needs <strong>improvement</strong></li>
                                    <li>91 to 100 means <strong>good</strong></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="tpweb-accessibility genesis understand-report">
                    <div className="container">
                        <div className="row">
                            <h2 className="text-center">UNDERSTANDING THE REPORT</h2>
                            <div className="col-xs-12">
                                <h3 className="text-center">TEST STATUS</h3>
                                <table className="report-table">
                                    <thead>
                                        <tr>
                                            <th scope="col">Test status</th>
                                            <th scope="col">Result of each test criteria evaluated on the page</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Pass</td>
                                            <td>Page has passed the test criteria</td>
                                        </tr>
                                        <tr>
                                            <td>Fail</td>
                                            <td>Page has failed the test criteria</td>
                                        </tr>
                                        <tr>
                                            <td>Warn</td>
                                            <td>Accessibility issues could exist but need further investigation</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="col-xs-12" style={{marginTop: 20}}>
                                <h3 className="text-center">TEST IMPACT</h3>
                                <table className="report-table">
                                    <thead>
                                        <tr>
                                            <th scope="col">Impact</th>
                                            <th scope="col">Problem</th>
                                            <th scope="col">Experience</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Critical</td>
                                            <td>Accessibility Defect</td>
                                            <td>Core content and features may not be available</td>
                                        </tr>
                                        <tr>
                                            <td>Serious</td>
                                            <td>Accessibility Barrier</td>
                                            <td>Screen reader inconsistencies. Page content may not be communicated
                                                consistently to all users.</td>
                                        </tr>
                                        <tr>
                                            <td>Moderate</td>
                                            <td>Accessibility Hindrance</td>
                                            <td>Causes difficulty to access the content but still available</td>
                                        </tr>
                                        <tr>
                                            <td>Minor</td>
                                            <td>Accessibility Frustration</td>
                                            <td>Causes nuisance and annoying to users</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="tpweb-accessibility genesis action-frm-rprt">
                    <div className="container">
                        <div className="row">
                            <h2 className="text-center">ACTIONS FROM THE REPORT</h2>
                            <div className="col-md-6 col-xs-12">
                                <h3>FAILURE DETAILS</h3>
                                <p><strong>Issue Count:</strong><br /> Number of elements in the page causing the test
                                    criteria to fail</p>
                                <p><strong>Snippet:</strong><br /> Code block that causes the failure</p>
                                <p><strong>Screenshot:</strong><br /> Snapshot of the page with markings around the element
                                    where failure may have occurred</p>
                                <p><strong>Fix recommendation:</strong><br /> Instructions on how to fix the issue</p>
                            </div>
                            <div className="col-md-6 col-xs-12">
                                <h3>NEXT STEPS</h3>
                                <p><strong>Triaging:</strong><br /> Add notes on how the issue will be prioritized to be fixed
                                </p>
                                <p><strong>Tracking:</strong><br /> Track the status of the issue as <strong>Unresolved, Fix
                                        in
                                        progress</strong> or <strong>Resolved</strong></p>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="features-section insight-dashboard">
                    <div className="container">
                        <div className="row">
                            <h2 className="text-center">INSIGHTS AND DASHBOARDS</h2>
                            <div className="col-md-6 col-xs-12">
                                <div className="features-widget">
                                    <div className="feature-desc">
                                        <h3>HISTORICAL VIEW</h3>
                                        <p>This dashboard shows a historical overview of the website and
                                            the issues.</p>
                                        <ul>
                                            <li>Statistics and Trends over time</li>
                                            <li>Lifecycle of the issue</li>
                                            <li>Tracking the fix progress</li>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                            <div className="col-md-6 col-xs-12">
                                <div className="features-widget">
                                    <div className="feature-desc">
                                        <h3>HISTORICAL RUNS</h3>
                                        <p>Historical test reports are available for the websites for detailed tracking over
                                            time. This can be used to identify how
                                            the issues and the progress towards accessibility readiness have trended over
                                            time</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="dia-together">
                    <h2 className="heading1 text-center">LET’S MAKE DIGITAL INDIA ACCESSIBLE TOGETHER.</h2>
                    <div className="text-center">
                        <Link className="btn-default big gamyata-login" to="/login" title="Login">
                            <span className="fa fa-lock"></span> LOGIN
                        </Link>
                    </div>
                </section>
            </div>

            <LandingFooter />
        </div>
    );
};