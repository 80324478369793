import axios from 'axios'
import {SettingsModel} from '../models/SettingsModel'
import {API_URL} from './constants'

const SETTINGS_URL = `${API_URL}/settings`
const UPDATE_SETTINGS_URL = `${API_URL}/patch_settings`;

export function updateSettings(settingsList: Array<SettingsModel>) {
  return axios.post(UPDATE_SETTINGS_URL, settingsList);
}

export function getSettings(identifier?: string) {
  return axios.get<Array<SettingsModel>>(SETTINGS_URL, {params: {filter: {where: {identifier}}}});
}

const settingsCache: Record<string, SettingsModel> = {};

export async function getSetting(name: string) {
  if (settingsCache[name]) {
    return settingsCache[name];
  }
  try {
    const response = await getSettings(name);
    settingsCache[name] = response.data[0];
    return settingsCache[name];
  } catch(e) {
    return null;
  }
}
