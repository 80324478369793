import { FC } from "react";
import { Link, useLocation } from "react-router-dom";
import { checkIsActive } from "../../../_monic/helpers";
import { useSelector, shallowEqual } from "react-redux";
import { RootState } from "../../../setup";
import { UserModel } from "../../modules/auth/models/UserModel";

interface Props {
    className: string
    children: string
    to: string
    allowedUsers?: string[],
    backgroundUrl?: string,
    externalLink?: boolean
}

const MenuItem: React.FC<Props> = ({ className, children, to, allowedUsers, backgroundUrl, externalLink = false }) => {
    const {pathname} = useLocation();
    const isActive = checkIsActive(pathname, to);
    const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

    if (allowedUsers && !allowedUsers.includes(user.gamyata?.role)) {
        return null;
    }

    const bgUrl = isActive && backgroundUrl ? backgroundUrl?.split('.svg')[0] + '-color.svg' : backgroundUrl;
    const style: any = bgUrl ? {backgroundImage: `url('${bgUrl}')`} : undefined;
    return (
        <li className={`dropdown ${isActive ? 'current-item' : ''}`}>
            {externalLink ? 
            <a className={className} href={to} data-target="main-content" data-toggle="collapse" style={style}
               target="_blank"
               onClick={(e) => !window.confirm('You are being redirected to an external website. Please note that SugamyaWeb cannot be held responsible for external websites content & privacy policies.') && e.preventDefault()}>
                {children}
            </a> : 
            <Link className={className} to={to} data-target="main-content" data-toggle="collapse" style={style}>
                {children}
            </Link>}
        </li>
    )
};

export const Sidebar: FC<{ aside?: boolean }> = ({ aside }) => {
    const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

    return (
        <aside className="sidebar">
            <div className="scroll-pane">
                <nav className="sidebar-menu">
                    <ul>
                        <MenuItem className="dashboard" to="/v2/dashboard">
                            Dashboard
                        </MenuItem>
                    </ul>
                    <h2>Activity</h2>
                    <ul>
                        <MenuItem className="manage-site" to="/v2/core/website/manage">
                            Manage Websites
                        </MenuItem>
                        <MenuItem className="test-log" to="/v2/activities/testmgmt/schedules-by-website">
                            Manage Test
                        </MenuItem>
                        <MenuItem className="heatmap" to="/v2/activities/reports/mywebsites/heatmap" allowedUsers={['SUPERADMIN', 'ADMIN']}>
                            Heatmap
                        </MenuItem>
                        <MenuItem className="manage-developer" backgroundUrl="/assetsv2/images/chrome-extension.svg" to="https://chrome.google.com/webstore/detail/sugamyaweb/dpmbmbopkfmepmmhjibeioidmoifohip" externalLink={true}>
                            Download Browser Extension
                        </MenuItem>
                    </ul>
                    {user.gamyata?.role == 'TESTER' ? null : <>
                    <h2>Core</h2>
                    <ul>
                        <MenuItem className="view-porganization" to="/v2/core/org/manage" allowedUsers={['SUPERADMIN']}>
                            View Organization
                        </MenuItem>
                        <MenuItem className="manage-developer" backgroundUrl="/assetsv2/images/Manage Super Admins.svg" to="/v2/core/users/superadmin" allowedUsers={['SUPERADMIN']}>
                            Manage Super Admins
                        </MenuItem>
                        <MenuItem className="manage-developer" backgroundUrl="/assetsv2/images/Manage Onboarding.svg" to="/v2/core/onboarding/manage" allowedUsers={['SUPERADMIN']}>
                            Manage Onboarding
                        </MenuItem>
                        <MenuItem className="manage-developer" backgroundUrl="/assetsv2/images/Manage Super Admins.svg" to="/v2/core/users/admin" allowedUsers={['SUPERADMIN', 'ADMIN']}>
                            Manage Nodal Officers
                        </MenuItem>
                        <MenuItem className="manage-developer" backgroundUrl="/assetsv2/images/Manage Super Admins.svg" to="/v2/core/users/tester" allowedUsers={['SUPERADMIN', 'ADMIN']}>
                            Manage QA Testers
                        </MenuItem>
                        <MenuItem className="manage-developer" backgroundUrl="/assetsv2/images/Manage API Key.svg" to="/v2/core/apikeys/view" allowedUsers={['SUPERADMIN']}>
                            Manage API Keys
                        </MenuItem>
                        <MenuItem className="manage-developer" backgroundUrl="/assetsv2/images/View Logs.svg" to="/v2/core/activitylogs" allowedUsers={['SUPERADMIN']}>
                            View Logs
                        </MenuItem>
                        <MenuItem className="manage-developer" backgroundUrl="/assetsv2/images/settings.svg" to="/v2/core/settings" allowedUsers={['SUPERADMIN']}>
                            Settings
                        </MenuItem>
                    </ul>
                    </>}
                </nav>
            </div>
        </aside>
    );
}
