/* eslint-disable jsx-a11y/anchor-is-valid */
import _ from 'lodash'
import React, {FC} from 'react'
import {useIntl} from 'react-intl'
import { useSelector, shallowEqual } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { RootState } from '../../../setup'
import {PageTitle} from '../../../_monic/layout/core'
import {
  MixedWidget2,
  MixedWidget10,
  MixedWidget11,
  ListsWidget2,
  ListsWidget3,
  ListsWidget4,
  ListsWidget5,
  ListsWidget6,
  TablesWidget5,
  TablesWidget10,
  MixedWidget8,
} from '../../../_monic/partials/widgets'
import { NotificationWidget } from '../../../_monic/partials/widgets/lists/NotificationWidget'
import { UserModel } from '../../modules/auth/models/UserModel'
import { AdminDashboardPage } from './AdminDashboardWrapper'
import { SuperAdminDashboardPage } from './SuperAdminDashboardWrapper'
import { TesterDashboardPage } from './TesterDashboardWrapper'

const WelcomePageTitle = () => {
  return <PageTitle></PageTitle>;
}

const DashboardWrapper: FC = () => {
  const {pathname}: any = useLocation();
  const role = pathname.split('/dashboard/')[1];
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const r = role || user.gamyata?.role;
  return (
    <>
      <WelcomePageTitle />
      {r?.toUpperCase() == 'TESTER' ? <TesterDashboardPage /> : null }
      {r?.toUpperCase() == 'ADMIN' ? <AdminDashboardPage /> : null }
      {r?.toUpperCase() == 'SUPERADMIN' ? <SuperAdminDashboardPage /> : null }
    </>
  )
}

export {DashboardWrapper, WelcomePageTitle}
