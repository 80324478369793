/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react'
import { TestInstanceModel } from '../../auth/models/TestRunModel';
import { TestResultTab } from './TestResultsTab';

type Props = {
  stats: any,
  tabUrlPrefix: string,
  testInstances: TestInstanceModel[] | undefined
  status: string
  tags: Array<string>
  categories: Array<string>
  testReportingDetails: TestReportingDetails
}

const WarningsList: React.FC<Props> = ({stats, tabUrlPrefix, testInstances, status, tags, categories, testReportingDetails}) => {
  const [selectedTest, setSelectedTest] = useState<string | null>(null);
  if (status != 'COMPLETE' && status != "PARTIALLY COMPLETE") {
    return (
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body pt-9 pb-0'>
        {status == 'FAILURE' ? <h3 className='fw-bolder'>Test failed due to server error</h3> : 
        <div> <span>The test is in progress... Please wait </span> <span className="spinner-border spinner-border-sm align-middle ms-2"></span></div>}
        <br />
        </div>
      </div>
    );
  }
  if (stats == null) {
    return null;
  }
  const {warningCntByTest, warnedPagesByTest} = stats;
  if (warningCntByTest == null) {
    return null;
  }
  if (selectedTest != null) {
    const instanceIds = warnedPagesByTest[selectedTest].map((x: any) => x.instanceId);
    testInstances = testInstances?.filter(i => instanceIds.includes(i.id));
    return (
    <div>
      <div className='my-5'>
        <button
              onClick={() => setSelectedTest(null)}
              type='button'
              className='btn btn-lg text-primary fw-bolder me-3'
              data-kt-stepper-action='previous'
            >
            {'<<'} Warnings List
            </button>
      </div>
      <TestResultTab testInstances={testInstances} status={status} selectedTest={selectedTest} 
       tags={tags} categories={categories} testReportingDetails={testReportingDetails}/>
    </div>
    );
  }
  return (
    <div className='flex-column flex-lg-row-auto w-100  mb-10 mb-lg-0'>
      <div className='row card card-flush' style={{
      marginLeft: -13,
      marginRight: -13,
    }}>
        <div className='card-body pt-5'>
          <div
          className='scroll-y me-n5 pe-5 h-200px h-lg-auto'
          id='warnings-list'
          >
          {
              warningCntByTest.map((x: any) => {            
              return (
                  <>
                  <div className='d-flex flex-stack py-4'>
                  <div className='d-flex align-items-center'>
                      <div className='ms-5'>
                      <span className='fs-5  text-gray-900 mb-2'>
                        <a href='#' className='fs-5 fw-bolder text-primary-900 text-hover-info mb-2' onClick={() => setSelectedTest(x.title)}>
                          {x.title}
                        </a>
                      </span>
                      </div>
                  </div>
                  
                  <div className='d-flex  align-items-end ms-2 pb-2'>
                  <span className='badge badge-sm badge-light-warning mx-2'>{warnedPagesByTest[x.title].length} Pages Affected</span>
                  </div>
                  </div>
                  <div className='separator separator-dashed'></div>
                  </>
              );
              })   
          }
          </div>
      </div>
      </div>
  </div>
  )
}

export {WarningsList}
