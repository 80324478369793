import {AxiosResponse} from "axios";
import {useEffect, useRef, useState} from "react";
import {StepperComponent} from "../../../../_monic/assets/ts/components";
import {NonComplianceModel, TestInstanceModel, TestInstanceAuditDetails} from "../../auth/models/TestRunModel";
import {getTestInstanceWithNC} from "../../auth/redux/TestRunCRUD";
import {ResultDetails} from "./ResultDetails";
import {PagesList} from "./PagesList";
import { decodeURISafe, useQuery } from "../../utils";

type Props = {
    testInstances: TestInstanceModel[] | undefined
    status: string
    showIssueCnt?: boolean
    selectedTest?: string
    tags: Array<string>,
    categories: Array<string>,
    testReportingDetails: TestReportingDetails,
}

const TestResultTab: React.FC<Props> = ({testInstances, status, showIssueCnt, selectedTest, tags, categories, testReportingDetails}) => {
    const [resultView, setResultView] = useState<number>(0);
    const [dataError, setDataError] = useState<DataError>();
    const [page, setPage] = useState<TestInstanceModel>();
    const [pageNo, setPageNo] = useState<number>(0);

    const stepperRef = useRef<HTMLDivElement | null>(null)
    const stepper = useRef<StepperComponent | null>(null)

    const queryParams: URLSearchParams = useQuery();
    const instanceId = decodeURIComponent(queryParams.get('instanceId') || ''); 

    const onPageUrlClick = (id: number) => {
        getTestInstanceWithNC(id).then((result: AxiosResponse) => setPage(result.data)).catch(e => setDataError({error: "Server Error! Cannot fetch data!"}));
        setPageNo(1);
        if (!stepper.current) {
            return;
        }
        stepper.current.goNext();
        return;
    }

    useEffect(() => {
        if (!stepperRef.current) {
        return
        }
        loadStepper()
        if (instanceId && instanceId.length > 0) {
            onPageUrlClick(parseInt(instanceId));
        }
    }, [stepperRef, instanceId])

    const loadStepper = () => {
        stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
    }

    if (status == "FAILURE") {
        return (
        <div className='card mb-5 mb-xl-10'>
          <div className='card-body pt-9 pb-0'>
            <h3 className='fw-bolder'>
            Test failed due to server error 
            </h3>
            <br />
          </div>
        </div>  
      );
    }
    
    if (dataError) {
        return (
            <div className='card mb-5 mb-xl-10'>
            <div
                className='card-header border-0 cursor-pointer'
                role='button'
                data-bs-toggle='collapse'
                data-bs-target='#kt_account_profile_details'
                aria-expanded='true'
                aria-controls='kt_account_profile_details'
            >
                {dataError ? (
                <div>
                    <br />
                    <div className='mb-lg-15 alert alert-danger d-flex flex-column-fluid flex-center'>
                        <div className='alert-text font-weight-bold'>{dataError.error}</div>
                    </div>
                    </div>
                ) : (
                <div>
                    <br />
                    <span>Loading... </span> <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </div>
            )}
            </div>
            </div>
            );
        }

    let screenshotDetails = null;
    let andiScreenshotDetails = null;

    if (page != null && (page.status == "COMPLETE" || page.status == "PARTIALLY COMPLETE")) {
        screenshotDetails = page.screenshots?.lh;
        andiScreenshotDetails = page.screenshots?.andi;
    }

    const compileAuditDetailsWithAllTests = (auditDetails: TestInstanceAuditDetails) => {
        const result: Array<AuditDetails> = auditDetails.auditDetails || [];
        const passedTests = auditDetails.passedTests || [];
        const warnedTests = auditDetails.warnedTests || [];
        return result.concat(passedTests.map(x => ({
            id: x,
            failingElements: [],
            status:	'Pass',
        })), warnedTests.map(x => ({
            id: x,
            failingElements: [],
            status:	'Warn',
        })));
    }
    const auditDetails = page?.auditDetails == null ? [] : compileAuditDetailsWithAllTests(page?.auditDetails);

    return (
        <div className="" style={{
            marginLeft: -13,
            marginRight: -13,
          }}>
        <div
        ref={stepperRef}
        className='stepper stepper-links d-flex flex-column'
        >
        <div className='stepper-nav bg-white w-100' style={{padding: 5}}>
            <div className='stepper-item current' data-kt-stepper-element='nav'>                
                {pageNo == 0? <h3 className='stepper-title'>Pages</h3> : null}
            </div>

            <div className='stepper-item' data-kt-stepper-element='nav'>
                {pageNo == 1 ? <h3 className='stepper-title'>Result details</h3> : null}
            </div>
        </div>
        <div className='mx-auto w-100 pb-10'>
                <div className='current' data-kt-stepper-element='content'>
                <PagesList onPageUrlClick={onPageUrlClick} testInstances={testInstances} showIssueCnt={showIssueCnt} />
                </div>

                <div data-kt-stepper-element='content'>
                <ResultDetails instanceId={page?.id} url={decodeURISafe(page?.url || '')}  status={page?.status} cntTotalIssues={page?.stats && page?.stats.cntTotalIssues} ncList={page?.nonCompliances || []}
                tags={tags} categories={categories} auditDetails={auditDetails} testReportingDetails={testReportingDetails} tab={resultView} 
                prevStep={() => {
                    stepper.current && stepper.current.goPrev();
                    setPageNo(0);
                }} setTab={setResultView} 
                screenshotDetails={screenshotDetails} andiScreenshotDetails={andiScreenshotDetails} selectedTest={selectedTest} />
                </div>
            </div>
        </div>
        </div>
    );
}

export {TestResultTab}