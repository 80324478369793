import React from 'react'
import {PageLink, PageTitle} from '../../../_monic/layout/core'
import {Redirect, Route, Switch} from 'react-router-dom'
import {AddWebsite} from './components/AddWebsite'
import {ManageWebsite} from './components/ManageWebsite'
import {EditWebsite} from './components/EditWebsite'
import { WelcomePageTitle } from '../../pages/dashboard/DashboardWrapper'
import { ManageWebpage } from './components/ManageWebpage'

const breadCrumbs: Array<PageLink> = [
  {
    title: 'Website',
    path: '/core/website/manage',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const OrganizationManagementPage: React.FC = () => {
  return (
    <>
    <Switch>
        <Route path='/core/website/add/:orgId'>
        <WelcomePageTitle />
        {/* <PageTitle breadcrumbs={breadCrumbs}>Add Website</PageTitle> */}
          <AddWebsite />
        </Route>
        <Route path='/core/website/add'>
        <WelcomePageTitle />
        {/* <PageTitle breadcrumbs={breadCrumbs}>Add Website</PageTitle> */}
          <AddWebsite />
        </Route>
        <Route path='/core/website/manage/:orgId'>
        <WelcomePageTitle />
        {/* <PageTitle breadcrumbs={breadCrumbs}>Manage Websites</PageTitle> */}
          <ManageWebsite className='mb-5 mb-xl-8' />
        </Route>
        <Route path='/core/website/manage'>
        <WelcomePageTitle />
        {/* <PageTitle breadcrumbs={breadCrumbs}>Manage Websites</PageTitle> */}
          <ManageWebsite className='mb-5 mb-xl-8' />
        </Route>
        <Route path='/core/website/edit/:id/:orgId'>
        <WelcomePageTitle />
        {/* <PageTitle breadcrumbs={breadCrumbs}>Edit Website</PageTitle> */}
          <EditWebsite/>
        </Route>
        <Route path='/core/website/edit/:id'>
        <WelcomePageTitle />
        {/* <PageTitle breadcrumbs={breadCrumbs}>Edit Website</PageTitle> */}
          <EditWebsite/>
        </Route>
        <Route path='/core/website/:websiteId/webpage/manage'>
        <WelcomePageTitle />
        {/* <PageTitle breadcrumbs={breadCrumbs}>Add Website</PageTitle> */}
          <ManageWebpage />
        </Route>
        <Redirect from='/core/website' exact={true} to='/core/website/manage' />
        <Redirect to='/core/website/manage' />
      </Switch>      
    </>
  )
}

export default OrganizationManagementPage
