import _ from "lodash";
import {useState} from "react";
import cronParser, { CronFields } from "cron-parser";
import { useSelector, shallowEqual } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { RootState } from "../../../../setup";
import {TestRunModel} from "../../auth/models/TestRunModel";
import { getUrlPrefix } from "../../utils";
import {getScoreColor} from './../../testmgmt/components/TestResult';
import { ICronExpression } from "cron-parser/types/common";

type Props = {
    testRuns: TestRunModel[] | undefined
    status?: string
    startTimeEpoch?: string
    repeatFrequencyMs?: number
    created?: any
    testSettings?: any
}

const TestRunsTab: React.FC<Props> = (props) => {
    const {search} = useLocation();
    const {testRuns, status, startTimeEpoch, repeatFrequencyMs, created, testSettings} = props;
    const [dataError, setDataError] = useState<DataError>();
    const isMobile = useSelector<RootState>(({auth}) => auth.isMobile, shallowEqual)
    const urlPrefix = getUrlPrefix(isMobile);

    if (status == 'ACTIVE' && startTimeEpoch == '-1' && testRuns?.length == 0) {
        return (
            <div>
              <span>Loading... </span> <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </div>
          );
    }
    
    if (dataError) {
        return (
            <div className='card mb-5 mb-xl-10'>
            <div
                className='card-header border-0 cursor-pointer'
                role='button'
                data-bs-toggle='collapse'
                data-bs-target='#kt_account_profile_details'
                aria-expanded='true'
                aria-controls='kt_account_profile_details'
            >
                {dataError ? (
                <div>
                    <br />
                    <div className='mb-lg-15 alert alert-danger d-flex flex-column-fluid flex-center'>
                        <div className='alert-text font-weight-bold'>{dataError.error}</div>
                    </div>
                    </div>
                ) : (
                <div>
                    <br />
                    <span>Loading... </span> <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </div>
            )}
            </div>
            </div>
            );
        }

    const nextScheduledRuns = [];
    if (repeatFrequencyMs && repeatFrequencyMs > 0 && created && testSettings) {
      // compute next 10 future test runs
      const createdDate = new Date(created || '');
      // const everyXDayCount = Math.round(repeatFrequencyMs / 86400000);
      // const options = {
      //   currentDate: new Date(),
      //   iterator: true,
      // };
      // const interval: ICronExpression<CronFields, true> = cronParser.parseExpression(`${createdDate.getSeconds()} ${createdDate.getMinutes()} ${createdDate.getHours()} */${everyXDayCount} * *`, options);
      let i = 1;
      while (i < 11) {
        //const scheduledTime = interval.next().value.toDate();
        const scheduledTime = new Date(createdDate.getTime() + (repeatFrequencyMs * i));
        nextScheduledRuns.push({
          ...testSettings,
          scheduledTime,
          status: "SCHEDULED",
        });
        i += 1;
      }
    }

    return (
        <div className='flex-column flex-lg-row-auto w-100  mb-10 mb-lg-0'>
      <div className='card card-flush'>

      <div className='card-body pt-5'>
          {/* begin::Table */}
          <table className='table table-full-bordered table-row-gray-100 align-middle gs-0 gy-3'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bolder'>
                <th scope='col' className='min-w-150px'>Test Mode</th>
                <th scope='col' className='min-w-150px'>WCAG Version</th>
                <th scope='col' className='min-w-150px'>WCAG Level</th>
                <th scope='col' className='min-w-150px'>Test Time</th>
                <th scope='col' className='min-w-100px text-end'>Status</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
            {testRuns?.map((testRun) => {
               let badgeClass = "badge-primary";
               if (testRun.status == "COMPLETE") {
                 badgeClass = "badge-success";
               } else if (testRun.status == "PARTIALLY COMPLETE") {
                 badgeClass = "badge-warning";
               } else if (testRun.status == "FAILURE") {
                 badgeClass = "badge-danger";
               }            
               // text-primary-900 text-hover-info
              return ( 
                       <tr key={testRun.id}>
                       <td>
                        <Link to={urlPrefix + `/activities/testmgmt/testresult/${testRun.id}/${search}`} className='fs-5 fw-bolder text-primary-900 text-hover-info mb-2'>
                            {_.startCase(testRun.testMode)}
                        </Link>
                       </td>
                       <td>
                        {testRun.wcagVersion}
                       </td>
                       <td>
                        {testRun.wcagLevel}
                       </td>
                       <td>
                        {new Date(testRun.created).toLocaleString('en-IN')}
                       </td>
                       <td className="text-end">
                       <Link to={urlPrefix + `/activities/testmgmt/testresult/${testRun.id}/${search}`} className='fs-5 fw-bolder text-primary-900 text-hover-info mb-2'>
                        {(testRun.status == "COMPLETE" || testRun.status == "PARTIALLY COMPLETE") && testRun.stats != null ? (<span className={`badge badge-sm badge-light-${getScoreColor(testRun.stats.score)} mx-2`}>Score: {testRun.stats.score}</span>): null}
                        {(testRun.status == "COMPLETE" || testRun.status == "PARTIALLY COMPLETE") && testRun.stats != null ? (<span className='badge badge-sm badge-light-danger mx-2'>{testRun.stats.cntTotalIssues} Issues</span>): null}
                  
                        <span className={"badge " + badgeClass}>{testRun.status}</span>
                        </Link>
                       </td>
                     </tr>
                     );
              })}
              {nextScheduledRuns.map(scheduledRun => {
                return ( 
                  <tr>
                  <td>
                    {_.startCase(scheduledRun.testMode)}
                  </td>
                  <td>
                    {scheduledRun.wcagVersion}
                  </td>
                  <td>
                    {scheduledRun.wcagLevel}
                  </td>
                  <td>
                    {new Date(scheduledRun.scheduledTime).toLocaleString('en-IN')}
                  </td>
                  <td className="text-end">
                   <span className={"badge badge-primary"}>{scheduledRun.status}</span>
                  </td>
                </tr>
                );
              })}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
      </div>
      </div>
  </div>
    );
}

export {TestRunsTab}