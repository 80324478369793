/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { RootState } from '../../../../setup'
import {KTSVG} from '../../../../_monic/helpers'
import {OrganizationModel} from '../../auth/models/OrganizationModel'
import {deleteOrganization, viewOrganization} from '../../auth/redux/OrganizationCRUD'
import { getUrlPrefix } from '../../utils'

type Props = {
  className: string
}

const ManageOrganization: React.FC<Props> = ({className}) => {
  const history = useHistory();
  const [data, setData] = useState<Array<OrganizationModel>>();
  const [dataError, setDataError] = useState<{error: string}>();

  useEffect(() => {
    viewOrganization().then((result) => setData(result.data)).catch(e => setDataError({error: "Server Error! Cannot fetch data!"}));
  }, []);

  const isMobile = useSelector<RootState>(({auth}) => auth.isMobile, shallowEqual)
  const urlPrefix = getUrlPrefix(isMobile);

  const onDelete = (id: number) => {
    if (!window.confirm('Are you sure want to delete?')) {
      return;
    }
    deleteOrganization(id).then(() => {
      setData(data?.filter(x => x.id != id));
    });
  }

  if (dataError || data == null) {
    return (
      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header border-0'
        >
          {dataError ? (
            <div>
              <br />
                <div className='mb-lg-15 alert alert-danger d-flex flex-column-fluid flex-center'>
                  <div className='alert-text font-weight-bold'>{dataError.error}</div>
                </div>
                </div>
          ) : (
            <div>
              <br />
              <span>Loading... </span> <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
           </div>
        )}
        </div>
      </div>
      );
  }

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>Organization</span>
        </h3>
        <div className='card-toolbar'>
          {/* begin::Menu */}
          <div className='card-toolbar'>
          <Link to={urlPrefix + '/core/org/create'} className='btn btn-sm btn-light-primary'>
            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
            Create
          </Link>
        </div>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-full-bordered table-row-gray-100 align-middle gs-0 gy-3'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bolder'>
                <th className='min-w-150px'>Organization Id</th>
                <th className='min-w-140px'>Organization Name</th>
                <th className='min-w-140px'>Average Score</th>
                <th className='min-w-100px text-end'>Actions</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {data?.map((row) => (
              <tr>
                <td>
                  <span className='text-primary-900 text-hover-info fs-6'>
                    {row.id}
                  </span>
                </td>
                <td>
                  <span className='text-primary-900 text-hover-info d-block mb-1 fs-6'>
                    {row.name}
                  </span>
                </td>
                <td>
                  <span className='text-primary-900 text-hover-info d-block mb-1 fs-6'>
                    {row.avgScore ? Math.round(row.avgScore) : ""}
                  </span>
                </td>
                <td className='text-end'>
                  <button
                    onClick={() => {
                      history.push(urlPrefix + `/core/website/manage/${row.id}`);
                    }}
                    type='button'
                    className='btn btn-sm btn-light-primary me-3'
                    data-kt-stepper-action='previous'
                  >
                    View Websites
                  </button>
                  <button
                    onClick={() => {
                      history.push(urlPrefix + `/core/users/manage/admin/${row.id}`);
                    }}
                    type='button'
                    className='btn btn-sm btn-light-primary me-3'
                    data-kt-stepper-action='previous'
                  >
                    View Nodal Officers
                  </button>
                  <Link
                    title='Edit'
                    to={urlPrefix + `/core/org/edit/${row.id}`}
                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                  >
                    <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                  </Link>
                  <Link title='Delete' to='#' onClick={() => onDelete(row.id)} className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'>
                    <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
                  </Link>
                </td>
              </tr>
              ))}
              </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export {ManageOrganization}
