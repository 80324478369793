import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {put, takeLatest} from 'redux-saga/effects'
import {UserModel} from '../models/UserModel'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  Login: '[Login] Action',
  Logout: '[Logout] Action',
  PreLogin: '[PreLogin] Action',
  UserRequested: '[Request User] Action',
  UserLoaded: '[Load User] Auth API',
  SetUser: '[Set User] Action',
}

const initialAuthState: IAuthState = {
  user: undefined,
  isMobile: undefined,
}

export interface IAuthState {
  user?: any
  isMobile?: boolean
  redirectTo?: string
}

export const reducer = persistReducer(
  {storage, key: 'gamyatha-auth', whitelist: ['user', 'isMobile', 'redirectTo']},
  (state: IAuthState = initialAuthState, action: ActionWithPayload<IAuthState>) => {
    switch (action.type) {
      case actionTypes.Login: {
        const user = action.payload?.user
        return {...state, user}
      }

      case actionTypes.PreLogin: {
        const isMobile = action.payload?.isMobile
        const redirectTo = action.payload?.redirectTo
        return {isMobile, redirectTo}
      }

      case actionTypes.Logout: {
        return {...initialAuthState}
      }

      case actionTypes.UserRequested: {
        return {...state, user: undefined}
      }

      case actionTypes.UserLoaded: {
        const user = action.payload?.user
        return {...state, user}
      }

      case actionTypes.SetUser: {
        const user = action.payload?.user
        return {...state, user}
      }

      default:
        return state
    }
  }
)

export const actions = {
  login: (user: any) => ({type: actionTypes.Login, payload: {user}}),
  preLogin: (isMobile: boolean, redirectTo?: string) => ({
    type: actionTypes.PreLogin,
    payload: {isMobile, redirectTo},
  }),
  logout: () => ({type: actionTypes.Logout}),
  requestUser: () => ({
    type: actionTypes.UserRequested,
  }),
  fulfillUser: (user: UserModel) => ({type: actionTypes.UserLoaded, payload: {user}}),
  setUser: (user: UserModel) => ({type: actionTypes.SetUser, payload: {user}}),
}

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    //yield put(actions.requestUser())
  })

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    //const {data: user} = yield getUserByToken()
    //yield put(actions.fulfillUser(user))
  })
}
