/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../helpers'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import { useSelector, shallowEqual } from 'react-redux'
import { UserModel } from '../../../../app/modules/auth/models/UserModel'
import { RootState } from '../../../../setup'
import { Settings } from '../../../../app/utils/config'

export function AsideMenuMain() {
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  return (
    <>

      <AsideMenuItem to='/v2/core/website/manage' skipReactRouter={true} title={'Try the new Beta UI'} icon='/media/icons/duotune/general/gen022.svg' />

      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title='Dashboard'
        fontIcon='bi-app-indicator'
      />

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Activities</span>
        </div>
      </div>

      
      {user.gamyata?.role == 'SUPERADMIN' || user.gamyata?.role == 'ADMIN' ? (
          <>
            {/* <AsideMenuItem to='/core/website/add' title={'Add Website'} icon='/media/icons/duotune/general/gen022.svg' /> */}
            <AsideMenuItem to='/core/website/manage' title={'Manage Websites'} icon='/media/icons/duotune/general/gen022.svg' />
          </>
        ) : null }
      
      <AsideMenuItem to='/activities/testmgmt/runtest' title='Run Test' icon='/media/icons/duotune/general/gen022.svg' />
      <AsideMenuItem to='/activities/testmgmt/runtest/schedule' title='Add Standard Test' icon='/media/icons/duotune/general/gen022.svg' />
      {/* <AsideMenuItem to='/activities/testmgmt/manageschedules' title='Manage Standard Test' icon='/media/icons/duotune/general/gen022.svg' /> */}
      <AsideMenuItem to='/activities/testmgmt/manageresults' title='View Test Runs' icon='/media/icons/duotune/general/gen022.svg' />
      {/* <AsideMenuItem to='/activities/testmgmt/managenoncompliance' title='Manage Non Compliance' icon='/media/icons/duotune/general/gen022.svg' /> */}
      <AsideMenuItem to='/activities/reports/mywebsites/heatmap' title='Websites Heatmap' icon='/media/icons/duotune/general/gen022.svg' />
      {/* <AsideMenuItem to='/activities/reports/mywebsites' title='Websites Dashboard' icon='/media/icons/duotune/general/gen022.svg' /> */}

      {user.gamyata?.role == 'SUPERADMIN' || user.gamyata?.role == 'ADMIN' ? (
        <>
        <div className='menu-item'>
          <div className='menu-content pt-8 pb-2'>
            <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Core</span>
          </div>
        </div>

        <AsideMenuItem to='/core/users/manage/admin' title={'Manage ' + Settings.AdminLabel} icon='/media/icons/duotune/general/gen022.svg' />
        <AsideMenuItem to='/core/users/manage/tester' title={'Manage ' + Settings.TesterLabel} icon='/media/icons/duotune/general/gen022.svg' />

        {user.gamyata?.role == 'SUPERADMIN' ? <>
        <AsideMenuItem to='/core/org/manage' title='View Organization' icon='/media/icons/duotune/general/gen022.svg' /> 
        <AsideMenuItem to='/core/users/onboarding' title='Manage Onboarding' icon='/media/icons/duotune/general/gen022.svg' />
        <AsideMenuItem to='/core/users/manage/superadmin' title='Manage Super Admin' icon='/media/icons/duotune/general/gen022.svg' />
        <AsideMenuItem to='/core/users/activitylogs' title={'Activity Logs'} icon='/media/icons/duotune/general/gen022.svg' />          
        <AsideMenuItem to='/core/settings' title={'Settings'} icon='/media/icons/duotune/general/gen022.svg' />          
        </> : null}
        
        </>
      ) : null}

      <AsideMenuItem to='/core/users/view' title={'View Profile'} icon='/media/icons/duotune/general/gen022.svg' />
      <AsideMenuItem to='https://chrome.google.com/webstore/detail/sugamyaweb/dpmbmbopkfmepmmhjibeioidmoifohip' title={'Download Browser Extension'} icon='/media/icons/duotune/general/gen022.svg' externalLink={true} />
    </>
  )
}
