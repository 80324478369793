/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import { getCSSVariableValue } from '../../../_monic/assets/ts/_utils'
import {PageTitle} from '../../../_monic/layout/core'
import {
  MixedWidget2,
  MixedWidget10,
  MixedWidget11,
  ListsWidget2,
  ListsWidget3,
  ListsWidget4,
  ListsWidget5,
  ListsWidget6,
  TablesWidget5,
  TablesWidget10,
  MixedWidget8,
} from '../../../_monic/partials/widgets'
import { ActivitiesWidget } from '../../../_monic/partials/widgets/lists/ActivitiesWidget'
import { LastNRuns } from '../../../_monic/partials/widgets/lists/LastNRuns'
import { NotificationWidgetTester } from '../../../_monic/partials/widgets/lists/NotificationWidget-Tester'
import { TodoWidget } from '../../../_monic/partials/widgets/lists/TodoWidget-Tester'
import { TrendsWidget } from '../../../_monic/partials/widgets/lists/TrendsWidget'
import { HistoricalStats, HistoricalStatsChartData } from '../../../_monic/partials/widgets/mixed/HistoricalStats'
import { IssueCountsSuperAdmin } from '../../../_monic/partials/widgets/mixed/IssueCountsSuperAdmin'
import { LineChart } from '../../../_monic/partials/widgets/mixed/LineChart'
import { OrgStatsSuperAdmin } from '../../../_monic/partials/widgets/mixed/OrgStatsSuperAdmin'
import { PieChart, PieChartData } from '../../../_monic/partials/widgets/mixed/PieChart'
import { TreeMapChart, TreeMapChartData } from '../../../_monic/partials/widgets/mixed/TreeMapChart'
import { getAccessibilityStatus, getBottom5Score, getIssueStats, getScoreTrendMonthly, getTop5Score, getWebsites } from '../../modules/auth/redux/TestRunCRUD'
import { getScoreColor } from '../../modules/testmgmt/components/TestResult'

export const SuperAdminDashboardPage: FC = () => {
  const [top5ScoreData, setTop5ScoreData] = useState<HistoricalStatsChartData>();
  const [bottom5ScoreData, setBottom5ScoreData] = useState<HistoricalStatsChartData>();
  const [scoreTrendMonthly, setScoreTrendMonthly] = useState<HistoricalStatsChartData>();
  const [accessibilityStatusData, setAccessibilityStatusData] = useState<PieChartData>();
  const [heatmapData, setHeatmapData] = useState<TreeMapChartData>();
  const [orgHeatmapData, setOrgHeatmapData] = useState<TreeMapChartData>();
  const [dataError, setDataError] = useState<{error: string}>();

  useEffect(() => {
    getTop5Score().then((result) => {
      if (!result.data) {
        return;
      }
      const res = result.data.map(x => ({baseUrl: x.url, score: x.latestScore || 0})).sort((a, b) => b.score - a.score);
      setTop5ScoreData({
        xAxis: res.map(x => x.baseUrl),
        yAxis: res.map(x => x.score),
        max: `Highest Score: ${res[0].score}`
      });
    }).catch(e => setDataError({error: "Server Error! Cannot fetch data!"}));
    getBottom5Score().then((result) => {
      if (!result.data) {
        return;
      }
      const res = result.data.map(x => ({baseUrl: x.url, score: x.latestScore || 0})).sort((a, b) => a.score - b.score);
      setBottom5ScoreData({
        xAxis: res.map(x => x.baseUrl),
        yAxis: res.map(x => x.score),
        max: `Lowest Score: ${res[0].score}`
      });
    }).catch(e => setDataError({error: "Server Error! Cannot fetch data!"}));
    getAccessibilityStatus().then((result) => {
      if (!result.data) {
        return;
      }
    setAccessibilityStatusData({
        xAxis: ['Compliant', 'Non Compliant', 'Not Tested'],
        yAxis: [result.data.compliant, result.data.nonCompliant, result.data.untested],
        colors: [getCSSVariableValue('--bs-success'), getCSSVariableValue('--bs-danger'), getCSSVariableValue('--bs-warning')],
        max: `Total: ${result.data.compliant + result.data.nonCompliant + result.data.untested}`
      });
    }).catch(e => setDataError({error: "Server Error! Cannot fetch data!"}));
    getScoreTrendMonthly().then((result) => {
      if (!result.data) {
        return;
      }
      const res = result.data.map(x => ({label: `${x.year}-${x.month}`, value: Math.round(x.avgScore)}));
      setScoreTrendMonthly({
        xAxis: res.map(x => x.label),
        yAxis: res.map(x => x.value),
        max: ''
      });
    }).catch(e => setDataError({error: "Server Error! Cannot fetch data!"}));
    getWebsites().then(result => {
      const websites = result.data.filter(w => w.latestScore != null);
      setHeatmapData({
        seriesData: websites.map(w => ({x: w.name, y: w.latestCntPages || 1, data: {org: w.organization?.name, url: w.url, score: w.latestScore}})),
        colors: websites.map(w => getCSSVariableValue('--bs-' + getScoreColor(w.latestScore || 0))),
        max: '',
      });
      const websitesByOrg: Record<number, any> = {};
      for (const w of websites) {
        const x = websitesByOrg[w.organizationId] || {websites: [], totalScore: 0, name: w.organization?.name};
        x.websites.push(w);
        x.totalScore += w.latestScore;
        websitesByOrg[w.organizationId] = x;
      }
      Object.values(websitesByOrg).forEach(w => {
        w.avgScore = w.totalScore / w.websites.length;
      });
      setOrgHeatmapData({
        seriesData: Object.values(websitesByOrg).map(w => ({x: w.name, y: Math.round(w.avgScore || 0), data: {org: w.name, websitesCnt: w.websites.length}})),
        colors: Object.values(websitesByOrg).map(w => getCSSVariableValue('--bs-' + getScoreColor(w.avgScore || 0))),
        max: '',
      });
    }).catch(e => setDataError({error: "Server Error! Cannot fetch data!"}));
  }, []);
  return (
  <>
    {/* begin::Row */}
    {/* <div className='row gy-5 g-xl-8'> */}
      
        {accessibilityStatusData ? <PieChart className='' chartHeight='250px' chartData={accessibilityStatusData} title='Accessibility Status' /> : null}
        {top5ScoreData ? <HistoricalStats className='' chartHeight='250px' chartData={top5ScoreData} title='Top 5 Accessibility Sites' yAxisName='score' /> : null}
        {bottom5ScoreData ? <HistoricalStats className='' chartHeight='250px' chartData={bottom5ScoreData}  title='Bottom 5 Accessibility Sites' yAxisName='score' /> : null}
        {scoreTrendMonthly ? <LineChart className='' chartHeight='250px' chartData={scoreTrendMonthly}  title='Monthly Accessibility Trend' /> : null}
        {heatmapData ? <TreeMapChart className='' chartHeight='250px' chartData={heatmapData} title='Website Accessibility Heatmap' /> : null}
        {orgHeatmapData ? <TreeMapChart className='' chartHeight='250px' chartData={orgHeatmapData} title='Org Accessibility Heatmap' /> : null}
      
    {/* </div> */}
    {/* end::Row */}
  </>
  )
};
