import React, { useEffect, useLayoutEffect, useState } from 'react'
import { PageDataProvider } from '../../../_monic/layout/core'
import { useQuery } from '../../modules/utils'
import { Header } from './Header'
import { Sidebar } from './Sidebar'
import styled from 'styled-components'
import classnames from 'classnames';
import { Dropdown } from 'react-bootstrap-v5'
import { Link, useHistory } from 'react-router-dom'
import $ from 'jquery';

const scriptUrls = [
  '/assetsv2/js/jquery.min.js',
  '/assetsv2/js/bootstrap.js',
  '/assetsv2/js/jquery.dataTables.min.js',
  '/assetsv2/js/dataTables.bootstrap.min.js',
  '/assetsv2/js/jquery.jscrollpane.min.js',
  '/assetsv2/js/jquery.mousewheel.js',
  '/assetsv2/js/custom.js',
];

const MasterLayout: React.FC<{ disableSidebar?: boolean }> = ({ children, disableSidebar }) => {
  const queryParams: URLSearchParams = useQuery();
  const history = useHistory();
  const utmSource = decodeURIComponent(queryParams.get('utm_source') || '');
  // TODO:
  const isExtensionView = utmSource == 'gamyata-chrome-ext';

  const [scriptsLoaded, setScriptsLoaded] = useState(false);

  useEffect(() => {
    const scripts: Node[] = [];

    const loadScript = (url: string) => {
      return new Promise((resolve: any) => {
        const script = document.createElement('script');
        script.src = url;
        script.onload = () => {
          scripts.push(script);
          resolve();
        };

        document.body.appendChild(script);
      });
    };

    const loadScripts = async () => {
      for (const url of scriptUrls) {
        await loadScript(url);
      }

      $(document).ready(function () {
        document.addEventListener("keyup", function (e) {
          if (e.keyCode === 9) {
            jQuery("body").addClass("show-focus-outlines");
          }
        });
      
        document.addEventListener("mousedown", function (e) {
          jQuery("body").removeClass("show-focus-outlines");
        });
      });

      setScriptsLoaded(true);
    };

    loadScripts();

    return () => {
      scripts.forEach((script) => {
        document.body.removeChild(script);
      });
    };
  }, []);

  useLayoutEffect(() => {
    // TODO: Remove this once v1 is deprecated
    $('link[title=v1Css]')?.remove();
  }, []);

  if (!scriptsLoaded) {
    return <div>Loading scripts...</div>;
  }

  const closeRightSidebar = () => $(".close-error-dtls-pop")[0]?.click();

  return (
    <PageDataProvider>
      <Header disableSidebar={disableSidebar} onClick={(e) => {
        if ($(e.target).closest('.header-section').siblings('.show-details-pop').length > 0) {
          closeRightSidebar();
        }
      }} />
      <div className='big-contain main-container-outer' onClick={(e) => {
        if ($(e.target)?.hasClass('show-details-pop')) {
          closeRightSidebar();
        }
      }}>
        <section className="main-container">
          <div className="container-fluid">
            <div className="row inner-container">
              {disableSidebar ? children : <>
                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 col-custom">
                  <Sidebar />
                </div>
                <div className="col-lg-9 col-md-9 col-sm-6 col-xs-12 col-content">
                  <div className="main-container-outer">
                    <div className="main-content">
                      {children}
                    </div>
                  </div>
                </div>
              </>}
            </div>
          </div>
        </section>
      </div>
    </PageDataProvider>
  )
}

const CustomToggle = React.forwardRef<any, any>(({ children, onClick, className }, ref) => (
  <a
    className={className}
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </a>
));

const DeleteDropdown: React.FC<{ onClick: any }> = ({ onClick }) => {
  return (
    <Dropdown alignRight={true}>
      <Dropdown.Toggle as={CustomToggle} className="dropdown-toggle more-link">
        <em className="fa fa-ellipsis-v" aria-hidden="true"></em>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item href="#" onClick={onClick}>
          <img src="/assetsv2/images/Failure.svg" alt="Delete" className="del-icon" />
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

const BadgedLi = styled.li`
    &:before {
        background-color: ${props => props.theme.backgroundColor} !important; 
    }
`;

const NcStatusColorMap: Record<string, string> = {
  'RESOLVED': '#32ba7c',
  'FIX IN PROGRESS': '#d88727',
  'UNRESOLVED': '#810404',
}

const NcTd = styled.td<{ ncStatus: string }>`
    &:before {
        background-color: ${props => NcStatusColorMap[props.ncStatus]} !important; 
    }
`;

const ErrorMsg = styled.div`
  margin-top: 0.3rem;

  .fv-help-block {
      color: red;
      font-size: 1.1rem;
      font-weight: 400;
  }

  &.valid-feedback,
  &.invalid-feedback {
      display: block;
      font-weight: 400;
  }
`;

const HelpLink = styled.a`
    &:link {
      color: aqua !important; 
    }
    &:hover {
        color: pink !important; 
    }
`;

const ImageIconLink = styled.a`
  background-repeat: no-repeat;
`;

const createIconComponent = (normalImage: string, activeImage: string, width: string, height: string) => styled(ImageIconLink)`
  &:link {
    background-image: url(${normalImage});
    width: ${width};
    height: ${height};
  }
  &:hover {
    background-image: url(${activeImage});
  }
`;

const ViewIconLink = createIconComponent('/assetsv2/images/view-icon-normal.svg', '/assetsv2/images/view-icon-active.svg', '32px', '32px');
const RunIconLink = createIconComponent('/assetsv2/images/run-icon-normal.svg', '/assetsv2/images/run-icon-active.svg', '32px', '32px');
const EditIconLink = createIconComponent('/assetsv2/images/edit-icon-big-normal.svg', '/assetsv2/images/edit-icon-big-active.svg', '32px', '32px');
const DeleteIconLink = createIconComponent('/assetsv2/images/delete-icon-big-normal.svg', '/assetsv2/images/delete-icon-big-active.svg', '32px', '32px');

const SortedTh: React.FC<{
  fieldName: string,
  sortColumn?: string, setSortColumn: React.Dispatch<React.SetStateAction<string | undefined>>,
  sortDirection?: string, setSortDirection: React.Dispatch<React.SetStateAction<string | undefined>>
}> =
  ({ children, fieldName, sortColumn, setSortColumn, sortDirection, setSortDirection }) => {
    const handleSort = (column: string) => {
      if (column === sortColumn) {
        // If the same column is clicked, toggle the sort direction
        setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
      } else {
        // If a different column is clicked, set the new sort column and default to ascending direction
        setSortColumn(column);
        setSortDirection('asc');
      }
    };
    return (
      <th scope="col"
        onClick={() => handleSort(fieldName)}
        className={classnames({
          sorting: sortColumn != fieldName,
          [`sorting_${sortColumn == fieldName ? sortDirection : ''}`]: sortColumn == fieldName,
        })}
      >
        {children}
      </th>
    )
  }

export type BreadcrumbData = {
  href?: string,
  text: string
}

const Breadcrumbs: React.FC<{ data: BreadcrumbData[] }> = ({ data }) => {
  return (
    <div className="bredcrumb-cnntr">
      <ul>
        <li><Link to="/v2/dashboard">Home</Link></li>
        {data.map(x => {
          return (
            <li>
              {x.href ?
                <Link to={x.href}>
                  {x.text}
                </Link> : x.text}
            </li>
          )})}
      </ul>
    </div>
  )
};

export { MasterLayout, BadgedLi, ErrorMsg, HelpLink, EditIconLink, DeleteIconLink, ViewIconLink, RunIconLink, DeleteDropdown, SortedTh, NcTd, Breadcrumbs }
